import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';

import {
    ApplicationStateService,
    selectSettingsState
} from '../application-state';

/**
 * Service that copes with determining and changing state of settings related to the
 * engine.
 */
@Injectable({
    providedIn: 'root'
})
export class EngineSettingsService {
    constructor(private applicationStateService: ApplicationStateService) {}

    /**
     * Emits the current VR enabled state.
     */
    @MemoizeObservable()
    getVrModeActiveState$(): Observable<boolean> {
        return this.getSettingsState$().pipe(
            map((settingsState) =>
                settingsState ? settingsState.vrEnabled : false
            ),
            distinctUntilChanged(),
            lazyShareReplay()
        );
    }

    /**
     * Emits the current VR available state.
     */
    @MemoizeObservable()
    getVrModeAvailableState$(): Observable<boolean> {
        return this.getSettingsState$().pipe(
            map((settingsState) =>
                settingsState ? settingsState.vrAvailable : false
            ),
            distinctUntilChanged(),
            lazyShareReplay()
        );
    }

    /**
     * Emits the current sound enabled state.
     */
    @MemoizeObservable()
    getSoundActiveState$(): Observable<boolean> {
        return this.getSettingsState$().pipe(
            map((settingsState) =>
                settingsState ? settingsState.soundEnabled : false
            ),
            distinctUntilChanged(),
            lazyShareReplay()
        );
    }

    /**
     * Emits the current pricing enabled state.
     */
    @MemoizeObservable()
    getPricingActiveState$(): Observable<boolean> {
        return this.getSettingsState$().pipe(
            map((settingsState) =>
                settingsState ? settingsState.pricingEnabled : false
            ),
            distinctUntilChanged(),
            lazyShareReplay()
        );
    }

    /**
     * Emits the current active language.
     */
    @MemoizeObservable()
    getActiveLanguage$(): Observable<string | undefined> {
        return this.getSettingsState$().pipe(
            map((settingsState) => settingsState?.activeLanguage || undefined),
            distinctUntilChanged(),
            lazyShareReplay()
        );
    }

    private getSettingsState$() {
        return this.applicationStateService
            .getState()
            .pipe(selectSettingsState);
    }
}
