import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';

import { UpdatableStrategyProvider } from '../strategy/strategy-provider';
import { PRODUCT_DATA_STRATEGY_PROVIDER_TOKEN } from './product-data-strategy.interface';
import { ProductDataService } from './product-data.service';

@NgModule({
    declarations: [],
    imports: [CommonModule]
})
export class ProductDataModule {
    static forRoot(config?: {
        productDataStrategyProviderDefinition?: Provider;
    }): ModuleWithProviders<ProductDataModule> {
        return {
            ngModule: ProductDataModule,
            providers: [
                ProductDataService,
                config?.productDataStrategyProviderDefinition ?? {
                    provide: PRODUCT_DATA_STRATEGY_PROVIDER_TOKEN,
                    useValue: new UpdatableStrategyProvider()
                }
            ]
        };
    }
}
