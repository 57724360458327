import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import {
    MSAL_GUARD_CONFIG,
    MsalGuard,
    MsalGuardConfiguration
} from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { UiNotificationService } from '@mhp/ui-components';

import { ROUTE_ERROR } from '../app-route-names';

/**
 * Guard being responsible for lazily fetching the dealer-module and delegating
 * to the MsalGuard for handling authentication.
 */
@Injectable()
export class DealerAuthGuard {
    constructor(
        private readonly router: Router,
        private readonly uiNotificationService: UiNotificationService,
        private readonly msalGuard: MsalGuard,
        @Inject(MSAL_GUARD_CONFIG)
        private readonly msalGuardConfig: MsalGuardConfiguration
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        return this.msalGuard.canActivate(route, state).pipe(
            map((result) => {
                if (
                    !result &&
                    this.msalGuardConfig.interactionType ===
                        InteractionType.Popup
                ) {
                    // only show error when we're staying local. In case we're redirecting the dealer to the login-page, do nothing here
                    this.uiNotificationService.showError(
                        'Missing valid authentication...'
                    ); // TODO: i18n
                    // redirect to error
                    return this.router.parseUrl(`/${ROUTE_ERROR}`);
                }
                return result;
            }),
            catchError((error) => {
                this.uiNotificationService.showError(
                    `Error while trying to authenticate: ${error.message}`
                ); // TODO: i18n
                // redirect to error
                return of(this.router.parseUrl(`/${ROUTE_ERROR}`));
            })
        );
    }
}
