import { DOCUMENT } from '@angular/common';
import {
    Inject,
    Injectable,
    InjectionToken,
    Renderer2,
    RendererFactory2
} from '@angular/core';

import { attachScriptToDocumentHead } from '../../helper/script-integration.helper';
import { GoogleTagManagerTrackingStrategy } from '../google-tag-manager-tracking-strategy.interface';
import { TrackingProperties } from '../tracking-properties.interface';

export interface GoogleTagManagerEnvironmentSpec {
    gtmAuth: string;
    gtmPreview: string;
    gtmCookiesWin: string;
}

export interface GoogleTagManagerDefaultTrackingStrategyConfig {
    trackingId: string;
    sendPageView?: boolean;
    sequenceProperties?: Set<string>;
    gtmEnvironmentSpec?: GoogleTagManagerEnvironmentSpec;
}

export const GOOGLE_TAG_MANAGER_DEFAULT_TRACKING_STRATEGY_CONFIG =
    new InjectionToken<GoogleTagManagerDefaultTrackingStrategyConfig>(
        'GOOGLE_TAG_MANAGER_DEFAULT_TRACKING_STRATEGY_CONFIG'
    );

declare const dataLayer;

/**
 * Strategy that tracks GA-events via regular tracking-script.
 *
 * See https://developers.google.com/tag-platform/tag-manager/web/datalayer
 */
@Injectable()
export class GoogleTagManagerDefaultTrackingStrategy
    implements GoogleTagManagerTrackingStrategy
{
    private globalProperties: TrackingProperties = {};

    private ga4DataLayer: TrackingProperties = {};

    private renderer: Renderer2;

    constructor(
        private readonly rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private readonly document: Document,
        @Inject(GOOGLE_TAG_MANAGER_DEFAULT_TRACKING_STRATEGY_CONFIG)
        private readonly config: GoogleTagManagerDefaultTrackingStrategyConfig
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
        this.config = { sendPageView: false, ...this.config };
        this.initialize();
    }

    setGlobalProperties(properties: TrackingProperties) {
        console.warn(
            'DEPRECATION WARNING: Use setGA4DataLayerVariables instead',
            properties
        );

        this.globalProperties = Object.assign(
            this.globalProperties,
            properties
        );
    }

    setGA4DataLayerVariables(properties: TrackingProperties) {
        const { sequenceProperties } = this.config;

        dataLayer.push(function () {
            Object.entries(properties).forEach(([key, value]) => {
                if (sequenceProperties?.has(key) && value) {
                    // treat as sequence value : concatenation
                    const sequenceKey = `${key}_sequence`;
                    const currentValue = this.get(sequenceKey);
                    if (currentValue) {
                        if (!currentValue.endsWith(value)) {
                            this.set(sequenceKey, `${currentValue} | ${value}`);
                        }
                    } else {
                        this.set(sequenceKey, value);
                    }
                }
                // replace w/ the recent value
                this.set(key, value);
            });
        });
    }

    /**
     *
     * @param action
     * @param category
     * @param label
     * @param properties
     * @deprecated Use trackGA4Event instead
     */
    trackEvent(
        action: string,
        category: string,
        label: string,
        properties: TrackingProperties
    ) {
        console.warn(
            'DEPRECATION WARNING: Use trackGA4Event instead',
            category,
            action,
            label,
            properties
        );

        dataLayer.push({
            event: category,
            'event action': action,
            'event label': label,
            ...this.globalProperties,
            ...properties
        });
    }

    trackGA4Event(eventName: string, properties?: TrackingProperties) {
        if (properties) {
            // to possibly cope with sequence properties
            this.setGA4DataLayerVariables(properties);
        }
        dataLayer.push({
            event: eventName,
            ...properties
        });
    }

    private initialize(): void {
        let gtmEnvironmentExtension = '';
        if (this.config.gtmEnvironmentSpec) {
            gtmEnvironmentExtension = `&gtm_auth=${this.config.gtmEnvironmentSpec.gtmAuth}&gtm_preview=${this.config.gtmEnvironmentSpec.gtmPreview}&gtm_cookies_win=${this.config.gtmEnvironmentSpec.gtmCookiesWin}`;
        }

        const scriptText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '${gtmEnvironmentExtension}';f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${this.config.trackingId}');`;

        attachScriptToDocumentHead(this.document, this.renderer, scriptText, {
            async: true
        });
    }
}
