/* eslint-disable max-classes-per-file */
import { CustomError } from '@mhp/common';

/**
 * Error thrown in case a recaptcha check failed while processing a request.
 */
export class RecaptchaError extends CustomError {
    constructor(message: string, details?: any) {
        super(message, details);
    }
}

/**
 * Error thrown in case the recaptcha-service fails to connect to the recaptcha backend.
 */
export class RecaptchaConnectionError extends CustomError {
    constructor(message: string, details?: any) {
        super(message, details);
    }
}
