import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import { enhanceSelector } from '@mhp/ui-shared-services';

import { LocalApplicationState } from '../../../state/local-application-state.interface';
import { MenuScope, MenuState } from '../common-menu-state.interface';

/**
 * Selects the current state of the configuration menu.
 */
export const selectCommonMenuState = enhanceSelector<
    LocalApplicationState,
    MenuState
>(pipe(map((state) => state.menu)), {
    distinctUntilChanged: true
});

/**
 * Selects the current state of the configuration menu.
 */
export const selectMenuScope = enhanceSelector<
    LocalApplicationState,
    MenuScope | undefined
>(
    pipe(
        selectCommonMenuState,
        map((state) => state.activeMenuScope)
    ),
    {
        distinctUntilChanged: true
    }
);
