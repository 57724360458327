import { isNumber } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';

import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Material } from '@mhp-immersive-exp/contracts/src/configuration/configuration-response.interface';

export interface DynamicAssetsServiceConfig {
    // base-url to the http-server containing protocol, host and port, e.g. http://127.0.0.1:4444/`
    baseUrl: string | Observable<string>;
    // default size for splitter-thumbnails
    splitterThumbnailsDefaultSize?: number;
}

export const DYNAMIC_ASSETS_SERVICE_CONFIG_TOKEN =
    new InjectionToken<DynamicAssetsServiceConfig>(
        'DynamicAssetsServiceConfig'
    );

@Injectable({
    providedIn: 'root'
})
export class DynamicAssetsService {
    private readonly baseUrlSubject = new BehaviorSubject<string>(
        `//${window.location.hostname}/`
    );

    private readonly splitterDefaultSize: number;

    constructor(
        @Inject(DYNAMIC_ASSETS_SERVICE_CONFIG_TOKEN)
        config: DynamicAssetsServiceConfig
    ) {
        if (config.baseUrl instanceof Observable) {
            config.baseUrl.subscribe((httpServerUrl) =>
                this.baseUrlSubject.next(
                    this.ensureEndsWithSlash(httpServerUrl)
                )
            );
        } else {
            this.baseUrlSubject.next(this.ensureEndsWithSlash(config.baseUrl));
        }

        this.splitterDefaultSize = isNumber(
            config.splitterThumbnailsDefaultSize
        )
            ? config.splitterThumbnailsDefaultSize
            : 120;
    }

    /**
     * Build the URL for the "splitter" of a given material.
     * @param productId The productId for which to fetch the asset
     * @param material The material to serve the splitter for
     * @param size The target size in px, defaults to 120px
     */
    getOptionSplitterUrl(productId: string, material: Material, size?: number) {
        const checkedSize = isNumber(size) ? size : this.splitterDefaultSize;

        const parentMaterialId = material.parent;
        const colorRed = material?.tintColor?.[0];
        const colorGreen = material?.tintColor?.[1];
        const colorBlue = material?.tintColor?.[2];

        return `${this.baseUrlSubject.value}splitter?productId=${productId}&parent=${parentMaterialId}&r=${colorRed}&g=${colorGreen}&b=${colorBlue}&width=${checkedSize}&height=${checkedSize}`;
    }

    private ensureEndsWithSlash(httpServerUrl: string) {
        if (!httpServerUrl.endsWith('/')) {
            return `${httpServerUrl}/`;
        }
        return httpServerUrl;
    }
}
