import { Environment } from '@mhp-immersive-exp/contracts/src/environment/environment.interface';
import { OptionMetadata } from '@mhp-immersive-exp/contracts/src/generic/metadata-aware.interface';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {
    Animation,
    GetAnimationsResponsePayload,
    GetCamerasResponsePayload
} from '@mhp-immersive-exp/contracts/src';
import { Cinematic } from '@mhp-immersive-exp/contracts/src/cinematic/cinematic.interface';
import { REQUEST_GET_CINEMATICS } from '@mhp-immersive-exp/contracts/src/cinematic/get-cinematics-request.interface';
import { GetCinematicsResponsePayload } from '@mhp-immersive-exp/contracts/src/cinematic/get-cinematics-response.interface';
import { REQUEST_GET_HIGHLIGHTS } from '@mhp-immersive-exp/contracts/src/highlight/get-highlights-request.interface';
import { GetHighlightsResponsePayload } from '@mhp-immersive-exp/contracts/src/highlight/get-highlights-response.interface';
import { Highlight } from '@mhp-immersive-exp/contracts/src/highlight/highlight.interface';
import { NotImplementedError } from '@mhp/common';

import { SocketIOService } from '../../communication';
import { ProductDataStrategy } from '../product-data-strategy.interface';

/**
 * ProductConfigurationCommunicationFacade implementation based on socket-io calls.
 * To be used when application is used in context where engine is available.
 */
@Injectable()
export class ProductDataSocketStrategy implements ProductDataStrategy {
    constructor(private readonly socketIoService: SocketIOService) {}

    getAvailableProducts$(): Observable<string[]> {
        // FIXME: Implement for socket-communication
        return of(['C9RBC']);
    }

    getAvailableProductAlternatives$(productId: string): Observable<any[]> {
        throw new NotImplementedError(
            'getAvailablePreconfiguredProducts$ is not yet implemented for socket-communication.'
        );
    }

    getAvailableEnvironments$(productId: string): Observable<Environment[]> {
        return this.socketIoService
            .request<
                Record<string, never>,
                { environments: Environment[] }
            >('getenvironments', {})
            .pipe(map((getEnvPayload) => getEnvPayload.environments));
    }

    /**
     * @inheritdoc
     */
    getDefaultEnvironment$ (productId: string): Observable<Environment<OptionMetadata> | undefined> {
        return of(undefined);
    }

    getAvailableCameras$(
        productId: string
    ): Observable<GetCamerasResponsePayload | undefined> {
        return this.socketIoService.request<
            Record<string, never>,
            GetCamerasResponsePayload
        >('getcameras', {});
    }

    getAvailableAnimations$(productId: string): Observable<Animation[]> {
        return this.socketIoService
            .request<
                Record<string, never>,
                GetAnimationsResponsePayload
            >('getanimations', {})
            .pipe(map((getAnimPayload) => getAnimPayload.animations));
    }

    getAvailableHighlights$(productId: string): Observable<Highlight[]> {
        return this.socketIoService
            .request<
                Record<string, never>,
                GetHighlightsResponsePayload
            >(REQUEST_GET_HIGHLIGHTS, {})
            .pipe(map((getEnvPayload) => getEnvPayload.highlights));
    }

    getAvailableCinematics$(productId: string): Observable<Cinematic[]> {
        return this.socketIoService
            .request<
                Record<string, never>,
                GetCinematicsResponsePayload
            >(REQUEST_GET_CINEMATICS, {})
            .pipe(map((getEnvPayload) => getEnvPayload.cinematics));
    }
}
