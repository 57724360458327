import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { EngineStatePatchService } from './engine-state-patch.service';

@NgModule({
    declarations: [],
    imports: [CommonModule]
})
export class EngineStatePatchModule {
    static forRoot(): ModuleWithProviders<EngineStatePatchModule> {
        return {
            ngModule: EngineStatePatchModule,
            providers: [EngineStatePatchService]
        };
    }
}
