import { merge, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { UiMatDialogService } from '@mhp/ui-components';

import { environment } from '../../../environments/environment';
import { VisualizationMode } from '../../configuration';
import { VisualizationModeService } from '../../configuration/visualization-mode/visualization-mode.service';
import {
    UserInactivityDialogComponent,
    UserInactivityDialogData
} from '../user-inactivity/user-inactivity-dialog/user-inactivity-dialog.component';
import { UserInactivityService } from '../user-inactivity/user-inactivity.service';

/**
 * Service that needs to be eagerly referenced and is performing some side-effect
 * tasks.
 * - listen to changes of the active visualization mode
 * - in case stream gets active, start listening to the user-inactivity stream provided
 *   by UserInactivityService and in case of user-inactivity, show a decision-dialog to
 *   the user to allow him to acknowledge that he's still there.
 */
@Injectable({
    providedIn: 'root'
})
export class StreamInactivityService {
    constructor(
        private readonly visualizationModeService: VisualizationModeService,
        private readonly userInactivityService: UserInactivityService,
        private readonly dialogService: UiMatDialogService
    ) {
        this.initTrackUserInactivityWhenStreamIsActive();
    }

    private initTrackUserInactivityWhenStreamIsActive() {
        this.visualizationModeService
            .getActiveVisualizationMode$()
            .pipe(
                switchMap((activeVisualizationMode) => {
                    if (activeVisualizationMode !== VisualizationMode.STREAM) {
                        return of(undefined);
                    }

                    let waitingForUserDecision = false;

                    return this.userInactivityService.getUserInactivity$().pipe(
                        filter(() => !waitingForUserDecision),
                        tap(() => {
                            waitingForUserDecision = true;
                        }),
                        switchMap(() =>
                            this.dialogService
                                .openFullscreen$<
                                    UserInactivityDialogComponent,
                                    UserInactivityDialogData
                                >(UserInactivityDialogComponent, {
                                    data: {
                                        decisionTime:
                                            environment.appConfig.stream
                                                .waitForUserActionTimoutOnUserInactivity
                                    }
                                })
                                .pipe(
                                    switchMap((dialogRef) =>
                                        merge(
                                            dialogRef.componentInstance
                                                .decisionSource,
                                            dialogRef
                                                .afterClosed()
                                                .pipe(map(() => 'USER'))
                                        )
                                    ),
                                    take(1)
                                )
                        ),
                        tap(() => {
                            waitingForUserDecision = false;
                        })
                    );
                })
            )
            .subscribe((decisionSource) => {
                if (decisionSource === 'TIMEOUT') {
                    this.visualizationModeService.setTargetVisualizationMode(
                        VisualizationMode.BASIC
                    );
                }
            });
    }
}
