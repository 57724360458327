import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';

import { UpdatableStrategyProvider } from '../strategy/strategy-provider';
import { OptionLabelPipe } from './option-label.pipe';
import { PRODUCT_CONFIGURATION_STRATEGY_PROVIDER_TOKEN } from './product-configuration-strategy.interface';
import { ProductConfigurationService } from './product-configuration.service';

@NgModule({
    declarations: [OptionLabelPipe],
    imports: [CommonModule],
    exports: [OptionLabelPipe]
})
export class ProductConfigurationModule {
    /**
     * Import the module along with its providers.
     * By default, an empty UpdatableStrategyProvider instance is provided for PRODUCT_CONFIGURATION_STRATEGY_PROVIDER_TOKEN
     * @param config An optional config that allows providing an alternative PRODUCT_CONFIGURATION_STRATEGY_PROVIDER_TOKEN instance.
     */
    static forRoot(config?: {
        productConfigurationStrategyProviderDefinition?: Provider;
    }): ModuleWithProviders<ProductConfigurationModule> {
        return {
            ngModule: ProductConfigurationModule,
            providers: [
                ProductConfigurationService,
                config?.productConfigurationStrategyProviderDefinition ?? {
                    provide: PRODUCT_CONFIGURATION_STRATEGY_PROVIDER_TOKEN,
                    useValue: new UpdatableStrategyProvider()
                }
            ]
        };
    }
}
