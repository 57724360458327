import { Observable, combineLatest, of } from 'rxjs';
import {
    distinctUntilChanged,
    map,
    switchMap,
    withLatestFrom
} from 'rxjs/operators';

import { Inject, Injectable, Optional, SkipSelf } from '@angular/core';
import {
    Animation,
    GetCamerasResponsePayload
} from '@mhp-immersive-exp/contracts/src';
import { Cinematic } from '@mhp-immersive-exp/contracts/src/cinematic/cinematic.interface';
import { Environment } from '@mhp-immersive-exp/contracts/src/environment/environment.interface';
import { OptionMetadata } from '@mhp-immersive-exp/contracts/src/generic/metadata-aware.interface';
import { Highlight } from '@mhp-immersive-exp/contracts/src/highlight/highlight.interface';
import { ProductAlternative } from '@mhp-immersive-exp/contracts/src/product/products-file.interface';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { ProductState } from '@mhp/communication-models';

import {
    ApplicationStateService,
    selectSettingsState
} from '../application-state';
import { selectProductState } from '../product-configuration/state';
import {
    StrategyProvider,
    withCurrentStrategy$,
    withStrategy$
} from '../strategy';
import {
    PRODUCT_DATA_STRATEGY_PROVIDER_TOKEN,
    ProductDataStrategy
} from './product-data-strategy.interface';

@Injectable()
export class ProductDataService<
    CameraMeta extends OptionMetadata = OptionMetadata,
    EnvironmentMeta extends OptionMetadata = OptionMetadata,
    T extends ProductDataStrategy<
        CameraMeta,
        EnvironmentMeta
    > = ProductDataStrategy<CameraMeta, EnvironmentMeta>
> {
    constructor(
        protected readonly applicationStateService: ApplicationStateService,
        @Inject(PRODUCT_DATA_STRATEGY_PROVIDER_TOKEN)
        protected readonly strategyProvider: StrategyProvider<T>,
        @Optional() @SkipSelf() parentService?: ProductDataService
    ) {
        if (parentService) {
            throw new Error(
                'ProductDataService is already loaded. Use modules forRoot() only once where needed.'
            );
        }
    }

    /**
     * Get a list of all available products.
     */
    getAvailableProducts$(): Observable<string[]> {
        return withCurrentStrategy$(this.strategyProvider).pipe(
            switchMap((strategy) => strategy.getAvailableProducts$())
        );
    }

    /**
     * Get a list of all available preconfigured-products for the currently
     * active product id.
     * FIXME: Add typings when able to merge again
     */
    getPreconfiguredProducts$(): Observable<ProductAlternative[] | undefined> {
        return this.getActiveProductId$().pipe(
            withLatestFrom(withCurrentStrategy$(this.strategyProvider)),
            switchMap(([productId, strategy]) => {
                if (!productId) {
                    return of(undefined);
                }
                return strategy.getAvailableProductAlternatives$(productId);
            }),
            lazyShareReplay()
        );
    }

    /**
     * Get a list of all available environments for the given product.
     */
    @MemoizeObservable()
    getAvailableEnvironmentsForProduct$(
        productId: string
    ): Observable<Environment[]> {
        return withCurrentStrategy$(this.strategyProvider).pipe(
            switchMap((strategy) =>
                strategy.getAvailableEnvironments$(productId)
            ),
            lazyShareReplay()
        );
    }

    /**
     * Get a list of all available environments for the loaded product.
     */
    @MemoizeObservable()
    getAvailableEnvironments$(): Observable<Environment<EnvironmentMeta>[]> {
        return this.getActiveProductId$().pipe(
            withLatestFrom(withCurrentStrategy$(this.strategyProvider)),
            switchMap(([productId, facade]) => {
                if (!productId) {
                    return of([]);
                }
                return facade.getAvailableEnvironments$(productId);
            }),
            lazyShareReplay()
        );
    }

    /**
     * Get the default environment for the given productId.
     * @param productId The productId to fetch the default environment for.
     * @return Observable stream emitting the current default environment if defined.
     */
    getDefaultEnvironment$(productId: string): Observable<Environment<EnvironmentMeta> | undefined> {
        return withCurrentStrategy$(this.strategyProvider).pipe(
            switchMap((strategy) =>
                strategy.getDefaultEnvironment$(productId)
            ),
            lazyShareReplay()
        );
    }

    /**
     * Get a list of all available cameras for the given product.
     */
    @MemoizeObservable()
    getAvailableAnimationsForProduct$(
        productId: string
    ): Observable<Animation[]> {
        return withCurrentStrategy$(this.strategyProvider).pipe(
            switchMap((strategy) =>
                strategy.getAvailableAnimations$(productId)
            ),
            lazyShareReplay()
        );
    }

    /**
     * Get a list of all available animations for the loaded product.
     */
    @MemoizeObservable()
    getAvailableAnimations$(): Observable<Animation[]> {
        return this.getActiveProductId$().pipe(
            switchMap((productId) => {
                if (!productId) {
                    return of([]);
                }
                return this.getAvailableAnimationsForProduct$(productId);
            }),
            lazyShareReplay()
        );
    }

    /**
     * Get a list of all available cameras for the given product.
     */
    @MemoizeObservable()
    getAvailableCamerasForProduct$(
        productId: string
    ): Observable<GetCamerasResponsePayload<CameraMeta> | undefined> {
        return withCurrentStrategy$(this.strategyProvider).pipe(
            switchMap((strategy) => strategy.getAvailableCameras$(productId)),
            lazyShareReplay()
        );
    }

    /**
     * Get the set of available cameras.
     */
    @MemoizeObservable()
    getAvailableCameras$<O extends Record<string, unknown>>(
        options?: O
    ): Observable<GetCamerasResponsePayload<CameraMeta> | undefined> {
        return combineLatest([
            this.getActiveProductId$(),
            withStrategy$(this.strategyProvider),
            this.applicationStateService.getState().pipe(
                selectSettingsState,
                map((state) => !!state?.vrEnabled)
            ) // in case vr mode gets enabled / disabled, we have to re-fetch available cameras from the backend
        ]).pipe(
            switchMap(([productId, communicationFacade]) => {
                if (!productId) {
                    return of(undefined);
                }
                return communicationFacade.getAvailableCameras$(
                    productId,
                    options
                );
            }),
            lazyShareReplay()
        );
    }

    /**
     * Get a list of all available cinematics for the loaded product.
     */
    @MemoizeObservable()
    getAvailableCinematics$(): Observable<Cinematic[]> {
        return this.getActiveProductId$().pipe(
            withLatestFrom(withStrategy$(this.strategyProvider)),
            switchMap(([productId, facade]) => {
                if (!productId) {
                    return of([]);
                }
                return facade.getAvailableCinematics$(productId);
            }),
            lazyShareReplay()
        );
    }

    /**
     * Get a list of all available highlights for the loaded product.
     */
    @MemoizeObservable()
    getAvailableHighlights$(): Observable<Highlight[]> {
        return this.getActiveProductId$().pipe(
            withLatestFrom(withCurrentStrategy$(this.strategyProvider)),
            switchMap(([productId, facade]) => {
                if (!productId) {
                    return of([]);
                }
                return facade.getAvailableHighlights$(productId);
            }),
            lazyShareReplay()
        );
    }

    /**
     * Get the active productId (if any).
     */
    @MemoizeObservable()
    private getActiveProductId$(): Observable<string | undefined> {
        return this.getProductState$().pipe(
            map((productState) => productState && productState.id),
            distinctUntilChanged()
        );
    }

    /**
     * Determines if a product has already been loaded or not.
     */
    @MemoizeObservable()
    private getProductState$(): Observable<ProductState | undefined> {
        return this.applicationStateService.getState().pipe(selectProductState);
    }
}
