/* eslint-disable no-underscore-dangle */
import { isString } from 'lodash-es';

import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input
} from '@angular/core';

export enum IconSize {
    XXSMALL = 'xxsmall',
    XSMALL = 'xsmall',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
    XLARGE = 'xlarge'
}

@Component({
    selector: 'mhp-ui-icon',
    templateUrl: './ui-icon.component.html',
    styleUrls: ['./ui-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiIconComponent {
    readonly ICON_SIZE = IconSize;

    @Input({
        required: true
    })
    svgIcon!: string;

    @Input()
    @HostBinding('class.ui-icon--inline')
    set inline(inline: boolean | string | undefined) {
        if (isString(inline)) {
            this._inline = true;
        } else {
            this._inline = !!inline;
        }
    }

    get inline() {
        return this._inline;
    }

    @Input()
    size = IconSize.MEDIUM;

    private _inline = false;
}
