import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { UiIconButtonModule } from '../ui-buttons';
import {
    UiMatDialogActionsLeftDirective,
    UiMatDialogActionsRightDirective,
    UiMatDialogComponent,
    UiMatDialogContentDirective,
    UiMatDialogHeaderDirective
} from './ui-mat-dialog.component';

@NgModule({
    declarations: [
        UiMatDialogComponent,
        UiMatDialogActionsRightDirective,
        UiMatDialogActionsLeftDirective,
        UiMatDialogContentDirective,
        UiMatDialogHeaderDirective
    ],
    imports: [CommonModule, UiIconButtonModule, MatDialogModule],
    exports: [
        UiMatDialogComponent,
        UiMatDialogActionsRightDirective,
        UiMatDialogActionsLeftDirective,
        UiMatDialogContentDirective,
        UiMatDialogHeaderDirective,
        MatDialogModule
    ]
})
export class UiMatDialogModule {}
