/* eslint-disable no-underscore-dangle */
import { clamp } from 'lodash-es';
import { BehaviorSubject, Observable, pairwise, switchMap } from 'rxjs';
import { map, skip, startWith } from 'rxjs/operators';

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import drag from '@interactjs/actions/drag/plugin';
import autostart from '@interactjs/auto-start/plugin';
import { Interactable } from '@interactjs/core/Interactable';
import inertia from '@interactjs/inertia/plugin';
import interact from '@interactjs/interact';

import { ImageSrcset, UiBaseComponent } from '../common';

export interface TurntableImageError {
    image: ImageSrcset;
    error?: never;
}

interface TurntableImage extends ImageSrcset {
    step: number;
}

export interface InteractionStrategy {
    initialize: (
        // eslint-disable-next-line no-use-before-define
        component: UiTurntableComponent,
        element: HTMLDivElement
    ) => void;
    destroy: () => void;
}

interact.use(drag);
interact.use(inertia);
interact.use(autostart);

@Component({
    selector: 'mhp-ui-turntable',
    templateUrl: './ui-turntable.component.html',
    styleUrls: ['./ui-turntable.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiTurntableComponent
    extends UiBaseComponent
    implements OnInit, OnDestroy
{
    private _images: ImageSrcset[];

    private _currentStep = 0;

    private imagesLoadedCount = 0;

    private imageLoadErrorsCount = 0;

    private readonly preloadStatusSubject = new BehaviorSubject<number>(0);

    private readonly renderImagesSubject = new BehaviorSubject<
        TurntableImage[]
    >([]);

    /**
     * Distance in px that needs to be dragged before moving to the next step
     */
    @Input()
    stepDistance = 20;

    @Input()
    set images(value: ImageSrcset[]) {
        this._images = value;
        if (this._currentStep >= this.images.length) {
            this.goToStep(this.images.length - 1);
        }
    }

    get images(): ImageSrcset[] {
        return this._images;
    }

    /**
     * Number > 0 that defines the resistance for the inertia behavior.
     * Smaller values define lower resistance.
     * Defaults to 1.
     */
    @Input()
    inertiaResistance = 1;

    @Input()
    loadParallelCount = 2;

    @Input()
    loop = true;

    @Input()
    set startingStep(value: number) {
        this._currentStep = Math.round(value);
        this.activeStep.next(this._currentStep);
    }

    @Input()
    interactionStrategy?: InteractionStrategy;

    /**
     * Emits the current preload status in the range from 0 (nothing loaded) to 1 (all loaded).
     * Note that the emitted status will also contain images for which preloading failed.
     * See #preloadError to get updates on these.
     */
    @Output()
    readonly preloadStatus = this.preloadStatusSubject
        .asObservable()
        .pipe(skip(1));

    /**
     * Emits an error in case an image could not be loaded
     */
    @Output()
    readonly preloadError = new EventEmitter<TurntableImageError>();

    /**
     * Emits the currently active step
     */
    @Output()
    readonly activeStep = new EventEmitter<number>();

    @ViewChild('turntable', {
        static: true
    })
    turntableRef!: ElementRef<HTMLDivElement>;

    get currentStep(): number {
        return this._currentStep;
    }

    readonly renderImages$ = this.renderImagesSubject.asObservable();

    readonly imageTrackBy = (index: number) => index;

    constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
        super();

        this.completeOnDestroy(
            this.preloadStatusSubject,
            this.preloadError,
            this.activeStep
        );
    }

    ngOnInit() {
        // handle interactionStrategy changes
        this.observeProperty<UiTurntableComponent, InteractionStrategy>(
            'interactionStrategy'
        )
            .pipe(
                startWith(undefined),
                pairwise(),
                map(([prevStrategy, currentStrategy]) => [
                    prevStrategy,
                    currentStrategy ?? this.createDefaultInteractionStrategy()
                ]),
                switchMap(
                    ([prevStrategy, currentStrategy]) =>
                        new Observable((subscriber) => {
                            if (prevStrategy) {
                                prevStrategy.destroy();
                            }
                            if (currentStrategy) {
                                currentStrategy.initialize(
                                    this,
                                    this.turntableRef.nativeElement
                                );
                            }

                            return () => {
                                if (currentStrategy) {
                                    currentStrategy.destroy();
                                }
                            };
                        })
                ),
                this.takeUntilDestroy()
            )
            .subscribe();

        // when input images change, reset the preloading-status
        this.observeProperty<UiTurntableComponent, ImageSrcset[]>(
            'images',
            true
        ).subscribe(() => {
            this.resetPreloading();
        });
    }

    onImageLoaded() {
        this.imagesLoadedCount += 1;
        this.updatePreloadingStatus();
    }

    onImageLoadError(turntableImage: ImageSrcset) {
        this.imageLoadErrorsCount += 1;

        this.preloadError.emit({
            image: turntableImage
        });

        this.updatePreloadingStatus();
    }

    allImagesLoaded() {
        return this.preloadStatusSubject.value === 1;
    }

    getTotalSteps() {
        return this.images?.length || 0;
    }

    goToStep(step: number) {
        if (this._currentStep === step) {
            return;
        }
        this._currentStep = step;
        this.changeDetectorRef.detectChanges();

        this.activeStep.next(this._currentStep);
    }

    private updatePreloadingStatus() {
        const totalImagesLoaded =
            this.imagesLoadedCount + this.imageLoadErrorsCount;
        this.preloadStatusSubject.next(
            Math.min(1, totalImagesLoaded / this.getTotalSteps())
        );
        this.registerForRender(this.loadParallelCount);
    }

    private resetPreloading() {
        this.imagesLoadedCount = 0;
        this.imageLoadErrorsCount = 0;

        this.preloadStatusSubject.next(0);
        this.renderImagesSubject.next([]);
        setTimeout(() => {
            this.registerForRender(1, this.currentStep);
        }, 0);
    }

    private registerForRender(maxImgCount: number, startFrom = 0) {
        const queuedImages = this.renderImagesSubject.value;
        const outstandingImagesLoadCount =
            queuedImages.length -
            (this.imagesLoadedCount + this.imageLoadErrorsCount);

        const nextBatch = this.images
            .slice(startFrom)
            .reduce((collectedValues, currentValue, index) => {
                if (
                    outstandingImagesLoadCount + collectedValues.length >=
                    maxImgCount
                ) {
                    return collectedValues;
                }
                if (
                    queuedImages.find(
                        (turntableImage) =>
                            turntableImage.step === index + startFrom
                    )
                ) {
                    return collectedValues;
                }
                return [
                    ...collectedValues,
                    {
                        ...currentValue,
                        step: index + startFrom
                    }
                ];
            }, [] as TurntableImage[]);

        this.renderImagesSubject.next([...queuedImages, ...nextBatch]);
    }

    private createDefaultInteractionStrategy() {
        let interactInstance: Interactable;

        return {
            initialize: (
                component: UiTurntableComponent,
                element: HTMLElement
            ) => {
                let startingStep = 0;
                let diffX = 0;

                interactInstance = interact(element)
                    .draggable({
                        startAxis: 'x',
                        inertia: {
                            resistance: component.inertiaResistance
                        },
                        listeners: {
                            start: () => {
                                startingStep = component.currentStep;
                                diffX = 0;
                            },
                            move: (event) => {
                                diffX += event.dx;

                                const stepsMovedFromStart =
                                    diffX < 0
                                        ? Math.ceil(
                                              diffX / component.stepDistance
                                          )
                                        : Math.floor(
                                              diffX / component.stepDistance
                                          );

                                const totalSteps = component.getTotalSteps();
                                let targetStepRaw =
                                    startingStep + stepsMovedFromStart;

                                if (!component.loop) {
                                    targetStepRaw = clamp(
                                        targetStepRaw,
                                        0,
                                        totalSteps - 1
                                    );
                                }

                                const resultingStep =
                                    ((targetStepRaw % totalSteps) +
                                        totalSteps) %
                                    totalSteps;

                                component.goToStep(resultingStep);
                            }
                        }
                    })
                    .styleCursor(false);
            },
            destroy: () => {
                interactInstance?.unset();
            }
        };
    }
}
