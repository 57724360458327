import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
    selector: 'mhp-loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: ['./loading-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingIndicatorComponent {
    @HostBinding('class.loading-indicator--loading')
    @Input()
    loading: boolean;

    @Input()
    statusText?: string;

    @Input()
    showLoadingSpinnerWhenLoading = true;

    @Input()
    progress = -1;

    @HostBinding('class.loading-indicator--full-opaque')
    @Input()
    fullOpaque: boolean;

    @Input()
    showHeader: boolean;

    @Input()
    progressSpinnerMinimumProgress = 0.1; // Determines a minimum progress to display in the loading spinner

    @HostBinding('style.zIndex')
    @Input()
    zIndex?: number;

    progressSpinnerMode: ProgressSpinnerMode = 'determinate';

    progressSpinnerColor: ThemePalette = 'primary';
}
