import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';

import { UpdatableStrategyProvider } from '../strategy/strategy-provider';
import { ENGINE_CONTROL_STRATEGY_PROVIDER_TOKEN } from './engine-control-strategy.interface';
import { EngineControlService } from './engine-control.service';
import { EngineStateService } from './engine-state.service';

@NgModule({
    declarations: [],
    imports: [CommonModule]
})
export class EngineControlModule {
    /**
     * Import the module along with its providers.
     * By default, an empty UpdatableStrategyProvider instance is provided for ENGINE_CONTROL_STRATEGY_PROVIDER_TOKEN
     * @param config An optional config that allows providing an alternative ENGINE_CONTROL_STRATEGY_PROVIDER_TOKEN instance.
     */
    static forRoot(config?: {
        engineControlStrategyProviderDefinition?: Provider;
    }): ModuleWithProviders<EngineControlModule> {
        return {
            ngModule: EngineControlModule,
            providers: [
                EngineControlService,
                EngineStateService,
                config?.engineControlStrategyProviderDefinition ?? {
                    provide: ENGINE_CONTROL_STRATEGY_PROVIDER_TOKEN,
                    useValue: new UpdatableStrategyProvider()
                }
            ]
        };
    }
}
