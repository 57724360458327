import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { UiIconComponent } from './ui-icon/ui-icon.component';

const UI_ICON_SPRITE_URL = 'assets/icons/ui-components.icon-sprite.svg';

@NgModule({
    imports: [HttpClientModule, MatIconModule, CommonModule],
    exports: [UiIconComponent],
    declarations: [UiIconComponent]
})
export class UiIconsModule {
    constructor(iconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
        iconRegistry.addSvgIconSetInNamespace(
            'mhp-ui',
            domSanitizer.bypassSecurityTrustResourceUrl(UI_ICON_SPRITE_URL)
        );
    }
}
