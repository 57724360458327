import { createAction, props } from '@ngrx/store';

export const setNetworkConnectionState = createAction(
    '[LOCAL-SHARED-STATE]: setNetworkConnectionState',
    props<{ connectionState: boolean }>()
);

export const setHubConnectionState = createAction(
    '[LOCAL-SHARED-STATE]: setHubConnectionState',
    props<{ connectionState: boolean }>()
);

export const setEngineConnectionState = createAction(
    '[LOCAL-SHARED-STATE]: setEngineConnectionState',
    props<{ connectionState: boolean | undefined }>()
);

export const increasePendingConfigurationChanges = createAction(
    '[LOCAL-SHARED-STATE]: increase pending configuration changes'
);

export const decreasePendingConfigurationChanges = createAction(
    '[LOCAL-SHARED-STATE]: decrease pending configuration changes'
);
