import { isEmpty } from 'lodash-es';
import {
    Observable,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    map,
    of,
    switchMap
} from 'rxjs';

import { Injectable, Optional, SkipSelf } from '@angular/core';
import {
    Camera,
    CameraType
} from '@mhp-immersive-exp/contracts/src/camera/camera.interface';
import { Cinematic } from '@mhp-immersive-exp/contracts/src/cinematic/cinematic.interface';
import {
    Environment,
    EnvironmentLightingProfileState
} from '@mhp-immersive-exp/contracts/src/environment/environment.interface';
import { OptionMetadata } from '@mhp-immersive-exp/contracts/src/generic/metadata-aware.interface';
import { Highlight } from '@mhp-immersive-exp/contracts/src/highlight/highlight.interface';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import {
    AnimationState,
    CinematicState,
    ContextOptionState,
    HighlightState
} from '@mhp/communication-models';

import { ApplicationStateService } from '../application-state';
import { ProductConfigurationService } from '../product-configuration';
import { ProductDataService } from '../product-data';
import {
    selectAnimationStates,
    selectCameraState,
    selectCinematicState,
    selectEnvironmentState,
    selectHighlightState
} from './state';

/**
 * Service to get information about engine specific state, e.g. active environment, day/night state, ...
 */
@Injectable()
export class EngineStateService<
    CameraMeta extends OptionMetadata = OptionMetadata,
    EnvironmentMeta extends OptionMetadata = OptionMetadata
> {
    constructor(
        private readonly applicationStateService: ApplicationStateService,
        private readonly productDataService: ProductDataService<
            CameraMeta,
            EnvironmentMeta
        >,
        private readonly productConfigurationService: ProductConfigurationService,
        @Optional() @SkipSelf() parentService?: EngineStateService
    ) {
        if (parentService) {
            throw new Error(
                'EngineControlService is already loaded. Use modules forRoot() only once where needed.'
            );
        }
    }

    /**
     * Returns the active environment-id if any.
     */
    @MemoizeObservable()
    getActiveEnvironmentId$(): Observable<string | undefined> {
        return this.applicationStateService.getState().pipe(
            selectEnvironmentState,
            map((environment) => environment && environment.id)
        );
    }

    /**
     * Returns the active environment if any.
     */
    @MemoizeObservable()
    getActiveEnvironment$(): Observable<
        Environment<EnvironmentMeta> | undefined
    > {
        return combineLatest([
            this.getActiveEnvironmentId$(),
            this.productDataService.getAvailableEnvironments$()
        ]).pipe(
            debounceTime(0),
            map(([activeEnvironmentId, availableEnvironments]) =>
                availableEnvironments?.find(
                    (env) => env.id === activeEnvironmentId
                )
            )
        );
    }

    /**
     * Returns the active environment-state if any.
     */
    @MemoizeObservable()
    getActiveEnvironmentDayNightState$(): Observable<
        EnvironmentLightingProfileState | undefined
    > {
        return this.applicationStateService.getState().pipe(
            selectEnvironmentState,
            map((environment) => (environment ? environment.state : undefined))
        );
    }

    /**
     * Returns the active environments ContextOptionStates if any.
     */
    @MemoizeObservable()
    getActiveEnvironmentContextOptionStates$(): Observable<
        ContextOptionState[] | undefined
    > {
        return this.applicationStateService.getState().pipe(
            selectEnvironmentState,
            map((environment) =>
                environment ? environment.options : undefined
            )
        );
    }

    /**
     * Returns the active environment-id if any.
     */
    @MemoizeObservable()
    getEnvironmentMode$(): Observable<
        EnvironmentLightingProfileState | undefined
    > {
        return this.applicationStateService.getState().pipe(
            selectEnvironmentState,
            map((environment) => (environment ? environment.state : undefined))
        );
    }

    /**
     * Emits the current list of AnimationStates valid for the loaded product.
     */
    @MemoizeObservable()
    getAnimationStates$(): Observable<AnimationState[]> {
        return this.productConfigurationService.isProductLoaded$().pipe(
            switchMap((productLoaded) => {
                if (!productLoaded) {
                    return of([]);
                }
                return this.applicationStateService.getState().pipe(
                    selectAnimationStates,
                    map((animStates) => animStates || [])
                );
            }),
            lazyShareReplay()
        );
    }

    /**
     * Emits the current active camera.
     */
    @MemoizeObservable()
    getActiveCamera$(): Observable<string | undefined> {
        return this.productConfigurationService.isProductLoaded$().pipe(
            switchMap((productLoaded) => {
                if (!productLoaded) {
                    return of(undefined);
                }
                return this.applicationStateService
                    .getState()
                    .pipe(selectCameraState);
            })
        );
    }

    /**
     * Get the default camera for the current context (exterior/interior).
     */
    @MemoizeObservable()
    getDefaultCameraForContext$(): Observable<string | undefined> {
        return combineLatest([
            this.getActiveCamera$(),
            this.productDataService.getAvailableCameras$()
        ]).pipe(
            map(([activeCamera, availableCamerasInfo]) => {
                if (!availableCamerasInfo) {
                    return undefined;
                }
                const activeCameraInfo = availableCamerasInfo.cameras.find(
                    (currentCamera) => currentCamera.id === activeCamera
                );

                if (!activeCameraInfo) {
                    return undefined;
                }

                const currentCameraType = Array.isArray(activeCameraInfo.type)
                    ? activeCameraInfo.type[0]
                    : activeCameraInfo.type;

                if (
                    currentCameraType === 'Int' ||
                    currentCameraType === 'IntVR'
                ) {
                    if (
                        availableCamerasInfo.cameras.find(
                            (camera) =>
                                camera.id === availableCamerasInfo.defaultInt
                        )
                    ) {
                        return availableCamerasInfo.defaultInt;
                    }
                    // find the first available matching the current context
                    return availableCamerasInfo.cameras.filter((camera) =>
                        camera.type?.some(
                            (type) => type === 'Int' || type === 'IntVR'
                        )
                    )?.[0]?.id;
                }

                if (
                    availableCamerasInfo.cameras.find(
                        (camera) =>
                            camera.id === availableCamerasInfo.defaultExt
                    )
                ) {
                    return availableCamerasInfo.defaultExt;
                }
                // find the first available matching the current context
                return availableCamerasInfo.cameras.filter((camera) =>
                    camera.type?.some(
                        (type) => type === 'Ext' || type === 'ExtVR'
                    )
                )?.[0]?.id;
            }),
            lazyShareReplay()
        );
    }

    /**
     * Get the available cameras for the current context where context is:
     * - active camera type (int / intVr / ext / extVr)
     */
    @MemoizeObservable()
    getAvailableCamerasForContext$(): Observable<string[] | undefined> {
        return combineLatest([
            this.getActiveCameraType$(),
            this.productDataService.getAvailableCameras$()
        ]).pipe(
            map(([activeCameraType, cameras]) =>
                cameras?.cameras
                    .filter(
                        (camera) =>
                            (Array.isArray(camera.type)
                                ? camera.type[0]
                                : camera.type) === activeCameraType
                    )
                    .map((camera) => camera.id)
            ),
            lazyShareReplay()
        );
    }

    /**
     * Get the type of the currently active camera.
     */
    @MemoizeObservable()
    getActiveCameraType$(): Observable<CameraType | undefined> {
        return combineLatest([
            this.getActiveCamera$(),
            this.productDataService.getAvailableCameras$()
        ]).pipe(
            map(([activeCamera, availableCamerasInfo]) => {
                if (!availableCamerasInfo) {
                    return undefined;
                }
                const activeCameraInfo: Camera | undefined =
                    availableCamerasInfo.cameras.find(
                        (currentCamera) => currentCamera.id === activeCamera
                    );

                if (!activeCameraInfo) {
                    return undefined;
                }

                return Array.isArray(activeCameraInfo.type)
                    ? activeCameraInfo.type[0]
                    : activeCameraInfo.type;
            }),
            distinctUntilChanged(),
            lazyShareReplay()
        );
    }

    /**
     * Emits the HighlightState valid for the loaded product.
     */
    @MemoizeObservable()
    getHighlightState$(): Observable<HighlightState | undefined> {
        return this.productConfigurationService.isProductLoaded$().pipe(
            switchMap((productLoaded) => {
                if (!productLoaded) {
                    return of(undefined);
                }
                return this.applicationStateService.getState().pipe(
                    selectHighlightState,
                    map((state) => state || undefined)
                );
            }),
            lazyShareReplay()
        );
    }

    /**
     * Emits the currently active highlight for the loaded product (if any).
     */
    @MemoizeObservable()
    getActiveHighlight$(): Observable<Highlight | undefined> {
        return combineLatest([
            this.productDataService.getAvailableHighlights$(),
            this.getHighlightState$()
        ]).pipe(
            map(([availableHighlights, highlightState]) => {
                if (!highlightState || isEmpty(availableHighlights)) {
                    return undefined;
                }
                return availableHighlights.find(
                    (highlight) => highlight.id === highlightState.id
                );
            }),
            lazyShareReplay()
        );
    }

    /**
     * Emits the currently active cinematic for the loaded product (if any).
     */
    @MemoizeObservable()
    getActiveCinematic$(): Observable<Cinematic | undefined> {
        return combineLatest([
            this.productDataService.getAvailableCinematics$(),
            this.getCinematicState$()
        ]).pipe(
            map(([availableCinematics, cinematicState]) => {
                if (!cinematicState || isEmpty(availableCinematics)) {
                    return undefined;
                }
                return availableCinematics.find(
                    (cinematic) => cinematic.id === cinematicState.id
                );
            }),
            lazyShareReplay()
        );
    }

    /**
     * Emits the CinematicState valid for the loaded product.
     */
    @MemoizeObservable()
    getCinematicState$(): Observable<CinematicState | undefined> {
        return this.productConfigurationService.isProductLoaded$().pipe(
            switchMap((productLoaded) => {
                if (!productLoaded) {
                    return of(undefined);
                }
                return this.applicationStateService.getState().pipe(
                    selectCinematicState,
                    map((state) => state || undefined)
                );
            }),
            lazyShareReplay()
        );
    }
}
