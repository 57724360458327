import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { OptionMetadata } from '@mhp-immersive-exp/contracts/src/generic/metadata-aware.interface';
import { IllegalStateError } from '@mhp/common';
import { ImageSrcset, ImageSrcsetEntry } from '@mhp/ui-components';

export interface StaticAssetsServiceConfig {
    // base-url to the http-server containing protocol, host and port, e.g. http://127.0.0.1:4444/`
    httpServerUrl: string | Observable<string>;
    // placeholder-image url
    placeholderImageUrl?: string;
    // camera-thumbnail sizes
    cameraThumbnailSizes?: number[];
    // environment-thumbnail sizes
    environmentThumbnailSizes?: number[];
    // animation-thumbnail sizes
    animationThumbnailSizes?: number[];
    // splitter-thumbnail sizes
    splitterThumbnailSizes?: number[];
    // option-thumbnail sizes
    optionThumbnailSizes?: number[];
    // meta-field name for thumbnail prefix
    optionMetaThumbnailPrefixKey?: string;
}

export const STATIC_ASSETS_SERVICE_CONFIG_TOKEN =
    new InjectionToken<StaticAssetsServiceConfig>(
        'StaticAssetsService configuration'
    );

const DEFAULT_FALLBACK_IMAGE_URL = 'assets/images/placeholder-image.png';

/**
 * Should be used to construct asset-urls and obtain access to asset-resources.
 *
 * FIXME: Split up into service that copes with engine-related resources (e.g. generated screenshots, pdfs, ...) and assets that are
 * accessible independently of the engine-instance (e.g. assets-server).
 */
@Injectable({
    providedIn: 'root'
})
export class StaticAssetService {
    private readonly httpServerUrlSubject = new BehaviorSubject<string>(
        `//${window.location.hostname}`
    );

    private readonly placeholderImageUrl: string;

    constructor(
        @Optional() private readonly httpClient: HttpClient,
        @Inject(STATIC_ASSETS_SERVICE_CONFIG_TOKEN)
        private readonly config: StaticAssetsServiceConfig
    ) {
        if (config.httpServerUrl instanceof Observable) {
            config.httpServerUrl.subscribe((httpServerUrl) =>
                this.httpServerUrlSubject.next(
                    this.ensureEndsWithSlash(httpServerUrl)
                )
            );
        } else {
            this.httpServerUrlSubject.next(
                this.ensureEndsWithSlash(config.httpServerUrl)
            );
        }

        this.placeholderImageUrl = config.placeholderImageUrl
            ? config.placeholderImageUrl
            : DEFAULT_FALLBACK_IMAGE_URL;

        this.checkPlaceholderImageAvailability();
    }

    /**
     * When the httpServerUrl is updated, execute the given callback and receive
     * the updated value.
     * e.g. staticAssetsService.withUpdatedBaseUrl$(() => staticAssetsService.getProductLogoThumbnailUrl(id));
     * @param callback
     */
    withUpdatedBaseUrl$<T>(callback: () => T) {
        return this.httpServerUrlSubject.pipe(
            distinctUntilChanged(),
            map(callback)
        );
    }

    /**
     * Provide the URL (or base64 encoded image, depending on bundler-config) to
     * a shared placeholder image.
     */
    getPlaceholderImage() {
        return this.placeholderImageUrl;
    }

    /**
     * Build the URL for a given products logo.
     * @param productId The products ID.
     */
    getProductLogoThumbnailUrl(productId: string) {
        return `${this.httpServerUrlSubject.value}assets/thumbnails/${productId}/product-logo.png`;
    }

    /**
     * Build the URL for a given options thumbnail.
     * @param optionCode The option code
     * @param productId The productId
     * @param optionMeta Optional metadata for the optionCode.
     * @param filenamePostfix An optional postfix to be appended to the filename.
     * @param options Optional additional options to customize file-name creation.
     */
    getOptionThumbnailUrl(
        optionCode: string,
        productId: string,
        optionMeta?: OptionMetadata,
        filenamePostfix?: string,
        options?: {
            fileExtension?: string;
        }
    ) {
        let fileNameBase = optionCode;

        if (
            this.config.optionMetaThumbnailPrefixKey &&
            optionMeta?.[this.config.optionMetaThumbnailPrefixKey]
        ) {
            fileNameBase = `${
                optionMeta?.[this.config.optionMetaThumbnailPrefixKey]
            }${fileNameBase}${filenamePostfix || ''}`;
        }

        return this.getThumbnailUrl(
            `assets/thumbnails/${productId}/options/${fileNameBase}.${
                options?.fileExtension ?? 'png'
            }`
        );
    }

    /**
     * Build the srcset for a given options thumbnail. Option is assumed to be of subtype 'image'
     * @param optionCode The option code
     * @param productId The product id
     * @param optionMeta Optional metadata for the optionCode.
     * @param options Optional additional options to customize file-name creation.
     */
    getOptionThumbnailSrcset(
        optionCode: string,
        productId: string,
        optionMeta?: OptionMetadata,
        options?: {
            fileExtension?: string;
        }
    ): ImageSrcset {
        return this.getOptionThumbnailSrcsetWithCustomFilenameBase(
            productId,
            () => {
                if (!this.config.optionThumbnailSizes) {
                    throw new IllegalStateError(
                        'Cannot generate srcset for option-thumbnails as optionsThumbnailSizes is not configured for StaticAssetsService.'
                    );
                }

                let fileNameBase = optionCode;

                if (
                    this.config.optionMetaThumbnailPrefixKey &&
                    optionMeta?.[this.config.optionMetaThumbnailPrefixKey]
                ) {
                    fileNameBase = `${
                        optionMeta?.[this.config.optionMetaThumbnailPrefixKey]
                    }${fileNameBase}`;
                }
                return fileNameBase;
            },
            options
        );
    }

    /**
     * Build the srcset for a given options thumbnail.
     * @param productId The product id
     * @param filenameBaseFactory Factory function providing the actual thumbnail basename (without file-extension) to be used.
     * @param options Optional additional options to customize file-name creation.
     */
    getOptionThumbnailSrcsetWithCustomFilenameBase(
        productId: string,
        filenameBaseFactory: () => string,
        options?: {
            fileExtension?: string;
        }
    ): ImageSrcset {
        if (!this.config.optionThumbnailSizes) {
            throw new IllegalStateError(
                'Cannot generate srcset for option-thumbnails as optionsThumbnailSizes is not configured for StaticAssetsService.'
            );
        }

        const basePath = this.getThumbnailUrl(
            `assets/thumbnails/${productId}/options`
        );

        return this.buildImageSrcsetUsingFilenameInfo(
            basePath,
            filenameBaseFactory(),
            options?.fileExtension ?? 'png',
            this.config.optionThumbnailSizes
        );
    }

    /**
     * Build the URL for a given options thumbnail.
     * @param optionCode The option code
     * @param optionMeta Optional metadata for the optionCode.
     * @param filenamePostfix An optional postfix to be appended to the filename.
     */
    getOptionMaterialSplitterUrl(
        optionCode: string,
        optionMeta?: OptionMetadata,
        filenamePostfix?: string
    ) {
        let fileNameBase = optionCode;

        if (
            this.config.optionMetaThumbnailPrefixKey &&
            optionMeta?.[this.config.optionMetaThumbnailPrefixKey]
        ) {
            fileNameBase = `${
                optionMeta?.[this.config.optionMetaThumbnailPrefixKey]
            }${fileNameBase}${filenamePostfix || ''}`;
        }

        return this.getThumbnailUrl(
            `assets/thumbnails/global/splitter/${fileNameBase}.jpg`
        );
    }

    /**
     * Build the srcset for a given options material splitter thumbnail.
     * @param optionCode The option code
     * @param optionMeta Optional metadata for the optionCode.
     */
    getOptionMaterialSplitterSrcset(
        optionCode: string,
        optionMeta?: OptionMetadata
    ): ImageSrcset {
        return this.getOptionMaterialSplitterSrcsetWithCustomFilenameBase(
            () => {
                let fileNameBase = optionCode;

                if (
                    this.config.optionMetaThumbnailPrefixKey &&
                    optionMeta?.[this.config.optionMetaThumbnailPrefixKey]
                ) {
                    fileNameBase = `${
                        optionMeta?.[this.config.optionMetaThumbnailPrefixKey]
                    }${fileNameBase}`;
                }

                return fileNameBase;
            }
        );
    }

    /**
     * Build the srcset for a given options thumbnails material splitter.
     * @param filenameBaseFactory Factory function providing the actual thumbnail basename (without file-extension) to be used.
     */
    getOptionMaterialSplitterSrcsetWithCustomFilenameBase(
        filenameBaseFactory: () => string
    ): ImageSrcset {
        if (!this.config.splitterThumbnailSizes) {
            throw new IllegalStateError(
                'Cannot generate srcset for splitter-thumbnails as splitterThumbnailSizes is not configured for StaticAssetsService.'
            );
        }

        const basePath = this.getThumbnailUrl(
            `assets/thumbnails/global/splitter`
        );

        return this.buildImageSrcsetUsingFilenameInfo(
            basePath,
            filenameBaseFactory(),
            'jpg',
            this.config.splitterThumbnailSizes
        );
    }

    /**
     * Build the URL for a given options thumbnail.
     * @param image The image as defined in the code metadata
     * @param productId The productId
     */
    getOptionThumbnailImageUrl(image: string, productId: string) {
        return this.getThumbnailUrl(
            `assets/thumbnails/${productId}/options/${image}`
        );
    }

    /**
     * Build the URL for a given collections image.
     * @param image The image as defined in the collection metadata.
     * @param productId The productId
     */
    getCollectionImageUrl(image: string, productId: string) {
        return this.getThumbnailUrl(
            `assets/thumbnails/${productId}/options/${image}`
        );
    }

    /**
     * Returns the URL for a given textureId.
     * @param textureId The textureId (assumed to not include file-ending)
     */
    getMaterialTextureUrl(textureId: string) {
        return this.getThumbnailUrl(`assets/textures/${textureId}.png`);
    }

    /**
     * Build the URL for a given cameras thumbnail.
     * @param thumbnailPath The relative path to the thumbnail.
     */
    getCameraThumbnailUrl(thumbnailPath: string | undefined) {
        return thumbnailPath ? this.getThumbnailUrl(thumbnailPath) : '';
    }

    /**
     * Build the URL for a given cameras thumbnail by camera ID.
     * @param productId The productId to be used.
     * @param cameraId The cameras ID.
     */
    getCameraThumbnailUrlById(productId: string, cameraId: string) {
        return this.getThumbnailUrl(
            `assets/thumbnails/${productId}/cameras/${cameraId}.jpg`
        );
    }

    /**
     * Build the URL for a given cameras thumbnail by camera ID, environmentId and productId.
     * @param productId The productId to be used.
     * @param environmentId The environmentId to be used.
     * @param cameraId The cameras ID.
     */
    getEnvironmentSpecificCameraThumbnailUrlById(
        productId: string,
        environmentId: string,
        cameraId: string
    ): ImageSrcset {
        if (!this.config.cameraThumbnailSizes) {
            throw new IllegalStateError(
                'Cannot generate srcset for camera-thumbnails as cameraThumbnailSizes is not configured for StaticAssetsService.'
            );
        }

        const basePath = this.getThumbnailUrl(
            `assets/thumbnails/${productId}/cameras`
        );
        const filenameBase =
            `${productId}_${environmentId}_${cameraId}`.toLowerCase();

        return this.buildImageSrcsetUsingFilenameInfo(
            basePath,
            filenameBase,
            'jpg',
            this.config.cameraThumbnailSizes
        );
    }

    /**
     * Build the URL for a given animations thumbnail.
     * @param thumbnailPath The relative path to the thumbnail.
     */
    getAnimationThumbnailUrl(thumbnailPath: string | undefined) {
        return thumbnailPath ? this.getThumbnailUrl(thumbnailPath) : '';
    }

    /**
     * Build the URL for a given cinematics thumbnail.
     * @param thumbnailPath The relative path to the thumbnail.
     */
    getFeatureThumbnailUrl(thumbnailPath: string) {
        return this.getThumbnailUrl(thumbnailPath);
    }

    /**
     * Build the URL for a given cinematics thumbnail.
     * @param thumbnailPath The relative path to the thumbnail.
     */
    getCinematicThumbnailUrl(thumbnailPath: string | undefined) {
        return thumbnailPath ? this.getThumbnailUrl(thumbnailPath) : '';
    }

    /**
     * Build the URL for a given environments thumbnail.
     * @param thumbnailPath The relative path to the thumbnail.
     */
    getEnvironmentThumbnailUrl(thumbnailPath: string | undefined) {
        return thumbnailPath ? this.getThumbnailUrl(thumbnailPath) : '';
    }

    /**
     * Build the URL for a given environments thumbnail by environment ID.
     * @param productId The product to get the environment-thumbnail for.
     * @param environmentId The environments ID.
     */
    getEnvironmentThumbnailUrlById(
        productId: string,
        environmentId: string
    ): ImageSrcset {
        if (!this.config.environmentThumbnailSizes) {
            throw new IllegalStateError(
                'Cannot generate srcset for environment-thumbnails as environmentThumbnailSizes is not configured for StaticAssetsService.'
            );
        }

        const basePath = this.getThumbnailUrl(
            `assets/thumbnails/${productId}/environments`
        );
        const filenameBase = `${productId}_${environmentId}`.toLowerCase();

        return this.buildImageSrcsetUsingFilenameInfo(
            basePath,
            filenameBase,
            'jpg',
            this.config.environmentThumbnailSizes
        );
    }

    /**
     * Build the URL for a given animations thumbnail by animation ID.
     * @param productId The product to get the animation-thumbnail for.
     * @param animationId The animations ID.
     */
    getAnimationThumbnailUrlById(
        productId: string,
        animationId: string
    ): ImageSrcset {
        if (!this.config.animationThumbnailSizes) {
            throw new IllegalStateError(
                'Cannot generate srcset for animation-thumbnails as animationThumbnailSizes is not configured for StaticAssetsService.'
            );
        }

        const basePath = this.getThumbnailUrl(
            `assets/thumbnails/${productId}/animations`
        );
        const filenameBase = `${productId}_${animationId}`.toLowerCase();

        return this.buildImageSrcsetUsingFilenameInfo(
            basePath,
            filenameBase,
            'jpg',
            this.config.animationThumbnailSizes
        );
    }

    /**
     * Build the URL for a given animation thumbnail by animation ID, environmentId and productId.
     * @param productId The productId to be used.
     * @param environmentId The environmentId to be used.
     * @param animationId The animations ID.
     */
    getEnvironmentSpecificAnimationThumbnailUrlById(
        productId: string,
        environmentId: string,
        animationId: string
    ): ImageSrcset {
        if (!this.config.animationThumbnailSizes) {
            throw new IllegalStateError(
                'Cannot generate srcset for animation-thumbnails as animationThumbnailSizes is not configured for StaticAssetsService.'
            );
        }

        const basePath = this.getThumbnailUrl(
            `assets/thumbnails/${productId}/animations`
        );
        const filenameBase =
            `${productId}_${environmentId}_${animationId}`.toLowerCase();

        return this.buildImageSrcsetUsingFilenameInfo(
            basePath,
            filenameBase,
            'jpg',
            this.config.animationThumbnailSizes
        );
    }

    /**
     * Build the URL for a given thumbnailPath.
     * @param thumbnailPath The http-server root-relative path to the thumbnail.
     */
    getThumbnailUrl(thumbnailPath: string) {
        return `${this.httpServerUrlSubject.value}${thumbnailPath}`;
    }

    /**
     * Build a url based on the configured http-server root.
     * @param path The relative path to the asset.
     */
    buildServerRootRelativeUrl(path: string) {
        return `${this.httpServerUrlSubject.value}${
            path.startsWith('/') ? path.substring(1) : path
        }`;
    }

    /**
     * Build an ImageSrcset using a urlFactory function.
     * @param sizes The sizes to generate entries for
     * @param urlFactory The factory that provides the url for a given size.
     */
    buildImageSrcset(
        sizes: number[],
        urlFactory: (
            size: number,
            resolutionPostfix: string,
            index: number
        ) => string
    ): ImageSrcset {
        return {
            sources: sizes.map((size, index): ImageSrcsetEntry => {
                const resolutionPostfix = index === 0 ? '' : `@${index + 1}`;
                return {
                    url: urlFactory(size, resolutionPostfix, index),
                    targetWidth: size
                };
            })
        };
    }

    /**
     * Build an ImageSrcset using the given information. For the different sizes that are provided, @2, @3, @4, ... postfixes are appended to the filenameBase.
     * E.g. sizes: [100, 200, 300] with filenameBase: 'example' and filenameExtension: 'jpg' will result in ['example.jpg', 'example@2.jpg', 'example@3.jpg']
     * @param basePath The base path to be used to prepend to the filenameBase
     * @param filenameBase The filename to be used
     * @param filenameExtension The extension of the file
     * @param sizes The sizes to generate entries for
     */
    buildImageSrcsetUsingFilenameInfo(
        basePath: string,
        filenameBase: string,
        filenameExtension: string,
        sizes: number[]
    ): ImageSrcset {
        const basePathAdjusted = this.ensureEndsWithSlash(basePath);
        return this.buildImageSrcset(
            sizes,
            (size, resolutionPostfix) =>
                `${basePathAdjusted}${filenameBase}${resolutionPostfix}.${filenameExtension}`
        );
    }

    private checkPlaceholderImageAvailability() {
        if (!this.httpClient) {
            console.log(
                'StaticAssetsService: HttpClient is not provided so cannot check for placeholder image availability.'
            );
        }
        this.httpClient
            .get(this.placeholderImageUrl, {
                observe: 'response',
                responseType: 'blob'
            })
            .subscribe({
                error: (error) => {
                    console.warn(
                        `StaticAssetsService: Failed fetching placeholder image. Make sure to make it accessible at ${this.placeholderImageUrl}`
                    );
                }
            });
    }

    private ensureEndsWithSlash(pathLikeString: string) {
        if (!pathLikeString.endsWith('/')) {
            return `${pathLikeString}/`;
        }
        return pathLikeString;
    }
}
