import isEqual from 'lodash/isEqual';
import {
    ConnectableObservable,
    MonoTypeOperatorFunction,
    Observable,
    OperatorFunction,
    throwError,
    timer
} from 'rxjs';
import {
    distinctUntilChanged,
    finalize,
    map,
    mergeMap,
    publishReplay,
    shareReplay,
    take,
    tap
} from 'rxjs/operators';

import { NgZone } from '@angular/core';

/**
 * Pipes the given source Observable through publishReplay and connects to the resulting ConnectableObservable
 * to make the stream hot.
 * Note that the observable sequence will only complete, when the source observable completes.
 * @param unsubscribeWhen An optional observable that controls unsubscription from the underlying stream. When this stream emits an item,
 * completes or errors out, the shared subscription to the underlying stream is unsubscribed.
 */
export function connectedPublishReplay<T>(
    unsubscribeWhen?: Observable<any>
): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>) => {
        const publishReplayedSource$ = source.pipe(publishReplay(1));
        const hotSubscription = (<ConnectableObservable<T>>(
            publishReplayedSource$
        )).connect();
        if (unsubscribeWhen) {
            unsubscribeWhen
                .pipe(
                    take(1),
                    finalize(
                        () =>
                            !hotSubscription.closed &&
                            hotSubscription.unsubscribe()
                    )
                )
                .subscribe(
                    () =>
                        !hotSubscription.closed && hotSubscription.unsubscribe()
                );
        }
        return publishReplayedSource$;
    };
}

/**
 * Share the given source observable. Connects to the source observable when a subscriber is subscribed,
 * disconnects from the source observable, when the last subscriber disconnects.
 * See https://itnext.io/the-magic-of-rxjs-sharing-operators-and-their-differences-3a03d699d255
 */
export function lazyShareReplay<T>(): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>) => source.pipe(shareReplay({ refCount: true, bufferSize: 1 }));
}

/**
 * Simply negate the incoming boolean (or undefined) value.
 */
export function negate(): OperatorFunction<boolean | undefined, boolean> {
    return (source) => source.pipe(map((value) => !value));
}

/**
 * Distinct until changed using deep-equality check.
 */
export function distinctUntilChangedEquality<T>(): MonoTypeOperatorFunction<T> {
    return (source) => source.pipe(distinctUntilChanged(isEqual));
}

/**
 * Steps into the given NgZone.
 * @param ngZone The ngZone to be used.
 */
export function runInZone<T>(ngZone: NgZone): MonoTypeOperatorFunction<T> {
    return (source) => new Observable((observer) => source.subscribe({
                next: (value: T) => ngZone.run(() => observer.next(value)),
                error: (error: any) => ngZone.run(() => observer.error(error)),
                complete: () => ngZone.run(() => observer.complete())
            }));
}

/**
 * May be used for debugging purposes to look into an emitted value.
 */
export function tapDebug<T>(): MonoTypeOperatorFunction<T> {
    return (source) => source.pipe(
            tap((value) => {
                // eslint-disable-next-line no-debugger
                debugger;
            })
        );
}

export const genericRetryStrategy =
    ({
        maxRetryAttempts = 3,
        scalingDuration = 1000,
        excludedStatusCodes = []
    }: {
        maxRetryAttempts?: number;
        scalingDuration?: number;
        excludedStatusCodes?: number[];
    } = {}) =>
    (attempts: Observable<any>) => attempts.pipe(
            mergeMap((error, i) => {
                const retryAttempt = i + 1;
                // if maximum number of retries have been met
                // or response is a status code we don't wish to retry, throw error
                if (
                    retryAttempt > maxRetryAttempts ||
                    excludedStatusCodes.find((e) => e === error.status)
                ) {
                    return throwError(error);
                }
                // retry after 1s, 2s, etc...
                return timer(retryAttempt * scalingDuration);
            })
        );
