<div
    #gridListItem
    class="ui-grid-list-item"
    [ngClass]="{
        'ui-grid-list-item--selected': selected,
        'ui-grid-list-item--disabled': disabled,
        'ui-grid-list-item--readonly': readonly,
        'ui-grid-list-item--has-title': !!title,
        'ui-grid-list-item--has-subtitle': !!subtitle,
        'ui-grid-list-item--has-fallback-image': fallbackImageLoaded
    }"
    (click)="onIntentSelect()"
    (keyup.enter)="onIntentSelect()"
    [attr.role]="uiGridListComponentParent ? 'listitem' : ''"
    [attr.tabindex]="disabled || readonly ? -1 : index"
>
    <ng-content select="mhp-ui-grid-list-item__extension-top"></ng-content>
    <div
        class="ui-grid-list-item__image"
        mat-ripple
        [matRippleDisabled]="disabled || readonly"
    >
        <mhp-ui-lazyload-image
            #lazyloadImage
            [imageFit]="imageFit"
            [imageUrls]="imageUrl"
            [imageFallbackUrl]="
                imageFallbackUrl || lazyloadImage.imageFallbackUrl
            "
            [aspectRatio]="imageAspectRatio"
            [willResize]="willResize"
            [title]="title"
            (stateChange)="
                $event === 'loading-failed' ? onImageLoadFailed() : undefined
            "
        ></mhp-ui-lazyload-image>
        <mhp-ui-selected-marker
            [selected]="selected"
            [color]="color"
            [markerSize]="'xxsmall'"
            [markerIconId]="selectedMarkerIconId"
        ></mhp-ui-selected-marker>
        <div *ngIf="iconId" class="ui-grid-list-item__icon">
            <mhp-ui-icon [svgIcon]="iconId" size="small"></mhp-ui-icon>
        </div>
        <ng-content
            select="mhp-ui-grid-list-item__image-extension"
        ></ng-content>
    </div>
    <div
        *ngIf="title && showTitleOnImageFallback"
        class="ui-grid-list-item__image-footer"
    >
        <div class="ui-grid-list-item__title">{{ title }}</div>
        <div *ngIf="!!subtitle" class="ui-grid-list-item__subtitle">
            {{ subtitle }}
        </div>
    </div>
    <ng-content select="mhp-ui-grid-list-item__extension-bottom"></ng-content>
</div>
