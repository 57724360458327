<mhp-dialog-header [hideTopGradient]="true"></mhp-dialog-header>
<mhp-ui-mat-dialog
    [title]="t('REGION_LANG_SELECT.HEADLINE')"
    [showClose]="false"
    *transloco="let t; read: 'COMMON'"
>
    <mhp-ui-mat-dialog-content>
        <div class="region-selector" *transloco="let t; read: 'COMMON'">
            <p>
                {{ t('SELECT_REGION.HEADLINE_SUB') }}
            </p>
            <form [formGroup]="formGroup">
                <div
                    class="region-selector__block region-selector__block--regions"
                >
                    <mat-form-field
                        class="mhp-ui-form-field--full-width mhp-ui-form-field--no-bottom-spacing"
                    >
                        <mat-label>{{ t('SELECT_REGION.HEADLINE') }}</mat-label>
                        <mat-select formControlName="region">
                            <mat-option
                                *ngFor="
                                    let region of getAvailableRegions$() | async
                                "
                                [value]="region.id"
                                >{{ region.label }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
                <div
                    class="region-selector__block region-selector__block--languages"
                >
                    <mat-form-field
                        class="mhp-ui-form-field--full-width mhp-ui-form-field--no-bottom-spacing"
                    >
                        <mat-label>{{
                            t('SELECT_LANGUAGE.HEADLINE')
                        }}</mat-label>
                        <mat-select formControlName="language">
                            <mat-option
                                *ngFor="let language of availableLanguages"
                                [value]="language"
                                >{{
                                    t('LANGUAGE.' + language.toUpperCase())
                                }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
        </div>
    </mhp-ui-mat-dialog-content>
    <mhp-ui-mat-dialog-actions-left *transloco="let t; read: 'COMMON'">
        <button
            *ngIf="!matDialogRef.disableClose"
            mat-button
            (click)="intentClose()"
        >
            {{ t('CANCEL') }}
        </button>
    </mhp-ui-mat-dialog-actions-left>
    <mhp-ui-mat-dialog-actions-right>
        <button
            mat-flat-button
            [disabled]="!formGroup.valid"
            (click)="intentConfirm()"
        >
            <span>{{ t('CONFIRM') }}</span>
        </button>
    </mhp-ui-mat-dialog-actions-right>
</mhp-ui-mat-dialog>
