import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Optional,
    Output,
    ViewChild
} from '@angular/core';
import { CanColor, ThemePalette } from '@angular/material/core';

import { ImageSrcset } from '../../common/ui-image-srcset/ui-image-srcset.pipe';
import { UiGridListComponent } from '../ui-grid-list.component';

@Component({
    selector: 'mhp-ui-grid-list-item',
    templateUrl: './ui-grid-list-item.component.html',
    styleUrls: ['./ui-grid-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiGridListItemComponent implements CanColor {
    @Input()
    selected: boolean;

    @Input()
    imageUrl: string | ImageSrcset | ImageSrcset[] | undefined;

    @Input()
    imageFallbackUrl?: string;

    @Input()
    imageAspectRatio?: number | undefined;

    @Input()
    title?: string | undefined;

    @Input()
    showTitleOnImageFallback = true;

    @Input()
    subtitle?: string;

    @Input()
    disabled? = false;

    @Input()
    readonly? = false;

    @Input()
    iconId?: string | undefined;

    @Input()
    selectedMarkerIconId: string | undefined = 'mhp-ui:check';

    @Input()
    color: ThemePalette;

    @Input()
    defaultColor: ThemePalette;

    @Input()
    index = 0;

    @Input()
    imageFit: 'cover' | 'contain' = 'cover';

    @Input()
    willResize = false;

    @Output()
    readonly intentSelect = new EventEmitter<void>();

    @ViewChild('gridListItem')
    gridListItem!: ElementRef<HTMLElement>;

    fallbackImageLoaded = false;

    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly elementRef: ElementRef,
        @Optional()
        public readonly uiGridListComponentParent?: UiGridListComponent
    ) {}

    onIntentSelect() {
        if (this.disabled || this.readonly) {
            return;
        }
        this.intentSelect.emit();
    }

    getImageContainerAspectPadding() {
        if (this.imageAspectRatio) {
            return `${100 / this.imageAspectRatio}%`;
        }
        return 0;
    }

    onImageLoadFailed() {
        this.fallbackImageLoaded = true;
        this.changeDetectorRef.detectChanges();
    }

    scrollIntoView(options?: ScrollIntoViewOptions) {
        this.elementRef?.nativeElement?.scrollIntoView(options);
    }

    focus() {
        this.gridListItem.nativeElement.focus();
    }
}
