import { last } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { IllegalStateError } from '@mhp/common';

export interface MetaSource {
    name?: string;
    property?: string;
    source$: Observable<string>;
}

export interface SiteMetaProvider {
    getTitle$?: () => Observable<string>;
    getDescription$?: () => Observable<string>;
    getMetaTagSources?: () => MetaSource[];
}

/**
 * Service to be used to add social-sharing metadata to the pages markup.
 */
@Injectable({
    providedIn: 'root'
})
export class SiteMetaService {
    private providerStack: SiteMetaProvider[] = [];

    private provider?: SiteMetaProvider;

    private subscriptions: Subscription[] = [];

    constructor(private readonly title: Title, private readonly meta: Meta) {}

    pushSiteMetaProvider(provider: SiteMetaProvider): () => void {
        this.providerStack.push(provider);
        this.assignLatestProvider();

        return () => {
            this.providerStack = this.providerStack.filter(
                (providerFromStack) => providerFromStack !== provider
            );
            this.assignLatestProvider();
        };
    }

    private assignLatestProvider() {
        this.provider = last(this.providerStack);

        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
        );

        if (!this.provider) {
            this.subscriptions = [];
            return;
        }

        let subscriptions: Subscription[] = [];

        if (this.provider.getTitle$) {
            subscriptions.push(
                this.createTitleBinding(this.provider.getTitle$())
            );
        }
        if (this.provider.getDescription$) {
            subscriptions.push(
                this.createMetaBinding(
                    'description',
                    this.provider.getDescription$()
                )
            );
        }

        if (this.provider.getMetaTagSources) {
            const additionalMetaTagSources = this.provider.getMetaTagSources();
            subscriptions = [
                ...subscriptions,
                ...additionalMetaTagSources.map((source) => {
                    const tagName = source.property ?? source.name;

                    if (!tagName) {
                        throw new IllegalStateError(
                            'Either property or name have to be provided'
                        );
                    }

                    return this.createMetaBinding(
                        tagName,
                        source.source$,
                        source.name ? 'name' : 'property'
                    );
                })
            ];
        }

        this.subscriptions = subscriptions;
    }

    private createMetaBinding(
        metaTag: string,
        source$: Observable<string>,
        nameOrProperty: 'name' | 'property' = 'name'
    ) {
        return new Observable<string>((subscriber) => {
            const previousTag = this.meta.getTag(
                `${nameOrProperty}='${metaTag}'`
            );
            const previousContent = previousTag?.getAttribute('content');

            const subscription = source$.subscribe((value) =>
                subscriber.next(value)
            );

            return () => {
                // restore previous tag
                const currentTag = this.meta.getTag(
                    `${nameOrProperty}='${metaTag}'`
                );
                if (currentTag) {
                    this.meta.removeTagElement(currentTag);
                }
                if (previousContent) {
                    const tagMeta: MetaDefinition = {
                        content: previousContent
                    };
                    tagMeta[nameOrProperty] = metaTag;
                    this.meta.addTag(tagMeta);
                }

                subscription.unsubscribe();
            };
        })
            .pipe(distinctUntilChanged())
            .subscribe((value) => {
                const existingTag = this.meta.getTag(
                    `${nameOrProperty}='${metaTag}'`
                );
                if (existingTag) {
                    this.meta.removeTagElement(existingTag);
                }
                const tagDefinition: MetaDefinition = {
                    content: value
                };

                if (nameOrProperty === 'name') {
                    tagDefinition.name = metaTag;
                } else {
                    tagDefinition.property = metaTag;
                }

                this.meta.addTag(tagDefinition);
            });
    }

    private createTitleBinding(source$: Observable<string>) {
        return new Observable<string>((subscriber) => {
            const previousTitle = this.title.getTitle();

            const subscription = source$.subscribe((value) =>
                subscriber.next(value)
            );

            return () => {
                // restore previous tag
                this.title.setTitle(previousTitle);

                subscription.unsubscribe();
            };
        })
            .pipe(distinctUntilChanged())
            .subscribe((value) => {
                this.title.setTitle(value);
            });
    }
}
