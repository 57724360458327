import { EMPTY, Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import {
    ComponentFactoryResolver,
    Injectable,
    ViewContainerRef
} from '@angular/core';
import { ApplicationStateService } from '@mhp/ui-shared-services';

import { ClosableMenuInterface } from '../configuration/configuration-menu/closable-menu.interface';
import { LocalApplicationState } from '../state/local-application-state.interface';
import { setMenuActive } from './state';
import { MenuScope } from './state/common-menu-state.interface';
import {
    selectCommonMenuState,
    selectMenuScope
} from './state/selectors/common-menu.selectors';

export interface MenuContextProvider<T extends ClosableMenuInterface> {
    handles(scope: MenuScope): boolean;
    getMenuComponent(): ComponentType<T>;
    getViewContainerRef(): ViewContainerRef;
    getComponentFactoryResolver(): ComponentFactoryResolver;

    /**
     * Return an observable that emits when the context of the underlying context-provider gets cleaned up (e.g. component gets destroyed and context for menu is no longer relevant)
     */
    getCleanup$(): Observable<void>;
}

/**
 * This service is required to be eagerly initialized to handle opening / closing
 * the application menu based on the given application state.
 * Resets the active menu entry when menu is closed.
 */
@Injectable({
    providedIn: 'root'
})
export class MenuService {
    private providers: MenuContextProvider<ClosableMenuInterface>[] = [];

    constructor(
        private applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly overlay: Overlay
    ) {
        this.initMenuStateChangeLogic();
    }

    registerViewContainerRefProvider<T extends ClosableMenuInterface>(
        provider: MenuContextProvider<T>
    ): () => void {
        this.providers = [...this.providers, provider];

        return () => {
            this.providers = this.providers.filter(
                (currentProvider) => provider !== currentProvider
            );
        };
    }

    /**
     * Initialize logic that handles opening and closing the configuration-menu
     * component based on the local state.
     */
    private initMenuStateChangeLogic() {
        return combineLatest([
            this.getMenuActiveState$(),
            this.applicationStateService.getLocalState().pipe(selectMenuScope)
        ])
            .pipe(
                distinctUntilChanged(),
                switchMap(([menuActive, menuScope]) => {
                    if (!menuActive || !menuScope) {
                        return EMPTY;
                    }

                    const provider = this.getMenuContextProvider(menuScope);

                    if (!provider) {
                        return EMPTY;
                    }

                    return new Observable<OverlayRef>((subscriber) => {
                        const overlayRef = this.overlay.create({
                            hasBackdrop: true,
                            backdropClass: 'mhp-configuration-menu__backdrop',
                            scrollStrategy:
                                this.overlay.scrollStrategies.block(),
                            height: '100vh'
                        });
                        const attachedPortal = overlayRef.attach(
                            new ComponentPortal(
                                provider.getMenuComponent(),
                                provider.getViewContainerRef(),
                                null,
                                provider.getComponentFactoryResolver()
                            )
                        );

                        overlayRef.backdropClick().subscribe(() => {
                            this.applicationStateService.dispatch(
                                setMenuActive({
                                    menuActive: false
                                })
                            );
                        });

                        subscriber.next(overlayRef);

                        return () => {
                            attachedPortal.instance
                                .prepareClose$()
                                .subscribe(() => {
                                    overlayRef.dispose();
                                    // ensure menu-active state is reset when menu is closed implicitly
                                    this.applicationStateService.dispatch(
                                        setMenuActive({
                                            menuActive: false
                                        })
                                    );
                                });
                        };
                    });
                })
            )
            .subscribe();
    }

    private getMenuContextProvider(scope: MenuScope) {
        return this.providers.find((provider) => provider.handles(scope));
    }

    private getMenuActiveState$() {
        return this.applicationStateService.getLocalState().pipe(
            selectCommonMenuState,
            map((menuState) => menuState.menuActive)
        );
    }
}
