import { ModuleWithProviders, NgModule } from '@angular/core';
import { Action, ActionReducer, RuntimeChecks, StoreModule } from '@ngrx/store';
import { StoreDevtoolsConfig, StoreDevtoolsModule } from '@ngrx/store-devtools';

import {
    engineApplicationStateReducer,
    localSharedApplicationStateReducer
} from './reducers';

/**
 * Defines the type of the local state when initializing ApplicationStateModule.
 */
export interface ReducerDefinition<S, A extends Action> {
    local: ActionReducer<S, A>;
}

/**
 * Optional configuration for the ApplicationStateModule which allows enabling
 * several dev-tools enhancements.
 */
export interface ApplicationStateModuleConfig {
    devTools?: {
        enabled: boolean;
        instrumentationOptions?: Partial<StoreDevtoolsConfig>;
        runtimeChecks?: Partial<RuntimeChecks>;
    };
}

@NgModule({
    imports: []
})
export class ApplicationStateModule {
    /**
     * Has to be called in the apps root-module to initialize correct
     * application state handling (shared/remote and local).
     * @param reducerDefinition The ReducerDefinition that defines the type of local state to expect.
     * @param config An optional config that allows enabling additional features.
     */
    static forRoot<S, A extends Action>(
        reducerDefinition: ReducerDefinition<S, A>,
        config?: ApplicationStateModuleConfig
    ): ModuleWithProviders<StoreModule | StoreDevtoolsModule>[] {
        /**
         * Sorry for this messy code, but AOT does not allow declaring
         * variables here or externalizing initialization to dedicated functions.
         * Everything has to be done inline.
         * This will change with Ivy, so please refactor when upgrading to Angular 9+
         */
        return [
            StoreModule.forRoot(
                {
                    ...reducerDefinition,
                    engine: engineApplicationStateReducer,
                    localShared: localSharedApplicationStateReducer
                },
                config &&
                    config.devTools &&
                    config.devTools.enabled &&
                    config.devTools.runtimeChecks
                    ? { runtimeChecks: config.devTools.runtimeChecks }
                    : undefined
            ),
            StoreDevtoolsModule.instrument({
                name: 'MHP Configurator',
                maxAge: 25,
                logOnly: true,
                ...((config &&
                    config.devTools &&
                    config.devTools.enabled &&
                    config.devTools.instrumentationOptions) ||
                    {}),
                connectInZone: false
            })
        ];
    }
}
