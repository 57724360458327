export enum StreamStatus {
    CONNECTING = 'CONNECTING',
    CONNECTED = 'CONNECTED',
    DISCONNECTED = 'DISCONNECTED',
    DISCONNECTING = 'DISCONNECTING'
}

export interface StreamingState {
    // the base url that points to the connected engine-host, e.g. https://experience.mhp.com. May be undefined in case application is not connected to an engine instance
    engineHostUrl?: string;
    streamStatus: StreamStatus;
}
