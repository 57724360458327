import { Observable, Subject, from, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { Inject, Injectable, InjectionToken } from '@angular/core';
import { NavigationExtras, Router, UrlTree } from '@angular/router';
import { ApplicationStateMachineState } from '@mhp/communication-models';

import {
    ApplicationStateService,
    selectApplicationStateMachineState
} from '../application-state';

export interface ApplicationStateRoutingMapper {
    /**
     * Resolve the target route based on a given ApplicationStateMachineState.
     * The resolve process may take other parameters  into account and is free to
     * return an Observable that emits route-updates in between state-transition changes.
     *
     * @param state The state that's currently valid
     */
    getTargetRouteForState(
        state: ApplicationStateMachineState
    ):
        | Observable<string | UrlTree | undefined>
        | Promise<string | UrlTree | undefined>;
}

export const APPLICATION_STATE_ROUTING_MAPPER =
    new InjectionToken<ApplicationStateRoutingMapper>(
        'ApplicationStateRoutingMapper'
    );

/**
 * This service is a simple wrapper around Router to ensure the URL is not updated.
 * This is crucial as every navigation triggered is based on application state and
 * thus updating the URL and possibly reloading the page based on this URL has no
 * meaning at all.
 */
@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    readonly navigationErrors$: Observable<any>;

    private readonly navigationErrorsSubject = new Subject<any>();

    constructor(
        private router: Router,
        private applicationStateService: ApplicationStateService<any>,
        @Inject(APPLICATION_STATE_ROUTING_MAPPER)
        private applicationStateRoutingMapper: ApplicationStateRoutingMapper
    ) {
        this.navigationErrors$ = this.navigationErrorsSubject.asObservable();

        this.initApplicationStateBasedNavigation();
    }

    /**
     * See Router#navigateByUrl
     * @param url
     * @param extras
     */
    navigateByUrl(
        url: string | UrlTree,
        extras?: NavigationExtras
    ): Promise<boolean> {
        return this.router.navigateByUrl(url, {
            ...extras,
            skipLocationChange: true
        });
    }

    private initApplicationStateBasedNavigation() {
        const routeResolver = (applicationStateMachineState) =>
            this.applicationStateRoutingMapper.getTargetRouteForState(
                applicationStateMachineState
            );

        this.applicationStateService
            .getState()
            .pipe(
                selectApplicationStateMachineState,
                switchMap((applicationStateMachineState) => {
                    const routeResolvable = routeResolver(
                        applicationStateMachineState
                    );

                    let resultResolvable$: Observable<
                        string | UrlTree | undefined
                    >;

                    if (routeResolvable instanceof Promise) {
                        resultResolvable$ = from(routeResolvable);
                    } else {
                        resultResolvable$ = routeResolvable;
                    }
                    return resultResolvable$.pipe(
                        catchError((error) => {
                            this.navigationErrorsSubject.next(error);
                            return of(undefined);
                        }),
                        tap((targetRoute) => {
                            if (!targetRoute) {
                                return;
                            }

                            this.navigateByUrl(targetRoute).catch((error) =>
                                this.navigationErrorsSubject.next(error)
                            );
                        })
                    );
                })
            )
            .subscribe();
    }
}
