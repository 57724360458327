import deepLock from 'deep-lock';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Identifiable } from '@mhp-immersive-exp/contracts/src/generic/identifiable.interface';
import { CustomError, IllegalStateError, lazyShareReplay } from '@mhp/common';
import { ExtendedPlatform } from '@mhp/ui-shared-services';

const SOCIAL_SHARING_URLS: Record<string, string> = {
    facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
    twitter: 'https://twitter.com/intent/tweet?url=',
    linkedin: 'https://www.linkedin.com/shareArticle?mini=true&url=',
    whatsapp: 'https://wa.me/?text=',
    weibo: 'https://service.weibo.com/share/share.php?url=',
    wechat: 'https://api.qrserver.com/v1/create-qr-code/?size=512x512&data=',
    line: 'https://lineit.line.me/share/ui?url=',
    vk: 'https://vk.com/share.php?url='
};

export interface SocialServiceModel extends Identifiable {
    name: string;
    iconId: string;
}

@Injectable({
    providedIn: 'root'
})
export class SocialSharingService {
    constructor(
        private readonly webShareService: NgNavigatorShareService,
        private readonly platform: ExtendedPlatform
    ) {}

    /**
     * Emit a list of SocialServiceModels that are supported in the current context,
     * taking into account:
     * - active region
     * - user-navigator
     */
    getSupportedServices$(
        activeCountry$: Observable<string | undefined>
    ): Observable<Readonly<SocialServiceModel[]>> {
        return activeCountry$.pipe(
            map((activeRegion) => {
                if (!activeRegion) {
                    throw new IllegalStateError('Expected active region');
                }
                let socialServices: SocialServiceModel[] = [];

                if (
                    !this.platform.IPAD_OS ||
                    !this.platform.IOS ||
                    !this.webShareService.canShare()
                ) {
                    socialServices = [
                        ...socialServices,
                        {
                            id: 'facebook',
                            name: 'Facebook',
                            iconId: 'facebook'
                        },
                        {
                            id: 'twitter',
                            name: 'X',
                            iconId: 'twitter'
                        },
                        {
                            id: 'linkedin',
                            name: 'LinkedIn',
                            iconId: 'linked_in'
                        },
                        {
                            id: 'whatsapp',
                            name: 'WhatsApp',
                            iconId: 'wa'
                        }
                    ];

                    switch (activeRegion) {
                        case 'CN':
                            socialServices = [
                                {
                                    id: 'weibo',
                                    name: 'Weibo',
                                    iconId: 'weibo'
                                },
                                {
                                    id: 'wechat',
                                    name: 'WeChat',
                                    iconId: 'wechat2'
                                },
                                ...socialServices
                            ];
                            break;
                        case 'JP':
                            socialServices = [
                                {
                                    id: 'line',
                                    name: 'Line',
                                    iconId: 'line'
                                },
                                ...socialServices
                            ];
                            break;
                        case 'RU':
                            socialServices = [
                                {
                                    id: 'vk',
                                    name: 'VK',
                                    iconId: 'vk'
                                },
                                ...socialServices
                            ];
                            break;
                        default:
                            break;
                    }
                }

                if (this.webShareService?.canShare()) {
                    socialServices = [
                        {
                            id: 'webshare',
                            name: 'Device',
                            iconId: 'share'
                        },
                        ...socialServices
                    ];
                }

                return deepLock(socialServices);
            }),
            lazyShareReplay()
        );
    }

    /**
     * Share the given information using a given social service or the Web-Sharing API.
     * @param serviceId
     * @param url
     * @param title
     * @param description
     */
    intentShareToSocial(
        serviceId: 'webshare' | string,
        url: string,
        title: string,
        description: string
    ): Observable<void> {
        if (serviceId === 'webshare') {
            return from(
                this.webShareService.share({
                    title,
                    text: description,
                    url
                })
            ).pipe(
                map(() => undefined),
                catchError((error) => {
                    if (error.error) {
                        return throwError(error.error);
                    }
                    return throwError(error);
                })
            );
        }
        const socialSharingUrlBase = SOCIAL_SHARING_URLS[serviceId];
        if (!socialSharingUrlBase) {
            throwError(
                new IllegalStateError(`Unknown serviceId: ${serviceId}`)
            );
        }
        const windowRef = window.open(socialSharingUrlBase + url, '_blank');
        return windowRef?.closed
            ? throwError(
                  new CustomError(
                      'Failed opening window to open sharing url',
                      socialSharingUrlBase + url
                  )
              )
            : of(undefined);
    }
}
