import { createAction, props } from '@ngrx/store';

import { StreamStatus } from '../streaming-state.interface';

/**
 * Set the streaming-status.
 */
export const setStreamStatus = createAction(
    '[LOCAL-STATE]: setStreamStatus',
    props<{ status: StreamStatus }>()
);

/**
 * Set the engineHostUrl.
 */
export const setEngineHostUrl = createAction(
    '[LOCAL-STATE]: setEngineHostUrl',
    props<{ engineHostUrl: string | undefined }>()
);
