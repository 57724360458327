import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationResponsePayload } from '@mhp-immersive-exp/contracts/src/configuration/configuration-response.interface';
import { recursiveDesctructureProjectParameters } from '@mhp-immersive-exp/sdk/utils';
import { AmlProjectReturn } from '@mhp/aml-shared/infor/models/infor-project-return.interface';
import { MemoizeObservable } from '@mhp/common';

@Injectable({
    providedIn: 'root'
})
export class CpqSessionHandlerService implements HttpInterceptor {
    private readonly sessionIdSubject = new BehaviorSubject<string | undefined>(
        undefined
    );

    private readonly configurationDetailIdSubject = new BehaviorSubject<
        string | undefined
    >(undefined);

    // subject emitting in case there is an intent to restart the current cpq-session
    private readonly sessionRestartIntentSubject = new Subject<void>();

    @MemoizeObservable()
    getSessionId$() {
        return this.sessionIdSubject
            .asObservable()
            .pipe(distinctUntilChanged());
    }

    @MemoizeObservable()
    getConfigurationDetailId$() {
        return this.configurationDetailIdSubject
            .asObservable()
            .pipe(distinctUntilChanged());
    }

    /**
     * Emits in case the current session-id and configuration-detail-id should get refreshed.
     */
    getSessionRestartIntent$(): Observable<void> {
        return this.sessionRestartIntentSubject.asObservable();
    }

    /**
     * Implement HttpInterceptor contract for use in ProductConfigurationHttpStrategyConfig#httpInterceptor
     *
     * Intercepting requests to the ruler, we are able to append and extract CPQ-related session infos.
     *
     * @param req Request to be intercepted.
     * @param next Handler to be called to execute the original request.
     */
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let adjustedHttpParams = this.sessionIdSubject.value
            ? req.params.appendAll(
                  recursiveDesctructureProjectParameters(
                      {
                          sessionId: this.sessionIdSubject.value
                      },
                      {} as Record<string, string>,
                      'projectParameters'
                  )
              )
            : req.params;

        // append unique request-id to avoid getting a response-copy from the local cache
        adjustedHttpParams = adjustedHttpParams.appendAll({
            reqId: uuidv4()
        });

        const adjustedRequest = req.clone({
            params: adjustedHttpParams
        });

        return next.handle(adjustedRequest).pipe(
            tap((response) => {
                if (!(response instanceof HttpResponse)) {
                    return;
                }

                // extract sessionId and configurationDetailId
                const configurationResponse = <
                    ConfigurationResponsePayload<AmlProjectReturn>
                >response?.body;

                const sessionId =
                    configurationResponse?.projectReturn?.sessionId;
                const configurationDetailId =
                    configurationResponse?.projectReturn?.configurationDetailId;

                if (sessionId) {
                    this.sessionIdSubject.next(sessionId);
                }
                if (configurationDetailId) {
                    this.configurationDetailIdSubject.next(
                        configurationDetailId
                    );
                }
            })
        );
    }

    /**
     * Discard the currently valid sessionId and configurationDetailId and
     * replace it with new IDs for the currently active configuration.
     */
    restartCurrentConfigurationSession() {
        this.resetConfigurationSession();
        this.sessionRestartIntentSubject.next();
    }

    resetConfigurationSession() {
        this.sessionIdSubject.next(undefined);
        this.configurationDetailIdSubject.next(undefined);
    }
}
