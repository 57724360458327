import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    Input
} from '@angular/core';
import {
    MAT_SNACK_BAR_DATA,
    MatSnackBarRef
} from '@angular/material/snack-bar';

import {
    UI_NOTIFICATION_CONFIG,
    UiNotificationConfig
} from './ui-notification-config.interface';

export interface NotificationData {
    message: string;
    actionLabel: string;
    renderActionNewline?: boolean;
    showClose?: boolean;
}

@Component({
    selector: 'mhp-ui-notification',
    templateUrl: './ui-notification.component.html',
    styleUrls: ['./ui-notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiNotificationComponent<
    D extends NotificationData = NotificationData
> {
    @Input()
    message: string;

    @Input()
    actionLabel?: string;

    @Input()
    showClose = true;

    @Input()
    renderActionNewline = false;

    readonly closeIconId: string;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) protected data: D,
        protected snackBarRef: MatSnackBarRef<UiNotificationComponent>,
        @Inject(UI_NOTIFICATION_CONFIG)
        config: UiNotificationConfig
    ) {
        if (!data) {
            throw new Error('Missing NotificationData.');
        }
        this.message = data.message;
        this.actionLabel = data.actionLabel;
        this.showClose = data.showClose ?? true;
        this.renderActionNewline = data.renderActionNewline ?? false;
        this.closeIconId = config.closeIconId;
    }

    intentClose(event?: MouseEvent | KeyboardEvent) {
        event?.stopPropagation();

        this.snackBarRef.dismiss();
    }

    intentTriggerAction() {
        this.snackBarRef.dismissWithAction();
    }
}
