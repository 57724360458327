import { Identifiable } from '@mhp-immersive-exp/contracts/src/generic/identifiable.interface';
import { ImageSrcset } from '@mhp/ui-components';

import { CategoryItem } from '../../configuration/categories';

export enum BrandStoreMirrorModeTargetState {
    MIRROR_OFF,
    MIRROR_OPTIONS,
    MIRROR_INTERIOR_VIEW
}

/**
 * State defining a mirrored option.
 */
export interface BrandStoreMirroredOption extends Identifiable {
    thumbnailImage: ImageSrcset | string | undefined;
    subType: 'text' | 'material' | 'image';
    label: string;
    selected?: boolean;
}

/**
 * State defining the state when mirroring options.
 */
export interface BrandStoreMirrorOptionsState {
    topLevelCategoryLabel?: string;
    firstLevelCategoryLabel?: string;
    secondLevelCategoryLabel?: string;
    mirroredOptions?: BrandStoreMirroredOption[];
}

/**
 * State defining which interior rendering is displayed.
 */
interface BrandStoreMirrorInteriorState {
    rendering: ImageSrcset;
}

/**
 * State defining the root for brand-store related mirror-information.
 */
export interface BrandStoreMirrorState {
    targetState?: BrandStoreMirrorModeTargetState;
    mirrorOptionsState?: BrandStoreMirrorOptionsState;
    mirrorInteriorState?: BrandStoreMirrorInteriorState;
}

export type ReducedCategoryItem = Pick<
    CategoryItem,
    'id' | 'selected' | 'label' | 'isCategorySelector'
> & {
    children?: ReducedCategoryItem[];
};

/**
 * State describing the current category-navigation-state.
 */
export interface BrandStoreCategoryNavigationState {
    // translated label for the category-menu header. This can be dropped in case translation-support and lang-sync is available for the mirror-ui
    categoryMenuHeader?: string;
    // the current step (0 - x)
    currentStep: number;
    // the amount of total steps available for category-navigation
    totalSteps: number;
    // the actual category-items that are currently active
    categoryItems?: ReducedCategoryItem[];
}

/**
 * State definition for the state in use for the VR options panel.
 */
interface BrandStoreMirrorVrState {
    categoryNavigationState?: BrandStoreCategoryNavigationState;
}

/**
 * Shared UI-State used to communicate the required state-details to the AML Brand-Store Screen-UI.
 */
export interface BrandStoreSharedUiState {
    // vrState
    vrActive?: boolean;
    // options currently mirrored
    mirrorState?: BrandStoreMirrorState;
    // mirrored vr-related state
    mirrorVrState?: BrandStoreMirrorVrState;
}
