import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
    declarations: [],
    imports: [CommonModule, MatTabsModule],
    exports: [MatTabsModule]
})
export class UiTabsModule {}
