import { Observable, of, take } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UiNotificationService } from '@mhp/ui-components';

import { REGION_GLOBAL } from './region-constants';
import { RegionService } from './region.service';

@Injectable({
    providedIn: 'root'
})
export class RegionSelectorGuard  {
    constructor(
        private readonly regionService: RegionService,
        private readonly router: Router,
        private readonly notificationService: UiNotificationService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        const sourceRegion = route.params.region;

        return this.regionService.getAvailableRegions$().pipe(
            take(1),
            map((availableRegionDefinitions) =>
                availableRegionDefinitions.map((definition) => definition.id)
            ),
            switchMap((availableRegions) => {
                if (
                    !sourceRegion ||
                    sourceRegion === REGION_GLOBAL ||
                    !availableRegions.includes(sourceRegion)
                ) {
                    // find out which region to use
                    return this.regionService.getActiveRegion$().pipe(
                        take(1),
                        switchMap((activeRegion) => {
                            if (
                                sourceRegion === REGION_GLOBAL &&
                                activeRegion &&
                                availableRegions.includes(activeRegion)
                            ) {
                                return of(activeRegion);
                            }

                            // request the user to select a region
                            return this.regionService
                                .requestRegionAndLanguageSelection$({
                                    allowClose: false
                                })
                                .pipe(
                                    map(
                                        (regionAndLanguage) =>
                                            regionAndLanguage.region
                                    )
                                );
                        }),
                        map((region) => {
                            const sourceUrl = state.url;
                            const redirectUrl = this.replaceWithTargetRegion(
                                sourceUrl,
                                sourceRegion,
                                region
                            );

                            // redirect to the selected region
                            return this.router.parseUrl(redirectUrl);
                        })
                    );
                }

                // seems like a valid region, update it in the application state
                return this.regionService
                    .setActiveRegion$(sourceRegion)
                    .pipe(map(() => true));
            })
        );
    }

    private replaceWithTargetRegion(
        targetUrl: string,
        sourceRegion: string,
        targetRegion: string
    ) {
        return targetUrl.replace(`/${sourceRegion}`, `/${targetRegion}`);
    }
}
