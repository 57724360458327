import { defer as _defer } from 'lodash-es';
import { Observable, Subject, defer, of } from 'rxjs';
import {
    catchError,
    concatMap,
    distinctUntilChanged,
    filter,
    first,
    map,
    switchMap,
    take
} from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import { CustomError, MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import {
    StrategyProvider,
    withCurrentStrategy$
} from '../../strategy/strategy-provider';
import { UiGlobalApplicationState } from '../ui-global-application-state.interface';
import {
    UI_SHARED_STATE_STRATEGY_PROVIDER_TOKEN,
    UiSharedStateStrategy
} from './ui-shared-state-strategy.interface';

export interface UiStateReducer<UiStateType> {
    reducerCallback: (uiState: UiStateType) => UiStateType;
    error?: CustomError;
}

/**
 * Service providing ApplicationState#uiState management functionality.
 * Allows to modify the ApplicationState#uiState subbranch which will then be send
 * as a patch to the engine which in turn broadcasts the change via the regular application-state
 * patch workflow to all interested parties.
 */
@UntilDestroy()
@Injectable()
export class UiSharedStateService<UiStateType = any> {
    private readonly patchQueue = new Subject<UiStateReducer<UiStateType>>();

    private patchQueueExecutions$: Observable<UiStateReducer<UiStateType>>;

    constructor(
        private store: Store<UiGlobalApplicationState<any, UiStateType>>,
        @Inject(UI_SHARED_STATE_STRATEGY_PROVIDER_TOKEN)
        private readonly strategyProvider: StrategyProvider<
            UiSharedStateStrategy<any>
        >
    ) {
        this.patchQueueExecutions$ = this.patchQueue.pipe(
            concatMap((reducer) =>
                this.reduceUiStateInternal(reducer).pipe(
                    catchError((error) => {
                        reducer.error = error;
                        return of(reducer);
                    })
                )
            ),
            untilDestroyed(this),
            lazyShareReplay()
        );

        // make it hot
        this.patchQueueExecutions$.subscribe();
    }

    /**
     * Get the ui-state to be notified of updates.
     */
    @MemoizeObservable()
    getState$(): Observable<UiStateType | undefined> {
        return this.store
            .select('engine', 'uiState')
            .pipe(distinctUntilChanged(), lazyShareReplay());
    }

    /**
     * Alter the shared uiState subbranch so that it can be patched.
     * Note that you need to subscribe to the returned observable for the resulting patch to be applied.
     * When the returned Observable completes, the patch has been applied and changes are locally visible.
     * If it errors, clients should retry modifying the state.
     * @param reducer The callback function that is given a clone of the current UiState and returns the altered UiState.
     */
    updateUiState(
        reducer: (uiState: UiStateType) => UiStateType
    ): Observable<void> {
        return defer(() => {
            const uiStateReducer = {
                reducerCallback: reducer
            };

            _defer(() => this.patchQueue.next(uiStateReducer));

            return this.patchQueueExecutions$.pipe(
                filter(
                    (emittedUiStateReducer) =>
                        emittedUiStateReducer === uiStateReducer
                ),
                first(),
                map((executedReducer) => {
                    if (executedReducer.error) {
                        throw executedReducer.error;
                    }
                    return undefined;
                })
            );
        });
    }

    private reduceUiStateInternal(
        reducer: UiStateReducer<UiStateType>
    ): Observable<UiStateReducer<UiStateType>> {
        return withCurrentStrategy$(this.strategyProvider).pipe(
            switchMap((strategy) =>
                strategy.reduceUiState$(reducer).pipe(take(1))
            )
        );
    }
}
