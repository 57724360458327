import { Injectable } from '@angular/core';
import {
    DefaultNumberTransformer,
    NumberStyles
} from '@jsverse/transloco-locale';

@Injectable()
export class LatinNumberTransformer extends DefaultNumberTransformer {
    public transform(
        value: number | string,
        type: NumberStyles,
        locale: string,
        options: Intl.NumberFormatOptions
    ): string {
        const adjustedLocale = `${locale}-u-nu-latn`;

        return super.transform(value, type, adjustedLocale, options);
    }
}
