import { Observable } from 'rxjs';

interface ElementDimensions {
    width: number;
    height: number;
}

/**
 * Wrap a ResizeObserver into an Observable to monitor element resizing.
 */
export class ResizeObservable extends Observable<ElementDimensions> {
    constructor(private readonly element: HTMLElement) {
        super((subscriber) => {
            const targetElement = this.element;
            const resizeObserver = new ResizeObserver((entries) => {
                const firstEntry = entries[0];
                if (!firstEntry) {
                    return;
                }
                subscriber.next({
                    width: firstEntry.contentRect.width,
                    height: firstEntry.contentRect.height
                });
            });
            resizeObserver.observe(targetElement);
            subscriber.next(this.getElementDimensions());

            return () => {
                resizeObserver.disconnect();
            };
        });
    }

    getElementDimensions(): ElementDimensions {
        const boundingClientRectSnapshot = this.element.getBoundingClientRect();
        return {
            width: boundingClientRectSnapshot.width,
            height: boundingClientRectSnapshot.height
        };
    }
}
