import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { UiButtonModule } from '../ui-buttons';
import { UiIconButtonModule } from '../ui-buttons/ui-icon-button/ui-icon-button.module';
import {
    UI_NOTIFICATION_CONFIG,
    UiNotificationConfig
} from './ui-notification-config.interface';
import { UiNotificationComponent } from './ui-notification.component';
import { UiNotificationService } from './ui-notification.service';

@NgModule({
    imports: [
        CommonModule,
        UiIconButtonModule,
        MatSnackBarModule,
        UiButtonModule
    ],
    exports: [UiNotificationComponent],
    declarations: [UiNotificationComponent]
})
export class UiNotificationModule {
    static forRoot(): ModuleWithProviders<UiNotificationModule> {
        return {
            ngModule: UiNotificationModule,
            providers: [
                UiNotificationService,
                {
                    provide: UI_NOTIFICATION_CONFIG,
                    useValue: <UiNotificationConfig>{
                        defaultAutohideDuration: 3000,
                        closeIconId: 'mhp-ui:close'
                    }
                }
            ]
        };
    }
}
