interface ObjectType {
    // eslint-disable-next-line no-use-before-define
    [id: string]: SortObject | undefined;
}

type SortObject =
    | string
    | number
    | boolean
    | ObjectType
    | (string | number | boolean | ObjectType)[];

function sortNumbers(k1: number, k2: number) {
    if (k1 < k2) {
        return -1;
    }
    if (k1 > k2) {
        return 1;
    }
    return 0;
}

function sortNumberAndString(k1: number | string, k2: number | string) {
    if (typeof k1 === 'number' && typeof k2 === 'string') {
        return -1;
    }
    if (typeof k1 === 'string' && typeof k2 === 'number') {
        return 1;
    }
    return 0;
}

function sortNumberAndBoolean(k1: number | boolean, k2: number | boolean) {
    if (typeof k1 === 'number' && typeof k2 === 'boolean') {
        return -1;
    }
    if (typeof k1 === 'boolean' && typeof k2 === 'number') {
        return 1;
    }
    return 0;
}

function sortNumberAndObject(k1: number | ObjectType, k2: number | ObjectType) {
    if (typeof k1 === 'number' && typeof k2 === 'object') {
        return -1;
    }
    if (typeof k1 === 'object' && typeof k2 === 'number') {
        return 1;
    }
    return 0;
}

function sortStrings(k1: string, k2: string) {
    k1 = k1.toLowerCase();
    k2 = k2.toLowerCase();
    if (k1 < k2) {
        return -1;
    }
    if (k1 > k2) {
        return 1;
    }
    return 0;
}

function sortStringAndBoolean(k1: string | boolean, k2: string | boolean) {
    if (typeof k1 === 'string' && typeof k2 === 'boolean') {
        return -1;
    }
    if (typeof k1 === 'boolean' && typeof k2 === 'string') {
        return 1;
    }
    return 0;
}

function sortStringAndObject(k1: string | ObjectType, k2: string | ObjectType) {
    if (typeof k1 === 'string' && typeof k2 === 'object') {
        return -1;
    }
    if (typeof k1 === 'object' && typeof k2 === 'string') {
        return 1;
    }
    return 0;
}

function sortBooleans(k1: boolean, k2: boolean) {
    if (k1 < k2) {
        return -1;
    }
    if (k1 > k2) {
        return 1;
    }
    return 0;
}

function sortBooleanAndObject(
    k1: boolean | ObjectType,
    k2: boolean | ObjectType
) {
    if (typeof k1 === 'boolean' && typeof k2 === 'object') {
        return 1;
    }
    if (typeof k1 === 'object' && typeof k2 === 'boolean') {
        return -1;
    }
    return 0;
}

function sortObjects(k1: ObjectType, k2: ObjectType) {
    const k1SortedKey = Object.keys(k1)[0].toLowerCase();
    const k2SortedKey = Object.keys(k2)[0].toLowerCase();
    if (k1SortedKey < k2SortedKey) {
        return -1;
    }
    if (k1SortedKey > k2SortedKey) {
        return 1;
    }
    return 0;
}

export function deepSortObject<T = SortObject | undefined>(object: T): T {
    if (!object) {
        return object;
    }
    let sortedObject: T;
    if (typeof object === 'string') {
        return object;
    }
    if (typeof object === 'number') {
        return object;
    }
    if (typeof object === 'boolean') {
        return object;
    }
    if (Array.isArray(object)) {
        sortedObject = object.map((i) =>
            typeof i === 'object' ? deepSortObject(i as ObjectType) : i
        ) as any as T;
        sortedObject = (sortedObject as any).sort((k1: any, k2: any) => {
            if (typeof k1 === 'number') {
                if (typeof k2 === 'number') {
                    return sortNumbers(k1, k2);
                }
                if (typeof k2 === 'string') {
                    return sortNumberAndString(k1, k2);
                }
                if (typeof k2 === 'boolean') {
                    return sortNumberAndBoolean(k1, k2);
                }
                if (typeof k2 === 'object') {
                    return sortNumberAndObject(k1, k2);
                }
            }
            if (typeof k1 === 'string') {
                if (typeof k2 === 'number') {
                    return sortNumberAndString(k1, k2);
                }
                if (typeof k2 === 'string') {
                    return sortStrings(k1, k2);
                }
                if (typeof k2 === 'boolean') {
                    return sortStringAndBoolean(k1, k2);
                }
                if (typeof k2 === 'object') {
                    return sortStringAndObject(k1, k2);
                }
            }
            if (typeof k1 === 'boolean') {
                if (typeof k2 === 'number') {
                    return sortNumberAndBoolean(k1, k2);
                }
                if (typeof k2 === 'string') {
                    return sortStringAndBoolean(k1, k2);
                }
                if (typeof k2 === 'boolean') {
                    return sortBooleans(k1, k2);
                }
                if (typeof k2 === 'object') {
                    return sortBooleanAndObject(k1, k2);
                }
            }
            if (typeof k1 === 'object') {
                if (typeof k2 === 'number') {
                    return sortNumberAndObject(k1, k2);
                }
                if (typeof k2 === 'string') {
                    return sortStringAndObject(k1, k2);
                }
                if (typeof k2 === 'boolean') {
                    return sortBooleanAndObject(k1, k2);
                }
                if (typeof k2 === 'object') {
                    return sortObjects(k1, k2);
                }
            }
            return null;
        });
    } else {
        sortedObject = {} as T;
        const keys = Object.keys(object);
        keys.sort((k1, k2) => {
            k1 = k1.toLowerCase();
            k2 = k2.toLowerCase();
            if (k1 < k2) {
                return -1;
            }
            if (k1 > k2) {
                return 1;
            }
            return 0;
        });
        for (const i in keys) {
            if (keys[i]) {
                const key = keys[i];
                if (typeof (object as any)[key] === 'object') {
                    (sortedObject as any)[key] = deepSortObject(
                        (object as any)[key]
                    );
                } else {
                    (sortedObject as any)[key] = (object as any)[key];
                }
            }
        }
    }
    return sortedObject;
}
