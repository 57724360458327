import { ModuleWithProviders, NgModule } from '@angular/core';
import { HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';

import { TouchPanelHammerConfig } from './ui-touch-panel-hammer-config';

/**
 * This module is required when UiTouchPanelModule is imported in
 * a lazy-loaded module only.
 * In this case, you need to import UiTouchPanelHammerModule in your root-module
 * for HAMMER_GESTURE_CONFIG to be available at application-load time.
 */
@NgModule({
    imports: [HammerModule]
})
export class UiTouchPanelHammerModule {
    static forRoot(): ModuleWithProviders<UiTouchPanelHammerModule> {
        return {
            ngModule: UiTouchPanelHammerModule,
            providers: [
                {
                    provide: HAMMER_GESTURE_CONFIG,
                    useClass: TouchPanelHammerConfig
                }
            ]
        };
    }
}
