import { Pipe, PipeTransform } from '@angular/core';

export interface ImageSrcsetEntry {
    url: string;
    targetWidth: number;
}

export interface ImageSrcset {
    sources: ImageSrcsetEntry[];
}

/**
 * Converts a given ImageSrcset to the srcset attribute representation.
 * @param imageSrcset
 */
export const convertImageSrcsetToSrcsetAttributeString = (
    imageSrcset: ImageSrcset
) => imageSrcset.sources
        .map((source) => `${source.url} ${source.targetWidth}w`)
        .join(',');

/**
 * Pure pipe transforming a given ImageSrcset[] into a string usable
 * for srcset attribute.
 */
@Pipe({
    name: 'uiImageSrcset',
    pure: true
})
export class UiImageSrcsetPipe implements PipeTransform {
    transform(value?: string | ImageSrcset): unknown {
        if (!value) {
            return value;
        }
        if (typeof value === 'string') {
            return value;
        }
        return convertImageSrcsetToSrcsetAttributeString(value);
    }
}
