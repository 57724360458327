import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UiImageSrcsetModule } from '../common/ui-image-srcset/ui-image-srcset.module';
import { UiTurntableComponent } from './ui-turntable.component';

@NgModule({
    declarations: [UiTurntableComponent],
    imports: [CommonModule, UiImageSrcsetModule],
    exports: [UiTurntableComponent]
})
export class UiTurntableModule {}
