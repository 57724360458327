import { identity, pickBy } from 'lodash-es';

import {
    animate,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';
import { IllegalStateError } from '@mhp/common';

import { EASE_OUT_EXPO } from './easings';

export interface AnimationOptions {
    name?: string;
    duration?: string;
    easing?: string;
}

interface SafeAnimationOptions extends AnimationOptions {
    name: string;
    duration: string;
    easing: string;
}

const fillDefaultOptions = (
    defaultOptions?: AnimationOptions,
    options?: AnimationOptions
): SafeAnimationOptions => {
    const safeOptions = {
        name: '',
        duration: '.2s',
        easing: EASE_OUT_EXPO,
        ...pickBy(defaultOptions, identity),
        ...pickBy(options, identity)
    };

    if (!safeOptions.name) {
        throw new IllegalStateError('Missing animation name property');
    }

    return safeOptions;
};

// animation definition to fade an element in and out when entering / leaving the DOM
export const ANIMATION_FADE_IN_OUT_EXPO = (options?: AnimationOptions) => {
    const safeOptions = fillDefaultOptions(
        {
            name: 'fadeInOut'
        },
        options
    );

    return trigger(safeOptions.name, [
        state(
            'void',
            style({
                opacity: 0
            })
        ),
        transition(
            'void <=> *',
            animate(`${safeOptions.duration} ${safeOptions.easing}`)
        )
    ]);
};

// animation definition to toggle an element (in height) when entering / leaving the DOM
export const ANIMATION_TOGGLE_HEIGHT = (options?: AnimationOptions) => {
    const safeOptions = fillDefaultOptions(
        {
            name: 'toggleHeight'
        },
        options
    );

    return trigger(safeOptions.name, [
        transition(':enter', [
            style({ height: 0, paddingTop: 0, paddingBottom: 0 }),
            animate(
                `${safeOptions.duration} ${safeOptions.easing}`,
                style({ height: '*', paddingTop: '*', paddingBottom: '*' })
            )
        ]),
        transition(':leave', [
            animate(
                `${safeOptions.duration} ${safeOptions.easing}`,
                style({ height: 0, paddingTop: 0, paddingBottom: 0 })
            )
        ])
    ]);
};

export interface SlideAnimationOptions extends AnimationOptions {
    orientation: 'BOTTOM' | 'RIGHT';
}

// animation definition to slide in/out an element when entering/leaving the DOM
export const ANIMATION_SLIDE_IN_OUT = (options?: SlideAnimationOptions) => {
    const safeOptions = fillDefaultOptions(
        {
            name: 'slideInOut'
        },
        options
    );

    let transformDef: { from: string; to: string };

    switch (options?.orientation) {
        case 'BOTTOM':
            transformDef = {
                from: 'translateY(100%)',
                to: 'translateY(0)'
            };
            break;
        case 'RIGHT':
            transformDef = {
                from: 'translateX(100%)',
                to: 'translateX(0)'
            };
            break;
        default:
            transformDef = {
                from: 'translateY(100%)',
                to: 'translateY(0)'
            };
    }

    return trigger(safeOptions.name, [
        transition(':enter', [
            style({ transform: transformDef.from }),
            animate(
                `${safeOptions.duration} ${safeOptions.easing}`,
                style({ transform: transformDef.to })
            )
        ]),
        transition(':leave', [
            animate(
                `${safeOptions.duration} ${safeOptions.easing}`,
                style({ transform: transformDef.from })
            )
        ])
    ]);
};
