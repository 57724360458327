import { Observable } from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    finalize,
    map,
    switchMap
} from 'rxjs/operators';

import { Inject, Injectable, Optional, SkipSelf } from '@angular/core';
import { ConfigModel } from '@mhp-immersive-exp/contracts/src/configuration/config-model.interface';
import { OptionGroup } from '@mhp-immersive-exp/contracts/src/configuration/configuration-response.interface';
import { ProjectParameters } from '@mhp-immersive-exp/contracts/src/project-parameters/project-parameters';
import { MemoizeObservable } from '@mhp/common';
import { ProductState } from '@mhp/communication-models';

import {
    ApplicationStateService,
    decreasePendingConfigurationChanges,
    increasePendingConfigurationChanges
} from '../application-state';
import { StrategyProvider, withCurrentStrategy$ } from '../strategy';
import { ConfigurationInfo } from './configuration-info.interface';
import {
    PRODUCT_CONFIGURATION_STRATEGY_PROVIDER_TOKEN,
    ProductConfigurationStrategy
} from './product-configuration-strategy.interface';
import { selectProductState } from './state';

@Injectable()
export class ProductConfigurationService<
    P extends ProjectParameters = ProjectParameters
> {
    constructor(
        private readonly applicationStateService: ApplicationStateService,
        @Inject(PRODUCT_CONFIGURATION_STRATEGY_PROVIDER_TOKEN)
        private readonly strategyProvider: StrategyProvider<ProductConfigurationStrategy>,
        @Optional() @SkipSelf() parentService?: ProductConfigurationService
    ) {
        if (parentService) {
            throw new Error(
                'ProductConfigurationService is already loaded. Use modules forRoot() only once where needed.'
            );
        }
    }

    /**
     * Fetch a configuration from the ruler.
     * @param productId The productId to fetch the configuration for.
     * @param config Optionally an already existing configuration.
     * @param projectParameters Optionally The projectParameters to include in the payload for the backend.
     * @param country Optionally the country that the current request relates to.
     */
    getConfigurationInfo$(
        productId: string,
        config?: ConfigModel[],
        projectParameters?: P,
        country?: string
    ): Observable<ConfigurationInfo<OptionGroup>> {
        return this.signalWaitingForConfiguration$(
            withCurrentStrategy$(this.strategyProvider).pipe(
                switchMap((facade) =>
                    facade.getConfigurationMetadata$(
                        productId,
                        config,
                        projectParameters,
                        country
                    )
                ),
                map((payload) => ({
                    productId,
                    country,
                    configuration: payload.config
                }))
            )
        );
    }

    /**
     * Adjust the given configuration by adding or removing config-options.
     *
     * @param productId The productId to use as context.
     * @param country The country to use as context.
     * @param config The configuration to be adjusted.
     * @param add The options to be added.
     * @param remove The options to be removed.
     */
    patchConfiguration$(
        productId: string,
        country: string,
        config: ConfigModel[],
        add?: ConfigModel[],
        remove?: ConfigModel[]
    ): Observable<ConfigModel[]> {
        return withCurrentStrategy$(this.strategyProvider).pipe(
            switchMap((facade) =>
                facade.patchConfiguration$(
                    productId,
                    config,
                    country,
                    add,
                    remove
                )
            )
        );
    }

    /**
     * Determines if a product has already been loaded or not.
     * Note that this may not take into account possible application specific
     * context (e.g. when switching to another navigation-area, semantics could be
     * that there is no configuration active. In this case, the returned stream will
     * return an active ProductState nevertheless, as the context-local specifics are
     * not known).
     */
    @MemoizeObservable()
    getProductState$(): Observable<ProductState | undefined> {
        return this.applicationStateService.getState().pipe(selectProductState);
    }

    /**
     * Get the active productId (if any) as defined in the application state.
     * Note that this may not take into account possible application specific
     * context (e.g. when switching to another navigation-area, semantics could be
     * that there is no configuration active. In this case, the returned stream will
     * return an active productId nevertheless, as the context-local specifics are
     * not known).
     */
    @MemoizeObservable()
    getActiveProductId$(): Observable<string | undefined> {
        return this.getProductState$().pipe(
            map((productState) => productState && productState.id),
            distinctUntilChanged()
        );
    }

    /**
     * Get the active productId and emits only in case it is set.
     */
    @MemoizeObservable()
    getAvailableActiveProductId$(): Observable<string> {
        return this.getActiveProductId$().pipe(
            filter((productId): productId is string => !!productId)
        );
    }

    /**
     * Determines if a product has already been loaded or not.
     */
    @MemoizeObservable()
    isProductLoaded$(): Observable<boolean> {
        return this.getProductState$().pipe(
            map((productState) => productState && productState.id),
            distinctUntilChanged(), // in case of a changing productId, re-emit too
            map((productId) => !!productId)
        );
    }

    private signalWaitingForConfiguration$<T>(observable: Observable<T>) {
        this.applicationStateService.dispatch(
            increasePendingConfigurationChanges()
        );

        return observable.pipe(
            finalize(() => {
                this.applicationStateService.dispatch(
                    decreasePendingConfigurationChanges()
                );
            })
        );
    }
}
