import { v4 as uuidv4 } from 'uuid';

import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector } from '@angular/core';

import { UiOverlayService } from '../ui-overlay';

export interface UiDialogOptions {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DIALOG_DATA = new InjectionToken<any>('DIALOG_DATA');

@Injectable({
    providedIn: 'root'
})
export class UiDialogService {
    overlayRefs: Map<
        string,
        { overlayRef: OverlayRef; resolve: (returnValue: unknown) => void }
    > = new Map<
        string,
        { overlayRef: OverlayRef; resolve: (returnValue: unknown) => void }
    >();

    constructor(
        private overlay: UiOverlayService,
        private injector: Injector
    ) {}

    async open<T>(
        component: ComponentType<T>,
        options: UiDialogOptions = {}
    ): Promise<unknown> {
        const portalInjector = Injector.create({
            providers: [
                {
                    provide: DIALOG_DATA,
                    useValue: options.data || {}
                }
            ],
            parent: this.injector
        });
        const uuid = uuidv4();
        const overlayRef = this.overlay.open(component, portalInjector);
        return new Promise((resolve) => {
            this.overlayRefs.set(uuid, { overlayRef, resolve });
            overlayRef.overlayElement.setAttribute('id', uuid);
        });
    }

    closeOverlay(id: string, returnValue: unknown = null) {
        const overlayRef = this.getOverlayRef(id);
        if (!overlayRef) {
            throw new Error(`Overlay with id [${id}] does not exist.`);
        }
        overlayRef.overlayRef.dispose();
        overlayRef.resolve(returnValue);
        this.overlayRefs.delete(id);
    }

    closeAllOverlays() {
        this.overlayRefs.forEach((overlayRef, key) => {
            overlayRef.overlayRef.dispose();
            overlayRef.resolve(null);
            this.overlayRefs.delete(key);
        });
    }

    private getOverlayRef(
        id: string
    ):
        | { overlayRef: OverlayRef; resolve: (returnValue: unknown) => void }
        | undefined {
        return this.overlayRefs.get(id);
    }
}
