import { first } from 'lodash-es';

import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ReservationData } from '@mhp-immersive-exp/sdk/streaming/monkeyway/internal/types/reservation-data.js';
import { IllegalStateError } from '@mhp/common';

import { EngineSessionData, SessionUrlService } from '../session';

export interface One2OneSessionUrlServiceConfig {
    publicBaseUrl: string;
    sessionHostReservationKeyQueryParamName: string;
}

export const ONE_2_ONE_SESSION_URL_SERVICE_CONFIG_TOKEN =
    new InjectionToken<One2OneSessionUrlServiceConfig>(
        'One2oneSessionUrlServiceConfig'
    );

/**
 * Service to get url infos in the one2one-context.
 */
@Injectable({
    providedIn: 'root'
})
export class One2oneSessionUrlService {
    constructor(
        private readonly sessionUrlService: SessionUrlService,
        @Inject(ONE_2_ONE_SESSION_URL_SERVICE_CONFIG_TOKEN)
        private readonly config: One2OneSessionUrlServiceConfig
    ) {}

    /**
     * Returns the url used for the client to connect to a given session.
     * @param sessionData
     */
    getClientUrlToSession(sessionData: ReservationData) {
        const connectionCode = first(
            sessionData.secondaryConnections
        )?.connectionCode;

        if (!connectionCode) {
            throw new IllegalStateError(
                'Could not find connectionCode for secondary connection'
            );
        }

        return `${this.config.publicBaseUrl}/configuration-session/${connectionCode}`;
    }

    /**
     * Returns the url used for the dealer to connect to a given session.
     * @param sessionData
     * @param configurationInfo Optional starting configuration
     */
    getDealerUrlToSession(
        sessionData: ReservationData,
        configurationInfo?: EngineSessionData
    ) {
        const dealerUrl =
            this.sessionUrlService.getDealerUrlToConfiguration(
                configurationInfo
            );
        return `${dealerUrl}?${this.config.sessionHostReservationKeyQueryParamName}=${sessionData.id}`;
    }
}
