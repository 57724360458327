import { Action, createReducer, on } from '@ngrx/store';

import {
    exitSearchState,
    setActiveCategorySelection,
    setActiveConfigurationOptions,
    setActiveModelId,
    setActiveNavigationArea,
    setActiveSearchTerm,
    setActiveStreamSessionInfo,
    setActiveVisualizationMode,
    setCandidateVisualizationMode,
    setCandidateVisualizationModeAck,
    setEditionsInfoShown,
    setEnvironmentInfoShown,
    setFocusTargetNode,
    setInitialSummaryVisit,
    setNavigationToSavedConfigurationActive,
    setSearchInputActive,
    setStageMinimizedState,
    setStageShrinkedState,
    setTargetVisualizationMode,
    setTransitionSilentlyToTargetVisualizationMode,
    setUpdateSessionDataInProgress,
    setUserEnvironmentLightingStateChoice,
    setUserToggledRoof,
    updateEnvironmentInfoShown
} from '../actions/configuration.actions';
import { LocalConfigurationState } from '../local-configuration-state.interface';

const INITIAL_STATE: LocalConfigurationState = {
    activeNavigationArea: undefined,
    searchInputActive: false,
    stageState: {
        shrinked: true
    },
    categorySelection: [],
    initialSummaryVisit: true,
    environmentInfoShown: [],
    editionsInfoShown: false,
    userToggledRoof: false,
    navigationToSavedConfigurationActive: false,
    updateSessionDataInProgress: false
};

const reducer = createReducer<LocalConfigurationState | undefined>(
    INITIAL_STATE,
    on(setActiveNavigationArea, (state: LocalConfigurationState, payload) => ({
        ...state,
        activeNavigationArea: payload.id
    })),
    on(
        setActiveConfigurationOptions,
        (state: LocalConfigurationState, payload) => ({
            ...state,
            activeConfiguration: payload.options
        })
    ),
    on(
        setActiveSearchTerm,
        (state: LocalConfigurationState, payload): LocalConfigurationState => ({
            ...state,
            activeSearchTerm: payload.searchTerm
        })
    ),
    on(
        setSearchInputActive,
        (state: LocalConfigurationState, payload): LocalConfigurationState => ({
            ...state,
            searchInputActive: payload.searchInputActive
        })
    ),
    on(
        exitSearchState,
        (state: LocalConfigurationState): LocalConfigurationState => ({
            ...state,
            searchInputActive: false,
            activeSearchTerm: undefined
        })
    ),
    on(
        setTargetVisualizationMode,
        (state: LocalConfigurationState, payload) => ({
            ...state,
            stageState: {
                ...state.stageState,
                targetVisualizationMode: payload.mode,
                candidateVisualizationMode: undefined,
                candidateVisualizationModeAck: false
            }
        })
    ),
    on(
        setCandidateVisualizationMode,
        (state: LocalConfigurationState, payload) => ({
            ...state,
            stageState: {
                ...state.stageState,
                candidateVisualizationMode: payload.mode
            }
        })
    ),
    on(
        setCandidateVisualizationModeAck,
        (state: LocalConfigurationState, payload) => ({
            ...state,
            stageState: {
                ...state.stageState,
                candidateVisualizationModeAck: payload.ack
            }
        })
    ),
    on(
        setActiveVisualizationMode,
        (state: LocalConfigurationState, payload) => ({
            ...state,
            stageState: {
                ...state.stageState,
                activeVisualizationMode: payload.mode,
                candidateVisualizationMode: undefined,
                candidateVisualizationModeAck: undefined
            }
        })
    ),
    on(
        setTransitionSilentlyToTargetVisualizationMode,
        (state: LocalConfigurationState, payload) => ({
            ...state,
            stageState: {
                ...state.stageState,
                transitionSilentlyToTargetVisualizationMode:
                    payload.transitionSilently
            }
        })
    ),
    on(
        setActiveStreamSessionInfo,
        (state: LocalConfigurationState, payload) => ({
            ...state,
            stageState: {
                ...state.stageState,
                sessionInfo: payload.streamSessionInfo
            }
        })
    ),
    on(setStageShrinkedState, (state: LocalConfigurationState, payload) => ({
        ...state,
        stageState: {
            ...state.stageState,
            shrinked: payload.shrinked
        }
    })),
    on(setStageMinimizedState, (state: LocalConfigurationState, payload) => ({
        ...state,
        stageState: {
            ...state.stageState,
            minimized: payload.minimized
        }
    })),
    on(setActiveModelId, (state: LocalConfigurationState, payload) => ({
        ...state,
        modelId: payload.modelId
    })),
    on(
        setActiveCategorySelection,
        (state: LocalConfigurationState, payload) => ({
            ...state,
            categorySelection: [...payload.selection]
        })
    ),
    on(setFocusTargetNode, (state: LocalConfigurationState, payload) => ({
        ...state,
        focusTargetNode: payload.id
    })),
    on(setInitialSummaryVisit, (state: LocalConfigurationState, payload) => ({
        ...state,
        initialSummaryVisit: payload.initialSummaryVisit
    })),
    on(setUserToggledRoof, (state: LocalConfigurationState, payload) => ({
        ...state,
        userToggledRoof: payload.userToggledRoof
    })),
    on(
        setNavigationToSavedConfigurationActive,
        (state: LocalConfigurationState, payload) => ({
            ...state,
            navigationToSavedConfigurationActive:
                payload.navigationToSavedConfigurationActive
        })
    ),
    on(
        setUpdateSessionDataInProgress,
        (state: LocalConfigurationState, payload) => ({
            ...state,
            updateSessionDataInProgress: payload.updateSessionDataInProgress
        })
    ),
    on(
        updateEnvironmentInfoShown,
        (state: LocalConfigurationState, payload): LocalConfigurationState => ({
            ...state,
            environmentInfoShown: [
                ...(state.environmentInfoShown ?? []).filter(
                    (envInfo) => envInfo.id !== payload.id
                ),
                {
                    id: payload.id,
                    timestamp: payload.timestamp
                }
            ]
        })
    ),
    on(
        setEnvironmentInfoShown,
        (state: LocalConfigurationState, payload): LocalConfigurationState => ({
            ...state,
            environmentInfoShown: [...payload.state]
        })
    ),
    on(
        setEditionsInfoShown,
        (state: LocalConfigurationState, payload): LocalConfigurationState => ({
            ...state,
            editionsInfoShown: payload.state
        })
    ),
    on(
        setUserEnvironmentLightingStateChoice,
        (state: LocalConfigurationState, payload): LocalConfigurationState => ({
            ...state,
            userActiveEnvironmentLightingStateChoice: payload.state
        })
    )
);

export function configurationReducer(
    state: LocalConfigurationState | undefined,
    action: Action
) {
    return reducer(state, action);
}
