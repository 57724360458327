<button
    mat-icon-button
    class="mhp-icon-button"
    [disabled]="disabled"
    [color]="color"
    [tabIndex]="tabIndex"
    [attr.aria-label]="label"
    [style.backgroundColor]="backgroundColor"
>
    <mhp-ui-icon [svgIcon]="svgIcon" [size]="size"
        ><ng-content></ng-content
    ></mhp-ui-icon>
</button>
