import { defaultsDeepCustom } from './environment-helper';
import {
    defaultEnvironmentConfig,
    environmentUrlFactory
} from './environment.default';

const AML_CLIENT_BASE_URL =
    'https://aml-infor-preview-4q6zszk7ge7yhcs.api-services.astonmartin.com';
export const AML_CLIENT_DEV_DEALER_BASE_URL =
    'https://aml-infor-dealer-4q6zszk7ge7yhcs.api-services.astonmartin.com';

export const environmentDevelopmentAdjusts = {
    production: true,
    appConfig: {
        ...environmentUrlFactory(AML_CLIENT_BASE_URL),
        baseUrls: {
            publicBaseUrl: AML_CLIENT_BASE_URL,
            dealerBaseUrl: AML_CLIENT_DEV_DEALER_BASE_URL
        },
        i18n: {
            translationFileUrlFactory: (lang: string) =>
                `${AML_CLIENT_BASE_URL}/static/assets/translations/${lang}.json`
        },
        featureToggles: {
            one2oneBuyingExperience: true
        }
    }
};

export const environment = defaultsDeepCustom(
    {},
    environmentDevelopmentAdjusts,
    defaultEnvironmentConfig
);
