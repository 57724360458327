import { Observable, from } from 'rxjs';

type SearchParamsModifier<E extends string> = (
    endpoint: E,
    searchParams: Record<string, string | string[]>
) =>
    | Record<string, string | string[]>
    | Promise<Record<string, string | string[]>>;

export interface SearchParamsModifierAware<E extends string> {
    searchParamsModifier?: SearchParamsModifier<E>;
}

/**
 * Static helper method that allows a SearchParamsModifier to patch the given search params, optionally async.
 *
 * @param searchParamsModifierAware
 * @param endpoint
 * @param inputSearchParams
 */
export const patchSearchParams$ = <E extends string>(
    searchParamsModifierAware: SearchParamsModifierAware<E>,
    endpoint: E,
    inputSearchParams: Record<string, string | string[]> | undefined
): Observable<Record<string, string | string[]> | undefined> =>
    from(
        Promise.resolve(
            searchParamsModifierAware?.searchParamsModifier
                ? searchParamsModifierAware.searchParamsModifier(
                      endpoint,
                      inputSearchParams ?? {}
                  )
                : inputSearchParams
        )
    );
