import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
    IllegalStateError,
    MemoizeObservable,
    lazyShareReplay
} from '@mhp/common';

/**
 * Small helper allowing to register an alternate data source
 * for a given data type.
 */
export class AlternateDataSource<T> {
    private readonly alternateDataSourceSubject = new BehaviorSubject<
        Observable<T> | undefined
    >(undefined);

    /**
     * Register an observable stream providing alternate data T.
     * @param dataSource$
     * @return Cleanup-callback to remove registered data source.
     */
    registerAlternateDataSource(dataSource$: Observable<T>): () => void {
        if (this.alternateDataSourceSubject.value) {
            throw new IllegalStateError(
                'Alternate data source already registered.'
            );
        }
        this.alternateDataSourceSubject.next(dataSource$);

        return () => {
            this.alternateDataSourceSubject.next(undefined);
        };
    }

    /**
     * Get access to the data of a possibly registered alternative data source.
     */
    @MemoizeObservable()
    getAlternateData$(): Observable<T | undefined> {
        return this.alternateDataSourceSubject.pipe(
            switchMap(
                (alternateDataSource$) => alternateDataSource$ ?? of(undefined)
            ),
            lazyShareReplay()
        );
    }
}
