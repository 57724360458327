/* eslint-disable max-classes-per-file */
import { CustomError } from '@mhp/common';

/**
 * Error thrown in case a configuration resolve request errors.
 */
export class ConfigurationResolveError extends CustomError {
    constructor(message: string, details?: any) {
        super(message, details);
    }
}

/**
 * Error thrown in case a configuration resolve request errors due to contained options that require authorization.
 */
export class ConfigurationResolveMissingAuthorizationError extends ConfigurationResolveError {
    constructor(message: string, details?: any) {
        super(message, details);
    }
}
