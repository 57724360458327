import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import {
    ApplicationState,
    ApplicationStateMachineState
} from '@mhp/communication-models';
import { Action, Store } from '@ngrx/store';

import { LocalSharedApplicationState } from './local-shared-application-state.interface';
import {
    selectApplicationStateMachineState,
    selectEngineApplicationState,
    selectLocalApplicationState,
    selectLocalSharedApplicationState
} from './selectors';
import { UiGlobalApplicationState } from './ui-global-application-state.interface';

/**
 * Service providing application-state management functionality.
 */
@Injectable({
    providedIn: 'root'
})
export class ApplicationStateService<
    LocalApplicationStateType = any,
    UiSharedStateType = any
> {
    private readonly store$: Observable<
        UiGlobalApplicationState<LocalApplicationStateType>
    >;

    constructor(
        private store: Store<
            UiGlobalApplicationState<LocalApplicationStateType>
        >
    ) {
        this.store$ = store.select((state) => state);
    }

    getState(): Observable<
        UiGlobalApplicationState<LocalApplicationStateType>
    > {
        return this.store$;
    }

    /**
     * Gets the engine branch of the UIGlobalApplicationState
     */
    @MemoizeObservable()
    getEngineState(): Observable<ApplicationState<UiSharedStateType>> {
        return this.store$.pipe(selectEngineApplicationState);
    }

    /**
     * Gets the local shared branch of the UIGlobalApplicationState
     */
    @MemoizeObservable()
    getLocalSharedState(): Observable<LocalSharedApplicationState> {
        return this.store$.pipe(selectLocalSharedApplicationState);
    }

    /**
     * Gets the local branch of the UIGlobalApplicationState
     */
    @MemoizeObservable()
    getLocalState(): Observable<LocalApplicationStateType> {
        return <Observable<LocalApplicationStateType>>(
            this.store$.pipe(selectLocalApplicationState)
        );
    }

    /**
     * Dispatch a given action to the underlying store.
     * @param action The action to be dispatched.
     */
    dispatch(action: Action) {
        this.store.dispatch(action);
    }

    /**
     * Select and observe a part of the state extracted by the given mapping function.
     *
     * @param mapFn The function used to extract the required part of the state.
     * @see Store#select
     */
    select<K>(
        mapFn: (state: UiGlobalApplicationState<LocalApplicationStateType>) => K
    ): Observable<K> {
        return this.store.select(mapFn);
    }

    /**
     * Emits only in case the engine-state has been initially fetched from the backend.
     */
    @MemoizeObservable()
    isEngineStateLoaded$(): Observable<void> {
        return this.getState().pipe(
            selectApplicationStateMachineState,
            filter((state) => state !== ApplicationStateMachineState.UNKNOWN),
            map(() => undefined),
            lazyShareReplay()
        );
    }
}
