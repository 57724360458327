import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UiIconsModule } from '../ui-icons';
import { UiSelectedMarkerComponent } from './ui-selected-marker.component';

@NgModule({
    declarations: [UiSelectedMarkerComponent],
    imports: [CommonModule, UiIconsModule],
    exports: [UiSelectedMarkerComponent]
})
export class UiSelectedMarkerModule {}
