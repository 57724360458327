import { combineReducers } from '@ngrx/store';

import { BrandStoreState } from '../brand-store-state.interface';

const INITIAL_STATE: BrandStoreState = {};

const reducer = combineReducers<BrandStoreState>({}, INITIAL_STATE);

export function brandStoreStateReducer(
    state: BrandStoreState | undefined,
    action: any
) {
    return reducer(state, action);
}
