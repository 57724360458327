import { cloneDeep, values } from 'lodash-es';
import { Observable, Subject, merge } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { EVENT_CONFIGURATION_UPDATE } from '@mhp-immersive-exp/contracts/src/configuration/configuration-update-event.interface';
import {
    MaterialEditModeRequestPayload,
    REQUEST_MATERIAL_EDIT_MODE
} from '@mhp-immersive-exp/contracts/src/material-editor/material-edit-mode-request.interface';
import {
    AddMaterialLibraryRequestPayload,
    REQUEST_ADD_MATERIAL_LIBRARY
} from '@mhp-immersive-exp/contracts/src/material-library/add-material-library-request.interface';
import { AddMaterialLibraryResponsePayload } from '@mhp-immersive-exp/contracts/src/material-library/add-material-library-response.interface';
import {
    AddMaterialRequestPayload,
    REQUEST_ADD_MATERIAL
} from '@mhp-immersive-exp/contracts/src/material-library/add-material-request.interface';
import { AddMaterialResponsePayload } from '@mhp-immersive-exp/contracts/src/material-library/add-material-response.interface';
import {
    CloneMaterialLibraryRequestPayload,
    REQUEST_CLONE_MATERIAL_LIBRARY
} from '@mhp-immersive-exp/contracts/src/material-library/clone-material-library-request.interface';
import { CloneMaterialLibraryResponsePayload } from '@mhp-immersive-exp/contracts/src/material-library/clone-material-library-response.interface';
import {
    DeleteMaterialLibraryRequestPayload,
    REQUEST_DELETE_MATERIAL_LIBRARY
} from '@mhp-immersive-exp/contracts/src/material-library/delete-material-library-request.interface';
import { DeleteMaterialLibraryResponsePayload } from '@mhp-immersive-exp/contracts/src/material-library/delete-material-library-response.interface';
import {
    DeleteMaterialRequestPayload,
    REQUEST_DELETE_MATERIAL
} from '@mhp-immersive-exp/contracts/src/material-library/delete-material-request.interface';
import { DeleteMaterialResponsePayload } from '@mhp-immersive-exp/contracts/src/material-library/delete-material-response.interface';
import { REQUEST_GET_MATERIAL_LIBRARIES } from '@mhp-immersive-exp/contracts/src/material-library/get-material-libraries-request.interface';
import { GetMaterialLibrariesResponsePayload } from '@mhp-immersive-exp/contracts/src/material-library/get-material-libraries-response.interface';
import { REQUEST_GET_TEXTURES } from '@mhp-immersive-exp/contracts/src/material-library/get-textures-request.interface';
import {
    GetTexturesResponsePayload,
    Texture
} from '@mhp-immersive-exp/contracts/src/material-library/get-textures-response.interface';
import {
    AddMaterialDTO,
    Material2
} from '@mhp-immersive-exp/contracts/src/material-library/material.interface';
import { MaterialLibrary } from '@mhp-immersive-exp/contracts/src/material-library/materiallibrary.interface';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';

import { ApplicationStateService } from '../application-state';
import { SocketIOService } from '../communication';
import { selectHubConnectionState } from '../engine';
import { ErrorHandlerService } from '../error-handler';

/**
 * Service providing management operations for material library.
 */
@Injectable({
    providedIn: 'root'
})
export class MaterialLibraryService {
    private readonly refreshAvailableMaterialLibrariesSubject =
        new Subject<void>();

    constructor(
        private socketIoService: SocketIOService,
        private errorHandlerService: ErrorHandlerService,
        private applicationStateService: ApplicationStateService
    ) {
        this.initMaterialLibrariesRefreshLogic();
    }

    /**
     * Returns a continuous stream of available material libraries which
     * is updated upon changes (delete, add).
     */
    getAvailableMaterialLibraries$() {
        return this.getAvailableMaterialLibrariesInternal$();
    }

    /**
     * Returns a continuous stream of available material libraries that are editable which
     * is updated upon changes (delete, add).
     */
    @MemoizeObservable()
    getAvailableEditableMaterialLibraries$() {
        return this.getAvailableMaterialLibrariesInternal$().pipe(
            map((availableMaterialLibraries) =>
                availableMaterialLibraries.filter(
                    (library) =>
                        !library.metadata.isDefault &&
                        library.metadata.overwritable
                )
            ),
            lazyShareReplay()
        );
    }

    /**
     * Delete material library.
     * @param id The libraries ID.
     */
    deleteMaterialLibrary$(id: string): Observable<string> {
        return this.socketIoService
            .request<
                DeleteMaterialLibraryRequestPayload,
                DeleteMaterialLibraryResponsePayload
            >(REQUEST_DELETE_MATERIAL_LIBRARY, {
                id
            })
            .pipe(map((response) => response.id));
    }

    /**
     * Create a new material-library.
     * @param name The name to be used.
     */
    createMaterialLibrary$(name: string): Observable<string> {
        return this.socketIoService
            .request<
                AddMaterialLibraryRequestPayload,
                AddMaterialLibraryResponsePayload
            >(REQUEST_ADD_MATERIAL_LIBRARY, {
                name
            })
            .pipe(map((response) => response.id));
    }

    /**
     * Duplicate the given material library using a new name.
     * @param existingLibraryId The id of the library to be duplicated
     * @param duplicateName The new name for the duplicated library.
     */
    duplicateMaterialLibrary$(
        existingLibraryId: string,
        duplicateName: string
    ) {
        return this.socketIoService
            .request<
                CloneMaterialLibraryRequestPayload,
                CloneMaterialLibraryResponsePayload
            >(REQUEST_CLONE_MATERIAL_LIBRARY, {
                id: existingLibraryId,
                newName: duplicateName
            })
            .pipe(map((response) => response.id));
    }

    /**
     * Get the materials for a given library.
     * @param libraryId The libraries ID.
     */
    getMaterialsForLibrary$(
        libraryId: string
    ): Observable<Material2[] | undefined> {
        return this.getAvailableMaterialLibrariesInternal$().pipe(
            map((availableLibraries) =>
                availableLibraries.find(
                    (library) => library.metadata.id === libraryId
                )
            ),
            map((matchingLibrary) => {
                if (!matchingLibrary) {
                    return undefined;
                }

                return this.mapMaterialLibraryObjectToMaterials(
                    matchingLibrary
                );
            })
        );
    }

    /**
     * Delete a given material from a library.
     * @param libraryId The libraries ID
     * @param materialCode The materials code
     * @return Observable emitting the code of the deleted material.
     */
    deleteMaterial$(
        libraryId: string,
        materialCode: string
    ): Observable<string> {
        return this.socketIoService
            .request<
                DeleteMaterialRequestPayload,
                DeleteMaterialResponsePayload
            >(REQUEST_DELETE_MATERIAL, {
                id: libraryId,
                code: materialCode
            })
            .pipe(map((response) => response.code));
    }

    /**
     * Saves a material (either new or existing) in the context of a material-library.
     * @param libraryId The libraries id.
     * @param material The material to be saved.
     * @return The code of the persisted material.
     */
    saveMaterial$(
        libraryId: string,
        material: AddMaterialDTO
    ): Observable<string> {
        return this.socketIoService
            .request<AddMaterialRequestPayload, AddMaterialResponsePayload>(
                REQUEST_ADD_MATERIAL,
                {
                    id: libraryId,
                    material
                }
            )
            .pipe(map((response) => response.code));
    }

    /**
     * Duplicates a material in the context of a material-library.
     * @param libraryId The libraries id.
     * @param material The material to be duplicated.
     * @return The code of the persisted material.
     */
    duplicateMaterial$(
        libraryId: string,
        material: AddMaterialDTO
    ): Observable<string> {
        const clonedMaterial = cloneDeep(material);
        clonedMaterial.code = undefined;
        clonedMaterial.name = `${clonedMaterial.name} - Duplicate`;

        return this.saveMaterial$(libraryId, clonedMaterial);
    }

    /**
     * Get the available textures for materials.
     */
    @MemoizeObservable()
    getAvailableMaterialTextures$(): Observable<Texture[]> {
        return this.socketIoService
            .request<undefined, GetTexturesResponsePayload>(
                REQUEST_GET_TEXTURES,
                undefined
            )
            .pipe(
                map((response) => response.textures),
                lazyShareReplay()
            );
    }

    /**
     * Request the engine to switch to material-edit-mode or leave it.
     * @param status Switch to / leave
     */
    requestEngineMaterialPreviewMode(status: boolean): Observable<void> {
        return this.socketIoService.request<
            MaterialEditModeRequestPayload,
            void
        >(REQUEST_MATERIAL_EDIT_MODE, {
            enabled: status
        });
    }

    /**
     * Returns an Observable that emits when changes on material-libraries are
     * done.
     */
    getMaterialLibraryUpdatesNotification$() {
        return this.refreshAvailableMaterialLibrariesSubject.asObservable();
    }

    private refreshAvailableMaterialLibraries() {
        this.refreshAvailableMaterialLibrariesSubject.next();
    }

    @MemoizeObservable()
    private getAvailableMaterialLibrariesInternal$(): Observable<
        MaterialLibrary[]
    > {
        return this.refreshAvailableMaterialLibrariesSubject.pipe(
            startWith(undefined),
            switchMap(() =>
                this.getAvailableMaterialLibrariesOnceInternal$().pipe(
                    this.errorHandlerService.applyRetryWithHintsOnError(
                        (error) => (error as Error).message,
                        {
                            maxRetries: Number.MAX_VALUE,
                            maxRetryTimeout: 3000
                        }
                    )
                )
            ),
            lazyShareReplay()
        );
    }

    private getAvailableMaterialLibrariesOnceInternal$(): Observable<
        MaterialLibrary[]
    > {
        return this.socketIoService
            .request<void, GetMaterialLibrariesResponsePayload>(
                REQUEST_GET_MATERIAL_LIBRARIES,
                undefined
            )
            .pipe(map((response) => response.libraries));
    }

    private mapMaterialLibraryObjectToMaterials(
        materialLibrary: MaterialLibrary
    ): Material2[] {
        return values(materialLibrary.materials);
    }

    private initMaterialLibrariesRefreshLogic() {
        merge(
            this.applicationStateService.getLocalSharedState().pipe(
                selectHubConnectionState,
                filter((connectionState) => !!connectionState)
            ),
            this.socketIoService.subscribe(EVENT_CONFIGURATION_UPDATE)
        ).subscribe(() => {
            this.refreshAvailableMaterialLibraries();
        });
    }
}
