import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';

import { UiIconsModule } from '../ui-icons';
import {
    UiExpansionPanelBodyDirective,
    UiExpansionPanelComponent,
    UiExpansionPanelHeaderDirective,
    UiExpansionPanelPrefixDirective
} from './ui-expansion-panel.component';
import {
    IntersectionMarker,
    UiStickyHeaderExpansionPanelComponent
} from './ui-sticky-header-expansion-panel/ui-sticky-header-expansion-panel.component';

@NgModule({
    imports: [CommonModule, MatExpansionModule, UiIconsModule, MatRippleModule],
    exports: [
        UiExpansionPanelPrefixDirective,
        UiExpansionPanelComponent,
        UiExpansionPanelHeaderDirective,
        UiExpansionPanelBodyDirective,
        UiStickyHeaderExpansionPanelComponent
    ],
    declarations: [
        UiExpansionPanelPrefixDirective,
        UiExpansionPanelComponent,
        UiExpansionPanelHeaderDirective,
        UiExpansionPanelBodyDirective,
        UiStickyHeaderExpansionPanelComponent,
        IntersectionMarker
    ]
})
export class UiExpansionPanelModule {}
