import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { translate } from '@jsverse/transloco';
import { MemoizeObservable } from '@mhp/common';
import { UiBaseComponent } from '@mhp/ui-components';
import { I18nService } from '@mhp/ui-shared-services';

import { RegionDefinition } from './region.interfaces';

interface TranslatedRegionDefinition extends RegionDefinition {
    label: string;
}

@Component({
    selector: 'mhp-region-selector',
    templateUrl: './region-selector.component.html',
    styleUrls: ['./region-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegionSelectorComponent extends UiBaseComponent {
    readonly formGroup: UntypedFormGroup;

    @Input()
    set regionSelectDisabled(regionSelectDisabled: boolean) {
        const regionFormField = this.formGroup.get('region');
        if (regionSelectDisabled) {
            regionFormField?.disable();
        } else {
            regionFormField?.enable();
        }
    }

    @Input()
    set suggestedRegion(value: string | undefined) {
        // only apply when not already set by user
        if (this.formGroup.get('region')?.value) {
            return;
        }
        this.formGroup.get('region')?.setValue(value);
    }

    @Input()
    set suggestedLanguage(value: string | undefined) {
        this.formGroup.get('language')?.setValue(value);
    }

    @Input()
    set availableRegions(value: RegionDefinition[]) {
        this.availableRegionsSubject.next(value);
    }

    @Input()
    availableLanguages?: string[];

    @Output()
    readonly languageChange = new EventEmitter<string>();

    @Output()
    readonly confirmed = new EventEmitter<{
        region: string;
        language: string;
    }>();

    availableRegionsTranslated?: TranslatedRegionDefinition[];

    private readonly availableRegionsSubject = new BehaviorSubject<
        RegionDefinition[] | undefined
    >(undefined);

    constructor(
        public readonly matDialogRef: MatDialogRef<RegionSelectorComponent>,
        private readonly i18nService: I18nService
    ) {
        super();

        this.formGroup = new UntypedFormGroup({
            region: new UntypedFormControl(undefined, Validators.required),
            language: new UntypedFormControl(undefined, Validators.required)
        });

        this.formGroup
            .get('language')
            ?.valueChanges.pipe(this.takeUntilDestroy())
            .subscribe(this.languageChange);

        this.completeOnDestroy(
            this.languageChange,
            this.availableRegionsSubject,
            this.confirmed
        );
    }

    intentClose() {
        this.matDialogRef.close();
    }

    @MemoizeObservable()
    getAvailableRegions$(): Observable<
        TranslatedRegionDefinition[] | undefined
    > {
        return combineLatest([
            this.availableRegionsSubject,
            this.i18nService.getActiveLang$()
        ]).pipe(
            map(([availableRegions]) =>
                availableRegions?.map((regionDefinition) => ({
                    ...regionDefinition,
                    label: translate<string>(
                        `COMMON.REGION.${regionDefinition.id}`
                    )
                }))
            ),
            map((availableRegions) =>
                availableRegions?.sort((regionA, regionB) =>
                    regionA.label.localeCompare(regionB.label)
                )
            )
        );
    }

    intentConfirm() {
        this.confirmed.next(this.formGroup.getRawValue());
    }
}
