import { ConfigurationRequestPayload } from '@mhp-immersive-exp/contracts/src/configuration/configuration-request.interface';
import { PatchConfigurationPayload } from '@mhp-immersive-exp/contracts/src/configuration/patch-configuration-payload.interface';

export const REQUEST_GET_CONFIGURATION_PROXIED = 'getconfiguration_proxied';
export const REQUEST_PATCH_CONFIGURATION_PROXIED = 'patchconfiguration_proxied';

export interface ProxiedConfigurationRequestPayload
    extends ConfigurationRequestPayload {
    country?: string;
}

export interface ProxiedPatchConfigurationRequestPayload
    extends PatchConfigurationPayload {
    productId: string;
    country: string;
}
