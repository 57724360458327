import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UiIconsModule } from '../../ui-icons/ui-icons.module';
import { UiButtonModule } from '../ui-button.module';
import { UiIconButtonComponent } from './ui-icon-button.component';

@NgModule({
    imports: [CommonModule, UiButtonModule, UiIconsModule],
    exports: [UiIconButtonComponent],
    declarations: [UiIconButtonComponent]
})
export class UiIconButtonModule {}
