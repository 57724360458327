import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigurationState } from '@mhp/communication-models';

import { enhanceSelector } from '../../../application-state/helpers';
import { selectConfigurationState } from '../../../application-state/selectors';

export const selectEnvironmentState = enhanceSelector(
    pipe(
        selectConfigurationState,
        map((state) => state?.environmentState)
    ),
    {
        distinctUntilChanged: true
    }
);
export const selectAnimationStates = enhanceSelector(
    pipe(
        selectConfigurationState,
        map(
            (state: ConfigurationState | undefined) =>
                state && state.animationStates
        )
    ),
    {
        distinctUntilChanged: true
    }
);
export const selectCameraState = enhanceSelector(
    pipe(
        selectConfigurationState,
        map((state: ConfigurationState | undefined) => state?.cameraState?.id)
    ),
    {
        distinctUntilChanged: true
    }
);
export const selectHighlightState = enhanceSelector(
    pipe(
        selectConfigurationState,
        map(
            (state: ConfigurationState | undefined) =>
                state && state.highlightState
        )
    ),
    {
        distinctUntilChanged: true
    }
);
export const selectCinematicState = enhanceSelector(
    pipe(
        selectConfigurationState,
        map(
            (state: ConfigurationState | undefined) =>
                state && state.cinematicState
        )
    ),
    {
        distinctUntilChanged: true
    }
);
