import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'mhp-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingSpinnerComponent {
    @Input()
    theme: 'DARK' | 'LIGHT' = 'DARK';

    @HostBinding('attr.style')
    get styleColor() {
        return this.sanitizer.bypassSecurityTrustStyle(
            `--spinner-color: ${
                this.theme === 'DARK' ? '255,255,255' : '0,0,0'
            }`
        );
    }

    constructor(private readonly sanitizer: DomSanitizer) {}
}
