/* eslint-disable max-classes-per-file */
import { fill, isEmpty, isNil, mean } from 'lodash-es';
import {
    BehaviorSubject,
    Observable,
    Subject,
    Subscription,
    merge,
    timer
} from 'rxjs';
import { map } from 'rxjs/operators';

import { ConnectionCutType } from '@mhp-immersive-exp/sdk/streaming/monkeyway/internal/types/errors.js';
import {
    BitrateLimitData,
    LatencyLimitData,
    LimitData,
    NoFrameLimitData,
    StreamMonitoringEventHandler
} from '@mhp-immersive-exp/sdk/streaming/monkeyway/internal/types/stream-monitoring.js';
import { CutStreamLimits } from '@mhp-immersive-exp/sdk/streaming/monkeyway/internal/types/stream-options.js';

import { ExtendedStreamingEventHandler } from './streaming-event-handler.interfaces';

export interface CutStreamLimitsConfig
    extends Omit<CutStreamLimits, 'handler' | 'noFrames'> {
    /**
     * cut connection when no more frames where received during x percent of the observed time-window
     */
    noFramesRatio?: number;
}

export interface SlidingTimeWindowBasedCutEventHandlerConfig {
    // how long is the time-window that will be observed for a mean-value that is used when checking for cutStreamLimits
    windowTimespan: number;
    // how often should the accumulated stats be inspected for cutStreamLimit breaches
    checkPeriodTimespan: number;
    // the interval in which monkeyway checks the connection-reports
    connectionReportCheckInterval: number;
    // the CutStreamLimits configured for the connection that is observed
    cutStreamLimits: CutStreamLimitsConfig;
}

enum CutAction {
    CUT,
    NO_CUT
}

class TtlCache<T> {
    private readonly cache = new Set<T>();

    constructor(private readonly itemTtl) {}

    addItem(item: T) {
        this.cache.add(item);
        setTimeout(() => {
            this.cache.delete(item);
        }, this.itemTtl);
    }

    getItems() {
        return Array.from(this.cache);
    }

    clear() {
        this.cache.clear();
    }
}

/**
 * Allows collection of emitted LimitData events over a configurable time-window and basing
 * the decision if the stream needs to be cut or not based on average values collected in this time-window.
 */
export class SlidingTimeWindowBasedStreamingEventHandler
    implements ExtendedStreamingEventHandler
{
    private readonly limitDataResetSubject = new Subject<void>();

    private readonly limitDataSnapshotEvaluationSubject =
        new BehaviorSubject<CutAction>(CutAction.NO_CUT);

    private readonly ttlCache: TtlCache<LimitData>;

    private readonly snapshotEvaluationSubscription: Subscription;

    private readonly handler: StreamMonitoringEventHandler;

    constructor(
        private readonly config: SlidingTimeWindowBasedCutEventHandlerConfig
    ) {
        this.ttlCache = new TtlCache(config.windowTimespan);

        this.snapshotEvaluationSubscription =
            this.initLimitDataSnapshotEvaluation$().subscribe(
                this.limitDataSnapshotEvaluationSubject
            );

        this.handler = (data: LimitData): boolean => {
            this.ttlCache.addItem(data);
            const evaluationResult =
                this.limitDataSnapshotEvaluationSubject.value;
            return evaluationResult === CutAction.CUT;
        };
    }

    destroy() {
        this.ttlCache.clear();
        this.limitDataResetSubject.complete();
        this.snapshotEvaluationSubscription.unsubscribe();
    }

    reset() {
        this.ttlCache.clear();
        this.limitDataResetSubject.next();
    }

    getHandler(): StreamMonitoringEventHandler {
        return this.handler;
    }

    private initLimitDataSnapshotEvaluation$(): Observable<CutAction> {
        return merge(
            timer(
                this.config.windowTimespan,
                this.config.checkPeriodTimespan
            ).pipe(map(() => this.ttlCache.getItems())),
            this.limitDataResetSubject.pipe(map((): LimitData[] => []))
        ).pipe(
            map((limitDataCollection) =>
                this.inspectSnapshot(limitDataCollection)
            )
        );
    }

    private inspectSnapshot(snapshot: LimitData[]): CutAction {
        if (isEmpty(snapshot)) {
            return CutAction.NO_CUT;
        }

        if (!isNil(this.config.cutStreamLimits.bitrate)) {
            const accumulatedBitrates = snapshot
                .filter(
                    (currentLimitData): currentLimitData is BitrateLimitData =>
                        currentLimitData.type === ConnectionCutType.BITRATE
                )
                .map((currentLimitData) => currentLimitData.currentBitrate);
            this.fillToExpectedCount(
                accumulatedBitrates,
                2 * this.config.cutStreamLimits.bitrate
            );
            const bitrateMean = mean(accumulatedBitrates);
            if (
                !isEmpty(accumulatedBitrates) &&
                bitrateMean <= this.config.cutStreamLimits.bitrate
            ) {
                return CutAction.CUT;
            }
        }

        if (!isNil(this.config.cutStreamLimits.latency)) {
            const accumulatedLatencies = snapshot
                .filter(
                    (currentLimitData): currentLimitData is LatencyLimitData =>
                        currentLimitData.type === ConnectionCutType.LATENCY
                )
                .map((currentLimitData) => currentLimitData.currentLatency);
            this.fillToExpectedCount(accumulatedLatencies, 0);
            const latencyMean = mean(accumulatedLatencies);
            if (
                !isEmpty(accumulatedLatencies) &&
                latencyMean >= this.config.cutStreamLimits.latency
            ) {
                return CutAction.CUT;
            }
        }

        if (!isNil(this.config.cutStreamLimits.noFramesRatio)) {
            const accumulatedNoFrameEntries = snapshot
                .filter(
                    (currentLimitData): currentLimitData is NoFrameLimitData =>
                        currentLimitData.type === ConnectionCutType.FRAMES
                )
                .map(() => 1);
            this.fillToExpectedCount(accumulatedNoFrameEntries, 0);
            const noFramesMean = mean(accumulatedNoFrameEntries);
            // more than half of the reported limit-breaches are no-frames
            if (noFramesMean >= this.config.cutStreamLimits.noFramesRatio) {
                return CutAction.CUT;
            }
        }

        return CutAction.NO_CUT;
    }

    private fillToExpectedCount<T>(values: T[], value: T) {
        const expectedCount = Math.ceil(
            this.config.windowTimespan /
                this.config.connectionReportCheckInterval
        );
        if (expectedCount <= 1) {
            throw new Error(
                'Timeframe to accumulate cut-stream data cannot be smaller or equal then the interval in which reports are checked by the mw-library.'
            );
        }
        const startFillFrom = values.length;
        if (values.length < expectedCount) {
            values.length = expectedCount;
        }
        fill(values, value, startFillFrom, expectedCount);
    }
}
