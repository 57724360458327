import { isEqual } from 'lodash-es';
import { Observable, OperatorFunction } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { lazyShareReplay } from '@mhp/common';

export interface SelectorHelperOptions {
    distinctUntilChanged?: boolean;
}

/**
 * Enhance a selector to be used as pipeable operator to select chunks of state.
 * The resulting operator will be enhanced using
 * - publishReplay(1), refCount() which enables sharing results to multiple subscribers
 * - [optional] distinctUntilChanged() using equality check to prevent unnecessary emissions
 * @param sourcePipe The pipe to be enhanced
 * @param options Additional options to customize enhancement.
 */
export const enhanceSelector = <T, R>(
    sourcePipe: OperatorFunction<T, R>,
    options?: SelectorHelperOptions
): OperatorFunction<T, R> => (source: Observable<T>) => {
        let targetPipe = source.pipe(sourcePipe);
        if (options && options.distinctUntilChanged) {
            targetPipe = targetPipe.pipe(distinctUntilChanged(isEqual));
        }

        targetPipe = targetPipe.pipe(lazyShareReplay());

        return targetPipe;
    };
