import { first, isBoolean } from 'lodash-es';
import { Observable, combineLatest, iif, of } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Animation } from '@mhp-immersive-exp/contracts/src/animation/animation.interface';
import {
    Camera,
    GetCamerasResponsePayload
} from '@mhp-immersive-exp/contracts/src/camera/camera.interface';
import { Cinematic } from '@mhp-immersive-exp/contracts/src/cinematic/cinematic.interface';
import { Environment } from '@mhp-immersive-exp/contracts/src/environment/environment.interface';
import { Highlight } from '@mhp-immersive-exp/contracts/src/highlight/highlight.interface';
import { ProductAlternativeDto } from '@mhp-immersive-exp/contracts/src/product/product-alternative.dto.interface';
import { ProductDataDto } from '@mhp-immersive-exp/contracts/src/product/product-data.dto.interface';
import { ProductDto } from '@mhp-immersive-exp/contracts/src/product/product.dto.interface';
import { getDerivativeStaticInfo } from '@mhp/aml-shared/derivate-mapping/derivate-mapping';
import { AMLCameraMeta } from '@mhp/aml-shared/product-data/aml-camera-meta.interface';
import { AMLEnvironmentMeta } from '@mhp/aml-shared/product-data/aml-environment-meta.interface';
import { AMLProductMeta } from '@mhp/aml-shared/product-data/aml-product-meta.interface';
import {
    IllegalStateError,
    MemoizeObservable,
    lazyShareReplay
} from '@mhp/common';
import {
    ApplicationStateService,
    SearchParamsModifierAware,
    patchSearchParams$,
    selectEnvironmentState
} from '@mhp/ui-shared-services';

import { environment } from '../../environments/environment';
import { RegionService } from '../settings/region-selector/region.service';
import { LocalApplicationState } from '../state';
import {
    AmlProductDataStrategy,
    PreConfiguration,
    ProductInfo
} from './aml-product-data-strategy.interface';

type ProductDataEndpoints = 'GET_PRODUCTS' | 'GET_PRODUCT_DETAILS';

export interface ProductDataHttpStrategyConfig
    extends SearchParamsModifierAware<ProductDataEndpoints> {
    baseUrl: string;
}

export const PRODUCT_DATA_HTTP_STRATEGY_CONFIG_TOKEN =
    new InjectionToken<ProductDataHttpStrategyConfig>(
        'PRODUCT_DATA_HTTP_STRATEGY_CONFIG_TOKEN'
    );

export interface AmlProductDataEnvironmentFilter {
    filterEnvironments$(
        environments: Environment<AMLEnvironmentMeta>[]
    ): Observable<Environment<AMLEnvironmentMeta>[]>;
}

export const AML_PRODUCT_DATA_ENVIRONMENT_FILTER_TOKEN =
    new InjectionToken<AmlProductDataEnvironmentFilter>(
        'AML_PRODUCT_DATA_ENVIRONMENT_FILTER_TOKEN'
    );

export interface AmlGetAvailableCamerasOptions extends Record<string, unknown> {
    // if the environment-filter should be skipped entirely to fetch all available cameras
    skipEnvironmentFilter?: boolean;
    // overrideTargetEnvironment: If set, this environment will override the active environment when filtering cameras
    overrideTargetEnvironment?: string;
}

/**
 * ProductDataHttpStrategy implementation based on product-data REST service.
 * To be used when application is used in context where data should be fetched from the REST service instead of
 * directly from the engine.
 * This implementation requires a PRODUCT_DATA_HTTP_STRATEGY_CONFIG_TOKEN to know to which endpoint to talk to.
 */
@Injectable()
export class AmlProductDataStrategyImpl implements AmlProductDataStrategy {
    constructor(
        @Inject(PRODUCT_DATA_HTTP_STRATEGY_CONFIG_TOKEN)
        private readonly productDataHttpStrategyConfig: ProductDataHttpStrategyConfig,
        private readonly httpClient: HttpClient,
        private readonly regionService: RegionService,
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        @Inject(AML_PRODUCT_DATA_ENVIRONMENT_FILTER_TOKEN)
        @Optional()
        private readonly amlProductDataEnvironmentFilter?: AmlProductDataEnvironmentFilter
    ) {}

    /**
     * Emit the currently valid products for a given model.
     * @param modelCode
     */
    getAvailableProductsForModel$(
        modelCode: string
    ): Observable<ProductInfo[]> {
        return this.getAvailableProductInfos$().pipe(
            map((productsData) =>
                productsData
                    .filter(
                        (productData) => productData.modelCode === modelCode
                    )
                    .map(
                        (productData): ProductInfo =>
                            this.mapToProductInfo(productData)
                    )
            ),
            lazyShareReplay()
        );
    }

    /**
     * Emits the ProductInfo for a given productId.
     * @param productId The productId for which to fetch a matching ProductInfo.
     * @param country Optionally the country to use. If not provided, the currently active country will be used.
     */
    getProductInfo$(
        productId: string,
        country?: string
    ): Observable<ProductInfo | undefined> {
        return this.getAvailableProductInfos$(country).pipe(
            map((products) =>
                products.find((product) => product.id === productId)
            ),
            map((product) => {
                if (!product) {
                    return undefined;
                }
                return this.mapToProductInfo(product);
            }),
            lazyShareReplay()
        );
    }

    /**
     * Emits the ProductInfo for the currently active productId.
     */
    getActiveProductInfo$(
        activeProductId$: Observable<string | undefined>
    ): Observable<ProductInfo | undefined> {
        return activeProductId$.pipe(
            switchMap((activeProductId) => {
                if (!activeProductId) {
                    return of(undefined);
                }
                return this.getProductInfo$(activeProductId);
            }),
            lazyShareReplay()
        );
    }

    /**
     * Returns a list of all available products for the currently active region, optionally filtered by a given modelCode.
     * This stream takes the currently active region into account and re-emits on region-changes.
     * @param modelCode
     */
    @MemoizeObservable()
    getAvailableProducts$(modelCode?: string): Observable<string[]> {
        return this.getAvailableProductInfos$().pipe(
            map((productsData) => {
                const allProductIds = productsData
                    .filter((productData) =>
                        modelCode ? productData.modelCode === modelCode : true
                    )
                    .map((productData) => productData.id);
                return [...new Set(allProductIds)];
            }),
            lazyShareReplay()
        );
    }

    /**
     * Get a list of all available designer-specs for a given productId.
     * This stream takes the currently active region into account and re-emits on region-changes.
     * @param productId
     */
    getAvailableProductAlternatives$(
        productId: string
    ): Observable<ProductAlternativeDto[] | undefined> {
        return this.getAvailableProductInfos$().pipe(
            map((productsData) =>
                productsData.find((productData) => productData.id === productId)
            ),
            map((productData) => {
                if (!productData) {
                    throw new IllegalStateError(
                        `No productData found for productId ${productId}`
                    );
                }
                return productData.productAlternatives;
            }),
            lazyShareReplay()
        );
    }

    getAvailableEnvironments$(
        productId: string
    ): Observable<Environment<AMLEnvironmentMeta>[]> {
        return this.getProductDetails$(productId).pipe(
            map((data): Environment<AMLEnvironmentMeta>[] => data.environments),
            map((environments) =>
                this.keepConfiguratorRelevantEntriesOnly(
                    environments,
                    environment.appConfig.configuration.environmentFilter
                )
            ),
            switchMap((environments) => {
                if (this.amlProductDataEnvironmentFilter) {
                    return this.amlProductDataEnvironmentFilter.filterEnvironments$(
                        environments
                    );
                }
                return of(environments);
            })
        );
    }

    /**
     * Provides a list of available cameras for a given productId.
     * This takes into account the following context-parameters:
     * - active environment: Depending on active environment, cameras may be filtered based on their relatedEnvironment property
     * - active vr-state: Depending on active VR-state, cameras may be filtered based on their type property
     * @param productId The productId for which to fetch a list of available cameras.
     * @param options The options to alter the resulting set of available cameras.
     */
    getAvailableCameras$(
        productId: string,
        options?: AmlGetAvailableCamerasOptions
    ): Observable<GetCamerasResponsePayload<AMLCameraMeta> | undefined> {
        return combineLatest([
            this.getProductDetails$(productId).pipe(
                map((productDataDto) => ({
                    defaultInt: productDataDto.cameras.defaultInt,
                    defaultExt: productDataDto.cameras.defaultExt,
                    cameras: productDataDto.cameras.cameras.map(
                        (currentCamera): Camera<AMLCameraMeta> => ({
                            id: currentCamera.id,
                            type: currentCamera.type ?? [],
                            availability: currentCamera.availability,
                            thumbnail: currentCamera.thumbnail,
                            meta: currentCamera.meta,
                            options: {}
                        })
                    )
                }))
            ),
            iif(
                () => !!options?.overrideTargetEnvironment,
                of(options?.overrideTargetEnvironment),
                this.applicationStateService.getState().pipe(
                    selectEnvironmentState,
                    map((currentEnvironment) => currentEnvironment?.id)
                )
            ),
            this.applicationStateService
                .getEngineState()
                .pipe(
                    map(
                        (engineState) =>
                            !!engineState?.settingsState?.vrAvailable &&
                            !!engineState?.settingsState?.vrEnabled
                    )
                ),
            this.getAvailableEnvironments$(productId)
        ]).pipe(
            map(
                ([
                    productDataCameras,
                    activeEnvironmentId,
                    vrCamerasOnly,
                    availableEnvironments
                ]) => ({
                    ...productDataCameras,
                    cameras: productDataCameras.cameras.filter(
                        (camera: Camera<AMLCameraMeta>) => {
                            if (
                                vrCamerasOnly &&
                                !!availableEnvironments?.find(
                                    (env) => env.id === activeEnvironmentId
                                )?.meta?.vr
                            ) {
                                if (
                                    !(
                                        camera.type?.includes('IntVR') ||
                                        camera.type?.includes('ExtVR')
                                    )
                                ) {
                                    return false;
                                }
                            } else if (
                                !(
                                    camera.type?.includes('Int') ||
                                    camera.type?.includes('Ext')
                                )
                            ) {
                                return false;
                            }

                            if (
                                options?.skipEnvironmentFilter ||
                                !activeEnvironmentId ||
                                !camera.meta?.relatedEnvironments
                            ) {
                                // no active environment-id or no skipEnvironmentFilter-info
                                return true;
                            }
                            // keep the camera only if the active environment is contained in the list of related environments
                            return !!camera.meta.relatedEnvironments?.find(
                                (relatedEnvironmentId) =>
                                    activeEnvironmentId === relatedEnvironmentId
                            );
                        }
                    )
                })
            )
        );
    }

    getAvailableAnimations$(productId: string): Observable<Animation[]> {
        return this.getProductDetails$(productId).pipe(
            map((data) =>
                data.animations.map(
                    (animation): Animation => ({
                        id: animation.id,
                        availability: animation.availability,
                        thumbnail: animation.thumbnail,
                        thumbCam: animation.thumbCam,
                        relatedOptions: animation.relatedOptions,
                        progress: 0
                    })
                )
            )
        );
    }

    getAvailableHighlights$(productId: string): Observable<Highlight[]> {
        return this.getProductDetails$(productId).pipe(
            map((data) => data.highlights)
        );
    }

    getAvailableCinematics$(productId: string): Observable<Cinematic[]> {
        return of([
            {
                id: environment.appConfig.visualization.defaultCinematic,
                name: environment.appConfig.visualization.defaultCinematic,
                duration: 60000,
                thumbnail: ''
            }
        ]);
        // FIXME: use cameras provided by product-data service
        // return this.getProductDetails$(productId).pipe(
        //     map((data) => data.cinematics)
        // );
    }

    /**
     *
     * @param country Optionally the country to use. If not provided, the currently active country will be used.
     */
    @MemoizeObservable()
    getAvailableProductInfos$(
        country?: string
    ): Observable<ProductDto<AMLProductMeta>[]> {
        return combineLatest([
            country ? of(country) : this.withRegion$(),
            patchSearchParams$(
                this.productDataHttpStrategyConfig,
                'GET_PRODUCTS',
                undefined
            )
        ]).pipe(
            switchMap(([region, patchedSearchParams]) =>
                this.httpClient
                    .get<ProductDto<AMLProductMeta>[]>(
                        `${this.productDataHttpStrategyConfig.baseUrl}/products/${region}`,
                        {
                            params: patchedSearchParams
                                ? new HttpParams({
                                      fromObject: patchedSearchParams
                                  })
                                : undefined
                        }
                    )
                    .pipe(
                        map((products) => products ?? []),
                        // reset the previously emitted value to prevent stale data for a different country being emitted
                        startWith(undefined)
                    )
            ),
            shareReplay(1),
            filter(
                (products): products is ProductDto<AMLProductMeta>[] =>
                    !!products
            )
        );
    }

    /**
     * Get the default environment for the given productId.
     * This might be defined through the defaults.environment property in the product-data or, if this is not set or cannot be matched to one
     * of the environments available in the current context, the first one in the list of available environments.
     *
     * @param productId The productId to fetch the default environment for.
     */
    getDefaultEnvironment$(
        productId: string
    ): Observable<Environment<AMLEnvironmentMeta> | undefined> {
        return combineLatest([
            this.getAvailableEnvironments$(productId),
            this.getProductDetails$(productId).pipe(
                map((data) => data?.defaults?.environment)
            )
        ]).pipe(
            map(([availableEnvironments, defaultEnvironmentId]) => {
                let defaultEnvironment = availableEnvironments?.find(
                    (currentEnvironment) =>
                        currentEnvironment.id === defaultEnvironmentId
                );

                if (!defaultEnvironment) {
                    // in case we do not obtain a default-environment from the product-data or it cannot be matched to the environments available in the current context, fall back to the first environment of the ones being contained in the data
                    defaultEnvironment = first(availableEnvironments);
                }
                return defaultEnvironment;
            })
        );
    }

    private withRegion$(): Observable<string> {
        return this.regionService
            .getActiveRegion$()
            .pipe(filter((region): region is string => !!region));
    }

    @MemoizeObservable()
    private getProductDetails$(
        productId: string
    ): Observable<ProductDataDto<AMLEnvironmentMeta, AMLCameraMeta>> {
        return patchSearchParams$(
            this.productDataHttpStrategyConfig,
            'GET_PRODUCT_DETAILS',
            undefined
        )
            .pipe(
                switchMap((patchedSearchParams) =>
                    this.httpClient.get<
                        ProductDataDto<AMLEnvironmentMeta, AMLCameraMeta>
                    >(
                        `${this.productDataHttpStrategyConfig.baseUrl}/products/${productId}/details`,
                        {
                            params: patchedSearchParams
                                ? new HttpParams({
                                      fromObject: patchedSearchParams
                                  })
                                : undefined
                        }
                    )
                )
            )
            .pipe(shareReplay(1));
    }

    private keepConfiguratorRelevantEntriesOnly<
        T extends { meta?: AMLEnvironmentMeta }
    >(entries: T[], entryFilter?: (entry: T) => boolean): T[] {
        return entries.filter((entry) => {
            if (entryFilter) {
                return entryFilter(entry);
            }

            return (
                !isBoolean(entry?.meta?.configurator) ||
                entry?.meta?.configurator === true
            );
        });
    }

    private mapToProductInfo(product: ProductDto<AMLProductMeta>): ProductInfo {
        return {
            id: product.id,
            modelId: product.modelCode,
            defaultConfig: product.defaultConfig,
            description: '', // FIXME: add missing description,
            preConfigurations: product.productAlternatives?.map(
                (spec): PreConfiguration => ({
                    id: spec.id,
                    name: spec.id,
                    description: '',
                    config: spec.config
                })
            ),
            pricing: product.pricing,
            isAuthorizedOnly: product.isAuthorizedOnly,
            isLimitedEdition: getDerivativeStaticInfo(product.id)
                .isLimitedEdition,
            meta: product?.meta
        };
    }
}
