import { merge } from 'lodash-es';
import { Observable, merge as mergeStatic } from 'rxjs';
import {
    filter,
    finalize,
    map,
    switchMap,
    takeUntil,
    tap
} from 'rxjs/operators';

import { ComponentType } from '@angular/cdk/portal';
import {
    Inject,
    Injectable,
    InjectionToken,
    Optional,
    TemplateRef
} from '@angular/core';
import { MatDialogState } from '@angular/material/dialog';
import { translate } from '@jsverse/transloco';
import { UiMatDialogConfig, UiMatDialogService } from '@mhp/ui-components';

import { StrategyProvider } from '../strategy/strategy-provider';
import {
    Button,
    DialogButtonPlacement,
    DialogButtonType,
    GenericTextModal
} from './generic-text-modal.interface';
import { GenericTextModalComponent } from './generic-text-modal/generic-text-modal.component';

export interface AdvancedConfirmDialogResult {
    /**
     * Close the dialog if the result could be sucessfully processed
     */
    closeDialog: () => void;
    /**
     * The result of the dialog
     */
    result: string | 'CANCEL';
}

export interface ConfirmDialogResult extends AdvancedConfirmDialogResult {
    result: 'OK' | 'CANCEL';
}

export interface CommonDialogsServiceConfig<D = any> {
    defaultCancelButtonType?: DialogButtonType;
    defaultDialogComponent?:
        | ComponentType<GenericTextModal>
        | TemplateRef<GenericTextModal>;
    defaultDialogOptions?: UiMatDialogConfig<D>;
}

export const COMMON_DIALOGS_SERVICE_CONFIG_TOKEN = new InjectionToken<
    StrategyProvider<CommonDialogsServiceConfig>
>('CommonDialogsServiceConfiguration');

// used to distinguish between internally triggered dialog-close actions and user-triggered.
const INTERNAL_CLOSE = 'INTERNAL_CLOSE';

interface AdvancedConfirmDialogOptions<D = any> {
    showCancel?: boolean;
    cancelText?: string;
    cancelButtonType?: DialogButtonType;
    skipWrappingParagraph?: boolean;
    dialogComponent?:
        | ComponentType<GenericTextModal>
        | TemplateRef<GenericTextModal>;
    dialogOptions?: UiMatDialogConfig<D>;
}

/**
 * Used to provide a simple facade for recurring confirm-dialog tasks.
 */
@Injectable({
    providedIn: 'root'
})
export class CommonDialogsService {
    constructor(
        private readonly dialogService: UiMatDialogService,
        @Optional()
        @Inject(COMMON_DIALOGS_SERVICE_CONFIG_TOKEN)
        private readonly config?: CommonDialogsServiceConfig
    ) {}

    /**
     * Open a simple text-confirm dialog to the user allowing him to choose
     * between OK and CANCEL.
     * @param title The title of the dialog
     * @param text The text to display
     * @param cleanup$ Should emit something when the host-component gets destroyed to indicate that the dialog has to be closed.
     *          This makes sure that no dangling dialogs without the originating context are stay around.
     * @param showCancel If cancel should be displayed.
     * @param confirmText The optional override for the confirm-button.
     * @param cancelText The optional override for the cancel-button.
     */
    openConfirmDialog(
        title: string,
        text: string,
        cleanup$: Observable<any>,
        showCancel = true,
        confirmText?: string,
        cancelText?: string
    ): Observable<ConfirmDialogResult> {
        const confirmButtons = [
            {
                id: 'OK',
                label: confirmText || translate<string>('COMMON.CONFIRM'),
                type: DialogButtonType.PRIMARY
            }
        ];

        return this.openAdvancedConfirmDialog(
            title,
            text,
            confirmButtons,
            cleanup$,
            showCancel,
            cancelText
        ).pipe(
            map((dialogResult): ConfirmDialogResult => {
                if (
                    dialogResult.result === 'OK' ||
                    dialogResult.result === 'CANCEL'
                ) {
                    return {
                        ...dialogResult,
                        result: dialogResult.result
                    };
                }
                throw new Error(
                    `Unexpected result from confirm-dialog: [${dialogResult.result}]`
                );
            })
        );
    }

    /**
     * Open a text-confirm dialog to the user allowing him to choose
     * between custom-actions and optionally CANCEL.
     * @param title The title of the dialog
     * @param text The text to display
     * @param confirmButtons The buttons to be shown to the user
     * @param cleanup$ Should emit something when the host-component gets destroyed to indicate that the dialog has to be closed.
     *          This makes sure that no dangling dialogs without the originating context are stay around.
     * @param showCancel If cancel should be displayed.
     * @param cancelText The optional override for the cancel-button.
     */
    openAdvancedConfirmDialog(
        title: string,
        text: string,
        confirmButtons: Button[],
        cleanup$: Observable<any>,
        showCancel = true,
        cancelText?: string
    ): Observable<AdvancedConfirmDialogResult> {
        const confirmModalRef = this.dialogService.open(
            this.config?.defaultDialogComponent || GenericTextModalComponent,
            this.config?.defaultDialogOptions
        );

        cleanup$
            .pipe(
                finalize(() => confirmModalRef.close(INTERNAL_CLOSE)),
                takeUntil(confirmModalRef.afterClosed())
            )
            .subscribe();

        const confirmModal = confirmModalRef.componentInstance;
        confirmModal.title = title;
        confirmModal.text = text;
        if (showCancel) {
            confirmModal.buttonsLeft = [
                {
                    id: 'CANCEL',
                    label: cancelText || translate<string>('COMMON.CANCEL'),
                    type:
                        this.config?.defaultCancelButtonType ||
                        DialogButtonType.LINK
                }
            ];
        }
        confirmModal.buttonsRight = confirmButtons;

        return mergeStatic(
            confirmModal.buttonClick,
            confirmModalRef.beforeClosed().pipe(
                filter((result) => result !== INTERNAL_CLOSE),
                map(() => 'CANCEL')
            )
        ).pipe(
            takeUntil(confirmModalRef.afterClosed()),
            tap((buttonId: string) => {
                if (
                    buttonId === 'CANCEL' &&
                    confirmModalRef.getState() === MatDialogState.OPEN
                ) {
                    confirmModalRef.close(INTERNAL_CLOSE);
                }
            }),
            map(
                (buttonId): AdvancedConfirmDialogResult => ({
                    closeDialog: () => confirmModalRef.close(INTERNAL_CLOSE),
                    result: buttonId
                })
            )
        );
    }

    /**
     * Open a simple text-confirm dialog to the user allowing him to choose
     * between OK and CANCEL.
     * @param title The title of the dialog
     * @param text The text to display
     * @param confirmText The optional override for the confirm-button.
     * @param options Options to use when opening the confirm dialog.
     */
    openConfirmDialog$(
        title: string,
        text: string,
        confirmText?: string,
        options?: AdvancedConfirmDialogOptions
    ): Observable<ConfirmDialogResult> {
        const confirmButtons = [
            {
                id: 'OK',
                label: confirmText || translate<string>('COMMON.CONFIRM'),
                type: DialogButtonType.PRIMARY
            }
        ];

        return this.openAdvancedConfirmDialog$(
            title,
            text,
            confirmButtons,
            options
        ).pipe(
            map((dialogResult): ConfirmDialogResult => {
                if (
                    dialogResult.result === 'OK' ||
                    dialogResult.result === 'CANCEL'
                ) {
                    return {
                        ...dialogResult,
                        result: dialogResult.result
                    };
                }
                throw new Error(
                    `Unexpected result from confirm-dialog: [${dialogResult.result}]`
                );
            })
        );
    }

    /**
     * Open a text-confirm dialog to the user allowing him to choose
     * between custom-actions and optionally CANCEL.
     * Dialog stays open as long as a subscriber is subscribed to the returned Observable.
     *
     * @param title The title of the dialog
     * @param text The text to display
     * @param confirmButtons The buttons to be shown to the user
     * @param options Options to use when opening the confirm dialog.
     */
    openAdvancedConfirmDialog$(
        title: string,
        text: string,
        confirmButtons: Button[],
        options?: AdvancedConfirmDialogOptions
    ): Observable<AdvancedConfirmDialogResult> {
        return this.dialogService
            .open$(
                options?.dialogComponent ||
                    this.config?.defaultDialogComponent ||
                    GenericTextModalComponent,
                merge(
                    {},
                    this.config?.defaultDialogOptions,
                    options?.dialogOptions
                )
            )
            .pipe(
                switchMap((modalRef) => {
                    const confirmModal = modalRef.componentInstance;
                    confirmModal.title = title;
                    confirmModal.text = text;
                    confirmModal.useWrappingParagraph =
                        !options?.skipWrappingParagraph;
                    if (options?.showCancel) {
                        confirmModal.buttonsLeft = [
                            {
                                id: 'CANCEL',
                                label:
                                    options?.cancelText ||
                                    translate<string>('COMMON.CANCEL'),
                                type:
                                    options?.cancelButtonType ||
                                    this.config?.defaultCancelButtonType ||
                                    DialogButtonType.LINK
                            }
                        ];
                    }
                    confirmModal.buttonsLeft = [
                        ...(confirmModal.buttonsLeft || []),
                        ...confirmButtons.filter(
                            (button) =>
                                button.placement === DialogButtonPlacement.LEFT
                        )
                    ];
                    confirmModal.buttonsRight = confirmButtons.filter(
                        (button) =>
                            !button.placement ||
                            button.placement === DialogButtonPlacement.RIGHT
                    );

                    return mergeStatic(
                        confirmModal.buttonClick,
                        modalRef.beforeClosed().pipe(
                            filter((result) => result !== INTERNAL_CLOSE),
                            map(() => 'CANCEL')
                        )
                    ).pipe(
                        takeUntil(modalRef.afterClosed()),
                        tap((buttonId: string) => {
                            if (
                                buttonId === 'CANCEL' &&
                                modalRef.getState() === MatDialogState.OPEN
                            ) {
                                modalRef.close(INTERNAL_CLOSE);
                            }
                        }),
                        map(
                            (buttonId): AdvancedConfirmDialogResult => ({
                                closeDialog: () =>
                                    modalRef.close(INTERNAL_CLOSE),
                                result: buttonId
                            })
                        )
                    );
                })
            );
    }
}
