import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { UiTouchPanelHammerModule } from './ui-touch-panel-hammer.module';
import { UiTouchPanelComponent } from './ui-touch-panel.component';

/**
 * When loading UiTouchPanelModule in a lazily loaded module, you'll
 * have to import UiTouchPanelHammerModule.forRoot() in your applications root
 * module and import UiTouchPanelModule in your lazily loaded module.
 * Otherwise, when eagerly loading this module, import using UiTouchPanel.forRoot()
 * which will provide UiTouchPanelHammerModules providers as well.
 */
@NgModule({
    declarations: [UiTouchPanelComponent],
    imports: [CommonModule, UiTouchPanelHammerModule],
    exports: [UiTouchPanelComponent]
})
export class UiTouchPanelModule {
    static forRoot(): ModuleWithProviders<UiTouchPanelModule> {
        return {
            ngModule: UiTouchPanelModule,
            providers: UiTouchPanelHammerModule.forRoot().providers
        };
    }
}
