import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ConfigModel } from '@mhp-immersive-exp/contracts/src/configuration/config-model.interface';
import {
    ConfigurationResponsePayload,
    ProjectReturn
} from '@mhp-immersive-exp/contracts/src/configuration/configuration-response.interface';
import { ProjectParameters } from '@mhp-immersive-exp/contracts/src/project-parameters/project-parameters';

import { SocketIOService } from '../../communication';
import { ProductConfigurationStrategy } from '../product-configuration-strategy.interface';
import {
    ProxiedConfigurationRequestPayload,
    ProxiedPatchConfigurationRequestPayload,
    REQUEST_GET_CONFIGURATION_PROXIED,
    REQUEST_PATCH_CONFIGURATION_PROXIED
} from './proxied-configuration-request.interface';

/**
 * ProductConfigurationStrategy implementation that can be used to talk to a proxy-endpoint registered
 * with a backend hub.
 * Usecase could be to allow access to information that is otherwise not available without being authenticated (user / dealer scenario).
 */
@Injectable()
export class ProductConfigurationHubProxyStrategy<
    P extends ProjectParameters = ProjectParameters,
    PR extends ProjectReturn = ProjectReturn
> implements ProductConfigurationStrategy<P>
{
    constructor(private readonly socketIoService: SocketIOService) {}

    getConfigurationMetadata$(
        productId: string,
        config?: ConfigModel[] | undefined,
        projectParameters?: P,
        country?: string | undefined
    ): Observable<ConfigurationResponsePayload<PR>> {
        return this.socketIoService.request<
            ProxiedConfigurationRequestPayload,
            ConfigurationResponsePayload<PR>
        >(REQUEST_GET_CONFIGURATION_PROXIED, {
            productId,
            options: config || [],
            country
        });
    }

    patchConfiguration$(
        productId: string,
        config: ConfigModel[],
        country: string,
        add?: ConfigModel[],
        remove?: ConfigModel[]
    ): Observable<ConfigModel[]> {
        return this.socketIoService.request<
            ProxiedPatchConfigurationRequestPayload,
            ConfigModel[]
        >(REQUEST_PATCH_CONFIGURATION_PROXIED, {
            productId,
            country,
            options: config || [],
            add,
            remove
        });
    }
}
