<div class="ui-mat-dialog">
    <mhp-ui-icon-button
        *ngIf="showClose"
        class="ui-mat-dialog__action-close"
        svgIcon="mhp-ui:close"
        [size]="closeIconSize"
        mat-dialog-close
    ></mhp-ui-icon-button>
    <div
        class="ui-mat-dialog__header"
        [class.ui-mat-dialog__header--empty]="!title && !subtitle && !header"
    >
        <div *ngIf="subtitle" class="ui-mat-dialog__header-subtitle">
            {{ subtitle }}
        </div>
        <div *ngIf="title" class="ui-mat-dialog__header-title">{{ title }}</div>
        <ng-content
            *ngIf="!title"
            select="mhp-ui-mat-dialog-header"
        ></ng-content>
    </div>
    <mat-dialog-content
        class="ui-mat-dialog__content"
        [ngClass]="{
            'ui-mat-dialog__content--no-header': !title && !subtitle && !header,
            'ui-mat-dialog__content--no-actions': !actionsLeft && !actionsRight
        }"
        tabindex="-1"
    >
        <ng-content select="mhp-ui-mat-dialog-content"></ng-content>
    </mat-dialog-content>
    <mat-dialog-actions
        class="ui-mat-dialog__actions"
        [ngClass]="{
            'ui-mat-dialog__actions--empty': !actionsLeft && !actionsRight,
            'ui-mat-dialog__actions--left-empty': !actionsLeft,
            'ui-mat-dialog__actions--right-empty': !actionsRight
        }"
    >
        <div class="ui-mat-dialog__actions-left">
            <ng-content select="mhp-ui-mat-dialog-actions-left"></ng-content>
        </div>
        <div class="ui-mat-dialog__actions-right">
            <ng-content select="mhp-ui-mat-dialog-actions-right"></ng-content>
        </div>
    </mat-dialog-actions>
</div>
