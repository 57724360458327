import { Action, createReducer, on } from '@ngrx/store';

import {
    exitSearchState,
    setActiveSearchTerm,
    setSearchInputActive
} from '../actions/order.actions';
import { LocalOrderState } from '../local-order-state.interface';

const INITIAL_STATE: LocalOrderState = {
    searchInputActive: false
};

const reducer = createReducer<LocalOrderState | undefined>(
    INITIAL_STATE,
    on(
        setActiveSearchTerm,
        (state: LocalOrderState, payload): LocalOrderState => ({
            ...state,
            activeSearchTerm: payload.searchTerm
        })
    ),
    on(
        setSearchInputActive,
        (state: LocalOrderState, payload): LocalOrderState => ({
            ...state,
            searchInputActive: payload.searchInputActive
        })
    ),
    on(
        exitSearchState,
        (state: LocalOrderState): LocalOrderState => ({
            ...state,
            searchInputActive: false,
            activeSearchTerm: undefined
        })
    )
);

export function orderReducer(
    state: LocalOrderState | undefined,
    action: Action
) {
    return reducer(state, action);
}
