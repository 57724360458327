import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule, MarkdownModule } from '@mhp/ui-shared-services';

@NgModule({
    declarations: [],
    exports: [],
    imports: [CommonModule, MarkdownModule.forRoot(), I18nModule]
})
export class CookieModule {}
