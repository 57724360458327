<div class="notification">
    <div class="notification__row-1">
        <span
            class="notification__message"
            [ngClass]="{
                'notification__message--has-close': showClose,
                'notification__message--has-action':
                    actionLabel && !renderActionNewline
            }"
            [innerHtml]="message"
        ></span>
        <ng-container *ngIf="!renderActionNewline">
            <ng-container *ngTemplateOutlet="cta"></ng-container>
        </ng-container>
        <mhp-ui-icon-button
            *ngIf="showClose"
            class="notification__action-close"
            [svgIcon]="closeIconId"
            size="small"
            (click)="intentClose()"
        ></mhp-ui-icon-button>
    </div>
    <div class="notification__row-2" *ngIf="renderActionNewline">
        <ng-container *ngTemplateOutlet="cta"></ng-container>
    </div>
</div>

<ng-template #cta>
    <button
        *ngIf="actionLabel"
        mat-flat-button
        class="notification__action mhp-ui-button--invert"
        (click)="intentTriggerAction()"
    >
        {{ actionLabel }}
    </button>
</ng-template>
