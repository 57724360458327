import { isNumber } from 'lodash-es';

/**
 * Small wrapper around a record of UTM parameters
 */
export class UtmParametersWrapper {
    constructor(private readonly utmParameters: Record<string, string>) {}

    /**
     * Returns a clone of the wrapped parameters.
     */
    getParameters(): Record<string, string> {
        return {
            ...this.utmParameters
        };
    }

    /**
     * Returns the wrapped parameters as a URL encoded string, e.g. "utm_source=test&utm_medium=test".
     * @param maxLength The maximum length of the URL encoded string. If the string exceeds this length, the remaining parameters will be dropped.
     */
    getAsUrlEncodedString(maxLength?: number): string | undefined {
        return (
            Object.entries(this.utmParameters).reduce(
                (joinedUrlParameters, [key, value]) => {
                    const joinedUrlParametersCandidate = joinedUrlParameters
                        ? `${joinedUrlParameters}&${key}=${value}`
                        : `${key}=${value}`;
                    if (
                        isNumber(maxLength) &&
                        maxLength >= 0 &&
                        joinedUrlParametersCandidate.length > maxLength
                    ) {
                        // max-length reached,  drop remaining parameters
                        return joinedUrlParameters;
                    }
                    return joinedUrlParametersCandidate;
                },
                ''
            ) || undefined
        );
    }
}
