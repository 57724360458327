import { ReservationData } from '@mhp-immersive-exp/sdk/streaming/monkeyway/internal/types/reservation-data.js';
import { TwilioTokenData } from '@mhp-immersive-exp/sdk/streaming/monkeyway/internal/types/session-data.js';
import { createAction, props } from '@ngrx/store';

import { AudioVideoSettings } from '../../../../common/audio-video-settings/audio-video-settings.interface';

/**
 * Set the target session data to be used to connect to one2one session.
 */
export const setTargetOne2OneSessionData = createAction(
    '[LOCAL-STATE]: setTargetOne2OneSessionData',
    props<{ sessionData: ReservationData | undefined }>()
);

/**
 * Set the target sessionKey to be used to connect to one2one session.
 */
export const setTargetOne2OneSessionKey = createAction(
    '[LOCAL-STATE]: setTargetOne2OneSessionKey',
    props<{ sessionKey: string | undefined }>()
);

/**
 * Set the active client-session-code that is used to connect to one2one session.
 */
export const setActiveOne2OneClientSessionCode = createAction(
    '[LOCAL-STATE]: setActiveOne2OneClientSessionCode',
    props<{ sessionCode: string }>()
);

/**
 * Clear the active client-session-code that is used to connect to one2one session.
 */
export const clearActiveOne2OneClientSessionCode = createAction(
    '[LOCAL-STATE]: clearActiveOne2OneClientSessionCode'
);

/**
 * Set twilio token once resolved using the monkeyway library
 */
export const setTwilioToken = createAction(
    '[LOCAL-STATE]: setTwilioToken',
    props<TwilioTokenData | { token: undefined }>()
);

/**
 * Update the audio and video settings for the video chat
 */
export const setAudioVideoSettings = createAction(
    '[LOCAL-STATE]: setAudioVideoSettings',
    props<AudioVideoSettings>()
);

/**
 * Update the video settings for the video chat
 */
export const setVideoSetting = createAction(
    '[LOCAL-STATE]: setVideoSetting',
    props<{ video: boolean }>()
);

/**
 * Set the user preference for the video setting
 */
export const setTargetVideoSetting = createAction(
    '[LOCAL-STATE]: setTargetVideoSetting',
    props<{ targetVideo: boolean }>()
);

/**
 * Update video device id
 */
export const setVideoDeviceId = createAction(
    '[LOCAL-STATE]: setVideoDeviceId',
    props<{ videoDeviceId: string | undefined }>()
);

/**
 * Update the audio settings for the video chat
 */
export const setAudioSetting = createAction(
    '[LOCAL-STATE]: setAudioSetting',
    props<{ audio: boolean }>()
);

/**
 * Set the user preference for the audio setting
 */
export const setTargetAudioSetting = createAction(
    '[LOCAL-STATE]: setTargetAudioSetting',
    props<{ targetAudio: boolean }>()
);

/**
 * Update audio device id
 */
export const setAudioDeviceId = createAction(
    '[LOCAL-STATE]: setAudioDeviceId',
    props<{ audioDeviceId: string | undefined }>()
);

/**
 * Update the audio mute setting for the video chat
 */
export const setRemoteParticipantMuteSetting = createAction(
    '[LOCAL-STATE]: setRemoteParticipantMuteSetting',
    props<{ remoteParticipantMuted: boolean }>()
);
