<mhp-header-bar
    class="dialog-header__header-bar"
    [ngClass]="{
        'dialog-header__header-bar--visible': dialogShown
    }"
    [hideLogo]="hideLogo"
    *transloco="let t; read: 'ICONS'"
>
    <ng-container header-bar_area-left>
        <mhp-ui-icon-button
            *ngIf="
                !disableClose &&
                (closeIconPosition === HeaderCloseIconPosition.START ||
                    (closeIconPosition === undefined &&
                        config?.defaultCloseIconPosition ===
                            HeaderCloseIconPosition.START))
            "
            class="dialog-header__action-close dialog-header__action-close--left"
            [matTooltip]="t('CLOSE_ALT')"
            [label]="t('CLOSE_ALT')"
            (click)="intentClose()"
            svgIcon="mhp-ui:close"
        ></mhp-ui-icon-button>
        <ng-content select="[dialog-header_area-left]"></ng-content>
    </ng-container>
    <ng-container header-bar_area-right>
        <mhp-ui-icon-button
            *ngIf="
                !disableClose &&
                (closeIconPosition === HeaderCloseIconPosition.END ||
                    (closeIconPosition === undefined &&
                        config?.defaultCloseIconPosition ===
                            HeaderCloseIconPosition.END))
            "
            [matTooltip]="t('CLOSE_ALT')"
            [label]="t('CLOSE_ALT')"
            class="dialog-header__action-close dialog-header__action-close--right"
            (click)="intentClose()"
            svgIcon="mhp-ui:close"
        ></mhp-ui-icon-button>
        <ng-content select="[dialog-header_area-right]"></ng-content>
    </ng-container>
</mhp-header-bar>
