<mhp-fullscreen-dialog
    [wrapInDialogBody]="true"
    [hideLogo]="true"
    [hideTopGradient]="true"
    [closeImplicit]="false"
    (dialogClose)="intentDialogClose()"
>
    <div class="user-inactivity-dialog" *transloco="let t">
        <div class="countdown-wheel">
            <mat-progress-spinner
                mode="determinate"
                [value]="getProgress$() | async"
                diameter="200"
                strokeWidth="6"
                class="countdown-wheel__spinner"
                aria-hidden="true"
            ></mat-progress-spinner>
            <div class="countdown-wheel__background"></div>
            <div class="countdown-wheel__progress-text">
                {{ getRemainingTimeAsDate$() | async | date: 'mm:ss' }}
            </div>
        </div>
        <p class="user-inactivity-dialog__description">
            {{ t('STREAMING.USER_INACTIVITY.DESCRIPTION') }}
        </p>
        <button
            mat-flat-button
            (click)="intentDialogClose()"
        >
            <span>{{ t('STREAMING.USER_INACTIVITY.CTA_STILL_THERE') }}</span>
        </button>
    </div>
</mhp-fullscreen-dialog>
