import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import { enhanceSelector } from '../helpers/selector-helper';
import { LocalSharedApplicationState } from '../local-shared-application-state.interface';
import { UiGlobalApplicationState } from '../ui-global-application-state.interface';

const selectLocalSharedApplicationStateFn = <T>(
    state: UiGlobalApplicationState<T>
) => state.localShared;
const selectLocalApplicationStateFn = <T>(state: UiGlobalApplicationState<T>) =>
    state.local;

export const selectLocalSharedApplicationState = enhanceSelector<
    UiGlobalApplicationState<any>,
    LocalSharedApplicationState
>(pipe(map(selectLocalSharedApplicationStateFn)));

export const selectLocalApplicationState = enhanceSelector(
    pipe(map(selectLocalApplicationStateFn))
);
