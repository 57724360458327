import {
    NestedCode,
    OptionCode,
    OptionCollection,
    OptionGroup,
    OptionList
} from '@mhp-immersive-exp/contracts/src/configuration/configuration-response.interface';

export type ConfigurationMetaItem =
    | OptionGroup
    | OptionCollection
    | OptionList
    | NestedCode
    | OptionCode;

export type ContentAwareConfigurationMetaItem =
    | OptionGroup
    | OptionCollection
    | OptionList
    | NestedCode;

export const isOptionGroup = (
    content: ConfigurationMetaItem
): content is OptionGroup => content.type === 'Group';

export const isOptionCode = (
    content: ConfigurationMetaItem
): content is OptionCode => content.type === 'Code';

export const isOptionList = (
    content: ConfigurationMetaItem
): content is OptionList => content.type === 'List';

export const isOptionCollection = (
    content: ConfigurationMetaItem
): content is OptionCollection => content.type === 'Collection';

export const isNestedCode = (
    content: ConfigurationMetaItem
): content is NestedCode => content.type === 'NestedCode';

export const isContentAware = (
    content: ConfigurationMetaItem
): content is ContentAwareConfigurationMetaItem =>
    isOptionGroup(content) ||
    isOptionCollection(content) ||
    isOptionList(content) ||
    isNestedCode(content);
