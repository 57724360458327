import { Action, createReducer, on } from '@ngrx/store';

import {
    clearActiveSalesforceTransactionKey,
    setActiveSalesforceTransactionKey
} from '../actions/salesforce-state.actions';
import { SalesforceState } from '../salesforce-state.interface';

const INITIAL_STATE: SalesforceState = {};

const reducer = createReducer<SalesforceState | undefined>(
    INITIAL_STATE,
    on(
        setActiveSalesforceTransactionKey,
        (state: SalesforceState, payload) => ({
            ...state,
            transactionKey: payload.transactionKey
        })
    ),
    on(clearActiveSalesforceTransactionKey, (state: SalesforceState) => ({
        ...state,
        transactionKey: undefined
    }))
);

export function salesforceStateReducer(
    state: SalesforceState | undefined,
    action: Action
) {
    return reducer(state, action);
}
