import { Observable } from 'rxjs';

import { MetaSource } from './site-meta.service';

// OPEN GRAPH
export const buildOpenGraphTitleMetaSource = (
    source$: Observable<string>
): MetaSource => ({
    property: 'og:title',
    source$
});

export const buildDescriptionMetaSource = (
    source$: Observable<string>
): MetaSource => ({
    name: 'description',
    source$
});

export const buildOpenGraphDescriptionMetaSource = (
    source$: Observable<string>
): MetaSource => ({
    property: 'og:description',
    source$
});

export const buildOpenGraphImageMetaSource = (
    source$: Observable<string>
): MetaSource => ({
    property: 'og:image',
    source$
});

export const buildOpenGraphImageWidthMetaSource = (
    source$: Observable<string>
): MetaSource => ({
    property: 'og:image:width',
    source$
});

export const buildOpenGraphImageHeightMetaSource = (
    source$: Observable<string>
): MetaSource => ({
    property: 'og:image:height',
    source$
});

// TWITTER
export const buildTwitterCardMetaSource = (
    source$: Observable<string>
): MetaSource => ({
    name: 'twitter:card',
    source$
});

export const buildTwitterSiteMetaSource = (
    source$: Observable<string>
): MetaSource => ({
    name: 'twitter:site',
    source$
});

export const buildTwitterTitleMetaSource = (
    source$: Observable<string>
): MetaSource => ({
    name: 'twitter:title',
    source$
});

export const buildTwitterDescriptionMetaSource = (
    source$: Observable<string>
): MetaSource => ({
    name: 'twitter:description',
    source$
});

export const buildTwitterImageMetaSource = (
    source$: Observable<string>
): MetaSource => ({
    name: 'twitter:image',
    source$
});
