<mhp-dialog-header [hideTopGradient]="true"></mhp-dialog-header>
<mhp-ui-mat-dialog [title]="title ? title : undefined" [showClose]="false">
    <mhp-ui-mat-dialog-content>
        <p
            *ngIf="useWrappingParagraph; else noParagraph"
            [innerHTML]="textSanitized"
        ></p>
        <ng-template #noParagraph>
            <div [innerHTML]="textSanitized"></div>
        </ng-template>
    </mhp-ui-mat-dialog-content>
    <mhp-ui-mat-dialog-actions-left *ngIf="buttonsLeft?.length">
        <ng-container *ngFor="let button of buttonsLeft">
            <ng-container
                *ngTemplateOutlet="buttonTpl; context: { $implicit: button }"
            ></ng-container>
        </ng-container>
    </mhp-ui-mat-dialog-actions-left>
    <mhp-ui-mat-dialog-actions-right *ngIf="buttonsRight?.length">
        <ng-container *ngFor="let button of buttonsRight">
            <ng-container
                *ngTemplateOutlet="buttonTpl; context: { $implicit: button }"
            ></ng-container>
        </ng-container>
    </mhp-ui-mat-dialog-actions-right>
    <ng-template #buttonTpl let-button>
        <button
            *ngIf="button.type === BUTTON_TYPE.LINK"
            mat-button
            class="mhp-ui-button--link-style"
            [disabled]="button.disabled"
            (click)="intentButtonClick(button.id)"
        >
            {{ button.label }}
        </button>
        <button
            *ngIf="button.type === BUTTON_TYPE.PRIMARY"
            mat-flat-button
            [disabled]="button.disabled"
            (click)="intentButtonClick(button.id)"
        >
            <span>{{ button.label }}</span>
        </button>
        <button
            *ngIf="button.type === BUTTON_TYPE.SECONDARY"
            mat-button
            [disabled]="button.disabled"
            (click)="intentButtonClick(button.id)"
        >
            {{ button.label }}
        </button>
    </ng-template>
</mhp-ui-mat-dialog>
