import { Observable } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import {
    HttpClient,
    HttpErrorResponse,
    HttpEvent,
    HttpInterceptor,
    HttpParams,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ConfigModel } from '@mhp-immersive-exp/contracts/src/configuration/config-model.interface';
import {
    ConfigurationResponsePayload,
    ProjectReturn
} from '@mhp-immersive-exp/contracts/src/configuration/configuration-response.interface';
import { PatchConfigurationPayload } from '@mhp-immersive-exp/contracts/src/configuration/patch-configuration-payload.interface';
import { ProjectParameters } from '@mhp-immersive-exp/contracts/src/project-parameters/project-parameters';
import { WebsocketErrorCode } from '@mhp-immersive-exp/contracts/src/websocket/websocket-error-codes';
import { recursiveDesctructureProjectParameters } from '@mhp-immersive-exp/sdk/utils';
import { IllegalStateError } from '@mhp/common';

import {
    SearchParamsModifierAware,
    patchSearchParams$
} from '../../helper/search-params-modifier';
import { SerializerService } from '../../serialization/serializer.service';
import { ConfigurationResolveMissingAuthorizationError } from '../errors/errors';
import { ProductConfigurationStrategy } from '../product-configuration-strategy.interface';

export interface ProductConfigurationHttpStrategyConfig
    extends SearchParamsModifierAware<
        'GET_CONFIGURATION' | 'PATCH_CONFIGURATION'
    > {
    baseUrl: string;
    httpInterceptor?: HttpInterceptor;
}

export const PRODUCT_CONFIGURATION_HTTP_STRATEGY_CONFIG_TOKEN =
    new InjectionToken<ProductConfigurationHttpStrategyConfig>(
        'Provider for ProductConfigurationHttpStrategyConfig'
    );

/**
 * ProductConfigurationCommunicationFacade implementation based on http calls.
 * To be used when application is used in context where engine may not be available.
 */
@Injectable()
export class ProductConfigurationHttpStrategy<
    P extends ProjectParameters = ProjectParameters,
    PR extends ProjectReturn = ProjectReturn
> implements ProductConfigurationStrategy<P>
{
    constructor(
        private readonly httpClient: HttpClient,
        private readonly serializerService: SerializerService,
        @Inject(PRODUCT_CONFIGURATION_HTTP_STRATEGY_CONFIG_TOKEN)
        private readonly config: ProductConfigurationHttpStrategyConfig
    ) {}

    getConfigurationMetadata$(
        productId: string,
        config?: ConfigModel[] | undefined,
        projectParameters?: P,
        country?: string | undefined
    ): Observable<ConfigurationResponsePayload<PR>> {
        return this.getConfigurationBackend$(
            productId,
            config,
            projectParameters,
            country
        );
    }

    patchConfiguration$(
        productId: string,
        config: ConfigModel[],
        country: string,
        add?: ConfigModel[],
        remove?: ConfigModel[]
    ): Observable<ConfigModel[]> {
        return this.patchConfigurationHttp$(productId, country, {
            options: config,
            add,
            remove
        });
    }

    private getConfigurationBackend$(
        productId: string,
        config?: ConfigModel[] | undefined,
        projectParameters?: P,
        country?: string | undefined
    ): Observable<ConfigurationResponsePayload<PR>> {
        const paramsObject: Record<string, string | string[]> = {
            options: this.serializerService.serializeData(config || [])
        };

        if (country) {
            paramsObject.country = country;
        }

        if (projectParameters) {
            recursiveDesctructureProjectParameters(
                projectParameters,
                paramsObject as Record<string, string>,
                'projectParameters'
            );
        }

        const request: HttpRequest<null> = new HttpRequest<null>(
            'GET',
            `${this.config.baseUrl}/configuration/${productId}`,
            null,
            {
                params: new HttpParams({
                    fromObject: paramsObject
                })
            }
        );

        let request$: Observable<HttpEvent<ConfigurationResponsePayload<PR>>>;

        if (this.config.httpInterceptor) {
            request$ = this.config.httpInterceptor.intercept(request, {
                handle: (
                    req: HttpRequest<null>
                ): Observable<HttpEvent<ConfigurationResponsePayload<PR>>> =>
                    this.httpClient.request<ConfigurationResponsePayload<PR>>(
                        req
                    )
            });
        } else {
            request$ =
                this.httpClient.request<ConfigurationResponsePayload<PR>>(
                    request
                );
        }

        return request$.pipe(
            filter(
                (
                    httpEvent
                ): httpEvent is HttpResponse<
                    ConfigurationResponsePayload<PR>
                > => httpEvent instanceof HttpResponse
            ),
            map((httpResponse): ConfigurationResponsePayload<PR> => {
                if (!httpResponse.body) {
                    throw new IllegalStateError(
                        'Response contained no body',
                        httpResponse
                    );
                }

                return httpResponse.body;
            }),
            catchError((error) => {
                if (
                    error instanceof HttpErrorResponse &&
                    error.status === 400 &&
                    error.error?.code ===
                        WebsocketErrorCode.RULER_FAILED_GETTING_CONFIGURATION_AUTHORIZATION
                ) {
                    throw new ConfigurationResolveMissingAuthorizationError(
                        error.error?.message,
                        error
                    );
                }
                throw error;
            })
        );
    }

    private patchConfigurationHttp$(
        productId: string,
        country: string,
        payload: PatchConfigurationPayload
    ) {
        const searchParams: Record<string, string | string[]> | undefined =
            undefined;

        return this.patchSearchParams$(
            'PATCH_CONFIGURATION',
            searchParams
        ).pipe(
            switchMap((patchedSearchParams) =>
                this.httpClient.post<ConfigModel[]>(
                    `${this.config.baseUrl}/configuration/${productId}/${country}/patch`,
                    payload,
                    {
                        params: patchedSearchParams
                            ? new HttpParams({
                                  fromObject: patchedSearchParams
                              })
                            : undefined
                    }
                )
            )
        );
    }

    private patchSearchParams$(
        endpoint: 'GET_CONFIGURATION' | 'PATCH_CONFIGURATION',
        inputSearchParams: Record<string, string | string[]> | undefined
    ): Observable<Record<string, string | string[]> | undefined> {
        return patchSearchParams$(this.config, endpoint, inputSearchParams);
    }
}
