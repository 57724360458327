import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';

import {
    Button,
    DialogButtonType,
    GenericTextModal
} from '../generic-text-modal.interface';

@Component({
    selector: 'mhp-ui-generic-text-modal',
    templateUrl: './generic-text-modal.component.html',
    styleUrls: ['./generic-text-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericTextModalComponent
    implements GenericTextModal, OnInit, OnDestroy
{
    readonly BUTTON_TYPE = DialogButtonType;

    @Input()
    title: string;

    @Input()
    text: string;

    @Input()
    useWrappingParagraph = true;

    @Input()
    buttonsLeft?: Button[];

    @Input()
    buttonsRight?: Button[];

    @Output()
    readonly buttonClick = new EventEmitter<string>();

    ngOnInit() {
        if (!this.title) {
            throw new Error('title must not be empty');
        }
        if (!this.text) {
            throw new Error('text must not be empty');
        }
    }

    ngOnDestroy() {
        this.buttonClick.complete();
    }

    intentButtonClick(buttonId: string) {
        this.buttonClick.emit(buttonId);
    }
}
