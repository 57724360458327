import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CanColor, ThemePalette } from '@angular/material/core';

export enum ButtonSize {
    XXSMALL = 'xxsmall',
    XSMALL = 'xsmall',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
    XLARGE = 'xlarge'
}

@Component({
    selector: 'mhp-ui-icon-button',
    templateUrl: './ui-icon-button.component.html',
    styleUrls: ['./ui-icon-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false
})
export class UiIconButtonComponent implements CanColor {
    @Input({
        required: true
    })
    svgIcon!: string;

    @Input()
    size: ButtonSize = ButtonSize.MEDIUM;

    @Input()
    backgroundColor?: string;

    @Input()
    color: ThemePalette;

    @Input()
    defaultColor: ThemePalette;

    @Input()
    disabled = false;

    @Input()
    tabIndex?: number;

    @Input()
    label?: string;
}
