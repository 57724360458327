import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import { PricingType } from '@mhp/aml-ui-shared-services';
import { enhanceSelector } from '@mhp/ui-shared-services';
import { createFeatureSelector, select } from '@ngrx/store';

import { LocalApplicationState } from '../../../state';
import { One2OneState } from '../../one2one/state/one2one-state.interface';
import {
    DealerInfo,
    DealerState,
    PricingSelectionSource
} from '../dealer-state.interface';

export const selectDealerState = createFeatureSelector<DealerState>('dealer');

export const selectDealerLoginState = enhanceSelector<
    LocalApplicationState,
    boolean
>(
    pipe(
        select(selectDealerState),
        map((state) => !!state.dealerInfo)
    ),
    {
        distinctUntilChanged: true
    }
);

export const selectDealerInfo = enhanceSelector<
    LocalApplicationState,
    DealerInfo | undefined
>(
    pipe(
        select(selectDealerState),
        map((state) => state.dealerInfo)
    ),
    {
        distinctUntilChanged: true
    }
);

export const selectPricingType = enhanceSelector<
    LocalApplicationState,
    PricingType
>(
    pipe(
        select(selectDealerState),
        map((state) => state.pricingSettings.type)
    ),
    {
        distinctUntilChanged: true
    }
);

export const selectPricingSelectionSource = enhanceSelector<
    LocalApplicationState,
    PricingSelectionSource
>(
    pipe(
        select(selectDealerState),
        map((state) => state.pricingSettings.source)
    ),
    {
        distinctUntilChanged: true
    }
);

export const selectOne2OneState = enhanceSelector<
    LocalApplicationState,
    One2OneState
>(
    pipe(
        select(selectDealerState),
        map((state) => state.one2oneState)
    ),
    {
        distinctUntilChanged: true
    }
);
