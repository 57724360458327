import { ConfigOption } from '@mhp-immersive-exp/contracts/src/configuration/config-option.interface';
import { Identifiable } from '@mhp-immersive-exp/contracts/src/generic/identifiable.interface';

import { EnvironmentLightingProfileState } from '@mhp-immersive-exp/contracts/src/environment/environment.interface';

export interface ApplicationState<UiStateType = any> {
    // used for applying patches to the state. Gets incremented on each state-change.
    version: number;

    applicationInfo?: ApplicationInfo;

    applicationStateMachineState: ApplicationStateMachineState;

    productSelectionState?: ProductSelectionState;

    /* When engine is performing actions like snapshotting, ..., clients should
     * disable interaction inputs for config-changes, animations, touch-pad, [... everything that affects the engine]
     * Also, in case it is a foreseeable long-running process, the user should be notified about what's going on,
     * so it's possible to set an optional identifier to give clients a hint.
     */
    engineState: EngineState;

    configurationState?: ConfigurationState;

    settingsState: SettingsState;

    /**
     * Separate state-branch controlled by the tablet-ui only.
     */
    uiState?: UiStateType;
}

/**
 * Exposes information about the running application.
 */
export interface ApplicationInfo {
    // the version that is currently running, e.g. 1.0.0 - May be undefined in case no release version is present, e.g. in a development environment
    releaseVersion: string | undefined;
}

/**
 * The state the application is currently in. Basically defining, what is currently
 * being shown on the customer-screen.
 */
export enum ApplicationStateMachineState {
    // application / tablet-ui is initializing, used internally by the tablet-ui until first state is fetched from the engine
    UNKNOWN = 'UNKNOWN',
    // application has just been started, no product has been selected. 2D-assets are displayed on screen.
    APPLICATION_START = 'APPLICATION_START',
    // application is in product-selection mode. User has not yet selected a product for configuration
    PRODUCT_SELECTION = 'PRODUCT_SELECTION',
    // application is in configuration mode, user can configure the selected product
    PRODUCT_CONFIGURATION = 'PRODUCT_CONFIGURATION',
    // application is in material edit mode, user can update a material displayed on a preview mesh. Entered/left through materialeditmode request
    MATERIAL_EDITOR = 'MATERIAL_EDITOR'
}

/**
 * State used for the product-selection in case multiple products are available.
 */
export interface ProductSelectionState {
    selectedProductId?: string;
}

/**
 * State used for the separate sub-states related to configuration-mode.
 */
export interface ConfigurationState {
    environmentState?: EnvironmentState;
    animationStates?: AnimationState[];
    cinematicState?: CinematicState;
    highlightState?: HighlightState;
    productState?: ProductState;
    // which is the active camera
    cameraState?: Identifiable;
    mirrorModeState?: MirrorModeState;
}

export interface ContextOptionState extends Identifiable {
    value: any;
}

/**
 * Environments have an id, day/night state and optionally additional options.
 */
export interface EnvironmentState extends Identifiable {
    state: EnvironmentLightingProfileState;
    options?: ContextOptionState[];
}

/**
 * Animations have an id and a state.
 * START indicates, that the animations playhead is at the beginning of the timeline,
 * END indicates that it's at the end (and thus can be played backwards).
 */
export interface AnimationState extends Identifiable {
    state: 'START' | 'END';
}

/**
 * Cinematics have an id and a playback-state. In case the cinematic is currently
 * active/playing, it has a startedAt unix-timestamp (ms) for clients to be able to
 * display a progress-bar.
 */
export interface CinematicState extends Identifiable {
    state: PlaybackState;
    startedAt?: number;
    progress?: number;
}

/**
 * See #CinematicState above.
 */
export interface HighlightState extends Identifiable {
    state: PlaybackState;
    startedAt?: number;
}

export enum PlaybackState {
    IS_PLAYING = 'IS_PLAYING',
    IS_STOPPED = 'IS_STOPPED'
}

/**
 * Defines the current state of the product being configured.
 */
export interface ProductState {
    // the currently selected product
    id: string;
    // the configuration-code, if persisted
    configurationId?: string;
    // the set of selected options
    configOptions: (string | ConfigOption)[];
    // the active country
    country?: string;
}

/**
 * Defines state related to the mirror-mode possibly being active.
 */
export interface MirrorModeState {
    summaryActive: boolean;
}

export interface EngineState {
    busy: boolean;
    identifier?: string;
}

/**
 * Defines state related to the application-settings.
 */
export interface SettingsState {
    activeLanguage: string;
    vrAvailable: boolean;
    vrEnabled: boolean;
    pricingEnabled: boolean;
    soundEnabled: boolean;
    launcherSettingsState?: LauncherSettingsState;
}

export interface LauncherSettingsState {
    resolution: string;

    // TODO: Define available launcher settings
    [key: string]: any;
}
