import { LazyLoadImageModule } from 'ng-lazyload-image';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';

import { UiIconsModule } from '../ui-icons';
import { UiLazyloadImageModule } from '../ui-lazyload-image/ui-lazyload-image.module';
import { UiSelectedMarkerModule } from '../ui-selected-marker';
import { UiGridListItemComponent } from './ui-grid-list-item/ui-grid-list-item.component';
import { UiGridListComponent } from './ui-grid-list.component';

@NgModule({
    declarations: [UiGridListComponent, UiGridListItemComponent],
    exports: [UiGridListComponent, UiGridListItemComponent],
    imports: [
        CommonModule,
        MatRippleModule,
        UiIconsModule,
        UiSelectedMarkerModule,
        LazyLoadImageModule,
        UiLazyloadImageModule
    ]
})
export class UiGridListModule {}
