import { Action, combineReducers, createReducer, on } from '@ngrx/store';

import { environment } from '../../../../environments/environment';
import { One2OneState } from '../../one2one/state';
import {
    clearActiveOne2OneClientSessionCode,
    setActiveOne2OneClientSessionCode,
    setAudioDeviceId,
    setAudioSetting,
    setAudioVideoSettings,
    setRemoteParticipantMuteSetting,
    setTargetAudioSetting,
    setTargetOne2OneSessionData,
    setTargetVideoSetting,
    setTwilioToken,
    setVideoDeviceId,
    setVideoSetting
} from '../../one2one/state/actions/one2one-state.actions';
import {
    resetDealerInfo,
    setDealerInfo,
    setPricingType
} from '../actions/dealer-state.actions';
import {
    DealerInfo,
    DealerState,
    PricingSettings
} from '../dealer-state.interface';

const INITIAL_STATE: DealerState = {
    dealerInfo: undefined,
    pricingSettings: {
        type: environment.appConfig.dealer.dealerBuild ? 'RRP' : 'HIDE',
        source: 'SYSTEM'
    },
    one2oneState: {
        audioVideoSettings: {
            audio: false,
            targetAudio: true,
            video: false,
            targetVideo: true
        }
    }
};

const dealerInfoReducer = createReducer<DealerInfo | undefined>(
    INITIAL_STATE.dealerInfo,
    on(
        setDealerInfo,
        (state: DealerInfo | undefined, payload) => payload.dealerInfo
    ),
    on(resetDealerInfo, () => undefined)
);

const pricingSettingsReducer = createReducer<PricingSettings>(
    INITIAL_STATE.pricingSettings,
    on(setPricingType, (state: PricingSettings, payload) => ({
        ...state,
        type: payload.pricingType,
        source: payload.settingSource
    }))
);

const one2oneStateReducer = createReducer<One2OneState>(
    INITIAL_STATE.one2oneState,
    on(setTargetOne2OneSessionData, (state: One2OneState, payload) => ({
        ...state,
        targetSessionData: payload.sessionData
    })),
    on(setActiveOne2OneClientSessionCode, (state: One2OneState, payload) => ({
        ...state,
        clientSessionCode: payload.sessionCode
    })),
    on(clearActiveOne2OneClientSessionCode, (state: One2OneState) => ({
        ...state,
        clientSessionCode: undefined
    })),
    on(setTwilioToken, (state: One2OneState, payload) => ({
        ...state,
        twilioTokenData: payload.token
            ? {
                  token: payload.token
              }
            : undefined
    })),
    on(setAudioVideoSettings, (state: One2OneState, payload) => ({
        ...state,
        audioVideoSettings: {
            ...state.audioVideoSettings,
            audio: payload.audio,
            video: payload.video,
            audioDeviceId: payload.audioDeviceId,
            videoDeviceId: payload.videoDeviceId,
            remoteParticipantMuted: payload.remoteParticipantMuted
        }
    })),
    on(setAudioSetting, (state: One2OneState, payload) => ({
        ...state,
        audioVideoSettings: {
            ...state.audioVideoSettings,
            audio: payload.audio
        }
    })),
    on(setTargetAudioSetting, (state: One2OneState, payload) => ({
        ...state,
        audioVideoSettings: {
            ...state.audioVideoSettings,
            targetAudio: payload.targetAudio
        }
    })),
    on(setAudioDeviceId, (state: One2OneState, payload) => ({
        ...state,
        audioVideoSettings: {
            ...state.audioVideoSettings,
            audioDeviceId: payload.audioDeviceId
        }
    })),
    on(setVideoSetting, (state: One2OneState, payload) => ({
        ...state,
        audioVideoSettings: {
            ...state.audioVideoSettings,
            video: payload.video
        }
    })),
    on(setTargetVideoSetting, (state: One2OneState, payload) => ({
        ...state,
        audioVideoSettings: {
            ...state.audioVideoSettings,
            targetVideo: payload.targetVideo
        }
    })),
    on(setVideoDeviceId, (state: One2OneState, payload) => ({
        ...state,
        audioVideoSettings: {
            ...state.audioVideoSettings,
            videoDeviceId: payload.videoDeviceId
        }
    })),
    on(setRemoteParticipantMuteSetting, (state: One2OneState, payload) => ({
        ...state,
        audioVideoSettings: {
            ...state.audioVideoSettings,
            remoteParticipantMuted: payload.remoteParticipantMuted
        }
    }))
);

const reducer = combineReducers<DealerState>(
    {
        dealerInfo: dealerInfoReducer,
        pricingSettings: pricingSettingsReducer,
        one2oneState: one2oneStateReducer
    },
    INITIAL_STATE
);

export function dealerStateReducer(
    state: DealerState | undefined,
    action: Action
) {
    return reducer(state, action);
}
