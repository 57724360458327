import {
    ApplicationState,
    ApplicationStateMachineState
} from '@mhp/communication-models';
import { Action, createReducer, on } from '@ngrx/store';

import {
    setActiveCamera,
    setActiveEngineConfiguration,
    setAnimationState,
    setEngineApplicationState,
    setEngineConfigurationState,
    setEnvironmentState
} from '../actions/engine-application-state.actions';
import { setUiSharedState } from '../ui-shared-state/actions/ui-shared-state.actions';

const INITIAL_STATE: ApplicationState = {
    version: -1,
    applicationStateMachineState: ApplicationStateMachineState.UNKNOWN,
    engineState: {
        busy: false
    },
    settingsState: {
        activeLanguage: 'en',
        soundEnabled: false,
        pricingEnabled: false,
        vrAvailable: false,
        vrEnabled: false
    }
};

const reducer = createReducer<ApplicationState>(
    INITIAL_STATE,
    // reducer that simply replaces the current engine-application-state with the one provided
    on(setEngineApplicationState, (appState, payload) => payload.state),
    on(setUiSharedState, (appState, payload) => ({
        ...appState,
        uiState: payload.uiSharedState
    })),
    on(setEngineConfigurationState, (appState, payload) => ({
        ...appState,
        configurationState: { ...payload.state }
    })),
    on(setActiveEngineConfiguration, (appState, payload) => ({
        ...appState,
        configurationState: {
            ...appState.configurationState,
            productState: {
                ...appState.configurationState?.productState,
                id: payload.productId,
                country: payload.country,
                configOptions: payload.options
            }
        }
    })),
    on(setActiveCamera, (appState, payload) => ({
        ...appState,
        configurationState: {
            ...appState.configurationState,
            cameraState: {
                id: payload.id
            }
        }
    })),
    on(setEnvironmentState, (appState, payload) => ({
        ...appState,
        configurationState: {
            ...appState.configurationState,
            environmentState: {
                id: payload.id,
                state: payload.state
            }
        }
    })),
    on(setAnimationState, (appState, payload) => ({
        ...appState,
        configurationState: {
            ...appState.configurationState,
            animationStates: [
                ...(appState.configurationState?.animationStates || []).filter(
                    (animationState) => animationState.id !== payload.id
                ),
                {
                    id: payload.id,
                    state: payload.state
                }
            ]
        }
    }))
);

export function engineApplicationStateReducer(
    state: ApplicationState,
    action: Action
) {
    return reducer(state, action);
}
