import { NgModule } from '@angular/core';

import {
    ERROR_HANDLER_CONFIG_TOKEN,
    ErrorHandlerServiceConfig
} from './error-handler.service';

@NgModule({
    providers: [
        {
            provide: ERROR_HANDLER_CONFIG_TOKEN,
            useValue: <ErrorHandlerServiceConfig>{
                maxRetries: 3,
                retryScalingDuration: 1000,
                maxRetryTimeout: 10000
            }
        }
    ]
})
export class ErrorHandlerModule {}
