import { flatten } from 'lodash-es';
import { Observable } from 'rxjs';

import { ComponentType } from '@angular/cdk/portal';
import {
    Inject,
    Injectable,
    InjectionToken,
    Optional,
    TemplateRef
} from '@angular/core';
import {
    MatDialog,
    MatDialogConfig,
    MatDialogRef,
    MatDialogState
} from '@angular/material/dialog';

export interface UiMatDialogServiceConfig {
    dialogMinWidth: number | string;
    dialogMaxWidth: number | string;
}

export const UI_MAT_DIALOG_SERVICE_CONFIG =
    new InjectionToken<UiMatDialogServiceConfig>('UiMatDialogServiceConfig');

export interface UiMatDialogConfig<D = any> extends MatDialogConfig<D> {
    opaque?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class UiMatDialogService {
    readonly config: UiMatDialogServiceConfig;

    constructor(
        private matDialog: MatDialog,
        @Inject(UI_MAT_DIALOG_SERVICE_CONFIG)
        @Optional()
        config: UiMatDialogServiceConfig
    ) {
        if (!config) {
            this.config = {
                dialogMinWidth: 500,
                dialogMaxWidth: 500
            };
        } else {
            this.config = config;
        }
    }

    /**
     *
     * @param componentOrTemplateRef
     * @param config
     * @deprecated Use open$ instead
     */
    open<T, D>(
        componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
        config: UiMatDialogConfig<D> = {}
    ): MatDialogRef<T> {
        return this.matDialog.open(
            componentOrTemplateRef,
            this.buildDialogConfig(config)
        );
    }

    /**
     * Open a dialog when subscribing to the returned observable.
     * The dialog is closed when the last subscriber has unsubscribed.
     *
     * @param componentOrTemplateRef
     * @param config
     */
    open$<T, D>(
        componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
        config: UiMatDialogConfig<D> = {}
    ): Observable<MatDialogRef<T>> {
        return this.buildDialogObservable(
            componentOrTemplateRef,
            this.buildDialogConfig(config)
        );
    }

    /**
     * Open a fullscreen-dialog.
     * @param componentOrTemplateRef
     * @param config
     * @deprecated Use openFullscreen$ instead
     */
    openFullscreen<T, D>(
        componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
        config: UiMatDialogConfig<D> = {}
    ): MatDialogRef<T> {
        return this.matDialog.open(
            componentOrTemplateRef,
            this.buildFullscreenConfig(config)
        );
    }

    /**
     * Open a fullscreen-dialog when subscribing to the returned observable.
     * The dialog is closed when the last subscriber has unsubscribed.
     * @param componentOrTemplateRef
     * @param config
     */
    openFullscreen$<T, D>(
        componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
        config: UiMatDialogConfig<D> = {}
    ): Observable<MatDialogRef<T>> {
        return this.buildDialogObservable(
            componentOrTemplateRef,
            this.buildFullscreenConfig(config)
        );
    }

    private buildDialogObservable<T, D>(
        componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
        config: UiMatDialogConfig<D>
    ) {
        return new Observable<MatDialogRef<T>>((subscriber) => {
            const dialogRef = this.matDialog.open(
                componentOrTemplateRef,
                config
            );
            subscriber.next(dialogRef);
            dialogRef.afterClosed().subscribe(() => subscriber.complete());

            return () => {
                if (dialogRef.getState() === MatDialogState.OPEN) {
                    dialogRef.close();
                }
            };
        });
    }

    private buildDialogConfig<D>(config: UiMatDialogConfig<D> = {}) {
        let panelClass = ['mhp-ui-modal-panel'];
        if (config.panelClass) {
            if (typeof config.panelClass === 'string') {
                panelClass = [...panelClass, config.panelClass];
            } else {
                panelClass = [...panelClass, ...config.panelClass];
            }
        }

        let backdropClass = ['mhp-ui-modal-backdrop'];

        if (config.opaque) {
            backdropClass.push('mhp-ui-modal-backdrop--opaque');
        }
        if (config.backdropClass) {
            backdropClass = flatten([...backdropClass, config.backdropClass]);
        }

        return {
            minWidth: this.config.dialogMinWidth,
            maxWidth: this.config.dialogMaxWidth,
            ...config,
            backdropClass,
            panelClass,
            autoFocus: false
        };
    }

    private buildFullscreenConfig<D>(config: UiMatDialogConfig<D> = {}) {
        let panelClass = [
            'mhp-ui-modal-panel',
            'mhp-ui-modal-panel--fullscreen'
        ];
        if (config.panelClass) {
            if (typeof config.panelClass === 'string') {
                panelClass = [...panelClass, config.panelClass];
            } else {
                panelClass = [...panelClass, ...config.panelClass];
            }
        }

        let backdropClass = [
            'mhp-ui-modal-backdrop',
            'mhp-ui-modal-backdrop--fullscreen'
        ];
        if (config.backdropClass) {
            backdropClass = flatten([...backdropClass, config.backdropClass]);
        }

        return {
            ...config,
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
            panelClass,
            backdropClass,
            autoFocus: false
        };
    }
}
