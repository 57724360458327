import { isNumber } from 'lodash-es';
import { Observable, asyncScheduler, combineLatest } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    throttleTime
} from 'rxjs/operators';

import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import {
    ApplicationStateMachineState,
    PlaybackState
} from '@mhp/communication-models';

import {
    ApplicationStateService,
    selectApplicationStateMachineState,
    selectConfigurationState,
    selectEngineState
} from '../application-state';
import { EngineStateService } from '../engine/engine-state.service';
import { EngineSettingsService } from '../settings';

export interface FeatureAvailabilityServiceConfig {
    engineBusyStateDebounceTime?: number;
    blockLocalConfigurationChangesWhileResolveTimeout?: number;
}

export const FEATURE_AVAILABILITY_SERVICE_CONFIG =
    new InjectionToken<FeatureAvailabilityServiceConfig>(
        'FeatureAvailabilityServiceConfig'
    );

const DEFAULT_ENGINE_BUSY_STATE_DEBOUNCE_TIME = 200;
const DEFAULT_BLOCK_LOCAL_CONFIGURATION_CHANGES_WHILE_RESOLVE_TIMEOUT = 200;

@Injectable()
export class FeatureAvailabilityService {
    constructor(
        private applicationStateService: ApplicationStateService,
        private engineStateService: EngineStateService,
        private engineSettingsService: EngineSettingsService,
        @Optional()
        @Inject(FEATURE_AVAILABILITY_SERVICE_CONFIG)
        private config?: FeatureAvailabilityServiceConfig
    ) {}

    /**
     * Emits if altering configuration is currently possible or not.
     */
    @MemoizeObservable()
    canAlterConfiguration$(): Observable<boolean> {
        /*
         * Take into account:
         * - if highlight is currently active
         * - if cinematic is currently active
         * - if engine is currently busy
         * - if local configuration-changes are pending
         */
        return combineLatest([
            this.engineStateService.getActiveHighlight$(),
            this.engineStateService.getActiveCinematic$(),
            this.getEngineBusyState$(),
            this.applicationStateService.getLocalSharedState().pipe(
                map((state) => state.configuration.pendingConfigurationChanges),
                throttleTime(
                    this.config &&
                        isNumber(
                            this.config
                                .blockLocalConfigurationChangesWhileResolveTimeout
                        )
                        ? this.config
                              .blockLocalConfigurationChangesWhileResolveTimeout
                        : DEFAULT_BLOCK_LOCAL_CONFIGURATION_CHANGES_WHILE_RESOLVE_TIMEOUT,
                    asyncScheduler,
                    {
                        leading: false,
                        trailing: true
                    }
                )
            )
        ]).pipe(
            map(
                ([
                    highlight,
                    cinematic,
                    engineBusy,
                    pendingConfigurationChanges
                ]) =>
                    !highlight &&
                    !cinematic &&
                    !engineBusy &&
                    pendingConfigurationChanges === 0
            ),
            lazyShareReplay()
        );
    }

    /**
     * Emits if using cinematics is currently possible or not.
     */
    @MemoizeObservable()
    canUseCinematics$(): Observable<boolean> {
        /*
         * Take into account:
         * - if vr-mode is currently active
         * - if engine is busy
         */
        return combineLatest([
            this.engineSettingsService.getVrModeActiveState$(),
            this.getEngineBusyState$()
        ]).pipe(
            map(([vrModeActive, engineBusy]) => !vrModeActive && !engineBusy),
            lazyShareReplay()
        );
    }

    /**
     * Emits if using highlights is currently possible or not.
     */
    @MemoizeObservable()
    canUseHighlights$(): Observable<boolean> {
        /*
         * Take into account:
         * - if vr-mode is currently active
         * - if engine is busy
         */
        // currently semantically identical with #canUseCinematics
        return this.canUseCinematics$();
    }

    /**
     * Emits if touchpad-usage is currently possible or not.
     */
    @MemoizeObservable()
    canUseTouchpad$(): Observable<boolean> {
        // for now, let it be semantically identical to #canAlterCamera logic
        return this.canAlterCamera$();
    }

    /**
     * Emits if altering active cameras is currently possible or not.
     */
    @MemoizeObservable()
    canAlterCamera$(): Observable<boolean> {
        /*
         * Take into account:
         * - if highlight is currently active
         * - if cinematics are currently active
         * - if engine is busy
         */
        return combineLatest([
            this.engineStateService.getActiveHighlight$(),
            this.engineStateService.getActiveCinematic$(),
            this.getEngineBusyState$()
        ]).pipe(
            map(
                ([highlight, cinematic, engineBusy]) =>
                    !highlight && !cinematic && !engineBusy
            ),
            lazyShareReplay()
        );
    }

    /**
     * This is a generic availability state for engine-related settings that may be used
     * in case no more specific availability state is available.
     * Relates to the engines busy-state indication.
     */
    @MemoizeObservable()
    canAlterEngineRelatedSettings$(): Observable<boolean> {
        return this.getEngineBusyState$().pipe(map((busy) => !busy));
    }

    /**
     * Emits whether mirror-mode is allowed to be active right now.
     */
    @MemoizeObservable()
    isMirrorModeAvailable$(): Observable<boolean> {
        return combineLatest([
            this.applicationStateService.getState().pipe(
                selectConfigurationState,
                map((configurationState) => {
                    const cinematicsPlaybackState =
                        configurationState?.cinematicState?.state;
                    return (
                        !cinematicsPlaybackState ||
                        cinematicsPlaybackState === PlaybackState.IS_STOPPED
                    );
                })
            ),
            this.applicationStateService.getState().pipe(
                selectApplicationStateMachineState,
                filter(
                    (state) => state !== ApplicationStateMachineState.UNKNOWN
                ),
                map(
                    (state) =>
                        state ===
                        ApplicationStateMachineState.PRODUCT_CONFIGURATION
                )
            )
        ]).pipe(
            map(
                ([noCinematicsPlaying, isInProductConfigurationState]) =>
                    noCinematicsPlaying && isInProductConfigurationState
            ),
            distinctUntilChanged()
        );
    }

    /**
     * Return the debounced engine busy state.
     * Debounced, because the busy state could change for just a fraction of a second
     * which would cause unnecessary glitches in the UI.
     * May be configured by providing a FEATURE_AVAILABILITY_SERVICE_CONFIG token.
     */
    private getEngineBusyState$(): Observable<boolean> {
        return this.applicationStateService.getState().pipe(
            selectEngineState,
            map((engineState) => engineState.busy),
            debounceTime(
                this.config && isNumber(this.config.engineBusyStateDebounceTime)
                    ? this.config.engineBusyStateDebounceTime
                    : DEFAULT_ENGINE_BUSY_STATE_DEBOUNCE_TIME
            ),
            lazyShareReplay()
        );
    }
}
