import { BehaviorSubject, Observable, combineLatest, merge, of } from 'rxjs';
import {
    distinctUntilChanged,
    first,
    map,
    startWith,
    switchMap
} from 'rxjs/operators';

import { Inject, Injectable, InjectionToken } from '@angular/core';
import { TranslocoLocaleService } from '@jsverse/transloco-locale';
import {
    IllegalStateError,
    MemoizeObservable,
    lazyShareReplay
} from '@mhp/common';
import { StorageMap } from '@ngx-pwa/local-storage';

export interface L10nSettings {
    country?: string;
}

/**
 * The configuration required to connect to a given sockJs-endpoint.
 */
export interface L10nServiceConfig {
    storageKeyCountry: string;
    // callback to map a given country (e.g. US) to a locale (en-US)
    countryToLocaleMapper: (country: string) => string;
}

export const L10N_SERVICE_CONFIG_TOKEN = new InjectionToken<L10nServiceConfig>(
    'L10nServiceConfig'
);

@Injectable({
    providedIn: 'root'
})
export class L10nService {
    private countryOverride$?: Observable<string | undefined>;

    private readonly countryOverrideSubject = new BehaviorSubject<
        string | undefined
    >(undefined);

    constructor(
        private readonly storageMap: StorageMap,
        private readonly translocoLocaleService: TranslocoLocaleService,
        @Inject(L10N_SERVICE_CONFIG_TOKEN)
        private readonly config: L10nServiceConfig
    ) {
        this.initActiveLocaleBinding();
    }

    /**
     * Persist the given settings so that they may be fetched via #loadSettings afterwards.
     * @param settings
     */
    updateSettings$(settings: L10nSettings): Observable<L10nSettings> {
        const updateSettings$ = this.getSettings$().pipe(
            first(),
            switchMap((currentSettings) => {
                if (
                    settings?.country &&
                    currentSettings?.country !== settings.country
                ) {
                    return this.storageMap.set(
                        this.config.storageKeyCountry,
                        settings.country,
                        {
                            type: 'string'
                        }
                    );
                }
                return of(settings);
            }),
            map(() => settings),
            lazyShareReplay()
        );
        updateSettings$.subscribe();
        return updateSettings$;
    }

    /**
     * Load settings previously persisted.
     */
    @MemoizeObservable()
    getSettings$(): Observable<L10nSettings> {
        return merge(
            this.storageMap.get(this.config.storageKeyCountry, {
                type: 'string'
            }),
            this.storageMap.watch(this.config.storageKeyCountry, {
                type: 'string'
            })
        ).pipe(
            distinctUntilChanged(),
            map((country) => ({
                country
            })),
            lazyShareReplay()
        );
    }

    /**
     * Get a stream emitting the currently active ISO country.
     */
    @MemoizeObservable()
    getActiveCountry$(): Observable<string | undefined> {
        return combineLatest([
            this.getSettings$().pipe(map((settings) => settings?.country)),
            this.countryOverrideSubject
        ]).pipe(
            map(([regularCountry, overrideCountry]) => {
                if (overrideCountry) {
                    return overrideCountry;
                }
                return regularCountry;
            }),
            distinctUntilChanged(),
            lazyShareReplay()
        );
    }

    /**
     * Register a stream that emits updated country information overriding the regular country.
     * The returned callback needs to be called to unregister the override.
     * @param country$
     * @return cleanup-callback
     */
    registerCountryOverride(
        country$: Observable<string | undefined>
    ): () => void {
        if (this.countryOverride$) {
            throw new IllegalStateError('Country override already registered');
        }

        this.countryOverride$ = country$;
        this.countryOverride$.subscribe((country) => {
            this.countryOverrideSubject.next(country);
        });

        return () => {
            this.countryOverride$ = undefined;
        };
    }

    /**
     * Returns the currency-symbol for the currently active locale.
     */
    getCurrencySymbol(): string | undefined {
        return this.translocoLocaleService.getCurrencySymbol();
    }

    /**
     * Emits the currency-symbol for the currently active locale.
     */
    @MemoizeObservable()
    getCurrencySymbol$(): Observable<string | undefined> {
        return this.translocoLocaleService.localeChanges$.pipe(
            startWith(this.translocoLocaleService.getLocale()),
            map(() => this.translocoLocaleService.getCurrencySymbol())
        );
    }

    /**
     * Format the given number as a currency-string.
     * @param value The value to be formatted.
     */
    formatCurrency(value: number): string {
        return this.translocoLocaleService.localizeNumber(
            value,
            'currency',
            this.translocoLocaleService.getLocale(),
            {
                currencyDisplay: 'symbol'
            }
        );
    }

    /**
     * Format the given number in a locale specific way.
     * @param value The value to be formatted.
     */
    formatNumber(value: number): string {
        return this.translocoLocaleService.localizeNumber(value, 'decimal');
    }

    /**
     * Format the given number in a locale specific way as percentage.
     * @param value The value to be formatted.
     */
    formatPercentage(value: number): string {
        return this.translocoLocaleService.localizeNumber(
            value,
            'percent',
            this.translocoLocaleService.getLocale(),
            {
                maximumFractionDigits: 2
            }
        );
    }

    private initActiveLocaleBinding() {
        this.getActiveCountry$().subscribe((activeCountry) => {
            if (!activeCountry) {
                return;
            }
            const matchingLocale =
                this.config.countryToLocaleMapper(activeCountry);
            if (!matchingLocale) {
                console.error(
                    `Could not find matching locale for country ${activeCountry}`
                );
                return;
            }
            this.translocoLocaleService.setLocale(matchingLocale);
        });
    }
}
