import { Injectable } from '@angular/core';
import { ConfigOption } from '@mhp-immersive-exp/contracts/src/configuration/config-option.interface';
import { OptionGroup } from '@mhp-immersive-exp/contracts/src/configuration/configuration-response.interface';

import {
    ConfigurationConverter,
    ConfigurationConverterMode
} from './configuration-converter';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationConverterService {
    private static readonly SELECTED_ONLY_CONVERTER =
        new ConfigurationConverter(ConfigurationConverterMode.SELECTED_ONLY);

    private static readonly COMPLETE_CONVERTER = new ConfigurationConverter(
        ConfigurationConverterMode.COMPLETE
    );

    /**
     * Reduces the given OptionGroup[] data to the resulting configuration relevant code structure.
     * @param optionGroups
     * @param mode In case of SELECTED_ONLY, only options that are currently
     * selected will be taken into account. In case of COMPLETE, all options will be taken into account.
     */
    convertToConfigurationFormat(
        optionGroups: OptionGroup[],
        mode: ConfigurationConverterMode
    ): (string | ConfigOption)[] {
        if (mode === ConfigurationConverterMode.SELECTED_ONLY) {
            return ConfigurationConverterService.SELECTED_ONLY_CONVERTER.convertToConfigurationFormat(
                optionGroups
            );
        }
        return ConfigurationConverterService.COMPLETE_CONVERTER.convertToConfigurationFormat(
            optionGroups
        );
    }
}
