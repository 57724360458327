import { isString } from 'lodash-es';

export function downloadFile(target: Blob | string, filename = '') {
    const objectUrl = isString(target) ? target : URL.createObjectURL(target);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = objectUrl;
    a.setAttribute('target', '_blank');
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    a.remove();

    URL.revokeObjectURL(objectUrl);
}
