import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    UiButtonModule,
    UiFormFieldModule,
    UiIconButtonModule,
    UiIconsModule,
    UiMatDialogModule,
    UiSelectModule
} from '@mhp/ui-components';
import { I18nModule } from '@mhp/ui-shared-services';

import { DialogModule } from '../dialog/dialog.module';
import { HeaderBarModule } from '../header-bar/header-bar.module';
import { RegionSelectorComponent } from './region-selector/region-selector.component';

@NgModule({
    declarations: [RegionSelectorComponent],
    imports: [
        CommonModule,
        UiMatDialogModule,
        UiIconButtonModule,
        I18nModule,
        UiButtonModule,
        HeaderBarModule,
        DialogModule,
        UiFormFieldModule,
        UiSelectModule,
        ReactiveFormsModule,
        UiIconsModule
    ]
})
export class SettingsModule {}
