import { Observable, combineLatest, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Directive, ElementRef, Input } from '@angular/core';

import { UiBaseComponent } from '../ui-base-component';

/**
 * Directive that allows to be bound to an element which should be scrolled into
 * view when the assigned input-stream emits.
 *
 * To make scrollIntoView functionality happen smoothly across devices,
 * take a look at the node-package seamless-scroll-polyfill (https://www.npmjs.com/package/seamless-scroll-polyfill)
 *
 * import { polyfill as installSeamlessScrollPolyfill } from 'seamless-scroll-polyfill';
 *
 * installSeamlessScrollPolyfill();
 */
@Directive({
    selector: '[mhpUiScrollIntoView]'
})
export class UiScrollIntoViewDirective extends UiBaseComponent {
    @Input()
    mhpUiScrollIntoView: Observable<boolean>;

    @Input()
    scrollIntoViewConfig?: ScrollIntoViewOptions;

    constructor(private readonly elementRef: ElementRef<HTMLElement>) {
        super();
        this.initScrollIntoViewBinding();
    }

    private initScrollIntoViewBinding() {
        combineLatest([
            this.observeProperty<
                UiScrollIntoViewDirective,
                Observable<boolean>
            >('mhpUiScrollIntoView', false).pipe(
                switchMap((scrollIntoViewSource$) => {
                    if (!scrollIntoViewSource$) {
                        return of(false);
                    }
                    return scrollIntoViewSource$;
                })
            ),
            this.observeProperty<
                UiScrollIntoViewDirective,
                ScrollIntoViewOptions | undefined
            >('scrollIntoViewConfig', false)
        ]).subscribe(([scrollElementIntoView, config]) => {
            if (!scrollElementIntoView) {
                return;
            }
            this.elementRef.nativeElement.scrollIntoView({
                behavior: 'smooth',
                ...(config || {})
            });
        });
    }
}
