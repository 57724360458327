/* eslint-disable max-classes-per-file */
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
    TRANSLOCO_LOADER,
    Translation,
    TranslocoLoader,
    TranslocoModule,
    provideTransloco,
    translocoConfig
} from '@jsverse/transloco';

import { I18nService, LanguageDefinition } from './i18n.service';
import { TranslateFallbackPipe } from './translate-fallback.pipe';

export interface I18nConfiguration {
    // in case the application is running in production-mode, certain optimizations are applied
    isProdMode: boolean;

    // list of available languages
    availableLangs: LanguageDefinition[];

    // the default language to be initially used
    defaultLang: string;

    // the fallback-language to be used when a translation-file cannot be found
    fallbackLang: string;

    logMissingKey?: boolean;

    /**
     * optional factory for creating a url from which to load translation files from.
     * Default is loading from /assets/i18n/{lang}.json
     * @param lang
     */
    translationFileUrlFactory?: (lang: string) => string;
}

class TranslocoHttpLoader implements TranslocoLoader {
    constructor(
        private readonly http: HttpClient,
        private readonly config?: I18nConfiguration
    ) {}

    getTranslation(lang: string) {
        const fileUrl = this.config?.translationFileUrlFactory
            ? this.config.translationFileUrlFactory(lang)
            : `assets/i18n/${lang}.json`;
        return this.http.get<Translation>(fileUrl);
    }
}

@NgModule({
    declarations: [TranslateFallbackPipe],
    imports: [CommonModule, HttpClientModule, TranslocoModule],
    exports: [TranslateFallbackPipe, TranslocoModule]
})
export class I18nModule {
    // force eager init of I18nService
    constructor(private i18nService: I18nService) {}

    static forRoot(config: I18nConfiguration): ModuleWithProviders<I18nModule> {
        const translocoProviders = provideTransloco({
            config: translocoConfig({
                availableLangs: config.availableLangs,
                defaultLang: config.defaultLang,
                fallbackLang: config.fallbackLang,
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: true,
                prodMode: config.isProdMode,
                missingHandler: {
                    // It will use the first language set in the `fallbackLang` property
                    useFallbackTranslation: true,
                    logMissingKey: config.logMissingKey
                }
            })
        });

        return {
            ngModule: I18nModule,
            providers: [
                ...translocoProviders,
                {
                    provide: TRANSLOCO_LOADER,
                    useFactory: (httpClient: HttpClient) =>
                        new TranslocoHttpLoader(httpClient, config),
                    deps: [HttpClient]
                },
                {
                    provide: TranslateFallbackPipe,
                    useClass: TranslateFallbackPipe
                }
            ]
        };
    }
}
