<router-outlet></router-outlet>

<ng-container *ngIf="getLoadingLayerState$() | async as loadingLayerState">
    <mhp-loading-indicator
        [statusText]="loadingLayerState.statusText"
        [loading]="loadingLayerState.loading"
        [showLoadingSpinnerWhenLoading]="
            loadingLayerState.showLoadingSpinnerWhenLoading
        "
        [fullOpaque]="loadingLayerState.fullOpaque"
        [showHeader]="loadingLayerState.showHeader"
        [zIndex]="loadingLayerState.zIndex"
    ></mhp-loading-indicator>
</ng-container>
