import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UiStopClickPropagationDirective } from './ui-stop-click-propagation.directive';

@NgModule({
    declarations: [UiStopClickPropagationDirective],
    imports: [CommonModule],
    exports: [UiStopClickPropagationDirective]
})
export class UiStopClickPropagationModule {}
