import { Observable, timer } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Output
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    IllegalStateError,
    MemoizeObservable,
    lazyShareReplay
} from '@mhp/common';
import { UiBaseComponent } from '@mhp/ui-components';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface UserInactivityDialogData {
    decisionTime: number;
}

/**
 * Dialog showing a timeout to the user allowing to either exit the dialog
 * with a user-initiated or a timeout-based decision.
 */
@UntilDestroy()
@Component({
    selector: 'mhp-user-inactivity-dialog',
    templateUrl: './user-inactivity-dialog.component.html',
    styleUrls: ['./user-inactivity-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserInactivityDialogComponent extends UiBaseComponent {
    @Output()
    readonly decisionSource = new EventEmitter<'USER' | 'TIMEOUT'>();

    readonly remainingTime$: Observable<number>;

    private readonly decisionTime: number;

    constructor(
        private readonly matDialogRef: MatDialogRef<UserInactivityDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data?: UserInactivityDialogData
    ) {
        super();

        if (!data?.decisionTime) {
            throw new IllegalStateError('Missing decision time from data');
        }

        this.decisionTime = data.decisionTime;

        this.completeOnDestroy(this.decisionSource);

        this.remainingTime$ = this.initRemainingTimeCountdown();

        this.remainingTime$.subscribe((remainingTime) => {
            if (remainingTime <= 0) {
                this.decisionSource.emit('TIMEOUT');
            }
        });
    }

    @MemoizeObservable()
    getRemainingTimeAsDate$(): Observable<Date> {
        return this.remainingTime$.pipe(
            map((remainingMs) => {
                const date = new Date(0, 0, 0, 0, 0, 0, 0);
                date.setTime(remainingMs);
                return date;
            })
        );
    }

    @MemoizeObservable()
    getProgress$(): Observable<number> {
        return this.remainingTime$.pipe(
            map((remainingMs) => (remainingMs / this.decisionTime) * 100),
            startWith(100)
        );
    }

    intentDialogClose() {
        this.decisionSource.emit('USER');
    }

    private initRemainingTimeCountdown() {
        const emissionInterval = 1000;
        const remainingTime$ = timer(0, emissionInterval).pipe(
            map((emitCount) => {
                const msPassed = emitCount * emissionInterval;
                return this.decisionTime - msPassed;
            }),
            lazyShareReplay(),
            untilDestroyed(this)
        );
        // make it hot
        remainingTime$.subscribe();
        return remainingTime$;
    }
}
