import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    UiButtonModule,
    UiIconsModule,
    UiProgressSpinnerModule
} from '@mhp/ui-components';
import { I18nModule } from '@mhp/ui-shared-services';

import { DialogModule } from '../../dialog/dialog.module';
import { HeaderBarModule } from '../../header-bar/header-bar.module';
import { UserInactivityDialogComponent } from './user-inactivity-dialog/user-inactivity-dialog.component';

@NgModule({
    declarations: [UserInactivityDialogComponent],
    imports: [
        CommonModule,
        HeaderBarModule,
        DialogModule,
        UiButtonModule,
        UiProgressSpinnerModule,
        I18nModule,
        UiIconsModule
    ],
    exports: [UserInactivityDialogComponent]
})
export class UserInactivityModule {}
