import { Route } from '@angular/router';

import { DealerAuthGuard } from './dealer-auth-guard';
import { DealerEntryParametersGuard } from './dealer-entry-parameters.guard';

/**
 * Add dealer auth-guard to root-routes. Skips routes defining a redirectTo directive.
 *
 * @param rootRoutes The application root-routes for which the dealer requires authentication.
 * @param routeVoter An optional voter which might be used to skip adding dealer authentication for certain routes (e.g. 404, error-page, ..)
 */
export const addDealerAuthGuard = (
    rootRoutes: Route[],
    routeVoter?: (route) => boolean
) => {
    // auth-guard for dealer-build
    rootRoutes.forEach((route) => {
        if (route.redirectTo || (routeVoter && !routeVoter(route))) {
            return;
        }
        route.canActivate = [...(route.canActivate || []), DealerAuthGuard];
    });
};

/**
 * Add the DealerEntryParametersGuard to the root-routes to resolve possible entry-parameters from the URL.
 *
 * @param rootRoutes The application root-routes.
 * @param routeVoter An optional voter which might be used to skip adding the resolver.
 */
export const addDealerEntryParametersGuard = (
    rootRoutes: Route[],
    routeVoter?: (route) => boolean
) => {
    rootRoutes.forEach((route) => {
        if (route.redirectTo || (routeVoter && !routeVoter(route))) {
            return;
        }
        route.canActivate = [
            ...(route.canActivate || []),
            DealerEntryParametersGuard
        ];
    });
};
