import { isEmpty } from 'lodash-es';

import {
    OptionCode,
    OptionCollection,
    OptionGroup,
    OptionList
} from '@mhp-immersive-exp/contracts/src/configuration/configuration-response.interface';
import { IllegalStateError } from '@mhp/common';

import {
    isOptionCode,
    isOptionCollection,
    isOptionGroup,
    isOptionList
} from './configuration-helper';

export class ConfigurationFilter<T extends OptionGroup = OptionGroup> {
    /**
     * Filters the given option groups so that only selected nodes are kept.
     * @param optionGroups
     */
    keepSelectedOnly(optionGroups: T[]): T[] {
        return optionGroups
            .map((optionGroup) => this.filterOptionGroup(optionGroup))
            .filter((optionGroup): optionGroup is T => !!optionGroup);
    }

    private filterOptionGroup(
        optionGroup: OptionGroup
    ): OptionGroup | undefined {
        const filteredContent = this.filterOptionGroupContent(
            optionGroup.content
        );

        if (!filteredContent) {
            return undefined;
        }

        return {
            ...optionGroup,
            content: filteredContent
        };
    }

    private filterOptionGroupContent(
        content: (OptionCode | OptionCollection | OptionGroup | OptionList)[]
    ):
        | (OptionCode | OptionCollection | OptionGroup | OptionList)[]
        | undefined {
        const filteredContent = content
            .map((currentContent) => {
                if (isOptionCode(currentContent)) {
                    return currentContent.selected ? currentContent : undefined;
                }

                if (isOptionGroup(currentContent)) {
                    return this.filterOptionGroup(currentContent);
                }
                if (isOptionCollection(currentContent)) {
                    return this.filterOptionCollection(currentContent);
                }
                if (isOptionList(currentContent)) {
                    return this.filterOptionList(currentContent);
                }
                throw new IllegalStateError(
                    `Unexpected node type ${(<any>currentContent).type}`
                );
            })
            .filter(
                (
                    currentContent
                ): currentContent is
                    | OptionCode
                    | OptionCollection
                    | OptionGroup
                    | OptionList => !!currentContent
            );

        if (isEmpty(filteredContent)) {
            return undefined;
        }
        return filteredContent;
    }

    private filterOptionCollection(
        optionCollection: OptionCollection
    ): OptionCollection | undefined {
        const filteredContent = optionCollection.content
            .map((currentContent) => {
                if (isOptionCode(currentContent)) {
                    return currentContent.selected ? currentContent : undefined;
                }
                if (isOptionCollection(currentContent)) {
                    return this.filterOptionCollection(currentContent);
                }
                if (isOptionList(currentContent)) {
                    return this.filterOptionList(currentContent);
                }
                throw new IllegalStateError(
                    `Unexpected node type ${(<any>currentContent).type}`
                );
            })
            .filter(
                (currentContent): currentContent is OptionCode | OptionList =>
                    !!currentContent
            );

        if (isEmpty(filteredContent)) {
            return undefined;
        }

        return {
            ...optionCollection,
            content: filteredContent
        };
    }

    private filterOptionList(optionList: OptionList): OptionList | undefined {
        if (!optionList.selected) {
            return undefined;
        }
        return {
            ...optionList,
            content: optionList.content.map((currentNestedCode) => ({
                ...currentNestedCode,
                content: currentNestedCode.content.filter(
                    (currentOptionCode) => currentOptionCode.selected
                )
            }))
        };
    }
}
