import { Observable, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { MemoizeObservable, lazyShareReplay } from '@mhp/common';

export interface StrategyMeta {
    type: string;
    properties?: ReadonlyMap<string, any>;
}

export interface StrategyWithMeta<T> {
    strategy: T;
    meta: StrategyMeta;
}

export interface StrategyProvider<T> {
    getStrategyWithMeta$(): Observable<StrategyWithMeta<T>>;
    getStrategy$(): Observable<T>;
}

/**
 * Returns an observable emitting the current valid instance and completing afterwards.
 * @param strategyProvider The provider to get the stream from.
 */
export const withCurrentStrategy$ = <T>(
    strategyProvider: StrategyProvider<T>
) => strategyProvider.getStrategy$().pipe(take(1));

/**
 * Returns an observable emitting the currently valid instance.
 * @param strategyProvider The provider to get the stream from.
 */
export const withStrategy$ = <T>(strategyProvider: StrategyProvider<T>) => strategyProvider.getStrategy$();

/**
 * Defines a reactive provider for exchangeable implementations of a defined service.
 */
export class UpdatableStrategyProvider<T> implements StrategyProvider<T> {
    private readonly strategySubject = new ReplaySubject<StrategyWithMeta<T>>(
        1
    );

    constructor(defaultStrategyWithMeta?: StrategyWithMeta<T>) {
        if (defaultStrategyWithMeta) {
            this.strategySubject.next(defaultStrategyWithMeta);
        }
    }

    /**
     * Set the currently valid strategy.
     * @param strategy The strategy to activate.
     * @param meta The metadata to be passed along.
     */
    setStrategy(strategy: T, meta: StrategyMeta) {
        this.strategySubject.next({
            strategy,
            meta
        });
    }

    @MemoizeObservable()
    getStrategy$(): Observable<T> {
        return this.strategySubject.pipe(
            map((strategyWithMeta) => strategyWithMeta.strategy),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    getStrategyWithMeta$(): Observable<StrategyWithMeta<T>> {
        return this.strategySubject.asObservable();
    }
}
