<img
    #lazyloadedImage
    class="lazyload-image__image lazyload-image__image--current"
    [sizes]="srcsetSize"
    [lazyLoad]="imageUrl | uiImageSrcset"
    [customObservable]="getObservableHook$() | async"
    [useSrcset]="useSrcset"
    (onStateChange)="onStateChanged($event)"
    [alt]="title"
    draggable="false"
/>
<ng-container *ngIf="previousImageUrl && !forceHidePreviousImage">
    <img
        *ngIf="useSrcsetPreviousImage; else noSrcset"
        class="lazyload-image__image lazyload-image__image--previous"
        [sizes]="srcsetSize"
        [srcset]="previousImageUrl | uiImageSrcset"
        aria-hidden="true"
        draggable="false"
    />
    <ng-template #noSrcset>
        <img
            class="lazyload-image__image lazyload-image__image--previous"
            [src]="previousImageUrl"
            aria-hidden="true"
            draggable="false"
        />
    </ng-template>
</ng-container>
<ng-container *ngIf="imageFallbackUrl">
    <img
        *ngIf="!useSrcsetFallbackImage; else srcsetFallbackImage"
        class="lazyload-image__fallback-image"
        [src]="imageFallbackUrl | uiImageSrcset"
        aria-hidden="true"
        draggable="false"
    />
    <ng-template #srcsetFallbackImage>
        <img
            class="lazyload-image__fallback-image"
            [sizes]="srcsetSize"
            [srcset]="imageFallbackUrl | uiImageSrcset"
            aria-hidden="true"
            draggable="false"
        />
    </ng-template>
</ng-container>
<div class="lazyload-image__loading-spinner">
    <div class="lazyload-image__loading-spinner-inner"></div>
</div>
