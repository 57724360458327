import { createAction, props } from '@ngrx/store';

/**
 * Set the active searchTerm.
 */
export const setActiveSearchTerm = createAction(
    '[LOCAL-STATE]: set active search term',
    props<{ searchTerm: string | undefined }>()
);

/**
 * Set the search active state.
 */
export const setSearchInputActive = createAction(
    '[LOCAL-STATE]: setSearchInputActive',
    props<{ searchInputActive: boolean }>()
);

/**
 * Exit and clear search state.
 */
export const exitSearchState = createAction('[LOCAL-STATE]: exitSearchState');
