import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import {
    SALESFORCE_TRACKING_SERVICE_CONFIG,
    SalesforceTrackingService,
    SalesforceTrackingServiceConfig
} from './salesforce-tracking.service';

/**
 * Module to be included in case tracking to Salesforce Datacloud is required.
 */
@NgModule({
    declarations: [],
    imports: [CommonModule]
})
export class SalesforceTrackingModule {
    /**
     * @param config Optional config in case the default tracking strategy should be used (see GoogleTagManagerDefaultTrackingStrategy)
     */
    static forRoot(
        config?: SalesforceTrackingServiceConfig
    ): ModuleWithProviders<SalesforceTrackingModule> {
        const providers = config
            ? [
                  {
                      provide: SALESFORCE_TRACKING_SERVICE_CONFIG,
                      useValue: config
                  }
              ]
            : [];

        return {
            ngModule: SalesforceTrackingModule,
            providers
        };
    }

    constructor(
        // eager init
        salesforceTrackingService: SalesforceTrackingService
        // eslint-disable-next-line @typescript-eslint/no-empty-function
    ) {}
}
