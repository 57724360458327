<div class="touch-panel" [class.touch-panel--disabled]="disabled">
    <div
        #touchPanel
        class="touch-panel__touch-area"
        (pinchmove)="onPinch($event)"
        (pinchstart)="onPinch($event)"
        (pinchend)="onPinch($event)"
        (pinchcancel)="onPinch($event)"
        (panmove)="onPan($event)"
        (panstart)="onPan($event)"
        (panend)="onPan($event)"
        (pancancel)="onPan($event)"
    ></div>
</div>
