import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import { StreamStatus, StreamingState } from "..";
import { enhanceSelector } from '../../../application-state/helpers';
import { LocalSharedApplicationState } from '../../../application-state/local-shared-application-state.interface';

/**
 * Selects the current streaming state.
 */
export const selectStreamingState = enhanceSelector<
    LocalSharedApplicationState,
    StreamingState | undefined
>(
    pipe(
        map((state) => state.streaming)
    ),
    {
        distinctUntilChanged: true
    }
);

/**
 * Selects the current login state.
 */
export const selectEngineHostUrl = enhanceSelector<
    StreamingState | undefined,
    string | undefined
>(
    pipe(
        map((state) => state?.engineHostUrl)
    )
);

/**
 * Selects the current login state.
 */
export const selectStreamStatus = enhanceSelector<
    StreamingState | undefined,
    StreamStatus | undefined
>(
    pipe(
        map((state) => state?.streamStatus)
    )
);
