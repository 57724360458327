import { createAction, props } from '@ngrx/store';

import { MenuScope } from '../common-menu-state.interface';

/**
 * Toggle the menu active state.
 */
export const toggleMenuActive = createAction(
    '[LOCAL-STATE]: toggle menu active'
);

/**
 * Set the active menu entry.
 */
export const setActiveMenuEntry = createAction(
    '[LOCAL-STATE]: set menu active menu entry',
    props<{ activeMenuEntry?: string }>()
);

/**
 * Set the active menu scope.
 */
export const setActiveMenuScope = createAction(
    '[LOCAL-STATE]: set active menu scope',
    props<{ activeMenuScope: MenuScope }>()
);

/**
 * Set the menu active state.
 */
export const setMenuActive = createAction(
    '[LOCAL-STATE]: set configuration menu active',
    props<{
        menuActive: boolean;
        activeMenuEntry?: string;
    }>()
);
