import { apply_patch as applyPatch } from 'jsonpatch';
import { isNumber } from 'lodash-es';
import {
    BehaviorSubject,
    Observable,
    Subject,
    Subscription,
    combineLatest,
    merge,
    of
} from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    filter,
    first,
    map,
    switchMap,
    take
} from 'rxjs/operators';

import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Request } from '@mhp-immersive-exp/contracts/src/request/request.enum';
import { PatchApplicationStateEventPayload } from '@mhp-immersive-exp/contracts/src/websocket/socket-response.interface';
import {
    IllegalStateError,
    MemoizeObservable,
    lazyShareReplay
} from '@mhp/common';
import {
    ApplicationState,
    GetApplicationStatePayload
} from '@mhp/communication-models';

import { setEngineApplicationState } from '../../application-state/actions';
import { ApplicationStateService } from '../../application-state/application-state.service';
import { SocketIOService } from '../../communication';
import { selectEngineConnectionState } from '../state';

/**
 * Responsible for keeping the UiGlobalApplicationState.engine branch up-to-date
 * by either fetching it completely or applying patches to it that are emitted by the engine.
 * Behavior can be started and stopped, depending on whether updates are of interest
 * or not.
 */
@Injectable()
export class EngineStatePatchService {
    private readonly patchingActiveSubject = new BehaviorSubject<boolean>(
        false
    );

    private readonly patchErrorSubject = new Subject<Error>();

    private readonly forceRefreshAppStateSubject = new BehaviorSubject<void>(
        undefined
    );

    private readonly applicationStatePatcher$: Observable<
        ApplicationState | undefined
    >;

    private stateUpdaterSubscription?: Subscription;

    constructor(
        private applicationStateService: ApplicationStateService,
        private socketIoService: SocketIOService,
        @Optional() @SkipSelf() parentService?: EngineStatePatchService
    ) {
        if (parentService) {
            throw new Error(
                'EngineStatePatchService is already loaded. Import module only where needed.'
            );
        }

        this.applicationStatePatcher$ = this.buildApplicationStatePatcher();
    }

    /**
     * Start listening to state changes happening on engine side.
     * - Initially fetches the engines state
     * - applies patches emitted by the engine afterwards
     */
    startListenToEngineStateChanges(): Observable<
        ApplicationState | undefined
    > {
        if (this.stateUpdaterSubscription) {
            throw new IllegalStateError(
                'Already listening to engine state changes.'
            );
        }
        const storeUpdater$ = this.buildStoreUpdater$(
            this.applicationStatePatcher$
        ).pipe(lazyShareReplay());
        this.stateUpdaterSubscription = storeUpdater$.subscribe();

        storeUpdater$.pipe(first()).subscribe(() => {
            this.patchingActiveSubject.next(true);
        });

        return storeUpdater$;
    }

    /**
     * Stop listening to state changes happening on engine side.
     */
    stopListenToEngineStateChanges() {
        this.stateUpdaterSubscription?.unsubscribe();
        this.stateUpdaterSubscription = undefined;
        this.patchingActiveSubject.next(false);
    }

    @MemoizeObservable()
    getEngineStatePatchActiveState$(): Observable<boolean> {
        return this.patchingActiveSubject
            .asObservable()
            .pipe(distinctUntilChanged(), lazyShareReplay());
    }

    /**
     * Trigger a refresh of the application state based on the application state
     * held in the backend.
     * WARNING: Before using this, please consider what might have gone wrong that
     * it's required to do a force-refresh and how this could be implemented in a
     * generic way.
     */
    forceRefreshApplicationState() {
        this.forceRefreshAppStateSubject.next();
    }

    /**
     * Returns an observable where possible patch errors are emitted. Useful for
     * debugging patch-errors.
     */
    getPatchError$() {
        return this.patchErrorSubject.asObservable();
    }

    private getRemoteApplicationState(): Observable<ApplicationState> {
        return this.socketIoService.request<
            Record<string, never>,
            GetApplicationStatePayload
        >('getapplicationstate', {});
    }

    private getRemoteApplicationStateSafe(): Observable<
        ApplicationState | undefined
    > {
        return this.getRemoteApplicationState().pipe(
            catchError(() => of(undefined))
        );
    }

    private buildApplicationStatePatcher(): Observable<
        ApplicationState | undefined
    > {
        return this.socketIoService
            .subscribe<PatchApplicationStateEventPayload>(
                Request.PATCH_APPLICATION_STATE
            )
            .pipe(
                switchMap((statePatchPayload) =>
                    this.applicationStateService.getEngineState().pipe(
                        take(1),
                        switchMap((localAppState: ApplicationState) => {
                            // FIXME: Workaround for incorrectly nested version-attribute in current application
                            const localAppStateVersion = isNumber(
                                localAppState.version
                            )
                                ? localAppState.version
                                : (<any>localAppState).configurationState
                                      .version;
                            if (
                                !localAppState ||
                                localAppStateVersion !==
                                    statePatchPayload.refVersion
                            ) {
                                // either there is no local appState or the version to be patched differs
                                return this.getRemoteApplicationStateSafe();
                            }
                            // matches - so patch!
                            try {
                                const patchedState = applyPatch(
                                    localAppState,
                                    statePatchPayload.patch
                                );
                                return of(patchedState);
                            } catch (error) {
                                this.patchErrorSubject.next(error);
                                return this.getRemoteApplicationStateSafe();
                            }
                        })
                    )
                )
            );
    }

    private buildStoreUpdater$(
        applicationStatePatcher$: Observable<ApplicationState | undefined>
    ) {
        const lastValidAppState$ = combineLatest([
            this.applicationStateService
                .getLocalSharedState()
                .pipe(selectEngineConnectionState),
            this.forceRefreshAppStateSubject
        ]).pipe(
            filter(([connected]) => !!connected),
            switchMap(() => this.getRemoteApplicationStateSafe())
        );

        return merge(lastValidAppState$, applicationStatePatcher$).pipe(
            map((appState) => {
                if (!appState) {
                    return undefined;
                }
                this.applicationStateService.dispatch(
                    setEngineApplicationState({ state: appState })
                );

                return appState;
            })
        );
    }
}
