/* eslint-disable max-classes-per-file */
import { CustomError } from './custom-error';

/**
 * Error thrown in case an expectation could not be fulfilled.
 */
export class IllegalStateError extends CustomError {
    constructor(message: string, details?: any) {
        super(message, details);
    }
}

/**
 * Error thrown in case something could not be found.
 */
export class NotFoundError extends CustomError {
    constructor(message: string, details?: any) {
        super(message, details);
    }
}

/**
 * Error thrown in case a user cancelled a process.
 */
export class UserCancelledError extends CustomError {
    constructor(message: string, details?: any) {
        super(message, details);
    }
}

/**
 * Error thrown in case an implementation is not there yet.
 */
export class NotImplementedError extends CustomError {
    constructor(message?: string, details?: any) {
        super(message || 'Not implemented yet', details);
    }
}

/**
 * Error thrown in case a user is not authenticated.
 */
export class NotAuthenticatedError extends CustomError {
    constructor(message: string, details?: any) {
        super(message, details);
    }
}

/**
 * Error thrown in case a user is not authorized to perform a certain action.
 */
export class NotAuthorizedError extends CustomError {
    constructor(message: string, details?: any) {
        super(message, details);
    }
}
