import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { Inject, Injectable, Type } from '@angular/core';
import {
    MatSnackBar,
    MatSnackBarConfig,
    MatSnackBarRef
} from '@angular/material/snack-bar';

import {
    UI_NOTIFICATION_CONFIG,
    UiNotificationConfig
} from './ui-notification-config.interface';
import {
    NotificationData,
    UiNotificationComponent
} from './ui-notification.component';

interface NotificationOptions {
    showClose?: boolean;
    renderActionNewline?: boolean;
    duration?: number;
}

@Injectable()
export class UiNotificationService {
    constructor(
        private snackBar: MatSnackBar,
        @Inject(UI_NOTIFICATION_CONFIG)
        private config: UiNotificationConfig
    ) {}

    /**
     * Show a notification that disappears after some time.
     * @param message The message to be shown
     * @param duration The optional duration after which the notification disappears
     * @param action The label for the optional action button
     * @param options Optional options to customize notification appearance
     */
    showAutohideNotification(
        message: string,
        duration?: number,
        action?: string,
        options?: NotificationOptions
    ): MatSnackBarRef<UiNotificationComponent> {
        return this.showNotification(
            message,
            duration || this.config.defaultAutohideDuration,
            action,
            options
        );
    }

    /**
     * Show a notification that needs to be closed by the user.
     * @param message The message to be shown
     * @param duration The optional duration after which the notification disappears
     * @param action The label for the optional action button
     * @param options Optional options to customize notification appearance
     */
    showNotification(
        message: string,
        duration?: number | undefined,
        action?: string,
        options?: NotificationOptions
    ): MatSnackBarRef<UiNotificationComponent> {
        return this.showNotificationInternal(
            message,
            undefined,
            duration,
            action,
            options
        );
    }

    /**
     * Show a custom notification.
     * @param notificationComponent The notification component to be used
     * @param options Optional options to pass-over to the MatSnackBar service
     * @return Observable emitting the MatSnackBarRef instance and completing after SnackBar has been dismissed.
     */
    showCustomNotification$<T, D = unknown>(
        notificationComponent: Type<T>,
        options?: MatSnackBarConfig<D>
    ): Observable<MatSnackBarRef<T>> {
        return new Observable<MatSnackBarRef<T>>((subscriber) => {
            const snackBarRef = this.snackBar.openFromComponent(
                notificationComponent,
                {
                    horizontalPosition: 'center',
                    verticalPosition: 'bottom',
                    ...options
                }
            );

            subscriber.next(snackBarRef);
            snackBarRef
                .afterDismissed()
                .pipe(take(1))
                .subscribe(() => subscriber.complete());

            return () => {
                snackBarRef.dismiss();
            };
        });
    }

    /**
     * Show an error-notification that needs to be closed by the user.
     * @param message The message to be shown
     * @param action An optional action to be displayed.
     * @param options Optional options to be used.
     */
    showError(
        message: string,
        action?: string,
        options?: NotificationOptions
    ): MatSnackBarRef<UiNotificationComponent> {
        return this.showNotificationInternal(
            message,
            'mat-mdc-snack-bar-container--error',
            undefined,
            action,
            options
        );
    }

    private showNotificationInternal(
        message: string,
        panelClass?: string,
        duration?: number,
        actionLabel?: string,
        options?: NotificationOptions
    ) {
        return this.snackBar.openFromComponent(UiNotificationComponent, {
            data: <NotificationData>{
                message,
                actionLabel,
                showClose: options?.showClose,
                renderActionNewline: options?.renderActionNewline
            },
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            panelClass,
            duration: duration ?? options?.duration
        });
    }
}
