import { Observable } from 'rxjs';

import { InjectionToken } from '@angular/core';

import { StrategyProvider } from '../../strategy/index';
import { UiStateReducer } from './ui-shared-state.service';

/**
 * Defines the communication-interface to trigger certain actions that are dependent on the backend the application
 * is currently talking to.
 */
export interface UiSharedStateStrategy<UiStateType> {
    reduceUiState$(
        reducer: UiStateReducer<UiStateType>
    ): Observable<UiStateReducer<UiStateType>>;
}

export const UI_SHARED_STATE_STRATEGY_PROVIDER_TOKEN = new InjectionToken<
    StrategyProvider<UiSharedStateStrategy<any>>
>('UiSharedStateStrategy');
