import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';

import { UpdatableStrategyProvider } from '../../strategy/strategy-provider';
import { UI_SHARED_STATE_STRATEGY_PROVIDER_TOKEN } from './ui-shared-state-strategy.interface';
import { UiSharedStateService } from './ui-shared-state.service';

@NgModule({
    declarations: [],
    imports: [CommonModule]
})
export class UiSharedStateModule {
    /**
     * Import the module along with its providers.
     * By default, an empty UpdatableStrategyProvider instance is provided for UI_SHARED_STATE_STRATEGY_PROVIDER_TOKEN
     * @param config An optional config that allows providing an alternative UI_SHARED_STATE_STRATEGY_PROVIDER_TOKEN instance.
     */
    static forRoot(config?: {
        uiSharedStateStrategyProviderDefinition?: Provider;
    }): ModuleWithProviders<UiSharedStateModule> {
        return {
            ngModule: UiSharedStateModule,
            providers: [
                UiSharedStateService,
                config?.uiSharedStateStrategyProviderDefinition ?? {
                    provide: UI_SHARED_STATE_STRATEGY_PROVIDER_TOKEN,
                    useValue: new UpdatableStrategyProvider()
                }
            ]
        };
    }
}
