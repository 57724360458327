import { CommonModule } from '@angular/common';
import {
    InjectionToken,
    ModuleWithProviders,
    NgModule,
    Type
} from '@angular/core';
import {
    TranslocoLocaleConfig,
    TranslocoLocaleModule,
    TranslocoNumberTransformer,
    provideTranslocoLocale,
    provideTranslocoNumberTransformer
} from '@jsverse/transloco-locale';

import { L10nService } from './l10n.service';

export const TRANSLOCO_DEFAULT_NUMBER_TRANSFORMER =
    new InjectionToken<TranslocoNumberTransformer>(
        'Translocos Default Number Transformer'
    );

@NgModule({
    declarations: [],
    imports: [CommonModule, TranslocoLocaleModule],
    exports: [TranslocoLocaleModule]
})
export class L10nModule {
    constructor(
        private readonly l10nService: L10nService // eager init
    ) {}

    static forRoot(
        config?: TranslocoLocaleConfig,
        numberTransformer?: Type<TranslocoNumberTransformer>
    ): ModuleWithProviders<L10nModule> {
        let providers = provideTranslocoLocale(config);

        if (numberTransformer) {
            providers = [
                ...providers,
                provideTranslocoNumberTransformer(numberTransformer)
            ];
        }

        return {
            ngModule: L10nModule,
            providers
        };
    }
}
