import memoize from 'lodash/memoize';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

memoize.Cache = Map;

/*
 * Decorator function that uses lodashs memoize functionality under the hood.
 * Apply to a method for which you want the result to be memoized.
 * Currently it works for methods that have 0 or 1 parameters set.
 */
export function Memoize() {
    return function (
        target: unknown,
        propertyKey: string,
        descriptor: PropertyDescriptor
    ) {
        const oldFunction = descriptor.value;
        const memoizedMethodName = `__${propertyKey}Memoized`;

        descriptor.value = function (...args: any[]) {
            if (arguments.length > 1) {
                throw new Error(
                    `Memoizing methods with more than one argument is not yet implemented: ${ 
                        propertyKey}`
                );
            }

            if (!(this as any)[memoizedMethodName]) {
                (this as any)[memoizedMethodName] = memoize(oldFunction);
            }

            return (this as any)[memoizedMethodName](...(args as any));
        };
    };
}

/**
 * A memoize function tailored for usage with Observable streams.
 * Memoization is cancelled in case the returned Observable errors out
 * (and thus would no longer be able to emit items). In this case, calling
 * the memoized function again will create a new Observable stream and keep
 * this one, until it errors out again.
 */
export function MemoizeObservable() {
    return function (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        target: any,
        propertyKey: string,
        descriptor: PropertyDescriptor
    ) {
        const oldFunction = descriptor.value;
        // include the targets class-name to prevent issues when calling a memoized super-implementation
        const memoizedMethodName = `__${target.constructor.name}.${propertyKey}Memoized`;

        descriptor.value = function (...args: any) {
            if (arguments.length > 1) {
                throw new Error(
                    `Memoizing methods with more than one argument is not yet implemented: ${ 
                        propertyKey}`
                );
            }

            const cacheKey = args[0];

            if (!(this as any)[memoizedMethodName]) {
                const oldFunctionWithErrorHandling = (
                    localCacheKey: string,
                    ...innerArgs: any
                ) => {
                    const originalResult = oldFunction.call(this, ...innerArgs);

                    if (!(originalResult instanceof Observable)) {
                        throw new Error(
                            `When memoizing observables, functions have to return Observables instead of ${ 
                                typeof originalResult}`
                        );
                    }

                    return originalResult.pipe(
                        tap({
                            error: () => {
                                (<Map<string, unknown>>(
                                    (this as any)[memoizedMethodName].cache
                                )).delete(localCacheKey);
                            }
                        })
                    );
                };

                (this as any)[memoizedMethodName] = memoize(
                    oldFunctionWithErrorHandling
                );
            }
            return (this as any)[memoizedMethodName].call(
                this,
                cacheKey,
                ...args
            );
        };
    };
}
