import { ConfigModel } from '@mhp-immersive-exp/contracts/src/configuration/config-model.interface';
import { EnvironmentLightingProfileState } from '@mhp-immersive-exp/contracts/src/environment/environment.interface';
import {
    ApplicationState,
    ConfigurationState
} from '@mhp/communication-models';
import { createAction, props } from '@ngrx/store';

export const setEngineApplicationState = createAction(
    '[ENGINE-APP-STATE]: setEngineApplicationState',
    props<{ state: ApplicationState }>()
);

export const setEngineConfigurationState = createAction(
    '[ENGINE-APP-STATE]: setEngineConfigurationState',
    props<{ state: ConfigurationState }>()
);

export const setActiveEngineConfiguration = createAction(
    '[ENGINE-APP-STATE]: setActiveEngineConfiguration',
    props<{
        productId: string;
        options: ConfigModel[];
        country?: string;
    }>()
);

export const setActiveCamera = createAction(
    '[ENGINE-APP-STATE]: set active camera',
    props<{ id: string }>()
);

export const setEnvironmentState = createAction(
    '[ENGINE-APP-STATE]: set environment state',
    props<{ id: string; state: EnvironmentLightingProfileState }>()
);

export const setAnimationState = createAction(
    '[ENGINE-APP-STATE]: setAnimationState',
    props<{ id: string; state: 'START' | 'END' }>()
);
