import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
    declarations: [],
    imports: [CommonModule, MatSelectModule, MatFormFieldModule],
    exports: [MatSelectModule, MatFormFieldModule]
})
export class UiSelectModule {}
