import { isEmpty, merge, uniq } from 'lodash-es';

import { ConfigModel } from '@mhp-immersive-exp/contracts/src/configuration/config-model.interface';
import { ConfigOption } from '@mhp-immersive-exp/contracts/src/configuration/config-option.interface';
import {
    OptionCode,
    OptionCollection,
    OptionGroup,
    OptionList
} from '@mhp-immersive-exp/contracts/src/configuration/configuration-response.interface';

import {
    isOptionCode,
    isOptionCollection,
    isOptionGroup,
    isOptionList
} from './configuration-helper';

export enum ConfigurationConverterMode {
    SELECTED_ONLY,
    COMPLETE
}

export class ConfigurationConverter {
    /**
     * @param mode In case of SELECTED_ONLY, only options that are currently
     * selected will be taken into account. In case of COMPLETE, all options will be taken into account.
     */
    constructor(private mode: ConfigurationConverterMode) {}

    /**
     * Reduces the given OptionGroup[] data to the resulting configuration relevant code structure.
     * @param optionGroups
     */
    convertToConfigurationFormat(optionGroups: OptionGroup[]): ConfigModel[] {
        const result: ConfigModel[] = [];

        optionGroups.forEach((optionGroup) => {
            const optionGroupResult = this.mapOptionGroup(optionGroup);
            optionGroupResult.forEach((item) => result.push(item));
        });

        return result;
    }

    private mapOptionGroup(optionGroup: OptionGroup): ConfigModel[] {
        const result: ConfigModel[] = [];

        optionGroup.content.forEach((optionGroupContent) => {
            if (isOptionCode(optionGroupContent)) {
                const optionCodeResult = this.mapOptionCode(optionGroupContent);
                if (optionCodeResult) {
                    result.push(optionCodeResult);
                }
            } else if (isOptionGroup(optionGroupContent)) {
                const optionGroupResult =
                    this.mapOptionGroup(optionGroupContent);
                result.push(...optionGroupResult);
            } else if (isOptionList(optionGroupContent)) {
                if (this.mode === ConfigurationConverterMode.SELECTED_ONLY) {
                    const optionListResult =
                        this.mapOptionList(optionGroupContent);
                    if (optionListResult) {
                        result.push(optionListResult);
                    }
                } else {
                    const optionListResult =
                        this.mapOptionListComplete(optionGroupContent);
                    result.push(...optionListResult);
                }
            } else if (isOptionCollection(optionGroupContent)) {
                const optionCollectionResult =
                    this.mapOptionCollection(optionGroupContent);
                if (optionCollectionResult) {
                    result.push(...optionCollectionResult);
                }
            }
        });

        return uniq(result);
    }

    private mapOptionList(optionList: OptionList): ConfigOption | undefined {
        if (
            this.mode === ConfigurationConverterMode.SELECTED_ONLY &&
            !optionList.selected
        ) {
            return undefined;
        }

        let result: ConfigOption = {};

        optionList.content.forEach((nestedCode) => {
            nestedCode.content.forEach((optionCode) => {
                const optionCodeResult = this.mapOptionCode(optionCode);

                if (!optionCodeResult) {
                    return;
                }

                result = merge(result, {
                    [optionList.code]: {
                        [nestedCode.code]: optionCode.code
                    }
                });
            });
        });

        return isEmpty(result) ? undefined : result;
    }

    private mapOptionListComplete(optionList: OptionList): ConfigOption[] {
        const result: ConfigOption[] = [];

        optionList.content.forEach((nestedCode) => {
            nestedCode.content.forEach((optionCode) => {
                result.push({
                    [optionList.code]: {
                        [nestedCode.code]: optionCode.code
                    }
                });
            });
        });

        return result;
    }

    private mapOptionCollection(
        optionCollection: OptionCollection
    ): ConfigModel[] | undefined {
        const result: ConfigModel[] = [];

        if (
            this.mode === ConfigurationConverterMode.SELECTED_ONLY &&
            !optionCollection.mandatory &&
            !optionCollection.selected
        ) {
            return undefined;
        }

        optionCollection.content.forEach((optionCollectionContent) => {
            if (isOptionCode(optionCollectionContent)) {
                const optionCodeResult = this.mapOptionCode(
                    optionCollectionContent
                );
                if (optionCodeResult) {
                    result.push(optionCodeResult);
                }
            } else if (isOptionList(optionCollectionContent)) {
                if (this.mode === ConfigurationConverterMode.SELECTED_ONLY) {
                    const optionListResult = this.mapOptionList(
                        optionCollectionContent
                    );
                    if (optionListResult) {
                        result.push(optionListResult);
                    }
                } else {
                    const optionListResult = this.mapOptionListComplete(
                        optionCollectionContent
                    );
                    result.push(...optionListResult);
                }
            } else if (isOptionCollection(optionCollectionContent)) {
                const optionCollectionResult = this.mapOptionCollection(
                    optionCollectionContent
                );
                if (optionCollectionResult) {
                    result.push(...optionCollectionResult);
                }
            }
        });

        return result;
    }

    private mapOptionCode(optionCode: OptionCode): string | undefined {
        if (
            this.mode === ConfigurationConverterMode.SELECTED_ONLY &&
            !optionCode.selected
        ) {
            return undefined;
        }
        return optionCode.code;
    }

    private isEmptyCode(code: string) {
        return isEmpty(code.trim());
    }
}
