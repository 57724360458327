import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import { enhanceSelector } from '../../application-state/helpers';
import {
    ConnectionState,
    LocalSharedApplicationState
} from '../../application-state/local-shared-application-state.interface';

export const selectConnectionState = enhanceSelector<
    LocalSharedApplicationState,
    ConnectionState
>(pipe(map((state) => state.connectionState)));

/**
 * Select the current network-state as indicated by the user-agent, e.g. browser.
 */
export const selectNetworkConnectionState = enhanceSelector<
    LocalSharedApplicationState,
    boolean | undefined
>(
    pipe(
        selectConnectionState,
        map((state) => state.networkConnected)
    ),
    {
        distinctUntilChanged: true
    }
);
