import { isNumber } from 'lodash-es';
import {
    MonoTypeOperatorFunction,
    Observable,
    TimeoutError,
    filter,
    first,
    retry,
    switchMap,
    take,
    tap,
    throwError,
    timer
} from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { IllegalStateError } from '@mhp/common';
import {
    UiNotificationComponent,
    UiNotificationService
} from '@mhp/ui-components';

import { ApplicationStateService } from '../application-state/application-state.service';
import {
    WebsocketConnectionUnavailableError,
    WebsocketEndpointUnavailableError,
    WebsocketRequestTimeoutError
} from '../communication';
import {
    selectEngineConnectionState,
    selectHubConnectionState
} from '../engine/state';

export interface ErrorHandlerServiceConfig {
    /**
     * The maximum amount of retries for retryable errors.
     */
    maxRetries: number;

    /**
     * The scaling duration that is used to increase the time between each retry.
     */
    retryScalingDuration: number;

    /**
     * The maximum time to wait between retries.
     */
    maxRetryTimeout: number;

    /**
     * If the message of an error should be appended to the message provided by a message-provider.
     */
    appendRootErrorMessage?: boolean;

    /**
     * An optional callback that will be called when all retries failed and the error is finally re-thrown.
     * @param error
     */
    errorCallback?: (error: unknown) => void;
}

export const ERROR_HANDLER_CONFIG_TOKEN =
    new InjectionToken<ErrorHandlerServiceConfig>('ErrorHandlerConfig');

/**
 * The type of the receiver (if relevant) the request is targeted at.
 */
export enum RequestReceiverType {
    ENGINE,
    RULER
}

export interface RetryContext {
    retryAttempt: number;
    nextRetryAttempt?: number;
    lastError: unknown;
    [key: string]: unknown;
}

export interface RetryConfig {
    /**
     * Depending on the endpointType, retry-optimizations might be possible
     */
    endpointType?: RequestReceiverType;

    /**
     * Will be called each time an error happens, no matter if it is retried or not
     * @param error
     * @param retryContext Object that might be used by the call-receiver to store context information later used in a #resolveCallback
     */
    errorCallback?: (error: unknown, retryContext: RetryContext) => void;

    /**
     * Will be called when an error happened and it could be resolved by a retry.
     * @param retryContext Object that might be used by the call-receiver to get context information previously stored in a #errorCallback
     */
    resolveCallback?: (retryContext: RetryContext) => void;

    /**
     * Some error-candidates are considered to be retryable (HTTP >= 500, websocket timeouts, websocket endpoint unavailable, ..).
     * If this behavior should be skipped, set #ignoreDefaultRetryableErrors to true
     */
    ignoreDefaultRetryableErrors?: boolean;

    /**
     * Will be called to determine if an unknown application error is eligible for retry.
     * @param error
     */
    isEligibleForRetry?: (error: unknown) => boolean | Observable<boolean>;

    /**
     * The maximum amount of retries for retryable errors.
     * Overrides the configured global value via ErrorHandlerConfig#maxRetries
     */
    maxRetries?: number;

    /**
     * The scaling duration that is used to increase the time between each retry.
     * Overrides the configured global value via ErrorHandlerConfig#retryScalingDuration
     */
    retryScalingDuration?: number;

    /**
     * The maximum time to wait between retries.
     * Overrides the configured global value via ErrorHandlerServiceConfig#maxRetryTimeout
     */
    maxRetryTimeout?: number;

    /**
     * A message provider that will be asked for a message that is displayed
     * when no retries are left and the error will be finally thrown.
     * @param error
     */
    messageProviderOnFinalError?: (error: unknown) => string;

    /**
     * Optional callback that prevents the global error-callback (if configured) to be notified of the error.
     * @param error The error that occurred.
     * @return boolean true in case the global error handler should not be notified.
     */
    skipGlobalErrorNotificationCallback?: (error: unknown) => boolean;
}

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {
    constructor(
        private applicationStateService: ApplicationStateService,
        @Inject(ERROR_HANDLER_CONFIG_TOKEN)
        private errorHandlerConfig: ErrorHandlerServiceConfig,
        @Optional() private notificationService?: UiNotificationService
    ) {}

    /**
     * Convenience method to apply retry logic as described in #applyRetry and show a provided notification-message to the user upon
     * errors.
     * @param retryConfig An optional config that further customizes the retry-logic
     * @param messageProvider A message provider that resolves a possible error to a message show to the user.
     */
    applyRetryWithHintsOnError<T>(
        messageProvider: (error: unknown) => string,
        retryConfig: RetryConfig = {}
    ): MonoTypeOperatorFunction<T> {
        const { notificationService } = this;
        if (!notificationService) {
            throw new IllegalStateError(
                'Unable to display hints as UiNotificationService is not present.'
            );
        }

        let lastSeenMessage: string | undefined;
        const errorCallback = (error: unknown, retryContext: RetryContext) => {
            const currentMessage = this.getErrorMessage(messageProvider, error);

            if (lastSeenMessage !== currentMessage) {
                const notificationRef =
                    notificationService.showError(currentMessage);
                retryContext.notificationRef = notificationRef;

                notificationRef.afterDismissed().subscribe(() => {
                    lastSeenMessage = undefined;
                });
                notificationRef.afterOpened().subscribe(() => {
                    lastSeenMessage = currentMessage;
                });
            }

            if (retryConfig.errorCallback) {
                retryConfig.errorCallback(error, retryContext);
            }
        };

        const resolveCallback = (retryContext: RetryContext) => {
            if (retryContext.notificationRef) {
                (<MatSnackBarRef<UiNotificationComponent>>(
                    retryContext.notificationRef
                )).dismiss();
            }
            if (retryConfig.resolveCallback) {
                retryConfig.resolveCallback(retryContext);
            }
        };

        return this.applyRetry<T>({
            ...retryConfig,
            errorCallback,
            resolveCallback
        });
    }

    /**
     * Apply retry-logic to an Observable chain:
     * - in case the source of the error is a ConnectionUnavailableError, the request is retried as soon as a connection is available again
     * - depending on the given RetryConfig#endpointType, retry-optimizations might be possible
     * - for all other errors a backoff based retry is used for a given or default amount of times
     * @param retryConfig An optional config that further customizes the retry-logic
     */
    applyRetry<T>(retryConfig: RetryConfig = {}): MonoTypeOperatorFunction<T> {
        let retryContext: RetryContext;
        return (source) =>
            source.pipe(
                retry({
                    delay: (error: unknown, index: number) => {
                        if (!retryContext) {
                            retryContext = {
                                lastError: error,
                                retryAttempt: -1
                            };
                        }

                        const retryIndex = index - 1;

                        return this.determineRetryLogic(
                            error,
                            retryIndex,
                            retryConfig,
                            retryContext
                        );
                    }
                }),
                tap(() => {
                    if (!retryContext || !retryConfig.resolveCallback) {
                        return;
                    }
                    retryConfig.resolveCallback(retryContext);
                })
            );
    }

    /**
     * Displays an error message while additionally adding information about the root-cause
     * of the error if configured via ErrorHandlerServiceConfig#appendRootErrorMessage
     * @param messageProvider
     * @param error
     */
    showErrorMessage(
        messageProvider: (error: unknown) => string,
        error: Error
    ) {
        const { notificationService } = this;
        if (!notificationService) {
            throw new IllegalStateError(
                'Unable to display hints as UiNotificationService is not present.'
            );
        }

        return notificationService.showError(
            this.getErrorMessage(messageProvider, error)
        );
    }

    private determineRetryLogic(
        error: unknown,
        errorCount: number,
        retryConfig: RetryConfig,
        retryContext: RetryContext
    ): Observable<unknown> {
        // default is to treat errors as non-recoverable, so rethrow them
        let result$: Observable<unknown> = throwError(() => error);

        const retryAttempt = errorCount + 1;
        const retryScalingDuration = isNumber(retryConfig.retryScalingDuration)
            ? retryConfig.retryScalingDuration
            : this.errorHandlerConfig.retryScalingDuration;
        const maxRetryTimeout = isNumber(retryConfig.maxRetryTimeout)
            ? retryConfig.maxRetryTimeout
            : this.errorHandlerConfig.maxRetryTimeout;
        const maxRetries = isNumber(retryConfig.maxRetries)
            ? retryConfig.maxRetries
            : this.errorHandlerConfig.maxRetries;

        retryContext.retryAttempt = retryAttempt - 1;
        retryContext.lastError = error;

        if (
            !retryConfig.ignoreDefaultRetryableErrors &&
            error instanceof WebsocketEndpointUnavailableError &&
            retryConfig.endpointType === RequestReceiverType.ENGINE
        ) {
            // request to engine. In case engine is not available, retry when engine is available again
            result$ = this.applicationStateService.getLocalSharedState().pipe(
                selectEngineConnectionState,
                first(),
                switchMap((connectionState) => {
                    // if engine is available at present, this endpoint simply does not exist...
                    if (connectionState) {
                        return throwError(() => error);
                    }
                    // engine is currently not available, so wait for its return
                    return this.applicationStateService
                        .getLocalSharedState()
                        .pipe(selectEngineConnectionState);
                }),
                filter((connectionState) => !!connectionState)
            );
        } else if (
            !retryConfig.ignoreDefaultRetryableErrors &&
            error instanceof WebsocketConnectionUnavailableError
        ) {
            if (retryAttempt > maxRetries) {
                result$ = throwError(() => error);
            } else {
                // retry when connection gets available again
                result$ = this.applicationStateService
                    .getLocalSharedState()
                    .pipe(
                        selectHubConnectionState,
                        filter((connectionState) => !!connectionState)
                    );
            }
        } else if (retryAttempt <= maxRetries) {
            if (
                !retryConfig.ignoreDefaultRetryableErrors &&
                (error instanceof WebsocketRequestTimeoutError ||
                    error instanceof WebsocketEndpointUnavailableError ||
                    error instanceof TimeoutError ||
                    (error instanceof HttpErrorResponse &&
                        (error.status === 0 || error.status >= 500)))
            ) {
                // we do not know, what's the reason, so just retry
                retryContext.nextRetryAttempt = Math.min(
                    retryAttempt * retryScalingDuration,
                    maxRetryTimeout
                );
                result$ = timer(retryContext.nextRetryAttempt);
            } else if (retryConfig.isEligibleForRetry) {
                const eligibleForRetry: boolean | Observable<boolean> =
                    retryConfig.isEligibleForRetry(error);
                if (eligibleForRetry instanceof Observable) {
                    const defaultResult$ = result$;
                    result$ = eligibleForRetry.pipe(
                        take(1),
                        switchMap((eligibleForRetryLocal) => {
                            if (!eligibleForRetryLocal) {
                                return defaultResult$;
                            }
                            retryContext.nextRetryAttempt = Math.min(
                                retryAttempt * retryScalingDuration,
                                maxRetryTimeout
                            );
                            return timer(retryContext.nextRetryAttempt);
                        })
                    );
                } else if (eligibleForRetry) {
                    retryContext.nextRetryAttempt = Math.min(
                        retryAttempt * retryScalingDuration,
                        maxRetryTimeout
                    );
                    result$ = timer(retryContext.nextRetryAttempt);
                }
            }
        }

        // handle on final error callback
        if (
            retryConfig.messageProviderOnFinalError &&
            this.notificationService
        ) {
            const { messageProviderOnFinalError } = retryConfig;
            const { notificationService } = this;
            result$ = result$.pipe(
                tap({
                    error: () => {
                        const finalErrorMessage = this.getErrorMessage(
                            messageProviderOnFinalError,
                            error
                        );
                        if (finalErrorMessage) {
                            notificationService.showError(finalErrorMessage);
                        }
                    }
                })
            );
        }

        if (this.errorHandlerConfig.errorCallback) {
            const { errorCallback } = this.errorHandlerConfig;

            result$ = result$.pipe(
                tap({
                    error: (localError) => {
                        try {
                            if (
                                !retryConfig.skipGlobalErrorNotificationCallback ||
                                !retryConfig.skipGlobalErrorNotificationCallback(
                                    localError
                                )
                            ) {
                                errorCallback(localError);
                            }
                        } catch (error2) {
                            // eslint-disable-next-line no-console
                            console.error(
                                'Failed calling errorCallback',
                                error2
                            );
                        }
                    }
                })
            );
        }

        if (retryConfig.errorCallback) {
            retryConfig.errorCallback(error, retryContext);
        }

        return result$;
    }

    private getErrorMessage(
        messageProvider: (error: unknown) => string,
        error: unknown
    ) {
        let currentMessage = messageProvider(error);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errorMessage = (error as any)?.message;
        if (this.errorHandlerConfig.appendRootErrorMessage && errorMessage) {
            currentMessage = `${currentMessage}<br>(root cause: ${errorMessage})`;
        }

        return currentMessage;
    }
}
