import { Identifiable } from '@mhp-immersive-exp/contracts/src/generic';

/**
 * Defines the ui-representation for a hotspot.
 */
export interface UiHotspot extends Identifiable {
    // relative coordinate from 0 (left-most) to 1 (right-most)
    x: number;
    // relative coordinate from 0 (top-most) to 1 (bottom-most)
    y: number;
}

export enum UiHotspotEventType {
    MOUSE_ENTER = 'MOUSE_ENTER',
    MOUSE_LEAVE = 'MOUSE_LEAVE',
    SELECT = 'SELECT'
}

/**
 * An event emitted by the ui happening on
 * a hotspot.
 */
export interface UiHotspotEvent<T extends UiHotspot> {
    hotspot: T;
    element: HTMLElement;
    type: UiHotspotEventType | string;
}
