/* eslint-disable @typescript-eslint/no-shadow */
import { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HashMap, TranslocoService, translate } from '@jsverse/transloco';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';

import {
    I18nSettings,
    I18nSettingsManager
} from './i18n-settings-manager.interface';

export interface LanguageDefinition {
    id: string;
    label: string;
}

export const I18N_SETTINGS_MANAGER_TOKEN = new InjectionToken<
    I18nSettingsManager<any>
>('I18nSettingsManager');

@Injectable({
    providedIn: 'root'
})
export class I18nService<T extends I18nSettings = I18nSettings> {
    constructor(
        private translocoService: TranslocoService,
        @Inject(I18N_SETTINGS_MANAGER_TOKEN)
        private settingsManager: I18nSettingsManager<T>,
        @Inject(DOCUMENT) private readonly document: Document
    ) {
        this.initActiveLanguageBinding();
    }

    static translateWithFallbackStatic<T>(
        key: string,
        fallback: T,
        parameters?: HashMap,
        language?: string
    ): string | T {
        const translation = translate(key, parameters, language);
        if (translation === key) {
            return fallback;
        }
        return translation;
    }

    /**
     * Select a translation for a key and receive updates when it changes.
     * @param key The translation key to be used.
     * @param parameters The parameters to be used for the translation.
     * @param language The language to be translated to. Defaults to the currently active language.
     */
    selectTranslate$(
        key: string,
        parameters?: HashMap,
        language?: string
    ): Observable<string> {
        return this.translocoService.selectTranslate(key, parameters, language);
    }

    /**
     * Select a translation-object for a key and receive updates when it changes.
     * @param key The translation key to be used.
     * @param parameters The parameters to be used for the translation.
     * @param language The language to be translated to. Defaults to the currently active language.
     */
    selectTranslateObject$<O = any>(
        key: string,
        parameters?: HashMap,
        language?: string
    ): Observable<O> {
        return this.translocoService.selectTranslateObject<O>(
            key,
            parameters,
            language
        );
    }

    /**
     * Select a translation for a key and receive updates when it changes.
     * @param key The translation key to be used.
     * @param fallback The fallback to be used when no translation is available.
     * @param parameters The parameters to be used for the translation.
     * @param language The language to be translated to. Defaults to the currently active language.
     */
    selectTranslateWithFallback$(
        key: string,
        fallback: string,
        parameters?: HashMap,
        language?: string
    ): Observable<string> {
        return this.translocoService
            .selectTranslate(key, parameters, language)
            .pipe(
                map((translation) => {
                    if (translation === key) {
                        return fallback;
                    }
                    return translation;
                })
            );
    }

    /**
     * Translates a given key using a fallback value in case no translation could be found.
     * @param key The translation key to be used.
     * @param fallback The fallback value to be used.
     * @param parameters The parameters to be used for the translation.
     * @param language The language to be translated to. Defaults to the currently active language.
     */
    translateWithFallback<T>(
        key: string,
        fallback: T,
        parameters?: HashMap,
        language?: string
    ): string | T {
        return I18nService.translateWithFallbackStatic(
            key,
            fallback,
            parameters,
            language
        );
    }

    /**
     * Get the currently active language.
     */
    @MemoizeObservable()
    getActiveLang$(): Observable<string> {
        return this.translocoService.selectTranslation().pipe(
            map(() => this.translocoService.getActiveLang()),
            distinctUntilChanged(),
            lazyShareReplay()
        );
    }

    /**
     * Update the currently active settings.
     * @param settings
     */
    updateSettings(settings: Partial<T>) {
        this.settingsManager.persistSettings(settings);
    }

    /**
     * Get a stream emitting the currently valid settings.
     */
    getSettings$(): Observable<T | undefined> {
        return this.settingsManager.loadSettings$();
    }

    /**
     * Get the available languages.
     */
    getAvailableLanguages(): LanguageDefinition[] {
        return <LanguageDefinition[]>this.translocoService.getAvailableLangs();
    }

    /**
     * May be used to pipe a translation obtained via #selectTranslate through
     * to use a fallback value instead of outputting the translation-key.
     * @param translationKey The translationKey that was used to obtain the translation.
     * @param fallbackValue The fallbackValue to be used when translation === translationKey.
     */
    mapWithFallback(
        translationKey: string,
        fallbackValue: string
    ): MonoTypeOperatorFunction<string> {
        return (source) =>
            source.pipe(
                map((translation) => {
                    if (translation === translationKey) {
                        return fallbackValue;
                    }
                    return translation;
                })
            );
    }

    /**
     * Bind the settings-manager state to transloco.
     * @private
     */
    private initActiveLanguageBinding() {
        this.settingsManager.loadSettings$().subscribe((settings) => {
            let checkedLanguage = settings?.language;
            if (!checkedLanguage) {
                const browserLanguage = navigator.language;
                if (
                    this.getAvailableLanguages().find(
                        (lang) => lang.id === browserLanguage
                    )
                ) {
                    checkedLanguage = browserLanguage;
                } else {
                    checkedLanguage = this.translocoService.getDefaultLang();
                }
            }
            this.translocoService.setActiveLang(checkedLanguage);

            // update html-lang attribute
            this.document.documentElement.lang = checkedLanguage;
        });
    }
}

/**
 * Delegates to I18nService#translateWithFallbackStatic
 *
 * @param key The translation key to be used..
 * @param fallback The fallback to be used if no translation exists for the given key.
 * @param parameters The parameters to be used for the translation.
 * @param language The language to be translated to. Defaults to the currently active language.
 */
export function translateWithFallback<T>(
    key: string,
    fallback: T,
    parameters?: HashMap,
    language?: string
): string | T {
    return I18nService.translateWithFallbackStatic(
        key,
        fallback,
        parameters,
        language
    );
}
