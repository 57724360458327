<div
    class="ui-img-comparison-slider__slider-container"
    [ngClass]="{
        'ui-img-comparison-slider__slider-container--fixed-ratio': !!fixedAspectRatio
    }"
>
    <img-comparison-slider
        #comparisonSlider
        [style]="
        '--handle-position-start: 85%; --divider-color: rgba(255, 255, 255 ,0); --default-handle-width: 36px; outline:none;'
        "
        handle="true"
        tabindex="-1"
    >
        <mhp-ui-lazyload-image
            slot="first"
            [imageUrls]="imageCurrentUrl"
            [willResize]="true"
            [aspectRatio]="fixedAspectRatio"
        />
        <mhp-ui-lazyload-image
            slot="second"
            [imageUrls]="imageNeutralUrl"
            [willResize]="true"
            [aspectRatio]="fixedAspectRatio"
        />
        <div
            slot="handle"
            class="ui-img-comparison-slider__slider-handle"
            [ngClass]="{
                'ui-img-comparison-slider__slider-handle--hidden': handleHidden
            }"
            [matTooltip]="dragIconLabel ?? ''"
            [matTooltipDisabled]="!dragIconLabel || (dragHandlerInteractedSubject | async) === true"
            [attr.aria-label]="dragIconLabel"
        >
            <mhp-ui-icon
                [svgIcon]="dragIconId"
                [size]="IconSize.LARGE"
            ></mhp-ui-icon>
        </div>
    </img-comparison-slider>

</div>
