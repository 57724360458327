import { Renderer2 } from '@angular/core';

/**
 * Attaches a script to the document head.
 * @param document The document to attach the script to.
 * @param renderer The render to use.
 * @param scriptSrcOrContent The script source or the content of the script.
 * @param options Additional options to apply
 */
export const attachScriptToDocumentHead = (
    document: Document,
    renderer: Renderer2,
    scriptSrcOrContent: string,
    options: {
        async?: boolean;
        contentAsSrcAttribute?: boolean;
    }
): HTMLScriptElement => {
    const script = renderer.createElement('script');

    if (!options.contentAsSrcAttribute) {
        const compressedText = renderer.createText(scriptSrcOrContent);
        renderer.appendChild(script, compressedText);
    } else {
        renderer.setAttribute(script, 'src', scriptSrcOrContent);
    }

    if (options.async) {
        renderer.setAttribute(script, 'async', '');
    }

    renderer.appendChild(document.head, script);

    return script;
};
