import { NgModule } from '@angular/core';

import { DEALER_HOOKS_TOKEN } from './dealer-hooks/dealer-hooks.interface';
import { NoopDealerHooksService } from './dealer-hooks/noop-dealer-hooks.service';

/**
 * This is a noop / placeholder module being used for regular ui-builds.
 */
@NgModule({
    declarations: [],
    imports: [],
    providers: [
        {
            provide: DEALER_HOOKS_TOKEN,
            useClass: NoopDealerHooksService
        }
    ]
})
export class NoopDealerModule {}
