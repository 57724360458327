<div
    class="ui-hotspots__hotspot"
    #hotspotRef
    *ngFor="
        let uiHotspot of getHotspotsWithAbsoluteCoordinates$() | async;
        trackBy: trackById;
        let i = index
    "
    [ngStyle]="{
        left: uiHotspot.x + 'px',
        top: uiHotspot.y + 'px'
    }"
    [attr.tabindex]="assignTabIndex ? 0 : -1"
    (click)="intentSelectHotspot(uiHotspot, hotspotRef, $event)"
    (keydown.enter)="intentSelectHotspot(uiHotspot, hotspotRef, $event)"
    (mouseleave)="onMouseLeaveHotspot(uiHotspot, hotspotRef, $event)"
    (mouseenter)="onMouseEnterHotspot(uiHotspot, hotspotRef, $event)"
>
    <ng-container
        *ngTemplateOutlet="
            hotspotTemplateRef;
            context: {
                uiHotspot: uiHotspot,
                uiHotspotElement: hotspotRef,
                index: i
            }
        "
    ></ng-container>

    <ng-content></ng-content>
</div>
