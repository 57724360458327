export const COUNTRY_TO_CURRENCY: Record<string, string> = {
    AE: 'EUR',
    AT: 'EUR',
    AU: 'AUD',
    AZ: 'EUR',
    BE: 'EUR',
    BH: 'EUR',
    BR: 'EUR',
    CA: 'CAD',
    CH: 'EUR',
    CL: 'USD',
    CN: 'CNY',
    CZ: 'EUR',
    DE: 'EUR',
    DK: 'EUR',
    EG: 'EUR',
    ES: 'EUR',
    FR: 'EUR',
    GR: 'EUR',
    GB: 'GBP',
    HK: 'GBP',
    HR: 'EUR',
    HU: 'EUR',
    ID: 'GBP',
    IL: 'EUR',
    IN: 'GBP',
    IT: 'EUR',
    JE: 'GBP',
    JO: 'EUR',
    JP: 'JPY',
    KH: 'GBP',
    KR: 'USD',
    KW: 'EUR',
    LB: 'EUR',
    LT: 'EUR',
    LU: 'EUR',
    LV: 'EUR',
    MA: 'EUR',
    MC: 'EUR',
    MX: 'USD',
    MY: 'GBP',
    NL: 'EUR',
    NO: 'EUR',
    NZ: 'GBP',
    OM: 'EUR',
    PE: 'USD',
    PH: 'USD',
    PL: 'EUR',
    PT: 'EUR',
    QA: 'EUR',
    RO: 'EUR',
    RU: 'EUR',
    SA: 'EUR',
    SE: 'EUR',
    SG: 'GBP',
    TH: 'GBP',
    TR: 'EUR',
    TW: 'GBP',
    UA: 'EUR',
    UK: 'GBP',
    US: 'USD',
    VN: 'GBP',
    ZA: 'EUR'
};
