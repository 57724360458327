/* eslint-disable max-classes-per-file */
import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    Directive,
    Input,
    OnInit,
    Optional
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { UiBaseComponent } from '../common';
import { ButtonSize } from '../ui-buttons';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'mhp-ui-mat-dialog-header'
})
export class UiMatDialogHeaderDirective {}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'mhp-ui-mat-dialog-content'
})
export class UiMatDialogContentDirective {}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'mhp-ui-mat-dialog-actions-left'
})
export class UiMatDialogActionsLeftDirective {}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'mhp-ui-mat-dialog-actions-right'
})
export class UiMatDialogActionsRightDirective {}

@Component({
    selector: 'mhp-ui-mat-dialog',
    templateUrl: './ui-mat-dialog.component.html',
    styleUrls: ['./ui-mat-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiMatDialogComponent extends UiBaseComponent implements OnInit {
    @Input()
    title: string;

    @Input()
    subtitle: string;

    @Input()
    showClose = true;

    @Input()
    closeIconSize = ButtonSize.SMALL;

    @ContentChild(UiMatDialogHeaderDirective)
    header: UiMatDialogHeaderDirective;

    @ContentChild(UiMatDialogActionsLeftDirective)
    actionsLeft: UiMatDialogActionsLeftDirective;

    @ContentChild(UiMatDialogActionsRightDirective)
    actionsRight: UiMatDialogActionsRightDirective;

    constructor(
        @Optional()
        private dialogRef: MatDialogRef<UiMatDialogComponent>
    ) {
        super();
    }

    ngOnInit() {
        if (!this.dialogRef) {
            return;
        }

        if (this.dialogRef.disableClose) {
            this.showClose = !this.dialogRef.disableClose;
        }
    }

    close() {
        if (this.dialogRef.disableClose) {
            return;
        }
        this.dialogRef.close();
    }
}
