import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    UiButtonModule,
    UiIconButtonModule,
    UiIconsModule,
    UiMatDialogModule,
    UiTooltipModule
} from '@mhp/ui-components';
import { I18nModule } from '@mhp/ui-shared-services';

import { HeaderBarModule } from '../header-bar/header-bar.module';
import { DialogBodyComponent } from './dialog-body/dialog-body.component';
import { DialogHeaderComponent } from './dialog-header/dialog-header.component';
import { FullscreenDialogComponent } from './fullscreen-dialog/fullscreen-dialog.component';
import { GenericTextDialogComponent } from './generic-text-dialog/generic-text-dialog.component';

@NgModule({
    declarations: [
        DialogHeaderComponent,
        DialogBodyComponent,
        FullscreenDialogComponent,
        GenericTextDialogComponent
    ],
    exports: [
        DialogHeaderComponent,
        DialogBodyComponent,
        FullscreenDialogComponent,
        UiMatDialogModule
    ],
    imports: [
        CommonModule,
        HeaderBarModule,
        I18nModule,

        UiIconButtonModule,
        UiButtonModule,
        UiMatDialogModule,
        UiIconsModule,
        UiTooltipModule
    ]
})
export class DialogModule {}
