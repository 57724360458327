/* eslint-disable no-underscore-dangle, max-classes-per-file */
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    Directive,
    ElementRef,
    EventEmitter,
    OnDestroy,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { IllegalStateError } from '@mhp/common';

import { UiBaseComponent } from '../../common/ui-base-component/ui-base.component';
import { UiExpansionPanelComponent } from '../ui-expansion-panel.component';

/**
 * Communicates its host-element to interested parties.
 */
@Directive({
    selector: '[mhpUiStickyHeaderIntersectionMarker]'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class IntersectionMarker implements OnDestroy {
    @Output()
    readonly elementRef = new ReplaySubject<ElementRef<HTMLElement>>(1);

    constructor(elementRef: ElementRef) {
        this.elementRef.next(elementRef);
    }

    ngOnDestroy(): void {
        this.elementRef.complete();
    }
}

/**
 * Can be used to wrap a UiExpansionPanelComponent to provide
 * sticky-header behavior.
 * Additionally, the UiExpansionPanelComponent#highlight property is set
 * according to the current stuck-status of the UiExpansionPanelComponent.
 */
@Component({
    selector: 'mhp-ui-sticky-header-expansion-panel',
    templateUrl: './ui-sticky-header-expansion-panel.component.html',
    styleUrls: ['./ui-sticky-header-expansion-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiStickyHeaderExpansionPanelComponent
    extends UiBaseComponent
    implements AfterViewInit, OnDestroy
{
    @Output()
    readonly stickyChange = new EventEmitter<boolean>();

    @ViewChild('uiExpansionPanelPrefix')
    panelPrefixTemplateRef?: TemplateRef<unknown>;

    @ViewChild('uiExpansionPanelHeaderPrefix')
    panelHeaderPrefixTemplateRef?: TemplateRef<unknown>;

    @ContentChild(UiExpansionPanelComponent)
    uiExpansionPanelComponentRef?: UiExpansionPanelComponent;

    panelIntersectionMarker: ElementRef<HTMLElement>;

    panelHeaderIntersectionMarker: ElementRef<HTMLElement>;

    constructor(private elementRef: ElementRef<HTMLElement>) {
        super();

        this.completeOnDestroy(this.stickyChange);
    }

    ngAfterViewInit(): void {
        if (
            this.uiExpansionPanelComponentRef &&
            (this.panelPrefixTemplateRef || this.panelHeaderPrefixTemplateRef)
        ) {
            this.uiExpansionPanelComponentRef.panelPrefix =
                this.panelPrefixTemplateRef;
            this.uiExpansionPanelComponentRef.headerPrefix =
                this.panelHeaderPrefixTemplateRef;
        }
    }

    onPanelIntersectionMarkerCreate(intersectionElementRef: ElementRef) {
        if (this.panelIntersectionMarker) {
            throw new IllegalStateError(
                'IntersectionMarker element already bound.'
            );
        }
        this.panelIntersectionMarker = intersectionElementRef;

        if (this.panelHeaderIntersectionMarker) {
            this.initStuckObservable();
        }
    }

    onPanelHeaderIntersectionMarkerCreate(intersectionElementRef: ElementRef) {
        if (this.panelHeaderIntersectionMarker) {
            throw new IllegalStateError(
                'IntersectionMarker element already bound.'
            );
        }
        this.panelHeaderIntersectionMarker = intersectionElementRef;

        if (this.panelIntersectionMarker) {
            this.initStuckObservable();
        }
    }

    private initStuckObservable() {
        const { offsetParent } = this.elementRef.nativeElement;

        const headerHightlight$ = new Observable<boolean>((subscriber) => {
            const intersectionObserver = new IntersectionObserver(
                (records) => {
                    let intersects = false;
                    // did the intersection-change happen on the top-border of the offset-parent?
                    let intersectionChangeAtTop = false;
                    for (const record of records) {
                        intersects = record.isIntersecting;
                        intersectionChangeAtTop = !!(
                            record.rootBounds &&
                            record.boundingClientRect.y <= record.rootBounds.y
                        );
                    }

                    // in case no intersection is detected, but the element is not attached to the DOM currently, do not change anything
                    if (
                        !intersects &&
                        !offsetParent?.contains(
                            this.panelIntersectionMarker.nativeElement
                        )
                    ) {
                        return;
                    }

                    // highlight, if the marker does NOT intersect (is not visible at the moment)
                    const doHighlight = !intersects && intersectionChangeAtTop;

                    subscriber.next(doHighlight);
                },
                {
                    threshold: [0],
                    root: offsetParent
                }
            );

            intersectionObserver.observe(
                this.panelIntersectionMarker.nativeElement
            );

            return () => {
                intersectionObserver.disconnect();
            };
        });

        const headerFullIntersection$ = new Observable<boolean>(
            (subscriber) => {
                const intersectionObserver = new IntersectionObserver(
                    (records) => {
                        let intersects = false;
                        for (const record of records) {
                            intersects = record.isIntersecting;
                        }

                        subscriber.next(intersects);
                    },
                    {
                        threshold: [1],
                        root: offsetParent
                    }
                );
                intersectionObserver.observe(
                    this.panelHeaderIntersectionMarker.nativeElement
                );

                return () => {
                    intersectionObserver.disconnect();
                };
            }
        );

        const aboveHeaderNoIntersection$ = new Observable<boolean>(
            (subscriber) => {
                const intersectionObserver = new IntersectionObserver(
                    (records) => {
                        let intersects = false;
                        for (const record of records) {
                            intersects = record.isIntersecting;
                        }

                        subscriber.next(!intersects);
                    },
                    {
                        threshold: [0, 1],
                        root: offsetParent
                    }
                );
                intersectionObserver.observe(
                    this.panelIntersectionMarker.nativeElement
                );

                return () => {
                    intersectionObserver.disconnect();
                };
            }
        );

        headerHightlight$
            .pipe(this.takeUntilDestroy())
            .subscribe((doHighlight) => {
                if (!this.uiExpansionPanelComponentRef) {
                    return;
                }

                this.uiExpansionPanelComponentRef.highlightWhenExpanded =
                    doHighlight;
                this.uiExpansionPanelComponentRef._detectChanges();
            });

        combineLatest([headerFullIntersection$, aboveHeaderNoIntersection$])
            .pipe(
                debounceTime(0),
                map(
                    ([headerFullIntersect, aboveHeaderNoIntersect]) =>
                        headerFullIntersect && aboveHeaderNoIntersect
                ),
                distinctUntilChanged(),
                this.takeUntilDestroy()
            )
            .subscribe(this.stickyChange);
    }
}
