import { first } from 'rxjs/operators';

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Inject,
    InjectionToken,
    Input,
    Optional,
    Output
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UiBaseComponent } from '@mhp/ui-components';

export enum DialogHeaderCloseIconPosition {
    START,
    END
}

/**
 * Config for DialogHeaderComponent.
 */
export interface DialogHeaderConfig {
    /**
     * The default close icon position.
     */
    defaultCloseIconPosition?: DialogHeaderCloseIconPosition;
}

export const DIALOG_HEADER_CONFIG_TOKEN =
    new InjectionToken<DialogHeaderConfig>('DialogHeaderConfig');

@Component({
    selector: 'mhp-dialog-header',
    templateUrl: './dialog-header.component.html',
    styleUrls: ['./dialog-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogHeaderComponent extends UiBaseComponent {
    @Input()
    disableClose = false;

    @Input()
    closeImplicit = true;

    @Input()
    hideTopGradient = false;

    @Input()
    hideLogo = false;

    @Input()
    closeIconPosition?: DialogHeaderCloseIconPosition;

    @Output()
    readonly dialogClose = new EventEmitter<void>();

    @HostBinding('class.dialog-header--show-top-gradient')
    get showTopGradient() {
        return !this.hideTopGradient;
    }

    protected readonly HeaderCloseIconPosition = DialogHeaderCloseIconPosition;

    protected readonly config?: DialogHeaderConfig;

    dialogShown = false;

    constructor(
        private readonly matDialogRef: MatDialogRef<DialogHeaderComponent>,
        private readonly changeDetectorRef: ChangeDetectorRef,
        @Optional()
        @Inject(DIALOG_HEADER_CONFIG_TOKEN)
        config?: DialogHeaderConfig
    ) {
        super();

        this.disableClose = !!matDialogRef.disableClose;

        this.config = config ?? {
            defaultCloseIconPosition: DialogHeaderCloseIconPosition.START
        };

        matDialogRef
            .afterOpened()
            .pipe(first(), this.takeUntilDestroy())
            .subscribe(() => {
                this.dialogShown = true;
                this.changeDetectorRef.detectChanges();
            });

        this.completeOnDestroy(this.dialogClose);
    }

    intentClose() {
        if (this.closeImplicit) {
            this.matDialogRef.close();
        }
        this.dialogClose.emit();
    }
}
