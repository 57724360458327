<mhp-header-bar
    *ngIf="showHeader"
    class="loading-indicator__header"
></mhp-header-bar>
<div class="loading-indicator__center-container">
    <ng-container *ngIf="showLoadingSpinnerWhenLoading">
        <mhp-loading-spinner
            *ngIf="progress === -1; else determinateLoading"
            class="loading-indicator__spinner"
            aria-hidden="true"
        ></mhp-loading-spinner>
        <ng-template #determinateLoading>
            <div class="loading-indicator__progress-spinner__wrapper">
                <mat-progress-spinner
                    [color]="progressSpinnerColor"
                    [mode]="progressSpinnerMode"
                    [value]="
                        progress < progressSpinnerMinimumProgress
                            ? 10
                            : progress * 100
                    "
                    diameter="30"
                    strokeWidth="2"
                    class="loading-indicator__progress-spinner__spinner"
                    aria-hidden="true"
                ></mat-progress-spinner>
                <div
                    class="loading-indicator__progress-spinner__background"
                ></div>
            </div>
        </ng-template>
    </ng-container>

    <ng-content select="[loading-indicator-above-status-text]"></ng-content>
    <p
        *ngIf="statusText"
        class="loading-indicator__status-text"
        [innerHTML]="statusText"
    ></p>
    <ng-content select="[loading-indicator-below-status-text]"></ng-content>
</div>
