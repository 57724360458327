import { createAction, props } from '@ngrx/store';

/**
 * Set the active salesforce transaction id.
 */
export const setActiveSalesforceTransactionKey = createAction(
    '[LOCAL-STATE]: setActiveSalesforceTransactionKey',
    props<{ transactionKey: string }>()
);

/**
 * Clear the active salesforce transaction id.
 */
export const clearActiveSalesforceTransactionKey = createAction(
    '[LOCAL-STATE]: clearActiveSalesforceTransactionKey'
);
