import {
    getCountryNameByCountryISOCode,
    getListOfCountryISOCodes,
    getTerritoryCodeByCountryISOCode
} from '@mhp/aml-shared/country-mapping/country-mapping';
import { IllegalStateError } from '@mhp/common';

import { RegionDefinition } from './region.interfaces';

export const REGION_GLOBAL = 'INT';

export const AVAILABLE_REGIONS: RegionDefinition[] =
    getListOfCountryISOCodes().map((isoCode) => {
        const niceName = getCountryNameByCountryISOCode(isoCode);
        const territory = getTerritoryCodeByCountryISOCode(isoCode);

        if (!niceName || !territory) {
            throw new IllegalStateError(
                `Missing either niceName or territory for iso-code ${isoCode}`
            );
        }

        return {
            id: isoCode,
            niceName,
            territory
        };
    });
