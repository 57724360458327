import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApplicationState } from '@mhp/communication-models';

import { enhanceSelector } from '../helpers/selector-helper';
import { UiGlobalApplicationState } from '../ui-global-application-state.interface';

const selectEngineApplicationStateFn = <T>(
    state: UiGlobalApplicationState<T>
) => state.engine;

const selectEngineConfigurationStateFn = (state: ApplicationState) =>
    state.configurationState;

const selectEngineSettingsStateFn = (state: ApplicationState) =>
    state.settingsState;

export const selectEngineApplicationState = enhanceSelector<
    UiGlobalApplicationState<any>,
    ApplicationState
>(pipe(map(selectEngineApplicationStateFn)));

/**
 * Selects the ApplicationState#engineState subbranch.
 */
export const selectEngineState = enhanceSelector(
    pipe(
        selectEngineApplicationState,
        map((appState) => appState.engineState)
    ),
    {
        distinctUntilChanged: true
    }
);

export const selectConfigurationState = enhanceSelector(
    pipe(selectEngineApplicationState, map(selectEngineConfigurationStateFn)),
    {
        distinctUntilChanged: true
    }
);

export const selectSettingsState = enhanceSelector(
    pipe(selectEngineApplicationState, map(selectEngineSettingsStateFn)),
    {
        distinctUntilChanged: true
    }
);

export const selectApplicationStateMachineState = enhanceSelector(
    pipe(
        selectEngineApplicationState,
        map((engineState) => engineState.applicationStateMachineState)
    ),
    {
        distinctUntilChanged: true
    }
);
