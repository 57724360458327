import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UiScrollIntoViewDirective } from './ui-scroll-into-view.directive';

@NgModule({
    declarations: [UiScrollIntoViewDirective],
    imports: [CommonModule],
    exports: [UiScrollIntoViewDirective]
})
export class UiScrollIntoViewModule {}
