<div
    #turntable
    class="turntable"
    [ngClass]="{
        'turntable--preloading': allImagesLoaded() === false,
        'turntable--drag-behavior-active': interactionStrategy === undefined
    }"
>
    <div
        class="turntable__step"
        *ngFor="
            let turntableImage of renderImages$ | async;
            trackBy: imageTrackBy
        "
        [ngClass]="{
            'turntable__step--active': turntableImage.step === currentStep
        }"
    >
        <img
            srcset="{{ turntableImage | uiImageSrcset }}"
            sizes="100vw"
            alt="Turntable-Image"
            decoding="sync"
            (load)="onImageLoaded()"
            (error)="onImageLoadError(turntableImage)"
        />
    </div>
</div>
