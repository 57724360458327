/* eslint-disable no-underscore-dangle, max-classes-per-file */
import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    Directive,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'mhp-ui-expansion-panel-prefix',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'mhp-ui-expansion-panel-prefix'
    }
})
export class UiExpansionPanelPrefixDirective {}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'mhp-ui-expansion-panel-header',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'mhp-ui-expansion-panel-header'
    }
})
export class UiExpansionPanelHeaderDirective {}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'mhp-ui-expansion-panel-body',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'mhp-ui-expansion-panel-body'
    }
})
export class UiExpansionPanelBodyDirective {}

@Component({
    selector: 'mhp-ui-expansion-panel',
    templateUrl: './ui-expansion-panel.component.html',
    styleUrls: ['./ui-expansion-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiExpansionPanelComponent
    implements AfterContentInit, AfterViewInit
{
    @Input()
    title: string;

    @Input()
    subtitle?: string;

    @Input()
    expanded = false;

    @Input()
    contentChanged = false;

    @Input()
    headerHeight = 40;

    @Input()
    highlight: boolean;

    @Input()
    highlightWhenExpanded: boolean;

    @Input()
    panelPrefix?: TemplateRef<unknown>;

    @Input()
    headerPrefix?: TemplateRef<unknown>;

    @Output()
    readonly opened = new EventEmitter<void>();

    @Output()
    readonly closed = new EventEmitter<void>();

    @ViewChild(MatExpansionPanel, { static: true })
    matExpansionPanel: MatExpansionPanel;

    @ContentChild(UiExpansionPanelHeaderDirective)
    customHeader: UiExpansionPanelHeaderDirective;

    _hasCustomHeader = false;

    initialized = false;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    ngAfterViewInit() {
        setTimeout(() => {
            this.initialized = true;
        });
    }

    ngAfterContentInit() {
        this._hasCustomHeader = !!this.customHeader;
    }

    onOpened() {
        this.expanded = true;
        this.opened.emit();
    }

    onClosed() {
        this.expanded = false;
        this.closed.emit();
    }

    /**
     * Meant for internal use only.
     */
    _detectChanges() {
        this.changeDetectorRef.detectChanges();
    }
}
