export enum MenuScope {
    MODEL_SELECTION = 'MODEL_SELECTION',
    CONFIGURATION = 'CONFIGURATION'
}

export interface MenuState {
    menuActive: boolean;
    activeMenuScope: MenuScope;
    activeMenuEntry?: string;
}
