/* eslint-disable max-classes-per-file */
import { WebsocketError } from '@mhp-immersive-exp/contracts/src/websocket/websocket-error';
import { CustomError } from '@mhp/common';

/**
 * Error thrown in case an error occurs while processing a request.
 */
export class RequestError extends CustomError {
    constructor(message: string, details?: any) {
        super(message, details);
    }
}

/**
 * Error thrown in case an error occurs during connection.
 */
export class WebsocketConnectionUnavailableError extends CustomError {
    constructor(message: string, details?: any) {
        super(message, details);
    }
}

export class WebsocketRequestError extends CustomError<WebsocketError> {
    constructor(message: string, source: WebsocketError) {
        super(message, source);
    }
}

/**
 * Error thrown in case a timeout happens while waiting for a response.
 */
export class WebsocketRequestTimeoutError extends WebsocketRequestError {
    constructor(message: string, source: WebsocketError) {
        super(message, source);
    }
}

/**
 * Error thrown in case an rpc-endpoint is not available.
 */
export class WebsocketEndpointUnavailableError extends WebsocketRequestError {
    constructor(message: string, source: WebsocketError) {
        super(message, source);
    }
}

/**
 * Error thrown in case an error is thrown by the endpoint.
 */
export class WebsocketEndpointResponseError extends WebsocketRequestError {
    constructor(message: string, source: WebsocketError) {
        super(message, source);
    }
}
