import { Observable } from 'rxjs';

import { Portal } from '@angular/cdk/portal';
import { InjectionToken } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { UiNavigationEntry } from '@mhp/ui-components';

import { FinancialServiceHooks } from './financial-services/financial-service-hooks.interface';

export interface DealerHooks {
    /**
     * Logs the dealer out of the application. Needs to be subscribed to.
     */
    intentLogout$(): Observable<true>;

    /**
     * Show the submit to salesforce / voe diaolg
     */
    openSubmitToSalesforceOrVoeDialog$(): Observable<void>;

    getConfigurationMainOne2OneSessionControl$(): Observable<
        Portal<any> | undefined
    >;

    adjustSidebarItems(
        sidebarItems: UiNavigationEntry<string>[],
        context: 'MODEL-SELECT' | 'CONFIGURATION-MENU' | 'ORDER-MANAGEMENT'
    ): UiNavigationEntry<string>[];

    /**
     * Provide the ability to handle a sidebar-item selection.
     * @return true in case the selection was handled, false in case not.
     */
    handleSidebarItemSelect(
        itemId: string,
        context: 'MODEL-SELECT' | 'CONFIGURATION-MENU' | 'ORDER-MANAGEMENT'
    ): boolean;

    handleDealerStartsSession$(route: ActivatedRouteSnapshot): Observable<void>;

    /**
     * Get the hooks for financial services integration.
     */
    getFinancialServiceHooks(): FinancialServiceHooks;
}

export const DEALER_HOOKS_TOKEN = new InjectionToken<DealerHooks>(
    'DealerHooks'
);
