import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MemoizeObservable } from '@mhp/common';
import { ErrorHandlerService } from '@mhp/ui-shared-services';

import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class IpLookupService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly errorHandlerService: ErrorHandlerService
    ) {}

    /**
     * Lookup the ISO-2 country code based on the users IP.
     */
    @MemoizeObservable()
    lookupCountry$(): Observable<string | undefined> {
        return this.httpClient
            .get<{ country?: string }>(
                `${environment.appConfig.dataProxy.url}/api/country`
            )
            .pipe(
                this.errorHandlerService.applyRetry({
                    skipGlobalErrorNotificationCallback: (error) =>
                        error instanceof HttpErrorResponse &&
                        error.status === 404
                }),
                map((response) => response?.country),
                shareReplay(1) // perform the lookup only once
            );
    }
}
