import { applyPatch, compare } from 'fast-json-patch';
import { cloneDeep, isEmpty, isEqual } from 'lodash-es';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, first, map, switchMap, timeout } from 'rxjs/operators';

import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import {
    PatchUiStateRequestPayload,
    REQUEST_PATCH_UI_STATE
} from '@mhp-immersive-exp/contracts/src/ui/patch-ui-state-request.interface';
import { Store } from '@ngrx/store';

import { UiGlobalApplicationState, UiStateReducer } from '../..';
import { SocketIOService } from '../../../communication';
import { EngineStatePatchService } from '../../../engine/engine-state-patch/engine-state-patch.service';
import { ErrorHandlerService } from '../../../error-handler/error-handler.service';
import { UiSharedStateStrategy } from '../ui-shared-state-strategy.interface';

export interface UiSharedStateSocketStrategyConfig {
    waitForStateAckTimeout: number;
}

export const UI_SHARED_STATE_SOCKET_STRATEGY_CONFIG_TOKEN =
    new InjectionToken<UiSharedStateSocketStrategyConfig>(
        'UiSharedStateSocketStrategyConfig'
    );
export const DEFAULT_WAIT_FOR_STATE_ACK_TIMEOUT = 10000;

/**
 * UiSharedStateStrategy implementation based on socket-io calls.
 * To be used when application is used in context where engine is available.
 */
@Injectable()
export class UiSharedStateSocketStrategy<UiStateType>
    implements UiSharedStateStrategy<UiStateType>
{
    constructor(
        private readonly store: Store<UiGlobalApplicationState<any>>,
        private readonly socketIoService: SocketIOService,
        private readonly engineStatePatchService: EngineStatePatchService,
        private readonly errorHandlerService: ErrorHandlerService,
        @Optional()
        @Inject(UI_SHARED_STATE_SOCKET_STRATEGY_CONFIG_TOKEN)
        private config?: UiSharedStateSocketStrategyConfig
    ) {}

    reduceUiState$(
        reducer: UiStateReducer<UiStateType>
    ): Observable<UiStateReducer<UiStateType>> {
        return combineLatest([
            this.store.select('engine', 'uiState'),
            this.engineStatePatchService
                .getEngineStatePatchActiveState$()
                .pipe(filter((active) => active))
        ]).pipe(
            first(),
            switchMap(([uiState]) => {
                const uiStateClone: UiStateType = cloneDeep(uiState || {});

                const reducedUiState = reducer.reducerCallback(uiStateClone);

                const patch = compare(uiState || {}, reducedUiState || {});

                if (isEmpty(patch)) {
                    // nothing to do, operation was a noop..
                    return of(reducer);
                }

                return this.socketIoService
                    .request<PatchUiStateRequestPayload, Record<string, never>>(
                        REQUEST_PATCH_UI_STATE,
                        {
                            patch
                        }
                    )
                    .pipe(
                        this.errorHandlerService.applyRetry(),
                        map(
                            () =>
                                applyPatch(cloneDeep(uiState), patch)
                                    .newDocument
                        )
                    )
                    .pipe(
                        switchMap((expectedUiState) => 
                            // as the patch is broadcasted by the engine by patching the application state, wait for a change in the uiState until it equals the uiState we expect from the patch
                             this.store.select('engine', 'uiState').pipe(
                                filter((latestUiState) =>
                                    isEqual(expectedUiState, latestUiState)
                                ),
                                timeout(
                                    (this.config &&
                                        this.config.waitForStateAckTimeout) ||
                                        DEFAULT_WAIT_FOR_STATE_ACK_TIMEOUT
                                ),
                                first(),
                                map(() => reducer)
                            )
                        )
                    );
            })
        );
    }
}
