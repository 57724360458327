import {
    SlidingTimeWindowBasedCutEventHandlerConfig,
    SlidingTimeWindowBasedStreamingEventHandler
} from './sliding-time-window-based-streaming-event-handler';
import {
    ExtendedStreamingEventHandler,
    ExtendedStreamingEventHandlerFactory
} from './streaming-event-handler.interfaces';

/**
 * Allows collection of emitted LimitData events over a configurable time-window and basing
 * the decision if the stream needs to be cut or not based on average values collected in this time-window.
 */
export class SlidingTimeWindowBasedStreamingEventHandlerFactory
    implements ExtendedStreamingEventHandlerFactory
{
    constructor(
        private readonly config: SlidingTimeWindowBasedCutEventHandlerConfig
    ) {}

    buildExtendedStreamingEventHandler(): ExtendedStreamingEventHandler {
        return new SlidingTimeWindowBasedStreamingEventHandler(this.config);
    }
}
