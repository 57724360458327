import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

import { UiToastComponent } from './ui-toast.component';

export interface UiToastServiceConfig {
    autohideDuration: number;
    easingTime: number;
    closeIconId: string;
}

export const UI_TOAST_SERVICE_CONFIG = new InjectionToken<UiToastServiceConfig>(
    'UiToastServiceConfig'
);

export interface UiToastConfig {
    autohideDuration?: number;
    color?: ThemePalette;
    showClose?: boolean;
}

export interface ToastHandle {
    toastId: number;
    close: () => void;
    onShown: () => Observable<unknown>;
    onClosed: () => Observable<unknown>;
    onTap: () => Observable<unknown>;
}

/**
 * Service to allow showing toast-style notifications.
 */
@Injectable({
    providedIn: 'root'
})
export class UiToastService {
    constructor(
        private toastrService: ToastrService,
        @Inject(UI_TOAST_SERVICE_CONFIG) private config: UiToastServiceConfig
    ) {}

    /**
     * Show a toast that is closed after a configurable timeout.
     * @param message The message to show
     * @param iconId The icon to show.
     * @param config Additional config.
     */
    showAutohide(
        message: string,
        iconId?: string,
        config?: UiToastConfig
    ): ToastHandle {
        const adjustedConfig: Partial<IndividualConfig> = {
            timeOut: config?.autohideDuration || this.config.autohideDuration,
            easeTime: this.config.easingTime
        };

        const toast = this.toastrService.show(
            message,
            undefined,
            adjustedConfig
        );
        return this.initToast(toast, iconId, config);
    }

    /**
     * Show a toast that is closed only on tap.
     * @param message The message to show
     * @param iconId The icon to show.
     * @param config Additional config.
     */
    showPersistent(message: string, iconId?: string, config?: UiToastConfig) {
        const adjustedConfig: Partial<IndividualConfig> = {
            disableTimeOut: true,
            easeTime: this.config.easingTime
        };

        const toast = this.toastrService.show(
            message,
            undefined,
            adjustedConfig
        );

        return this.initToast(toast, iconId, config);
    }

    private initToast(
        toast: ActiveToast<UiToastComponent>,
        iconId: string | undefined,
        config?: UiToastConfig
    ): ToastHandle {
        const toastComponentInstance = <UiToastComponent>(
            toast.toastRef.componentInstance
        );
        toastComponentInstance.closeIconId = this.config.closeIconId;
        toastComponentInstance.iconId = iconId;
        toastComponentInstance.color = config?.color;
        toastComponentInstance.showClose = config?.showClose;

        return {
            toastId: toast.toastId,
            close() {
                toast.toastRef.close();
            },
            onClosed() {
                return toast.onHidden;
            },
            onShown() {
                return toast.onShown;
            },
            onTap() {
                return toast.onTap;
            }
        };
    }
}
