import { EMPTY, Observable, of } from 'rxjs';

import { Portal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Memoize, MemoizeObservable } from '@mhp/common';
import { UiNavigationEntry } from '@mhp/ui-components';

import {
    FinancialServiceOfferBase,
    FinancialServiceOfferInputBase,
    OfferRow
} from '../../configuration/financial-services/financial-services.interfaces';
import { DealerHooks } from './dealer-hooks.interface';
import { FinancialServiceHooks } from './financial-services/financial-service-hooks.interface';

/**
 * Noop implementation of DealerHooks interface.
 */
@Injectable()
export class NoopDealerHooksService implements DealerHooks {
    intentLogout$(): Observable<true> {
        return EMPTY;
    }

    openSubmitToSalesforceOrVoeDialog$(): Observable<void> {
        return EMPTY;
    }

    @MemoizeObservable()
    getConfigurationMainOne2OneSessionControl$(): Observable<
        Portal<any> | undefined
    > {
        return of(undefined);
    }

    adjustSidebarItems(
        sidebarItems: UiNavigationEntry<string>[],
        context: 'MODEL-SELECT' | 'CONFIGURATION-MENU' | 'ORDER-MANAGEMENT'
    ): UiNavigationEntry<string>[] {
        return sidebarItems;
    }

    handleSidebarItemSelect(
        itemId: string,
        context: 'MODEL-SELECT' | 'CONFIGURATION-MENU' | 'ORDER-MANAGEMENT'
    ): boolean {
        return false;
    }

    handleDealerStartsSession$(
        route: ActivatedRouteSnapshot
    ): Observable<void> {
        return EMPTY;
    }

    @Memoize()
    getFinancialServiceHooks(): FinancialServiceHooks {
        return Object.freeze({
            isFinancialServiceOptionAvailable$(): Observable<boolean> {
                return of(false);
            },

            showFinancialServicesOptions$(
                context: Pick<MatDialogConfig, 'viewContainerRef'>
            ) {
                return EMPTY;
            },

            getFinancialServicesOfferInfo$(): Observable<{
                offerInput: FinancialServiceOfferInputBase | undefined;
                offer: FinancialServiceOfferBase | undefined;
            }> {
                return of({
                    offerInput: undefined,
                    offer: undefined
                });
            },

            getFinancialServicesOfferRows$(): Observable<
                OfferRow[] | undefined
            > {
                return of(undefined);
            }
        });
    }
}
