import { Action, createReducer, on } from '@ngrx/store';

import {
    clearActiveLoadingState,
    clearActiveLoadingStateIfInContext,
    setActiveLoadingState
} from '../actions/loading-state.actions';
import { LoadingState } from '../loading-state.interface';

const INITIAL_STATE: LoadingState = {
    loading: false
};

const reducer = createReducer<LoadingState | undefined>(
    INITIAL_STATE,
    on(setActiveLoadingState, (state: LoadingState, payload) => {
        const {
            loading,
            statusText,
            showLoadingSpinnerWhenLoading,
            fullOpaque,
            context,
            showHeader,
            zIndex
        } = payload;
        return {
            loading,
            statusText,
            showLoadingSpinnerWhenLoading,
            fullOpaque,
            context,
            showHeader,
            zIndex
        };
    }),
    on(clearActiveLoadingState, (state: LoadingState) => ({
        loading: false
    })),
    on(clearActiveLoadingStateIfInContext, (state: LoadingState, payload) =>
        state.context === payload.context
            ? {
                  loading: false
              }
            : state
    )
);

export function loadingStateReducer(
    state: LoadingState | undefined,
    action: Action
) {
    return reducer(state, action);
}
