import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import { LocalSharedApplicationState } from '../../../application-state';
import { enhanceSelector } from '../../../application-state/helpers';
import { selectConnectionState } from '../../../connection-state';

/**
 * Select the current connection-state to the engine.
 */
export const selectEngineConnectionState = enhanceSelector<
    LocalSharedApplicationState,
    boolean | undefined
>(
    pipe(
        selectConnectionState,
        map((state) => state.engineConnected)
    ),
    {
        distinctUntilChanged: true
    }
);

/**
 * Select the current connection-state to the hub.
 */
export const selectHubConnectionState = enhanceSelector<
    LocalSharedApplicationState,
    boolean | undefined
>(
    pipe(
        selectConnectionState,
        map((state) => state.hubConnected)
    ),
    {
        distinctUntilChanged: true
    }
);
