<div
    [@.disabled]="!initialized"
    class="expansion-panel"
    [ngClass]="{
        'expansion-panel--content-changed': contentChanged,
        'expansion-panel--expanded': expanded,
        'expansion-panel--custom-header': _hasCustomHeader,
        'expansion-panel--highlight':
            highlight || (highlightWhenExpanded && expanded)
    }"
>
    <ng-container *ngTemplateOutlet="panelPrefix"></ng-container>
    <mat-expansion-panel
        class="mat-elevation-z0"
        [hideToggle]="true"
        [expanded]="expanded"
        (opened)="onOpened()"
        (closed)="onClosed()"
    >
        <mat-expansion-panel-header
            [collapsedHeight]="headerHeight + 'px'"
            [expandedHeight]="headerHeight + 'px'"
        >
            <ng-container *ngTemplateOutlet="headerPrefix"></ng-container>
            <div *ngIf="!_hasCustomHeader" class="expansion-panel__header">
                <div
                    class="expansion-panel__expansion-state-indicator"
                    mat-ripple
                    matRippleCentered="true"
                >
                    <mhp-ui-icon svgIcon="mhp-ui:angle-right"></mhp-ui-icon>
                </div>
                <div class="expansion-panel__header-info">
                    <div class="expansion-panel__title">{{ title }}</div>
                    <div *ngIf="subtitle" class="expansion-panel__subtitle">
                        {{ subtitle }}
                    </div>
                </div>
            </div>
            <ng-content select="mhp-ui-expansion-panel-header"></ng-content>
        </mat-expansion-panel-header>
        <!-- Evaluate lazy-loading of content -->
        <ng-content select="mhp-ui-expansion-panel-body"></ng-content>
    </mat-expansion-panel>
</div>
