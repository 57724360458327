import { Observable, asyncScheduler, fromEvent, merge } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';

import { environment } from '../../../environments/environment';
import { ActivityReport } from '../../common/inactivity-tracking/activity-handler';
import { InactivityService } from '../../common/inactivity-tracking/inactivity.service';

const USER_INACTIVITY_TRACKING_ID = 'USER_INACTIVITY';

/**
 * Service providing information about user-inactivity.
 */
@Injectable({
    providedIn: 'root'
})
export class UserInactivityService {
    constructor(
        private readonly inactivityService: InactivityService,
        @Inject(DOCUMENT) private readonly document: Document
    ) {}

    /**
     * Get the stream which emits when the user has been inactive for
     * the configured amount of time.
     */
    @MemoizeObservable()
    getUserInactivity$(): Observable<void> {
        return new Observable<void>((subscriber) => {
            const handler = this.inactivityService.startInactivityTracking(
                USER_INACTIVITY_TRACKING_ID,
                {
                    inactivityTimeout:
                        environment.appConfig.stream.userInactivityTimeout
                }
            );

            handler.registerActivityProvider(
                this.createUserActivityProvider$()
            );

            const subscription = this.inactivityService
                .getInactivityStream$(USER_INACTIVITY_TRACKING_ID)
                .subscribe(subscriber);

            return () => {
                subscription.unsubscribe();
                this.inactivityService.stopInactivityTracking(
                    USER_INACTIVITY_TRACKING_ID
                );
            };
        }).pipe(lazyShareReplay());
    }

    private createUserActivityProvider$(): Observable<ActivityReport> {
        return merge(
            ...['mousemove', 'mousedown', 'touchstart', 'focus', 'keydown'].map(
                (eventName) => fromEvent(this.document, eventName)
            )
        ).pipe(
            throttleTime(200, asyncScheduler, {
                leading: false,
                trailing: true
            }),
            map(() => ({
                type: 'USER_ACTION'
            }))
        );
    }
}
