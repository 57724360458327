import SwiperCore from 'swiper';
import { SwiperModule } from 'swiper/angular';
import { SwiperModule as ISwiperModule } from 'swiper/types';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
    declarations: [],
    imports: [CommonModule, SwiperModule],
    exports: [SwiperModule]
})
export class UiSwiperModule {
    static registerFeatures(...modules: ISwiperModule[]) {
        SwiperCore.use(modules);
    }
}
