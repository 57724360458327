import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UiPreventNestedRippleDirective } from './ui-prevent-nested-ripple.directive';

@NgModule({
    declarations: [UiPreventNestedRippleDirective],
    exports: [UiPreventNestedRippleDirective],
    imports: [CommonModule]
})
export class UiPreventNestedRippleModule {}
