import { Identifiable } from '@mhp-immersive-exp/contracts/src';

export const EVENT_MIRROR_MODE_SELECT_OPTION = 'mirrormodeselectoption';
export type MirrorModeSelectOptionEventPayload = Identifiable;

export const EVENT_MIRROR_MODE_CHANGE_CATEGORY = 'mirrormodechangecategory';

/**
 * Payload when sending out a category-change event.
 * It might simply tell the direction in which to move next or a dedicated id.
 * One of the two has to be set. In case both are set, id takes precedence.
 */
export interface MirrorModeChangeCategoryEventPayload {
    // 0 -> previous, 1 -> next
    direction?: 0 | 1;
    // the category-id to select
    id?: string;
}
