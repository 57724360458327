import { isEqual } from 'lodash-es';
import { Observable, combineLatest, switchMap } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MemoizeObservable } from '@mhp/common';
import { UiNotificationService } from '@mhp/ui-components';
import {
    ApplicationStateService,
    I18nService,
    L10nService,
    gtmGA4SetDataLayerProps
} from '@mhp/ui-shared-services';
import { RecaptchaErrorHandlerService } from '@mhp/ui-shared-services/recaptcha/recaptcha-error-handler.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StorageMap } from '@ngx-pwa/local-storage';

import { VERSION } from '../version';
import { LoadingState } from './common/loading-indicator/state/loading-state.interface';
import { setNavigationToSavedConfigurationActive } from './configuration/state/actions/configuration.actions';
import { LocalApplicationState } from './state/local-application-state.interface';

@UntilDestroy()
@Component({
    selector: 'mhp-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
    constructor(
        private readonly storage: StorageMap,
        private readonly router: Router,
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly recaptchaErrorHandlerService: RecaptchaErrorHandlerService,
        private readonly uiNotificationService: UiNotificationService,
        private readonly i18nService: I18nService,
        private readonly l10nService: L10nService,
        @Inject(DOCUMENT) private readonly document: Document
    ) {
        this.initUserAgentScrollbarRenderTypeCheck();
        this.cleanupNavigationWhenLoadingCode();
        this.initRecaptchaRootErrorHandling();
        this.initAnalyticsRootPropertiesTracking();

        console.log(`Running version ${VERSION.raw}`, VERSION);
    }

    @MemoizeObservable()
    getLoadingLayerState$(): Observable<LoadingState> {
        return this.applicationStateService.getLocalState().pipe(
            map((state) => state.loading),
            distinctUntilChanged(isEqual)
        );
    }

    /**
     * To support styling scrollbars only when required, check if user-agent renders scrollbars at all.
     * See https://www.filamentgroup.com/lab/scrollbars/
     */
    private initUserAgentScrollbarRenderTypeCheck() {
        const parent = this.document.createElement('div');
        parent.setAttribute('style', 'width:30px;height:30px;overflow:scroll');

        const child = this.document.createElement<'div'>('div');
        child.setAttribute('style', 'width:100%;height:40px');
        parent.appendChild(child);
        this.document.body.appendChild(parent);

        const scrollbarWidth = 30 - child.clientWidth;
        if (scrollbarWidth) {
            this.document.documentElement.classList.add('has-scrollbars');
        }
        this.document.body.removeChild(parent);
    }

    /**
     * Make sure that the flag that indicates if a configuration is currently loading using a code is set to false after the navigation finishes
     */
    private cleanupNavigationWhenLoadingCode() {
        this.router.events
            .pipe(
                filter((e) => e instanceof NavigationEnd),
                tap(() => {
                    this.applicationStateService.dispatch(
                        setNavigationToSavedConfigurationActive({
                            navigationToSavedConfigurationActive: false
                        })
                    );
                })
            )
            .subscribe();
    }

    /**
     * Handles a special recaptcha-error that rarely occurs and is related
     * to network connection issues.
     * See https://mhpimmersive.atlassian.net/browse/AMR-519
     * @private
     */
    private initRecaptchaRootErrorHandling() {
        this.recaptchaErrorHandlerService
            .startRecaptchaConnectionErrorHandling$()
            .pipe(
                untilDestroyed(this),
                switchMap((error) =>
                    this.uiNotificationService
                        .showError(
                            error.message,
                            this.i18nService.translateWithFallback(
                                'COMMON.RELOAD',
                                'Reload'
                            )
                        )
                        .afterDismissed()
                )
            )
            .subscribe((dismiss) => {
                if (!dismiss.dismissedByAction) {
                    return;
                }

                // reload application
                document.location.reload();
            });
    }

    /**
     * Init updating the basic tracking-properties used for GA.
     * @private
     */
    private initAnalyticsRootPropertiesTracking() {
        // initialize datalayer-properties
        gtmGA4SetDataLayerProps({
            page_title: 'Aston Martin Configurator',
            url: window.location.hostname,
            browser_language: navigator.language
        });

        // keep region and language updated
        combineLatest([
            this.l10nService.getActiveCountry$(),
            this.i18nService.getActiveLang$()
        ])
            .pipe(untilDestroyed(this))
            .subscribe(([region, language]) => {
                if (!region) {
                    return;
                }

                gtmGA4SetDataLayerProps({
                    region,
                    language
                });
            });
    }
}
