import { createAction, props } from '@ngrx/store';

import { LoadingState } from '../loading-state.interface';

/**
 * Set the active loading-state.
 */
export const setActiveLoadingState = createAction(
    '[LOCAL-STATE]: setActiveLoadingState',
    props<LoadingState>()
);

/**
 * Clear the active loading-state.
 */
export const clearActiveLoadingState = createAction(
    '[LOCAL-STATE]: clearActiveLoadingState'
);

/**
 * Clear the active loading-state when currently in correct context.
 */
export const clearActiveLoadingStateIfInContext = createAction(
    '[LOCAL-STATE]: clearActiveLoadingStateIfInContext',
    props<{
        context: any;
    }>()
);
