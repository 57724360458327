import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Input,
    Output,
    Type,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { UiBaseComponent } from '@mhp/ui-components';

@Component({
    selector: 'mhp-fullscreen-dialog',
    templateUrl: './fullscreen-dialog.component.html',
    styleUrls: ['./fullscreen-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullscreenDialogComponent
    extends UiBaseComponent
    implements AfterViewInit
{
    @Input()
    component: Type<any>;

    @Input()
    wrapInDialogBody = true;

    @Input()
    hideTopGradient: boolean;

    @Input()
    hideLogo = false;

    @Input()
    closeImplicit = true;

    @Output()
    readonly dialogClose = new EventEmitter<void>();

    @ViewChild('componentOutlet', { static: false, read: ViewContainerRef })
    componentOutletViewContainerRef: ViewContainerRef;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {
        super();

        this.completeOnDestroy(this.dialogClose);
    }

    ngAfterViewInit() {
        if (!this.component) {
            return;
        }

        this.componentOutletViewContainerRef.clear();
        this.componentOutletViewContainerRef.createComponent(
            this.componentFactoryResolver.resolveComponentFactory(
                this.component
            )
        );

        this.changeDetectorRef.markForCheck();
    }

    intentDialogClose() {
        this.dialogClose.emit();
    }
}
