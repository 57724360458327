/**
 * The session state that is used to communicate to the one2one-client the
 * currently active session state.
 * When the dealer ends the session, session-state needs to be set to CLOSED before
 * destroying the session to be able to correctly display the status to the one2one-client.
 */
export enum One2OneSessionState {
    UNKNOWN = 'UNKNOWN',
    CLOSED = 'CLOSED',
    ACTIVE = 'ACTIVE'
}
