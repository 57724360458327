import { Action, combineReducers, createReducer, on } from '@ngrx/store';

import {
    ConfigurationState,
    ConnectionState,
    LocalSharedApplicationState
} from '..';
import { StreamStatus } from '../../streaming/state';
import { streamingStateReducer } from '../../streaming/state/reducers/streaming-state.reducer';
import {
    decreasePendingConfigurationChanges,
    increasePendingConfigurationChanges,
    setEngineConnectionState,
    setHubConnectionState,
    setNetworkConnectionState
} from '../actions';

const INITIAL_STATE: LocalSharedApplicationState = {
    connectionState: {
        networkConnected: undefined,
        engineConnected: undefined,
        hubConnected: undefined
    },
    configuration: {
        pendingConfigurationChanges: 0
    },
    streaming: {
        engineHostUrl: undefined,
        streamStatus: StreamStatus.DISCONNECTED
    }
};

const connectionStateReducer = createReducer<ConnectionState>(
    INITIAL_STATE.connectionState,
    on(setNetworkConnectionState, (connectionState, newConnectionState) => ({
        ...connectionState,
        networkConnected: newConnectionState.connectionState
    })),
    on(setHubConnectionState, (connectionState, newConnectionState) => ({
        ...connectionState,
        hubConnected: newConnectionState.connectionState
    })),
    on(setEngineConnectionState, (connectionState, newConnectionState) => ({
        ...connectionState,
        engineConnected: newConnectionState.connectionState
    }))
);

const configurationStateReducer = createReducer<ConfigurationState>(
    INITIAL_STATE.configuration,
    on(increasePendingConfigurationChanges, (state) => ({
        ...state,
        pendingConfigurationChanges: state.pendingConfigurationChanges + 1
    })),
    on(decreasePendingConfigurationChanges, (state) => ({
        ...state,
        pendingConfigurationChanges: Math.max(
            0,
            state.pendingConfigurationChanges - 1
        )
    }))
);

const reducer = combineReducers(
    {
        connectionState: connectionStateReducer,
        configuration: configurationStateReducer,
        streaming: streamingStateReducer
    },
    INITIAL_STATE
);

export function localSharedApplicationStateReducer(
    state: LocalSharedApplicationState,
    action: Action
) {
    return reducer(state, action);
}
