import { Inject, Injectable, Optional, SkipSelf } from '@angular/core';
import { IllegalStateError } from '@mhp/common';

import { GoogleTagManagerTrackingStrategy } from './google-tag-manager-tracking-strategy.interface';
import { GOOGLE_TAG_MANAGER_TRACKING_STRATEGY_TOKEN } from './google-tag-manager.token';
import { TrackingProperties } from './tracking-properties.interface';

/**
 * Service allowing to track GTM events.
 */
@Injectable({
    providedIn: 'root'
})
export class GoogleTagManagerService {
    constructor(
        @Inject(GOOGLE_TAG_MANAGER_TRACKING_STRATEGY_TOKEN)
        private readonly trackingStrategy: GoogleTagManagerTrackingStrategy,
        @Optional() @SkipSelf() googleTagManagerService: GoogleTagManagerService
    ) {
        if (googleTagManagerService) {
            throw new IllegalStateError(
                'GoogleTagManagerService should only be instantiated once.'
            );
        }
        // eslint-disable-next-line @typescript-eslint/no-this-alias, no-use-before-define
        service = this;
    }

    track(
        action: string,
        category: string,
        label: string,
        properties: TrackingProperties = {}
    ) {
        this.trackingStrategy.trackEvent(action, category, label, properties);
    }

    set(properties: TrackingProperties = {}) {
        this.trackingStrategy.setGlobalProperties(properties);
    }

    /**
     * Update GA4 data layer variables
     * @param properties The properties to update
     */
    setGA4DataLayerVariables(properties: TrackingProperties = {}) {
        this.trackingStrategy.setGA4DataLayerVariables(properties);
    }

    /**
     * Track a GA4 style event
     * @param eventName The name of the event to track
     * @param properties The properties of the event to track
     */
    trackGA4Event(eventName: string, properties: TrackingProperties = {}) {
        this.trackingStrategy.trackGA4Event(eventName, properties);
    }
}

// local reference to
let service: GoogleTagManagerService;

/**
 * Function to simplify access to the GoogleTagManagerService singleton.
 * The template for this has been taken from Transloco which exports its translate function the same way.
 * See https://github.com/ngneat/transloco/blob/master/libs/transloco/src/lib/transloco.service.ts#L81
 *
 * @param action The event action.
 * @param category The event category.
 * @param label The event label
 * @param properties Optional additional properties to be included with the event.
 */
export function gtmTrack(
    action: string,
    category: string,
    label: string,
    properties: TrackingProperties = {}
) {
    if (!service) {
        throw new IllegalStateError(
            'GoogleTagManagerService needs to be at least referenced somewhere to be initialized.'
        );
    }
    service.track(action, category, label, properties);
}

/**
 * Track a GA4 style event
 * @param event The name of the event to track
 * @param properties The properties of the event to track
 */
export function gtmGA4Track(
    event: string,
    properties: TrackingProperties = {}
) {
    if (!service) {
        throw new IllegalStateError(
            'GoogleTagManagerService needs to be at least referenced somewhere to be initialized.'
        );
    }
    service.trackGA4Event(event, properties);
}

export function gtmGA4SetDataLayerProps(properties: TrackingProperties = {}) {
    if (!service) {
        throw new IllegalStateError(
            'GoogleTagManagerService needs to be at least referenced somewhere to be initialized.'
        );
    }
    service.setGA4DataLayerVariables(properties);
}
