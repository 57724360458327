/* eslint-disable max-classes-per-file */
import {
    Attributes,
    IntersectionObserverHooks,
    LAZYLOAD_IMAGE_HOOKS,
    LazyLoadImageModule
} from 'ng-lazyload-image';
import { Observable, of } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';

import { UiImageSrcsetModule } from '../common/ui-image-srcset/ui-image-srcset.module';
import { UiLazyloadImageComponent } from './ui-lazyload-image.component';

/**
 * Override intersection-detection behavior to fix bug reported @ https://github.com/tjoskar/ng-lazyload-image/issues/473#issuecomment-811703487
 */
@Injectable()
class LazyLoadImageHooks extends IntersectionObserverHooks {
    override getObservable(
        attributes: Attributes<{
            isIntersecting: boolean;
        }>
    ) {
        if (this.skipLazyLoading(attributes)) {
            return of({ isIntersecting: true });
        }
        if (attributes.customObservable) {
            return attributes.customObservable;
        }
        const options: IntersectionObserverInit = {
            root: attributes.scrollContainer || null,
            threshold: [0]
        };
        if (attributes.offset) {
            options.rootMargin = `${attributes.offset}px`;
        }

        return new Observable<{ isIntersecting: boolean }>((subscriber) => {
            const observer = new IntersectionObserver((entrys) => {
                entrys.forEach((entry) => subscriber.next(entry));
            }, options);

            observer.observe(attributes.element);

            return () => {
                observer.disconnect();
            };
        });
    }
}

@NgModule({
    declarations: [UiLazyloadImageComponent],
    imports: [CommonModule, LazyLoadImageModule, UiImageSrcsetModule],
    exports: [UiLazyloadImageComponent],
    providers: [{ provide: LAZYLOAD_IMAGE_HOOKS, useClass: LazyLoadImageHooks }]
})
export class UiLazyloadImageModule {}
