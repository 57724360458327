export const ROUTE_MODEL_SELECT = 'model-select';
export const ROUTE_DERIVATE_SELECT = 'derivate-select';
export const ROUTE_CONFIGURATION = 'configuration';
export const ROUTE_CONFIGURATION_SESSION = 'configuration-session';
export const ROUTE_BRANDSTORE = 'brandstore';
export const ROUTE_DEALER_COMPLETE_ONE2ONE_REQUEST = 'complete-session-request';
export const ROUTE_ERROR = 'error';
export const ROUTE_ORDER_MANAGEMENT = 'order-management';
export const ROUTE_ORDER_DETAIL = 'order-detail';
export const ROUTE_ORDER_EDIT = 'order-edit';
export const ROUTE_ORDER_OVERVIEW = 'order-overview';

export const PARAMETER_REGION = ':region';
