/* eslint-disable no-underscore-dangle */
import { INPUT_END } from 'hammerjs';
import { isNumber } from 'lodash-es';

import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';

import { UiBaseComponent } from '../common';

export enum ZoomAction {
    START = 'START',
    MOVE = 'MOVE',
    END = 'END',
    CANCEL = 'CANCEL',
    UNKNOWN = 'UNKNOWN'
}

export interface Point {
    x: number;
    y: number;
}

export interface ZoomChange {
    zoomAction: ZoomAction;
    // relative position on panel (values from 0..1)
    panelRelative: Point;
    // the current absolute zoom level
    absolute: number;
    // the difference between the previous zoom level and the current zoom level
    relative: number;
    // the difference between when the last zoom-activity started and the current zoom level
    relativeSinceStart: number;
    // the actual scale with dampening applied
    scale: number;
}

export enum PanAction {
    START = 'START',
    MOVE = 'MOVE',
    END = 'END',
    CANCEL = 'CANCEL',
    UNKNOWN = 'UNKNOWN'
}

export interface PanChange {
    panAction: PanAction;
    // relative position on panel (values from 0 - 1, where 0:0 is top-left and 1:1 is bottom-right)
    panelRelative?: Point;
    // absolute position on panel in px (where 0:0 is top:left and <touch-area-width>:<touch-area-height> is bottom-right)
    panelAbsolute?: Point;
    // absolute movement since first touch
    absolute: Point;
    // relative movement since drag-start
    relative: Point;
}

interface ZoomState {
    start: number;
    current: number;
}

interface PanState {
    start: Point;
    current: Point;
}

@Component({
    selector: 'mhp-ui-touch-panel',
    templateUrl: './ui-touch-panel.component.html',
    styleUrls: ['./ui-touch-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiTouchPanelComponent extends UiBaseComponent implements OnInit {
    /*
     * INPUTS
     */
    @Input()
    zoomMin = Number.MIN_VALUE;

    @Input()
    zoomMax = Number.MAX_VALUE;

    @Input()
    zoom = 0;

    private _pan: Point = {
        x: 0,
        y: 0
    };

    @Input()
    get pan() {
        return {
            ...this._pan
        };
    }

    set pan(pan: Point) {
        const oldPan = pan;
        this._pan = {
            ...pan
        };
        this.panChange.emit({
            panAction: PanAction.UNKNOWN,
            absolute: pan,
            relative: {
                x: oldPan.x - pan.x,
                y: oldPan.y - pan.y
            }
        });
    }

    /**
     * The factor to apply to pinch-gesture scaling to get the resulting zoom factor
     */
    @Input()
    zoomDampening = 1;

    @Input()
    disabled = false;

    /*
     * OUTPUTS
     */

    /**
     * Emitted when the zoom-factor is changed.
     */
    @Output()
    readonly zoomChange = new EventEmitter<ZoomChange>();

    /**
     * Emitted when the touch-location on the panel changes.
     */
    @Output()
    readonly panChange = new EventEmitter<PanChange>();

    /*
     * INTERNALS
     */

    @ViewChild('touchPanel', {
        static: true
    })
    panelRef: ElementRef;

    private currentZoom: ZoomState | null = null;

    private currentPan: PanState | null = null;

    private panAfterPinchTimeout: number;

    private ignorePan: boolean;

    constructor() {
        super();
    }

    ngOnInit() {
        this.observeChangesOfProperty('zoom', false).subscribe(
            (currentZoomChange) => {
                if (!isNumber(currentZoomChange.currentValue)) {
                    return;
                }
                const currentZoom = currentZoomChange.currentValue;
                const previousZoom = currentZoomChange.previousValue;
                const adjustedZoom = this.adjustZoom(currentZoom);
                this.zoom = adjustedZoom;
                if (previousZoom !== adjustedZoom) {
                    this.zoomChange.emit({
                        zoomAction: ZoomAction.UNKNOWN,
                        panelRelative: {
                            x: 0.5,
                            y: 0.5
                        },
                        absolute: adjustedZoom,
                        relative: adjustedZoom - previousZoom,
                        relativeSinceStart: 0,
                        scale: 1
                    });
                }
            }
        );
    }

    /**
     * Translate a users pinch to zoom.
     * @param event
     */
    onPinch(event: HammerInput) {
        event.preventDefault();

        if (this.panAfterPinchTimeout) {
            clearTimeout(this.panAfterPinchTimeout);
        }
        this.panAfterPinchTimeout = window.setTimeout(() => {
            this.ignorePan = false;
        }, 500);
        this.ignorePan = true;

        const centerPoint = event.center;

        const panelRelativePoint = {
            x:
                this.getPanelRelativePosition(centerPoint).x /
                this.getPanelDiameter(),
            y:
                this.getPanelRelativePosition(centerPoint).y /
                this.getPanelDiameter()
        };

        const zoomAction = this.getZoomAction(event.type);

        if (!this.currentZoom || zoomAction === ZoomAction.START) {
            this.currentZoom = {
                start: this.zoom,
                current: this.zoom
            };
        }

        const distancePinched =
            event.scale > 1 ? 1 - 1 / event.scale : -1 * (1 - event.scale);

        const prevZoom = this.currentZoom.current;

        this.currentZoom.current = this.adjustZoom(
            this.currentZoom.start + distancePinched * this.zoomDampening
        );

        this.zoomChange.emit({
            zoomAction,
            panelRelative: {
                x: panelRelativePoint.x,
                y: panelRelativePoint.y
            },
            absolute: this.currentZoom.current,
            relative: this.currentZoom.current - prevZoom,
            relativeSinceStart:
                this.currentZoom.current - this.currentZoom.start,
            scale: event.scale * this.zoomDampening
        });

        if (zoomAction === ZoomAction.END) {
            // apply directly
            this.zoom = this.currentZoom.current;
            this.currentZoom = null;
        }
    }

    /**
     * Translate a users finger-move on the panel to the appropriate pan-events.
     * @param event
     */
    onPan(event: HammerInput) {
        event.preventDefault();

        if (this.ignorePan) {
            return;
        }

        const centerPoint = event.center;

        if (!this.currentPan) {
            this.currentPan = {
                start: { ...this.pan },
                current: {
                    x: this.pan.x + event.deltaX,
                    y: this.pan.y + event.deltaY
                }
            };
        }

        this.currentPan.current = {
            x: this.currentPan.start.x + event.deltaX,
            y: this.currentPan.start.y + event.deltaY
        };

        const panelRelativePoint = {
            x:
                this.getPanelRelativePosition(centerPoint).x /
                this.getPanelDiameter(),
            y:
                this.getPanelRelativePosition(centerPoint).y /
                this.getPanelDiameter()
        };

        const panelAbsolutePoint = {
            x:
                centerPoint.x -
                this.panelRef.nativeElement.getBoundingClientRect().left,
            y:
                centerPoint.y -
                this.panelRef.nativeElement.getBoundingClientRect().top
        };

        this.panChange.emit({
            panAction: this.getPanAction(event.type),
            panelRelative: panelRelativePoint,
            panelAbsolute: panelAbsolutePoint,
            absolute: { ...this.currentPan.current },
            relative: {
                x: event.deltaX,
                y: event.deltaY
            }
        });

        this._pan = { ...this.currentPan.current };

        if (event.eventType === INPUT_END) {
            this.currentPan = null;
        }
    }

    private getZoomAction(hammerEventType: string) {
        switch (hammerEventType) {
            case 'pinchstart':
                return ZoomAction.START;
            case 'pinchend':
                return ZoomAction.END;
            case 'pinchmove':
                return ZoomAction.MOVE;
            case 'pinchcancel':
                return ZoomAction.CANCEL;
            default:
                return ZoomAction.UNKNOWN;
        }
    }

    private getPanAction(hammerEventType: string) {
        switch (hammerEventType) {
            case 'panstart':
                return PanAction.START;
            case 'panend':
                return PanAction.END;
            case 'panmove':
                return PanAction.MOVE;
            case 'pancancel':
                return PanAction.CANCEL;
            default:
                return PanAction.UNKNOWN;
        }
    }

    private adjustZoom(zoom: number) {
        return Math.min(this.zoomMax, Math.max(this.zoomMin, zoom));
    }

    private getPanelRelativePosition(clientPos: Point): Point {
        const boundingClientRect =
            this.panelRef.nativeElement.getBoundingClientRect();
        const canvasTop = boundingClientRect.top;
        const canvasLeft = boundingClientRect.left;

        return {
            x: clientPos.x - canvasLeft,
            y: clientPos.y - canvasTop
        };
    }

    private getPanelDiameter() {
        return this.panelRef.nativeElement.clientWidth;
    }
}
