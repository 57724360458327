import deepLock from 'deep-lock';

export const TERRITORY_MAP: Record<string, string[]> = {
    AA: ['AU'],
    AB: ['BE'],
    AC: ['CA'],
    AD: ['AT', 'CZ', 'DE', 'GR', 'HR', 'PL', 'LT', 'LV', 'RO', 'UA', 'HU'],
    AE: ['UK', 'GB', 'JE'],
    AF: ['ES', 'FR', 'LU', 'MC', 'NL', 'PT'],
    AG: ['AE', 'BH', 'EG', 'KW', 'OM', 'QA', 'SA', 'JO'],
    AH: ['HK', 'MO'],
    AI: ['IT'],
    AJ: ['JP'],
    AK: ['NO'],
    AL: ['MX'],
    AM: ['DK', 'SE'],
    AN: ['CN'],
    AO: ['BR', 'CL'],
    AP: ['RU'],
    AQ: ['KR'],
    AR: ['IE'],
    AS: ['ZA'],
    AT: ['TW'],
    AU: ['US'],
    AV: ['SG', 'MY', 'TH', 'ID'],
    AW: ['CH'],
    AX: ['IN'],
    AY: ['TR', 'AZ', 'JE'],
    AZ: ['NZ'],
    BA: ['PH'],
    BB: ['PE'],
    BC: ['VN', 'KH'],
    BD: ['IL', 'LB', 'MA']
};

export const MENAT_COUNTRIES: string[] = [
    'AE',
    'AZ',
    'BH',
    'EG',
    'IL',
    'JO',
    'KW',
    'LB',
    'MA',
    'OM',
    'QA',
    'SA',
    'TR'
];

export const APAC_COUNTRIES: readonly string[] = deepLock([
    'KH',
    'HK',
    'IN',
    'ID',
    'JP',
    'MO',
    'MY',
    'NZ',
    'PH',
    'SG',
    'KR',
    'TW',
    'TH',
    'VN'
]);

export const PRICING_RESTRICTED_COUNTRIES: string[] = [
    'AE',
    'AT',
    'AU',
    'AZ',
    'BE',
    'BH',
    'CH',
    'CN',
    'DE',
    'DK',
    'EG',
    'ES',
    'FR',
    'GB',
    'GR',
    'HK',
    'HU',
    'ID',
    'IL',
    'IN',
    'IT',
    'JO',
    'JP',
    'KH',
    'KR',
    'KW',
    'LB',
    'LT',
    'LU',
    'LV',
    'MA',
    'MC',
    'MO',
    'MY',
    'NL',
    'NO',
    'NZ',
    'OM',
    'PH',
    'PL',
    'PT',
    'QA',
    'RO',
    'RU',
    'SA',
    'SE',
    'SG',
    'TH',
    'TR',
    'TW',
    'UA',
    'VN',
    'ZA'
];
