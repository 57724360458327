import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MenuService } from './menu.service';

@NgModule({
    declarations: [],
    imports: [CommonModule]
})
export class MenuModule {
    constructor(private readonly menuService: MenuService) {}
}
