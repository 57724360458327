import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Observable, defer, from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PDFRequest } from '@mhp-immersive-exp/contracts/src/pdf/pdf.interface';

import { RecaptchaError } from '../recaptcha/errors/errors';

/**
 * Provides pdf download
 */
@Injectable({
    providedIn: 'root'
})
export class PdfService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly recaptchaV3Service: ReCaptchaV3Service
    ) {}

    /**
     * Download a blob from the given URL using the 'GeneratePDF' recaptcha action.
     * @param baseUrl
     * @param payload
     */
    downloadPdf$(
        baseUrl: string,
        payload: Omit<PDFRequest, 'locale'>
    ): Observable<Blob> {
        return defer(() =>
            this.recaptchaV3Service.execute('GeneratePDF').pipe(
                switchMap((token: string) =>
                    this.httpClient.post<Blob>(`${baseUrl}/`, payload, {
                        headers: { recaptcha: token },
                        responseType: 'blob' as 'json'
                    })
                ),
                catchError((error) => {
                    let resolveErrorCausePromise: Promise<any> | undefined;
                    if (error instanceof HttpErrorResponse) {
                        if (
                            error.status === 400 &&
                            error.error instanceof Blob &&
                            error.error.type === 'application/json'
                        ) {
                            resolveErrorCausePromise = error.error
                                .text()
                                .then((text) => JSON.parse(text))
                                .then((errorResponse: any) => {
                                    if (
                                        errorResponse.message?.startsWith(
                                            'Low recaptcha score'
                                        )
                                    ) {
                                        throw new RecaptchaError(
                                            errorResponse.message,
                                            error
                                        );
                                    }
                                    throw error;
                                });
                        }
                    }
                    if (resolveErrorCausePromise) {
                        return from(resolveErrorCausePromise);
                    }
                    throw error;
                })
            )
        );
    }
}
