import {
    filter,
    firstValueFrom,
    interval,
    map,
    switchMap,
    tap,
    timeout
} from 'rxjs';

import { DOCUMENT } from '@angular/common';
import {
    Inject,
    Injectable,
    InjectionToken,
    Optional,
    Renderer2,
    RendererFactory2,
    signal
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IllegalStateError, Memoize } from '@mhp/common';
import {
    GoogleTagManagerDefaultTrackingStrategyConfig,
    attachScriptToDocumentHead
} from '@mhp/ui-shared-services';

import { OneTrustService } from '../cookie';
import {
    SalesforceConsent,
    SalesforceEvent,
    SalesforceInteractions
} from './salesfoce-interactions.types';

export interface SalesforceTrackingServiceConfig {
    // the full URL to the beacon-script to be loaded. If not defined, no events will be send out
    beaconScriptUrl?: string;
    // how long to wait for the beacon-script to be loaded. Defaults to 10s
    waitForBeaconInitialization?: number;
}

export const SALESFORCE_TRACKING_SERVICE_CONFIG =
    new InjectionToken<GoogleTagManagerDefaultTrackingStrategyConfig>(
        'GOOGLE_TAG_MANAGER_DEFAULT_TRACKING_STRATEGY_CONFIG'
    );

/**
 * This service provides functionality to track events to Salesforce Datacloud.
 *
 * See https://dev.azure.com/AstonMartinLagonda/Digital/_workitems/edit/51271
 */
@Injectable({
    providedIn: 'root'
})
export class SalesforceTrackingService {
    private readonly renderer: Renderer2;

    private salesforceInteractionsInitialized = signal<boolean>(false);

    constructor(
        private readonly oneTrustService: OneTrustService,
        private readonly rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private readonly document: Document,
        @Optional()
        @Inject(SALESFORCE_TRACKING_SERVICE_CONFIG)
        private readonly config?: SalesforceTrackingServiceConfig
    ) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias, no-use-before-define
        service = this;

        this.renderer = this.rendererFactory.createRenderer(null, null);

        // in case no beaconScriptUrl (or no config at all) is provided, simply skip initialization.
        if (!this.config?.beaconScriptUrl) {
            return;
        }

        this.initialize();

        this.oneTrustService
            .getConsentCategories$()
            .pipe(
                switchMap((categories) =>
                    this.sendUserTrackingConsent(categories.has('C0004'))
                ),
                takeUntilDestroyed()
            )
            .subscribe();
    }

    /**
     * Send out an event to the Salesforce backend.
     * @param event The event to be sent.
     */
    async sendEvent(event: SalesforceEvent) {
        if (!this.config?.beaconScriptUrl) {
            return;
        }
        const salesforceInteractions = await this.getSalesforceInteractions();
        salesforceInteractions.sendEvent(event);
    }

    /**
     * Update the user tracking consent.
     * @param consent The consent state (true = opt in, false = opt out)
     * @private
     */
    private async sendUserTrackingConsent(consent: boolean) {
        const salesforceInteractions = await this.getSalesforceInteractions();

        const consentPayload: SalesforceConsent = {
            status: consent
                ? salesforceInteractions.ConsentStatus.OptIn
                : salesforceInteractions.ConsentStatus.OptOut,
            purpose: salesforceInteractions.ConsentPurpose.Tracking,
            provider: 'OneTrust'
        };

        // check if interaction already has been initialized before deciding to call init or updateConsent
        if (!this.salesforceInteractionsInitialized()) {
            await salesforceInteractions.init({
                cookieDomain: 'configurator.astonmartin.com',
                consents: [consentPayload]
            });
            this.salesforceInteractionsInitialized.set(true);
        } else {
            salesforceInteractions.updateConsents(consentPayload);
        }
    }

    /**
     * Try to obtain a reference to the SalesforceInteractions global object.
     * As there is no way to provide an initialization-callback, we have to poll
     * for changes and timeout in case we don't see the expected instance on the global
     * object.
     * @private
     */
    @Memoize()
    private async getSalesforceInteractions(): Promise<SalesforceInteractions> {
        if ((window as any).SalesforceInteractions) {
            return Promise.resolve((window as any).SalesforceInteractions);
        }
        // we don't have any way to get notified when the script is loaded, so we have to poll for it
        const waitForInstanceMs =
            this.config?.waitForBeaconInitialization ?? 10000;
        return firstValueFrom(
            interval(200).pipe(
                map(() => (window as any).SalesforceInteractions),
                filter((interactionsInstance) => !!interactionsInstance),
                timeout(waitForInstanceMs),
                tap({
                    error: () =>
                        console.error(
                            `Failed getting reference to Salesforce Data Cloud integration after ${waitForInstanceMs}ms`
                        )
                })
            )
        );
    }

    /**
     * Initialize the Salesforce integration by loading the according beacon.
     * @private
     */
    private initialize(): void {
        if (!this.config?.beaconScriptUrl) {
            throw new IllegalStateError(
                'Missing configuration property "beaconScriptUrl"'
            );
        }
        const scriptText = `var _aaq = window._aaq || (window._aaq = []);
            (function () {
            var d = document,
              g = d.createElement("script"),
              s = d.getElementsByTagName("script")[0];
            g.type = "text/javascript";
            g.async = true;
            g.src =
              '${this.config?.beaconScriptUrl}';
            s.parentNode.insertBefore(g, s);
            })();`;

        attachScriptToDocumentHead(this.document, this.renderer, scriptText, {
            async: true
        });
    }
}

// local reference to SalesforceTrackingService
let service: SalesforceTrackingService;

/**
 * Function to simplify access to the SalesforceTrackingService singleton.
 * The template for this has been taken from Transloco which exports its translate function the same way.
 * See https://github.com/ngneat/transloco/blob/master/libs/transloco/src/lib/transloco.service.ts#L81
 */
export function sfEvent(event: SalesforceEvent) {
    if (!service) {
        throw new IllegalStateError(
            'SalesforceTrackingService needs to be at least referenced somewhere to be initialized.'
        );
    }
    service.sendEvent(event);
}
