import { identity, isEmpty } from 'lodash-es';
import {
    EMPTY,
    Observable,
    combineLatest,
    defer,
    from,
    merge,
    of,
    shareReplay,
    throwError
} from 'rxjs';
import {
    catchError,
    filter,
    last,
    map,
    startWith,
    switchMap,
    take,
    takeUntil,
    tap,
    withLatestFrom
} from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoLocaleService } from '@jsverse/transloco-locale';
import { DealerInfoService } from '@mhp/aml-ui-shared-services';
import {
    IllegalStateError,
    MemoizeObservable,
    NotFoundError,
    UserCancelledError,
    lazyShareReplay
} from '@mhp/common';
import { UiMatDialogService } from '@mhp/ui-components';
import {
    ApplicationStateService,
    I18nService,
    I18nSettings,
    L10nService
} from '@mhp/ui-shared-services';

import { environment } from '../../../environments/environment';
import { ROUTE_CONFIGURATION } from '../../app-route-names';
import { BACKDROP_CLASS_BLURRY } from '../../common/dialog/dialog.constants';
import { LocalApplicationState } from '../../state';
import { setActiveRegion } from '../state/actions/settings-state.actions';
import { IpLookupService } from './ip-lookup.service';
import { AVAILABLE_REGIONS } from './region-constants';
import { RegionSelectorComponent } from './region-selector.component';
import { RegionDefinition } from './region.interfaces';

export interface RegionAndLanguage {
    region: string;
    language: string;
}

export type RegionAndLanguageSelectionInterceptor = (
    continuation$: Observable<boolean>
) => Observable<boolean>;

export interface RegionAndLanguageSelectionOptions {
    allowClose?: boolean;
    disableRegionSelect?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class RegionService {
    constructor(
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly dialogService: UiMatDialogService,
        private readonly i18nService: I18nService<I18nSettings>,
        private readonly l10nService: L10nService,
        private readonly ipLookup: IpLookupService,
        private readonly translocoLocaleService: TranslocoLocaleService,
        private readonly router: Router,
        private readonly dealerInfoService: DealerInfoService,
        private readonly httpClient: HttpClient
    ) {
        this.getActiveRegion$().subscribe((regionId) => {
            this.applicationStateService.dispatch(
                setActiveRegion({
                    region: regionId
                })
            );
        });
    }

    /**
     * Returns the list of available RegionDefinitions if set.
     */
    @MemoizeObservable()
    getAvailableRegions$(): Observable<RegionDefinition[]> {
        return this.httpClient
            .get<string[]>(
                `${environment.appConfig.dataProxy.url}/api/country-list`
            )
            .pipe(
                map((availableCountriesForDealer): RegionDefinition[] => {
                    if (isEmpty(availableCountriesForDealer)) {
                        throw new IllegalStateError(
                            'No countries available for current context.'
                        );
                    }

                    return availableCountriesForDealer.map(
                        (currentIsoCountry): RegionDefinition => {
                            const matchingRegionDefinition =
                                AVAILABLE_REGIONS.find(
                                    (region) => region.id === currentIsoCountry
                                );
                            if (!matchingRegionDefinition) {
                                console.warn(
                                    'Could not match ISO country to region definition: ',
                                    currentIsoCountry
                                );
                                return {
                                    id: currentIsoCountry,
                                    niceName: currentIsoCountry,
                                    territory: currentIsoCountry
                                };
                            }
                            return matchingRegionDefinition;
                        }
                    );
                }),
                catchError((error) => {
                    console.warn(
                        'Failed fetching available countries for current context. Falling back to all available countries',
                        error
                    );
                    return of(AVAILABLE_REGIONS);
                }),
                shareReplay(1)
            );
    }

    /**
     * Returns the active region if set.
     */
    @MemoizeObservable()
    getActiveRegion$(): Observable<string | undefined> {
        return this.l10nService.getActiveCountry$();
    }

    /**
     * Updates the active region
     * @param regionId The regionId to be set as active.
     */
    setActiveRegion$(
        regionId: string | undefined
    ): Observable<string | undefined> {
        return this.l10nService
            .updateSettings$({
                country: regionId
            })
            .pipe(map((settings) => settings.country));
    }

    /**
     * Route to a new region. Keeps the remainder of the active url as-is.
     * @param region
     * @param urlModifier may be provided to modify the resulting url before routing to it.
     */
    async routeToRegion(
        region: string,
        urlModifier: (url: string) => string = identity
    ) {
        const currentUrl = this.router.routerState.snapshot.url;
        const currentRegion = currentUrl.split('/')[1];
        if (!currentRegion) {
            throw new IllegalStateError(
                `Failed determining current region in url: ${currentUrl}`
            );
        }
        const adjustedUrl = currentUrl.replace(
            `${currentRegion}/`,
            `${region}/`
        );
        if (adjustedUrl === currentUrl) {
            return true;
        }
        const modifiedUrl = urlModifier(adjustedUrl);
        return this.router.navigateByUrl(modifiedUrl);
    }

    /**
     * Request the user to select a region.
     * Note that this does not update the active version. This has to be done
     * manually when receiving the users choice.
     * @param options Optional behavior for the selector
     */
    requestRegionAndLanguageSelection$(
        options: RegionAndLanguageSelectionOptions = {
            allowClose: true,
            disableRegionSelect: false
        }
    ): Observable<RegionAndLanguage> {
        const regionSelectDialogOpen$ = this.dialogService
            .open$(RegionSelectorComponent, {
                disableClose: !options.allowClose,
                backdropClass: BACKDROP_CLASS_BLURRY,
                width: '500px'
            })
            .pipe(lazyShareReplay());

        let initialLanguage: string;

        const countryIpLookup$ = this.ipLookup
            .lookupCountry$()
            .pipe(catchError(() => of(undefined)));

        const dealerCountryLookup$ = this.dealerInfoService
            .getActiveDealerInfo$()
            .pipe(
                map((dealer) => {
                    if (!dealer) {
                        throw new NotFoundError(
                            'No dealer information available'
                        );
                    }
                    return dealer.countryISO2;
                }),
                catchError(() => countryIpLookup$)
            );

        const countrySuggestion$ = (
            environment.appConfig.dealer.dealerBuild
                ? dealerCountryLookup$
                : countryIpLookup$
        ).pipe(
            take(1),
            startWith(undefined) // initially show results without waiting for country-lookup. Will be set as suggested when available
        );

        return combineLatest([
            regionSelectDialogOpen$,
            this.getActiveRegion$().pipe(take(1)),
            this.i18nService.getActiveLang$().pipe(take(1)),
            countrySuggestion$,
            this.getAvailableRegions$()
        ]).pipe(
            takeUntil(regionSelectDialogOpen$.pipe(last())),
            switchMap(
                ([
                    dialogRef,
                    activeRegion,
                    activeLanguage,
                    suggestedCountry,
                    avaialableRegions
                ]) => {
                    if (!initialLanguage) {
                        initialLanguage = activeLanguage;
                    }

                    const { componentInstance } = dialogRef;
                    componentInstance.availableRegions = avaialableRegions;
                    componentInstance.availableLanguages = this.i18nService
                        .getAvailableLanguages()
                        .map((langDef) => langDef.id);

                    const isSingleCountryAvailableForSelection =
                        avaialableRegions.length === 1;

                    componentInstance.suggestedRegion =
                        isSingleCountryAvailableForSelection
                            ? avaialableRegions[0].id
                            : activeRegion || suggestedCountry;
                    componentInstance.suggestedLanguage = activeLanguage;
                    componentInstance.regionSelectDisabled =
                        !!options.disableRegionSelect ||
                        isSingleCountryAvailableForSelection;

                    return merge(
                        componentInstance.languageChange.pipe(
                            map(
                                (language): Partial<RegionAndLanguage> => ({
                                    language
                                })
                            )
                        ),
                        componentInstance.confirmed
                    ).pipe(
                        tap((regionAndLanguagePartial) => {
                            // update language on the fly when it is changed
                            if (regionAndLanguagePartial.language) {
                                this.i18nService.updateSettings({
                                    language: regionAndLanguagePartial.language
                                });
                            }
                        }),
                        // emit only when both language and region are emitted (on user confirm)
                        filter(
                            (
                                regionAndLanguage
                            ): regionAndLanguage is RegionAndLanguage =>
                                !!regionAndLanguage.language &&
                                !!regionAndLanguage.region
                        ),
                        // update the initial language
                        tap((regionAndLanguage) => {
                            initialLanguage = regionAndLanguage.language;
                        }),
                        // on complete, either re-set the active language or reset it to the previous active language in case user chose to cancel
                        tap({
                            complete: () =>
                                this.i18nService.updateSettings({
                                    language: initialLanguage
                                })
                        })
                    );
                }
            ),
            take(1)
        );
    }

    /**
     * Show the language and region-selector to the user and handle the chosen result.
     * @param options Optional behavior for the selector
     * @param interceptor An optional callback that allows to hook-in a possible pre-condition
     * that has to be fulfilled before applying the chosen region change.
     */
    requestAndApplyRegionAndLanguageSelection$(
        options: RegionAndLanguageSelectionOptions = {
            allowClose: true,
            disableRegionSelect: false
        },
        interceptor: RegionAndLanguageSelectionInterceptor = identity
    ) {
        return this.requestRegionAndLanguageSelection$(options).pipe(
            withLatestFrom(this.getActiveRegion$()),
            switchMap(([selectedRegionOrLanguage, activeRegion]) => {
                if (activeRegion === selectedRegionOrLanguage.region) {
                    return EMPTY;
                }

                const performRouting$ = defer(() =>
                    from(
                        this.routeToRegion(
                            selectedRegionOrLanguage.region,
                            (url) => {
                                const urlParts = url.split('/');
                                if (
                                    urlParts.find(
                                        (part) => part === ROUTE_CONFIGURATION
                                    )
                                ) {
                                    return urlParts.slice(0, 4).join('/');
                                }
                                return url;
                            }
                        )
                    )
                );

                return interceptor(performRouting$);
            }),
            catchError((error) =>
                error instanceof UserCancelledError ? EMPTY : throwError(error)
            )
        );
    }
}
