import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { SocketIOService } from './socket-io.service';

/**
 * To allow usage of SocketIo related functionality, import this module.
 */
@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: []
})
export class SocketIOModule {
    static forRoot(): ModuleWithProviders<SocketIOModule> {
        return {
            ngModule: SocketIOModule,
            providers: [SocketIOService]
        };
    }
}
