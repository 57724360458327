import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
    Button,
    DialogButtonType,
    GenericTextModal
} from '@mhp/ui-shared-services';

@Component({
    selector: 'mhp-generic-text-dialog',
    templateUrl: './generic-text-dialog.component.html',
    styleUrls: ['./generic-text-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericTextDialogComponent
    implements GenericTextModal, OnInit, OnDestroy
{
    private textField: string;

    readonly BUTTON_TYPE = DialogButtonType;

    @Input()
    title: string;

    @Input()
    set text(value: string) {
        this.textField = value;
        this.textSanitized = this.sanitizer.bypassSecurityTrustHtml(value);
    }

    get text(): string {
        return this.textField;
    }

    @Input()
    useWrappingParagraph = true;

    @Input()
    buttonsLeft?: Button[];

    @Input()
    buttonsRight?: Button[];

    @Output()
    readonly buttonClick = new EventEmitter<string>();

    textSanitized: SafeHtml;

    constructor(private readonly sanitizer: DomSanitizer) {}

    ngOnInit() {
        if (!this.textField) {
            throw new Error('text must not be empty');
        }
    }

    ngOnDestroy() {
        this.buttonClick.complete();
    }

    intentButtonClick(buttonId: string) {
        this.buttonClick.emit(buttonId);
    }
}
