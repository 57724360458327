import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { StreamingService } from './streaming.service';

@NgModule({
    declarations: [],
    imports: [CommonModule]
})
export class StreamingModule {
    static forRoot(): ModuleWithProviders<StreamingModule> {
        return {
            ngModule: StreamingModule,
            providers: [StreamingService]
        };
    }
}
