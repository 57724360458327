import { cloneDeep } from 'lodash-es';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { UiGlobalApplicationState, UiStateReducer } from '../..';
import { setUiSharedState } from '../actions/ui-shared-state.actions';
import { UiSharedStateStrategy } from '../ui-shared-state-strategy.interface';

/**
 * UiSharedStateStrategy implementation based on local calls only.
 * To be used when application is used in context where engine is not available.
 */
@Injectable({
    providedIn: 'root'
})
export class UiSharedStateLocalStrategy<UiStateType>
    implements UiSharedStateStrategy<UiStateType>
{
    constructor(
        private readonly store: Store<UiGlobalApplicationState<any, any>>
    ) {}

    reduceUiState$(
        reducer: UiStateReducer<UiStateType>
    ): Observable<UiStateReducer<UiStateType>> {
        return this.store.select('engine', 'uiState').pipe(
            first(),
            map((uiState) => {
                const uiStateClone: UiStateType = cloneDeep(uiState || {});

                const reducedUiState = reducer.reducerCallback(uiStateClone);

                this.store.dispatch(
                    setUiSharedState({
                        uiSharedState: reducedUiState
                    })
                );

                return reducer;
            })
        );
    }
}
