import { cloneDeep } from 'lodash-es';
import { Observable } from 'rxjs';
import { debounceTime, filter, map, startWith } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { IllegalStateError, lazyShareReplay } from '@mhp/common';

import { ActivityHandler } from './activity-handler';

export interface InactivityTrackingConfig {
    inactivityTimeout: number;
}

/**
 * Service providing general capabilities to track "inactivity" for different
 * contexts.
 */
@Injectable({
    providedIn: 'root'
})
export class InactivityService {
    private readonly handlerRegistry = new Map<
        string,
        {
            handler: ActivityHandler;
            config: InactivityTrackingConfig;
            inactivityStream$: Observable<void>;
        }
    >();

    /**
     * Start inactivity-tracking in context of a given trackingId using the
     * provided config.
     * @param trackingId
     * @param config
     * @throws IllegalStateError in case a tracking already exists for the given id.
     */
    startInactivityTracking(
        trackingId: string,
        config: InactivityTrackingConfig
    ) {
        if (this.handlerRegistry.get(trackingId)) {
            throw new IllegalStateError(
                `Handler with id ${trackingId} already exists`
            );
        }
        const activityHandler = new ActivityHandler();
        const inactivityStream$ = this.createInactivityStreamForHandler$(
            activityHandler,
            config
        );
        this.handlerRegistry.set(trackingId, {
            handler: activityHandler,
            config: cloneDeep(config),
            inactivityStream$
        });

        return activityHandler;
    }

    /**
     * Stop inactivity tracking for a given trackingId
     * @param trackingId
     */
    stopInactivityTracking(trackingId: string) {
        const handlerInfo = this.handlerRegistry.get(trackingId);
        if (!handlerInfo) {
            return;
        }
        handlerInfo.handler.destroy();
        this.handlerRegistry.delete(trackingId);
    }

    /**
     * Get the activity handler for a given trackingId to possibly
     * register additional activity-providers.
     * @param trackingId
     */
    getActivityHandler(trackingId: string) {
        return this.getHandlerInfo(trackingId).handler;
    }

    /**
     * Get the inactivity stream for a given trackingId
     * @param trackingId
     */
    getInactivityStream$(trackingId: string) {
        return this.getHandlerInfo(trackingId).inactivityStream$;
    }

    private getHandlerInfo(trackingId: string) {
        const handlerInfo = this.handlerRegistry.get(trackingId);
        if (!handlerInfo) {
            throw new IllegalStateError(
                `No handler info found for trackingId ${trackingId}`
            );
        }
        return handlerInfo;
    }

    private createInactivityStreamForHandler$(
        activityHandler: ActivityHandler,
        config: InactivityTrackingConfig
    ): Observable<void> {
        return activityHandler.getActivityStream$().pipe(
            filter((activity) => !!activity),
            startWith({
                type: 'INITIAL_INTERNAL'
            }),
            debounceTime(config.inactivityTimeout),
            map(() => undefined),
            lazyShareReplay()
        );
    }
}
