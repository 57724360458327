import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
    declarations: [],
    imports: [CommonModule, MatDatepickerModule],
    exports: [MatDatepickerModule]
})
export class UiDatepickerModule {}
