import { MonoTypeOperatorFunction, firstValueFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { ConfigModel } from '@mhp-immersive-exp/contracts/src/configuration/config-model.interface';
import { ConfigurationResponsePayload } from '@mhp-immersive-exp/contracts/src/configuration/configuration-response.interface';
import { WebsocketErrorCode } from '@mhp-immersive-exp/contracts/src/websocket/websocket-error-codes';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
    SocketIOService,
    WebsocketEndpointResponseError
} from '../../communication';
import { ProductConfigurationHttpStrategy } from '../http/product-configuration-http-strategy';
import {
    ProxiedConfigurationRequestPayload,
    ProxiedPatchConfigurationRequestPayload,
    REQUEST_GET_CONFIGURATION_PROXIED,
    REQUEST_PATCH_CONFIGURATION_PROXIED
} from './proxied-configuration-request.interface';

export const PRODUCT_CONFIGURATION_HUB_PROXY_CONFIGURATION = new InjectionToken(
    'ProductConfigurationHubProxyConfiguration'
);

export interface ProductConfigurationHubProxyConfiguration {
    getConfigurationResponsePayloadFilter?: (
        payload: ConfigurationResponsePayload
    ) => ConfigurationResponsePayload;
    patchConfigurationResponsePayloadFilter?: (
        payload: ConfigModel[]
    ) => ConfigModel[];
}

/**
 * Registrar that registers product-configuration related endpoints with the hub that allow controlled access to
 * possibly restricted information, e.g. restricted options only visible for authorized users.
 */
@UntilDestroy()
@Injectable()
export class ProductConfigurationHubProxyEndpointRegistrar {
    constructor(
        private readonly socketIoService: SocketIOService,
        private readonly productConfigurationHttpStrategy: ProductConfigurationHttpStrategy,
        @Optional()
        @Inject(PRODUCT_CONFIGURATION_HUB_PROXY_CONFIGURATION)
        private readonly config?: ProductConfigurationHubProxyConfiguration
    ) {
        this.registerGetConfigurationProxyEndpoint();
        this.registerPatchConfigurationProxyEndpoint();
    }

    private registerGetConfigurationProxyEndpoint() {
        this.socketIoService
            .registerEndpoint<
                ProxiedConfigurationRequestPayload,
                ConfigurationResponsePayload
            >(REQUEST_GET_CONFIGURATION_PROXIED, async (requestPayload) => {
                if (!requestPayload) {
                    throw new WebsocketEndpointResponseError('Bad Request', {
                        code: WebsocketErrorCode.WEBSOCKET_PAYLOAD_MISSING,
                        message: 'Payload missing'
                    });
                }
                const getConfigurationHttp$ =
                    this.productConfigurationHttpStrategy
                        .getConfigurationMetadata$(
                            requestPayload.productId,
                            requestPayload.options,
                            undefined,
                            requestPayload.country
                        )
                        .pipe(
                            this.filterGetConfigurationResponse(),
                            this.handleError('getting configuration')
                        );

                return firstValueFrom(getConfigurationHttp$);
            })
            .pipe(untilDestroyed(this))
            .subscribe();
    }

    private registerPatchConfigurationProxyEndpoint() {
        this.socketIoService
            .registerEndpoint<
                ProxiedPatchConfigurationRequestPayload,
                ConfigModel[]
            >(REQUEST_PATCH_CONFIGURATION_PROXIED, async (requestPayload) => {
                if (!requestPayload) {
                    throw new WebsocketEndpointResponseError('Bad Request', {
                        code: WebsocketErrorCode.WEBSOCKET_PAYLOAD_MISSING,
                        message: 'Payload missing'
                    });
                }
                const httpRequest$ = this.productConfigurationHttpStrategy
                    .patchConfiguration$(
                        requestPayload.productId,
                        requestPayload.options,
                        requestPayload.country,
                        requestPayload.add,
                        requestPayload.remove
                    )
                    .pipe(
                        this.filterPatchConfigurationResponse(),
                        this.handleError('patching configuration')
                    );

                return firstValueFrom(httpRequest$);
            })
            .pipe(untilDestroyed(this))
            .subscribe();
    }

    private filterGetConfigurationResponse(): MonoTypeOperatorFunction<ConfigurationResponsePayload> {
        return (source) => {
            if (!this.config?.getConfigurationResponsePayloadFilter) {
                return source;
            }

            return source.pipe(
                map((payload) =>
                    this.config?.getConfigurationResponsePayloadFilter
                        ? this.config.getConfigurationResponsePayloadFilter(
                              payload
                          )
                        : payload
                )
            );
        };
    }

    private filterPatchConfigurationResponse(): MonoTypeOperatorFunction<
        ConfigModel[]
    > {
        return (source) => {
            if (!this.config?.patchConfigurationResponsePayloadFilter) {
                return source;
            }

            return source.pipe(
                map((payload) =>
                    this.config?.patchConfigurationResponsePayloadFilter
                        ? this.config.patchConfigurationResponsePayloadFilter(
                              payload
                          )
                        : payload
                )
            );
        };
    }

    private handleError<T>(intent: string): MonoTypeOperatorFunction<T> {
        return (source) =>
            source.pipe(
                catchError((error) => {
                    let message: string;
                    if (error instanceof HttpErrorResponse) {
                        const fallbackMessage = `Proxied request returned status code ${error.status}: ${error.statusText}`;
                        if (error.error?.code) {
                            message = error.error?.message || fallbackMessage;
                            throw new WebsocketEndpointResponseError(message, {
                                code:
                                    WebsocketErrorCode[error.error?.code] ||
                                    WebsocketErrorCode.UNKNOWN,
                                message
                            });
                        }
                        throw new WebsocketEndpointResponseError(
                            fallbackMessage,
                            {
                                code: WebsocketErrorCode.UNKNOWN,
                                message: fallbackMessage
                            }
                        );
                    }
                    message = `Failed ${intent} due to unknown reasons`;
                    throw new WebsocketEndpointResponseError(message, {
                        code: WebsocketErrorCode.UNKNOWN,
                        message
                    });
                })
            );
    }
}
