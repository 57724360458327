import { ModuleWithProviders, NgModule } from '@angular/core';

import {
    ENGINE_CONNECTION_STATE_SERVICE_CONFIG_TOKEN,
    EngineConnectionStateService,
    EngineConnectionStateServiceConfig
} from './engine-connection-state.service';

@NgModule({
    imports: [],
    providers: [
        {
            provide: ENGINE_CONNECTION_STATE_SERVICE_CONFIG_TOKEN,
            useValue: <EngineConnectionStateServiceConfig>{
                applicationHeartbeatInterval: 2000,
                applicationHeartbeatClient: 'default'
            }
        }
    ]
})
export class EngineConnectionStateModule {
    static forRoot(): ModuleWithProviders<EngineConnectionStateModule> {
        return {
            ngModule: EngineConnectionStateModule,
            providers: [EngineConnectionStateService]
        };
    }
}
