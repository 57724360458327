<div
    class="ui-selected-marker"
    [ngClass]="{
        'ui-selected-marker--selected': selected,
        'ui-selected-marker--underline-hidden': !showUnderline,
        'mat-primary': !color || color === 'primary',
        'mat-accent': color === 'accent',
        'mat-warn': color === 'warn'
    }"
>
    <div
        class="ui-selected-marker__image-selected-icon"
        *ngIf="markerIconId !== undefined"
        [ngClass]="{
            'ui-selected-marker__image-selected-icon--centered': centerMarker
        }"
    >
        <mhp-ui-icon svgIcon="mhp-ui:check" [size]="markerSize"></mhp-ui-icon>
    </div>
</div>
