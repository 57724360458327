import { isString } from 'lodash-es';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { MonoTypeOperatorFunction, Observable, defer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SalesforceCompleteSessionInterface } from '@mhp/aml-shared/data-proxy/salesforce-complete-session.interface';
import { SalesforceConfigurationSendInterface } from '@mhp/aml-shared/data-proxy/salesforce-configuration-send.interface';
import { SalesforceCustomerBookingUpdateInterface } from '@mhp/aml-shared/data-proxy/salesforce-customer-booking-update.interface';
import { SalesforceCustomerBookingInterface } from '@mhp/aml-shared/data-proxy/salesforce-customer-booking.interface';
import { SalesforceDealerBookingInterface } from '@mhp/aml-shared/data-proxy/salesforce-dealer-booking.interface';
import { SalesforceSetPreferencesInterface } from '@mhp/aml-shared/data-proxy/salesforce-set-preferences.interface';
import { SalesforceSetPrivacyDisclaimerInterface } from '@mhp/aml-shared/data-proxy/salesforce-set-privacy-disclaimer.interface';
import { RecaptchaError } from '@mhp/ui-shared-services';

export type SalesforceRequestConfigurationViaEmailInterface = Omit<
    SalesforceConfigurationSendInterface,
    'zipCodeNumber' | 'dealerDirectoryAddressId' | 'dealerName'
>;

export interface LeadDetails {
    title: string;
    firstName: string;
    lastName: string;
    customerEmail: string;
    customerPhone: string;
    transactionKey: string;
}

export interface SalesforceServiceConfig {
    dataProxyBaseUrl: string;
}

export const SALESFORCE_SERVICE_CONFIG_TOKEN =
    new InjectionToken<SalesforceServiceConfig>('SalesforceServiceConfig');

/**
 * Service responsible for communicating with the synergy / dataproxy backend.
 */
@Injectable({
    providedIn: 'root'
})
export class SalesforceService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly recaptchaV3Service: ReCaptchaV3Service,
        @Inject(SALESFORCE_SERVICE_CONFIG_TOKEN)
        private readonly config: SalesforceServiceConfig
    ) {}

    /**
     * Submit the given info to synergy so that the user is contacted by the dealer
     * in question.
     * @param payload The info to submit.
     */
    submitToDealer(
        payload: SalesforceConfigurationSendInterface
    ): Observable<SalesforceConfigurationSendInterface> {
        return this.withRecaptchaProtection$(
            'SalesforceConfigurationSend'
        ).pipe(
            switchMap((token) =>
                this.httpClient
                    .post(
                        `${this.config.dataProxyBaseUrl}/api/salesforce/configurationsend`,
                        payload,
                        {
                            headers: { recaptcha: token }
                        }
                    )
                    .pipe(
                        this.handleRecaptchaError(),
                        map(() => payload)
                    )
            )
        );
    }

    /**
     * Submit the given info to synergy to request a pdf being sent to the email provided.
     * @param payload The info to submit.
     */
    requestPdfViaMail$(
        payload: SalesforceRequestConfigurationViaEmailInterface
    ) {
        return this.submitToDealer(payload);
    }

    /**
     * End a 1:1 session.
     * @param payload The info to be submitted
     */
    completeOne2OneSession$(payload: SalesforceCompleteSessionInterface) {
        return this.withRecaptchaProtection$('SalesforceCompleteSession').pipe(
            switchMap((token) =>
                this.httpClient
                    .patch(
                        `${this.config.dataProxyBaseUrl}/api/salesforce/completesession`,
                        payload,
                        {
                            headers: { recaptcha: token }
                        }
                    )
                    .pipe(
                        this.handleRecaptchaError(),
                        map(() => undefined)
                    )
            )
        );
    }

    /**
     * Create a dealer one2one booking.
     * @param payload The info to be submitted
     */
    createDealerOne2OneBooking$(payload: SalesforceDealerBookingInterface) {
        return this.withRecaptchaProtection$('SalesforceDealerBooking').pipe(
            switchMap((token) =>
                this.httpClient
                    .post(
                        `${this.config.dataProxyBaseUrl}/api/salesforce/dealerbooking`,
                        payload,
                        {
                            headers: { recaptcha: token }
                        }
                    )
                    .pipe(
                        this.handleRecaptchaError(),
                        map(() => undefined)
                    )
            )
        );
    }

    /**
     * Create a customer one2one booking.
     * @param payload The info to be submitted
     */
    createCustomerOne2OneBooking$(payload: SalesforceCustomerBookingInterface) {
        return this.withRecaptchaProtection$('SalesforceCustomerBooking').pipe(
            switchMap((token) =>
                this.httpClient
                    .post(
                        `${this.config.dataProxyBaseUrl}/api/salesforce/customerbooking`,
                        payload,
                        {
                            headers: { recaptcha: token }
                        }
                    )
                    .pipe(
                        this.handleRecaptchaError(),
                        map(() => undefined)
                    )
            )
        );
    }

    /**
     * Update a customer one2one-booking.
     * @param payload The info to be submitted
     */
    updateCustomerOne2OneBooking$(
        payload: SalesforceCustomerBookingUpdateInterface
    ) {
        return this.withRecaptchaProtection$(
            'SalesforceCustomerBookingUpdate'
        ).pipe(
            switchMap((token) =>
                this.httpClient
                    .patch(
                        `${this.config.dataProxyBaseUrl}/api/salesforce/customerbooking`,
                        payload,
                        {
                            headers: { recaptcha: token }
                        }
                    )
                    .pipe(
                        this.handleRecaptchaError(),
                        map(() => undefined)
                    )
            )
        );
    }

    /**
     * Update a customers preferences when joining a one2one session.
     * @param payload The info to be submitted
     */
    updateCustomerOne2OnePreferences$(
        payload: SalesforceSetPreferencesInterface
    ) {
        return this.withRecaptchaProtection$('SalesforceSetPreferences').pipe(
            switchMap((token) =>
                this.httpClient
                    .patch(
                        `${this.config.dataProxyBaseUrl}/api/salesforce/setpreferences`,
                        payload,
                        {
                            headers: { recaptcha: token }
                        }
                    )
                    .pipe(
                        this.handleRecaptchaError(),
                        map(() => undefined)
                    )
            )
        );
    }

    /**
     * Update a customers secret-models disclaimer agreement.
     * @param payload The info to be submitted
     */
    updateCustomerOne2OnePrivacyDisclaimer$(
        payload: SalesforceSetPrivacyDisclaimerInterface
    ) {
        return this.withRecaptchaProtection$(
            'SalesforceSetPrivacyDisclaimer'
        ).pipe(
            switchMap((token) =>
                this.httpClient
                    .patch(
                        `${this.config.dataProxyBaseUrl}/api/salesforce/setprivacydisclaimer`,
                        payload,
                        {
                            headers: { recaptcha: token }
                        }
                    )
                    .pipe(
                        this.handleRecaptchaError(),
                        map(() => undefined)
                    )
            )
        );
    }

    /**
     * Get customer details for an active transactionId
     * @param transactionId The transactionId to fetch the relevant LeadDetails for.
     */
    getLeadDetails$(
        transactionId: string
    ): Observable<LeadDetails | undefined> {
        return this.httpClient
            .get<{ customer: LeadDetails; message: string }>(
                `${this.config.dataProxyBaseUrl}/api/salesforce/customerbooking/${transactionId}`
            )
            .pipe(map(({ customer }) => customer));
    }

    private withRecaptchaProtection$(identifier: string) {
        return defer(() => this.recaptchaV3Service.execute(identifier));
    }

    private handleRecaptchaError<T>(): MonoTypeOperatorFunction<T> {
        return (source) =>
            source.pipe(
                catchError((error) => {
                    if (error instanceof HttpErrorResponse) {
                        if (
                            error.status === 400 &&
                            isString(error.error?.message) &&
                            error.error.message.startsWith(
                                'Low recaptcha score'
                            )
                        ) {
                            throw new RecaptchaError(
                                error.error?.message,
                                error
                            );
                        }
                    }
                    throw error;
                })
            );
    }
}
