import { Action, createReducer, on } from '@ngrx/store';

import { setActiveRegion } from '../actions/settings-state.actions';
import { SettingsState } from '../settings-state.interface';

const INITIAL_STATE: SettingsState = {};

const reducer = createReducer<SettingsState | undefined>(
    INITIAL_STATE,
    on(setActiveRegion, (state: SettingsState, payload) => ({
        ...state,
        activeRegion: payload.region
    }))
);

export function settingsStateReducer(
    state: SettingsState | undefined,
    action: Action
) {
    return reducer(state, action);
}
