import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatInputModule } from '@angular/material/input';

@NgModule({
    declarations: [],
    imports: [CommonModule, MatInputModule],
    exports: [MatInputModule]
})
export class UiInputModule {}
