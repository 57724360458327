export class CustomError<T = any> extends Error {
    __proto__: Error;

    /**
     * Tests wether a given errorCandidate is of type CustomerError.
     * @param errorCadidate
     */
    static isCustomError(errorCadidate: any): errorCadidate is CustomError {
        return errorCadidate instanceof CustomError;
    }

    constructor(public override message: string, public details?: T) {
        super(message);
        this.name = new.target.name;
        // see https://github.com/Microsoft/TypeScript/issues/13965 for this mess...
        const trueProto = new.target.prototype;
        // eslint-disable-next-line no-proto
        this.__proto__ = trueProto;
    }
}
