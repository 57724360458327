import { BehaviorSubject, Observable, filter, map, take } from 'rxjs';

import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { UtmParametersWrapper } from './utm-parameters-wrapper';

export const UTM_PARAMETERS_SOURCE = 'utm_source';

@Injectable({
    providedIn: 'root'
})
export class UtmParametersService {
    private readonly utmParametersSubject = new BehaviorSubject<
        Record<string, string> | undefined
    >(undefined);

    constructor(private readonly router: Router) {
        this.initGrabUTMParametersFromUrl();
    }

    /**
     * Get the utm parameters from the url. In case no `utm_` parameters have been caught yet, this method will return `undefined`.
     */
    getUtmParameters(): UtmParametersWrapper | undefined {
        return this.utmParametersSubject.value
            ? new UtmParametersWrapper(this.utmParametersSubject.value)
            : undefined;
    }

    /**
     * Get the utm parameters from the url once they have been caught.
     * Note that the resulting value might still not contain any entries in case no `utm_` parameters are available.
     */
    getUtmParameters$(): Observable<UtmParametersWrapper> {
        return this.utmParametersSubject.pipe(
            filter(
                (utmParameters): utmParameters is Record<string, string> =>
                    utmParameters !== undefined
            ),
            map((utmParameters) => new UtmParametersWrapper(utmParameters))
        );
    }

    /**
     * Wait for the first navigation to complete and grab utm parameters from url
     */
    private initGrabUTMParametersFromUrl() {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1),
                map(() => {
                    const { queryParams } =
                        this.router.routerState.snapshot.root;
                    // extract all utm parameters from url
                    return Object.entries(queryParams).reduce(
                        (previousValue, [currentKey, currentValue]) => {
                            if (currentKey.startsWith('utm_')) {
                                previousValue[currentKey] = currentValue;
                            }
                            return previousValue;
                        },
                        {} as Record<string, string>
                    );
                })
            )
            .subscribe((utmParameters) => {
                this.utmParametersSubject.next(utmParameters);
            });
    }
}
