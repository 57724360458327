import { isString } from 'lodash-es';
import { Observable, defer, from, of, switchMapTo } from 'rxjs';
import {
    catchError,
    filter,
    first,
    map,
    switchMap,
    take,
    withLatestFrom
} from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional, SkipSelf } from '@angular/core';
import { CameraType } from '@mhp-immersive-exp/contracts/src/camera/camera.interface';
import { Cinematic } from '@mhp-immersive-exp/contracts/src/cinematic/cinematic.interface';
import { EnvironmentLightingProfileState } from '@mhp-immersive-exp/contracts/src/environment/environment.interface';
import { Highlight } from '@mhp-immersive-exp/contracts/src/highlight/highlight.interface';
import { ScreenshotOptions } from '@mhp-immersive-exp/contracts/src/screenshot';
import {
    IllegalStateError,
    MemoizeObservable,
    lazyShareReplay
} from '@mhp/common';
import {
    AnimationState,
    CinematicState,
    ContextOptionState,
    HighlightState
} from '@mhp/communication-models';

import { ProductConfigurationService } from '../product-configuration';
import { ProductDataService } from '../product-data';
import { StrategyProvider, withCurrentStrategy$ } from '../strategy';
import {
    CinematicCaptureInfo,
    ENGINE_CONTROL_STRATEGY_PROVIDER_TOKEN,
    EngineControlState,
    EngineControlStrategy,
    ProductConfigurationInfo,
    ScreenshotInfo
} from './engine-control-strategy.interface';
import { EngineStateService } from './engine-state.service';

/**
 * Defines the contract for interceptors that may be used to trigger certain actions
 * before or after an engine-action has been triggered.
 */
export interface EngineActionInterceptor<T = unknown> {
    beforeAction?: (context?: T) => Observable<void> | Promise<void> | void;
    afterAction?: (context?: T) => Observable<void> | Promise<void> | void;
}

/**
 * Service to control engine specific parameters, e.g. active environment, day/night state, ...
 * The service is backed by a concrete implementation provided via ENGINE_CONTROL_COMMUNICATION_FACADE_PROVIDER_TOKEN.
 *
 * To get information about the engine state, see EngineStateService.
 */
@Injectable()
export class EngineControlService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly productDataService: ProductDataService,
        private readonly productConfigurationService: ProductConfigurationService,
        private readonly engineStateService: EngineStateService,
        @Inject(ENGINE_CONTROL_STRATEGY_PROVIDER_TOKEN)
        private readonly strategyProvider: StrategyProvider<EngineControlStrategy>,
        @Optional() @SkipSelf() parentService?: EngineControlService
    ) {
        if (parentService) {
            throw new Error(
                'EngineControlService is already loaded. Use modules forRoot() only once where needed.'
            );
        }
    }

    /**
     * Apply the given state in one go.
     * @param engineControlState The state to apply.
     */
    applyEngineControlState$(engineControlState: EngineControlState) {
        return withCurrentStrategy$(this.strategyProvider).pipe(
            switchMap((strategy) =>
                strategy.applyEngineControlState$(engineControlState)
            )
        );
    }

    /**
     * Sets the productId + configuration via the currently active engine-strategy.
     * @param configurationInfo The information to be applied.
     */
    setProductConfiguration$(
        configurationInfo: ProductConfigurationInfo
    ): Observable<ProductConfigurationInfo> {
        return withCurrentStrategy$(this.strategyProvider).pipe(
            switchMap((strategy) =>
                strategy.setProductConfiguration$(configurationInfo)
            )
        );
    }

    /**
     * Returns the active environment-id if any.
     * @deprecated See EngineStateService
     */
    @MemoizeObservable()
    getActiveEnvironmentId$(): Observable<string | undefined> {
        return this.engineStateService.getActiveEnvironmentId$();
    }

    /**
     * Returns the active environment-state if any.
     * @deprecated See EngineStateService
     */
    @MemoizeObservable()
    getActiveEnvironmentDayNightState$(): Observable<
        EnvironmentLightingProfileState | undefined
    > {
        return this.engineStateService.getActiveEnvironmentDayNightState$();
    }

    /**
     * Returns the active environments ContextOptionStates if any.
     * @deprecated See EngineStateService
     */
    @MemoizeObservable()
    getActiveEnvironmentContextOptionStates$(): Observable<
        ContextOptionState[] | undefined
    > {
        return this.engineStateService.getActiveEnvironmentContextOptionStates$();
    }

    /**
     * Update a context-options value.
     * @param id The context actions id
     * @param value The context options value
     */
    setContextOptionValue(id: string, value: string): Observable<void> {
        return withCurrentStrategy$(this.strategyProvider).pipe(
            switchMap((facade) => facade.setContextOptionValue$(id, value))
        );
    }

    /**
     * Switch to the given environment.
     * @param id
     * @param environmentState Optional. The target state for the environment to be applied.
     */
    setActiveEnvironmentId(
        id: string,
        environmentState?: EnvironmentLightingProfileState
    ): Observable<string> {
        return withCurrentStrategy$(this.strategyProvider).pipe(
            switchMap((facade) =>
                facade.setActiveEnvironmentId$(id, environmentState)
            )
        );
    }

    /**
     * Returns the active environment-id if any.
     * @deprecated See EngineStateService
     */
    @MemoizeObservable()
    getEnvironmentMode$(): Observable<
        EnvironmentLightingProfileState | undefined
    > {
        return this.engineStateService.getEnvironmentMode$();
    }

    /**
     * Set active environment mode to either day of night
     */
    toggleEnvironmentMode(
        jump = false
    ): Observable<EnvironmentLightingProfileState | undefined> {
        return this.getEnvironmentMode$().pipe(
            first(),
            withLatestFrom(withCurrentStrategy$(this.strategyProvider)),
            switchMap(([environmentMode, facade]) =>
                facade.setEnvironmentState$(
                    environmentMode === EnvironmentLightingProfileState.DAY
                        ? EnvironmentLightingProfileState.NIGHT
                        : EnvironmentLightingProfileState.DAY
                )
            ),
            lazyShareReplay()
        );
    }

    /**
     * Set active environment mode to either day of night
     */
    setEnvironmentMode(
        targetMode: EnvironmentLightingProfileState
    ): Observable<EnvironmentLightingProfileState | undefined> {
        return withCurrentStrategy$(this.strategyProvider).pipe(
            switchMap((strategy) => strategy.setEnvironmentState$(targetMode)),
            lazyShareReplay()
        );
    }

    /**
     * Emits the current list of AnimationStates valid for the loaded product.
     * @deprecated See EngineStateService
     */
    @MemoizeObservable()
    getAnimationStates$(): Observable<AnimationState[]> {
        return this.engineStateService.getAnimationStates$();
    }

    /**
     * Toggles the animation-state of a given animation
     * @param id The animations identifier
     * @param interceptor An optional interceptor that can be used to trigger actions before or after the animation has been set.
     */
    toggleAnimationState(
        id: string,
        interceptor?: EngineActionInterceptor<{
            id: string;
            direction: 'START' | 'END';
        }>
    ): Observable<'START' | 'END'> {
        return this.getAnimationStates$().pipe(
            take(1),
            map((animationStates) =>
                animationStates.find((animState) => animState.id === id)
            ),
            switchMap((animationState) => {
                const targetAnimationState =
                    (animationState?.state ?? 'START') === 'START'
                        ? 'END'
                        : 'START';
                return this.setAnimationState(
                    id,
                    targetAnimationState,
                    interceptor
                ).pipe(map(() => targetAnimationState));
            })
        );
    }

    /**
     * Sets the animation-state of a given animation
     * @param id The animations identifier
     * @param state The state to put the animation in
     * @param interceptor An optional interceptor that can be used to trigger actions before or after the animation has been set.
     */
    setAnimationState(
        id: string,
        state: 'START' | 'END',
        interceptor?: EngineActionInterceptor<{
            id: string;
            direction: 'START' | 'END';
        }>
    ): Observable<void> {
        return withCurrentStrategy$(this.strategyProvider).pipe(
            withLatestFrom(this.productDataService.getAvailableAnimations$()),
            switchMap(([facade, availableAnimations]) =>
                this.getAnimationStates$().pipe(
                    take(1),
                    switchMap((animationStates) => {
                        if (
                            animationStates.find(
                                (currentState) =>
                                    currentState.id === id &&
                                    currentState.state === state
                            )
                        ) {
                            return of(undefined);
                        }

                        const interceptedBefore$ = (
                            interceptor?.beforeAction
                                ? from(
                                      interceptor.beforeAction({
                                          id,
                                          direction: state
                                      }) || Promise.resolve()
                                  )
                                : of(undefined)
                        ).pipe(catchError(() => of(undefined)));
                        const interceptedAfter$ = (
                            interceptor?.afterAction
                                ? from(
                                      interceptor.afterAction({
                                          id,
                                          direction: state
                                      }) || Promise.resolve()
                                  )
                                : of(undefined)
                        ).pipe(catchError(() => of(undefined)));

                        return interceptedBefore$.pipe(
                            switchMapTo(
                                defer(() =>
                                    facade.setAnimationState$(id, state)
                                )
                            ),
                            // wait for animation-state actually changed
                            switchMap(() => {
                                if (
                                    !availableAnimations?.find(
                                        (animation) => animation.id === id
                                    )
                                ) {
                                    return of(undefined);
                                }
                                // wait until the animation got updated
                                return this.engineStateService
                                    .getAnimationStates$()
                                    .pipe(
                                        filter(
                                            (animationStates2) =>
                                                !!animationStates2.find(
                                                    (currentState) =>
                                                        currentState.id ===
                                                            id &&
                                                        currentState.state ===
                                                            state
                                                )
                                        ),
                                        take(1),
                                        map(() => undefined)
                                    );
                            }),
                            switchMapTo(defer(() => interceptedAfter$))
                        );
                    })
                )
            ),
            map(() => undefined)
        );
    }

    /**
     * Emits the current active camera.
     * @deprecated See EngineStateService
     */
    @MemoizeObservable()
    getActiveCamera$(): Observable<string | undefined> {
        return this.engineStateService.getActiveCamera$();
    }

    /**
     * Get the default camera for the current context (exterior/interior).
     * @deprecated See EngineStateService
     */
    @MemoizeObservable()
    getDefaultCameraForContext$(): Observable<string | undefined> {
        return this.engineStateService.getDefaultCameraForContext$();
    }

    /**
     * Get the available cameras for the current context where context is:
     * - active camera type (int / intVr / ext / extVr)
     * @deprecated See EngineStateService
     */
    @MemoizeObservable()
    getAvailableCamerasForContext$(): Observable<string[] | undefined> {
        return this.engineStateService.getAvailableCamerasForContext$();
    }

    /**
     * Get the type of the currently active camera.
     * @deprecated See EngineStateService
     */
    @MemoizeObservable()
    getActiveCameraType$(): Observable<CameraType | undefined> {
        return this.engineStateService.getActiveCameraType$();
    }

    /**
     * Reset the camera to the currently active contexts (ext/int) default camera.
     */
    resetCamera$() {
        return this.getDefaultCameraForContext$().pipe(
            first(),
            switchMap((defaultCameraId) => {
                if (!defaultCameraId) {
                    return of(undefined);
                }
                return this.setActiveCamera(defaultCameraId);
            })
        );
    }

    /**
     * Switch to the given camera.
     * @param id
     * @param skipIfUnchanged Skip setting the camera in case it's the same as the currently active one.
     */
    setActiveCamera(id: string, skipIfUnchanged = false): Observable<string> {
        return withCurrentStrategy$(this.strategyProvider).pipe(
            switchMap((facade) => {
                if (!skipIfUnchanged) {
                    return facade.setActiveCamera$(id);
                }
                return this.getActiveCamera$().pipe(
                    first(),
                    switchMap((activeCameraId) => {
                        if (activeCameraId === id) {
                            return of(id);
                        }
                        return facade.setActiveCamera$(id);
                    })
                );
            })
        );
    }

    /**
     * Emits the HighlightState valid for the loaded product.
     * @deprecated See EngineStateService
     */
    @MemoizeObservable()
    getHighlightState$(): Observable<HighlightState | undefined> {
        return this.engineStateService.getHighlightState$();
    }

    /**
     * Emits the currently active highlight for the loaded product (if any).
     * @deprecated See EngineStateService
     */
    @MemoizeObservable()
    getActiveHighlight$(): Observable<Highlight | undefined> {
        return this.engineStateService.getActiveHighlight$();
    }

    /**
     * Toggles the highlight-state of a given highlight
     * @param id The highlights identifier
     */
    toggleHighlightState(id: string): Observable<void> {
        return this.getHighlightState$().pipe(
            take(1),
            map((activeState) => {
                let targetState: 'ACTIVE' | 'INACTIVE';
                if (activeState && activeState.id === id) {
                    targetState = 'INACTIVE';
                } else {
                    targetState = 'ACTIVE';
                }
                return targetState;
            }),
            withLatestFrom(withCurrentStrategy$(this.strategyProvider)),
            switchMap(([targetState, facade]) =>
                facade.setHighlightState$(id, targetState)
            ),
            map(() => undefined)
        );
    }

    /**
     * Stops the highlight mode
     */
    stopHighlight(): Observable<void> {
        return this.getHighlightState$().pipe(
            take(1),
            withLatestFrom(withCurrentStrategy$(this.strategyProvider)),
            switchMap(([activeHighlightState, facade]) => {
                if (!activeHighlightState) {
                    return of(undefined);
                }
                return facade.stopHighlight$();
            })
        );
    }

    /**
     * Emits the currently active cinematic for the loaded product (if any).
     * @deprecated See EngineStateService
     */
    @MemoizeObservable()
    getActiveCinematic$(): Observable<Cinematic | undefined> {
        return this.engineStateService.getActiveCinematic$();
    }

    /**
     * Emits the CinematicState valid for the loaded product.
     * @deprecated See EngineStateService
     */
    @MemoizeObservable()
    getCinematicState$(): Observable<CinematicState | undefined> {
        return this.engineStateService.getCinematicState$();
    }

    /**
     * Toggles the playback-state of a given cinematic
     * @param id The cinematics identifier
     */
    toggleCinematicState(id: string): Observable<void> {
        return this.getCinematicState$().pipe(
            take(1),
            map((activeState) => {
                let targetState: 'ACTIVE' | 'INACTIVE';
                if (activeState && activeState.id === id) {
                    targetState = 'INACTIVE';
                } else {
                    targetState = 'ACTIVE';
                }
                return targetState;
            }),
            withLatestFrom(withCurrentStrategy$(this.strategyProvider)),
            switchMap(([targetState, facade]) =>
                facade.setCinematicState$(id, targetState)
            ),
            map(() => undefined)
        );
    }

    /**
     * Stops the cinematic mode
     */
    stopCinematic(): Observable<void> {
        return this.getCinematicState$().pipe(
            take(1),
            withLatestFrom(withCurrentStrategy$(this.strategyProvider)),
            switchMap(([activeState, facade]) => {
                if (!activeState) {
                    return of(undefined);
                }
                return facade.stopCinematic$();
            }),
            map(() => undefined)
        );
    }

    /**
     * Take a screenshot of the currently visible rendering and return the URL from which to fetch the image.
     * @param options Options for the screenshot request
     * @return Observable stream emitting the URL to the shot and completes afterwards.
     */
    takeScreenshot$(options?: ScreenshotOptions): Observable<ScreenshotInfo> {
        return withCurrentStrategy$(this.strategyProvider).pipe(
            switchMap((facade) => facade.takeScreenshot$(options)),
            first(),
            withLatestFrom(
                // eslint-disable-next-line no-nested-ternary
                options?.filenameBase
                    ? isString(options.filenameBase)
                        ? of(options.filenameBase)
                        : from(options.filenameBase)
                    : of(undefined)
            ),
            switchMap(
                ([
                    screenshotSource,
                    filenameBase
                ]): Observable<ScreenshotInfo> => {
                    let targetFilename = '';

                    if (screenshotSource.filename) {
                        targetFilename = screenshotSource.filename;
                    }

                    if (filenameBase || !targetFilename) {
                        let fileExtension: string | undefined;
                        if (screenshotSource.mimeType === 'image/jpeg') {
                            fileExtension = 'jpeg';
                        } else if (screenshotSource.mimeType === 'image/png') {
                            fileExtension = 'png';
                        }

                        if (!fileExtension) {
                            throw new IllegalStateError(
                                `Could not map mimeType ${screenshotSource.mimeType} to file-extension`
                            );
                        }

                        targetFilename = `${
                            filenameBase ?? uuidv4()
                        }.${fileExtension}`;
                    }

                    const { source } = screenshotSource;

                    if (source instanceof Blob) {
                        return of({
                            blob: source,
                            filename: targetFilename,
                            mimeType: screenshotSource.mimeType
                        });
                    }
                    if (options?.provideBlob) {
                        return this.httpClient
                            .get(source, {
                                responseType: 'blob'
                            })
                            .pipe(
                                map(
                                    (imageBlob): ScreenshotInfo => ({
                                        sourceUrl: source,
                                        blob: imageBlob,
                                        filename: targetFilename,
                                        mimeType: screenshotSource.mimeType
                                    })
                                )
                            );
                    }
                    return of({
                        sourceUrl: source,
                        filename: targetFilename,
                        mimeType: screenshotSource.mimeType
                    });
                }
            ),
            lazyShareReplay()
        );
    }

    /**
     * Request a cinematic capture.
     * Unsubscribing from the stream cancels the encoding process.
     *
     * @param cinematicId Cinematic ID to be captured
     *
     * @return Observable emitting the download-URL when ready.
     */
    captureCinematic$(cinematicId: string): Observable<CinematicCaptureInfo> {
        return withCurrentStrategy$(this.strategyProvider).pipe(
            switchMap((strategy) => strategy.captureCinematic$(cinematicId))
        );
    }
}
