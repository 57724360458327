import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CanColor, ThemePalette } from '@angular/material/core';

import { IconSize } from '../ui-icons/ui-icon';

@Component({
    selector: 'mhp-ui-selected-marker',
    templateUrl: './ui-selected-marker.component.html',
    styleUrls: ['./ui-selected-marker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiSelectedMarkerComponent implements CanColor {
    @Input()
    selected: boolean;

    @Input()
    color: ThemePalette;

    @Input()
    defaultColor: ThemePalette;

    @Input()
    centerMarker: boolean;

    @Input()
    markerSize = IconSize.MEDIUM;

    @Input()
    markerIconId: string | undefined = 'mhp-ui:check';

    @Input()
    showUnderline = true;
}
