import 'img-comparison-slider';
import { isNumber } from 'lodash-es';
import { ReplaySubject, fromEvent } from 'rxjs';

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ImageSrcset } from '../common';
import { IconSize } from '../ui-icons';

export const isMouseEventOrPointerEvent = (
    event: Event
): event is MouseEvent | PointerEvent =>
    event.type === 'mousedown' || event.type === 'pointerdown';

export const isElementAffected = (
    element: HTMLElement,
    e: MouseEvent | TouchEvent
): boolean => {
    const rect = element.getBoundingClientRect();
    let eventX: number;
    let eventY: number;
    if (isMouseEventOrPointerEvent(e)) {
        eventX = e.clientX;
        eventY = e.clientY;
    } else {
        eventX = e.touches[0].clientX;
        eventY = e.touches[0].clientY;
    }

    return (
        eventX >= rect.x &&
        eventX <= rect.x + rect.width &&
        eventY >= rect.y &&
        eventY <= rect.y + rect.height
    );
};

@UntilDestroy()
@Component({
    selector: 'mhp-ui-img-comparison-slider',
    templateUrl: './ui-img-comparison-slider.component.html',
    styleUrls: ['./ui-img-comparison-slider.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiImgComparisonSliderComponent implements AfterViewInit {
    protected readonly dragHandlerInteractedSubject =
        new ReplaySubject<boolean>(1);

    protected readonly IconSize = IconSize;

    @ViewChild('comparisonSlider')
    comparisonSlider!: ElementRef<HTMLElement>;

    @Input()
    imageCurrentUrl: ImageSrcset | string | undefined;

    @Input()
    imageNeutralUrl: ImageSrcset | string | undefined;

    @Input()
    handleHidden = false;

    @Input()
    fixedAspectRatio?: number;

    @Input()
    handleWidth = 40;

    @Input()
    dragIconId = 'mhp-ui:drag';

    @Input()
    dragIconLabel?: string;

    @Input()
    stopEventPropagationOnHandleInteraction = false;

    @Output()
    readonly dragHandlerInteracted =
        this.dragHandlerInteractedSubject.asObservable();

    @HostBinding('style.--aspectRatioPadding')
    get fixedAspectRatioPadding() {
        return isNumber(this.fixedAspectRatio)
            ? `${100 / this.fixedAspectRatio}%`
            : '0';
    }

    ngAfterViewInit() {
        this.adjustStyling();
        this.adjustBehavior();
    }

    private adjustStyling() {
        const secondElementSelector = '#second';
        const secondElement =
            this.comparisonSlider.nativeElement.shadowRoot?.querySelector<HTMLElement>(
                secondElementSelector
            );
        const firstElementSelector = '#first';
        const firstElement =
            this.comparisonSlider.nativeElement.shadowRoot?.querySelector<HTMLElement>(
                firstElementSelector
            );
        if (
            !this.assertElementExists(secondElement, secondElementSelector) ||
            !this.assertElementExists(firstElement, firstElementSelector)
        ) {
            return;
        }
        // adjust some stylings to fit our usecase
        secondElement.style.height = '100%';
        firstElement.style.zIndex = '10';
    }

    /**
     * When we interact with the comparison sliders handle, stop propagation of the
     * event to prevent collisions with touch-sensitive components further up in the
     * hierarchy.
     * @private
     */
    private adjustBehavior() {
        const handleElementSelector = '#handle';
        const handleElement =
            this.comparisonSlider.nativeElement.shadowRoot?.querySelector<HTMLElement>(
                handleElementSelector
            );
        const firstElementSelector = '#first';
        const firstElement =
            this.comparisonSlider.nativeElement.shadowRoot?.querySelector<HTMLElement>(
                firstElementSelector
            );
        if (
            !this.assertElementExists(handleElement, handleElementSelector) ||
            !this.assertElementExists(firstElement, firstElementSelector)
        ) {
            return;
        }
        (
            ['pointerdown', 'mousedown', 'touchstart'] as [
                'pointerdown',
                'mousedown',
                'touchstart'
            ]
        ).forEach((eventName) => {
            fromEvent<MouseEvent | TouchEvent | PointerEvent>(
                this.comparisonSlider.nativeElement,
                eventName
            )
                .pipe(untilDestroyed(this))
                .subscribe((event: MouseEvent | TouchEvent | PointerEvent) => {
                    if (isElementAffected(handleElement, event)) {
                        if (this.stopEventPropagationOnHandleInteraction) {
                            event.stopPropagation();
                        }
                        this.dragHandlerInteractedSubject.next(true);
                    }
                });
        });
    }

    private assertElementExists<T>(
        element: T,
        selector: string
    ): element is NonNullable<T> {
        if (!element) {
            throw new Error(
                `Could not find ${selector} element in img-comparison-slider. Has the library been upgraded?`
            );
        }
        return true;
    }
}
