import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { environment } from '../environments/environment';
import { SCOPE_DEPENDENT_REGION_CHILD_ROUTES } from '../environments/environment.modules';
import {
    PARAMETER_REGION,
    ROUTE_CONFIGURATION_SESSION,
    ROUTE_ERROR,
    ROUTE_MODEL_SELECT
} from './app-route-names';
import {
    addDealerAuthGuard,
    addDealerEntryParametersGuard
} from './dealer/dealer-routing-patcher';
import { ErrorComponent } from './error/error.component';
import { RegionSelectorGuard } from './settings/region-selector/region-selector-guard';

const REGION_CHILDREN: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: ROUTE_MODEL_SELECT
    },
    {
        path: ROUTE_MODEL_SELECT,
        canActivate: [RegionSelectorGuard],
        loadChildren: () =>
            import('./model-select/model-select.module').then(
                (m) => m.ModelSelectModule
            ),
        data: {
            animation: 'MODEL_SELECTION'
        }
    }
];

// determine the configuration-route in effect
REGION_CHILDREN.push(...SCOPE_DEPENDENT_REGION_CHILD_ROUTES);

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'INT/model-select'
    },
    {
        path: ROUTE_ERROR,
        component: ErrorComponent
    },
    {
        path: ROUTE_CONFIGURATION_SESSION,
        loadChildren: () =>
            import('./one2one/client/one2one-client.module').then(
                (m) => m.One2oneClientModule
            ),

        data: {
            animation: 'CONFIGURATION_SESSION'
        }
    },
    {
        path: PARAMETER_REGION,
        children: REGION_CHILDREN
    },
    {
        path: '**',
        redirectTo: 'INT/model-select'
    }
];

// for dealers or if authentication should be forced, add auth-guard declarations and additional routes
if (
    environment.appConfig.dealer.dealerBuild ||
    environment.appConfig.dealer.forceAuthentication
) {
    addDealerAuthGuard(routes, (route) => route.path !== ROUTE_ERROR);

    if (environment.appConfig.dealer.dealerBuild) {
        addDealerEntryParametersGuard(routes);
    }
}

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledNonBlocking'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
