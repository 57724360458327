import { PricingType } from '@mhp/aml-ui-shared-services';
import { createAction, props } from '@ngrx/store';

import { DealerInfo, PricingSelectionSource } from '../dealer-state.interface';

/**
 * Set the dealers info when logged in.
 */
export const setDealerInfo = createAction(
    '[LOCAL-STATE]: setDealerInfo',
    props<{ dealerInfo: DealerInfo }>()
);

/**
 * Unset the dealers info when logged out.
 */
export const resetDealerInfo = createAction('[LOCAL-STATE]: resetDealerInfo');

/**
 * Set the pricing-type to be shown
 */
export const setPricingType = createAction(
    '[LOCAL-STATE]: setPricingType',
    props<{ pricingType: PricingType; settingSource: PricingSelectionSource }>()
);
