import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { TrackGA4EventDirective } from './google-tag-manager-ga4.directive';
import { TrackEventDirective } from './google-tag-manager.directive';
import { GoogleTagManagerService } from './google-tag-manager.service';
import { GOOGLE_TAG_MANAGER_TRACKING_STRATEGY_TOKEN } from './google-tag-manager.token';
import {
    GOOGLE_TAG_MANAGER_DEFAULT_TRACKING_STRATEGY_CONFIG,
    GoogleTagManagerDefaultTrackingStrategy,
    GoogleTagManagerDefaultTrackingStrategyConfig
} from './strategies/google-tag-manager-default-tracking-strategy';

@NgModule({
    imports: [CommonModule],
    declarations: [TrackEventDirective, TrackGA4EventDirective],
    exports: [TrackEventDirective, TrackGA4EventDirective]
})
export class GoogleTagManagerModule {
    /**
     * @param config Optional config in case the default tracking strategy should be used (see GoogleTagManagerDefaultTrackingStrategy)
     */
    static forRoot(
        config?: GoogleTagManagerDefaultTrackingStrategyConfig
    ): ModuleWithProviders<GoogleTagManagerModule> {
        const providers = config
            ? [
                  {
                      provide: GOOGLE_TAG_MANAGER_TRACKING_STRATEGY_TOKEN,
                      useClass: GoogleTagManagerDefaultTrackingStrategy
                  },
                  {
                      provide:
                          GOOGLE_TAG_MANAGER_DEFAULT_TRACKING_STRATEGY_CONFIG,
                      useValue: config
                  }
              ]
            : [];

        return {
            ngModule: GoogleTagManagerModule,
            providers
        };
    }

    constructor(
        // eager init
        googleTagManagerService: GoogleTagManagerService
        // eslint-disable-next-line @typescript-eslint/no-empty-function
    ) {}
}
