import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { UiImageSrcsetModule } from '../common';
import { UiIconsModule } from '../ui-icons';
import { UiLazyloadImageModule } from '../ui-lazyload-image';
import { UiTooltipModule } from '../ui-tooltip';
import { UiImgComparisonSliderComponent } from './ui-img-comparison-slider.component';

@NgModule({
    declarations: [UiImgComparisonSliderComponent],
    imports: [
        CommonModule,
        UiImageSrcsetModule,
        NgOptimizedImage,
        UiLazyloadImageModule,
        UiIconsModule,
        UiTooltipModule
    ],
    exports: [UiImgComparisonSliderComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UiImgComparisonSliderModule {}
