import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { FeatureAvailabilityService } from './feature-availability.service';

@NgModule({
    declarations: [],
    imports: [CommonModule]
})
export class FeatureAvailabilityModule {
    static forRoot(): ModuleWithProviders<FeatureAvailabilityModule> {
        return {
            ngModule: FeatureAvailabilityModule,
            providers: [FeatureAvailabilityService]
        };
    }
}
