<ng-container>
    <div class="header-bar__area-left">
        <ng-content select="[header-bar_area-left]"></ng-content>
    </div>
    <div class="header-bar__area-center">
        <div
            class="header-bar__aml-logo"
            [ngClass]="{
                'header-bar__aml-logo--hidden': hideLogo
            }"
            #logoContainer
        ></div>
    </div>
    <div class="header-bar__area-right">
        <ng-content select="[header-bar_area-right]"></ng-content>
    </div>
</ng-container>
