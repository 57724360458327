<mat-icon
    class="ui-icon"
    [svgIcon]="svgIcon"
    [inline]="inline"
    [ngClass]="{
        'ui-icon--size-xxsmall': size === ICON_SIZE.XXSMALL,
        'ui-icon--size-xsmall': size === ICON_SIZE.XSMALL,
        'ui-icon--size-small': size === ICON_SIZE.SMALL,
        'ui-icon--size-medium': size === ICON_SIZE.MEDIUM,
        'ui-icon--size-large': size === ICON_SIZE.LARGE,
        'ui-icon--size-xlarge': size === ICON_SIZE.XLARGE,
        'ui-icon--inline': inline
    }"
    ><ng-content></ng-content
></mat-icon>
