import { Action, createReducer, on } from '@ngrx/store';

import {
    setActiveMenuEntry,
    setActiveMenuScope,
    setMenuActive,
    toggleMenuActive
} from '../actions/common-menu.actions';
import { MenuScope, MenuState } from '../common-menu-state.interface';

const INITIAL_STATE: MenuState = {
    menuActive: false,
    activeMenuScope: MenuScope.MODEL_SELECTION
};

const reducer = createReducer<MenuState | undefined>(
    INITIAL_STATE,
    on(toggleMenuActive, (state: MenuState) => ({
        ...state,
        menuActive: !state.menuActive
    })),
    on(setActiveMenuEntry, (state: MenuState, payload) => ({
        ...state,
        activeMenuEntry: payload.activeMenuEntry
    })),
    on(setActiveMenuScope, (state: MenuState, payload) => ({
        ...state,
        activeMenuScope: payload.activeMenuScope
    })),
    on(setMenuActive, (state: MenuState, payload) => ({
        ...state,
        menuActive: payload.menuActive,
        activeMenuEntry: payload.activeMenuEntry
    }))
);

export function commonMenuReducer(
    state: MenuState | undefined,
    action: Action
) {
    return reducer(state, action);
}
