/* eslint-disable no-underscore-dangle, max-classes-per-file */
import { isArray, isEmpty, isEqual } from 'lodash-es';
import {
    IntersectionObserverHooks,
    LAZYLOAD_IMAGE_HOOKS,
    StateChange
} from 'ng-lazyload-image';
import { Observable, asyncScheduler, of } from 'rxjs';
import { map, startWith, throttleTime } from 'rxjs/operators';

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Inject,
    InjectionToken,
    Input,
    NgZone,
    OnDestroy,
    Optional,
    Output,
    SkipSelf,
    ViewChild
} from '@angular/core';
import { IllegalStateError, MemoizeObservable } from '@mhp/common';

import { ImageSrcset, UiBaseComponent } from '../common';

export interface UiLazyloadImageComponentConfig {
    // placeholder-image url
    placeholderImageUrl: string;
}

export const UI_LAZY_LOAD_IMAGE_COMPONENT_CONFIG_TOKEN =
    new InjectionToken<UiLazyloadImageComponentConfig>(
        'UiLazyloadImageComponentConfig'
    );

export type UiLazyLoadImageLoadingState =
    | 'start-loading'
    | 'loading-succeeded'
    | 'loading-failed';

const DEFAULT_RATIO = 16 / 9;
const FADE_DURATION = 500;

interface ImageInfo {
    imageSrc: string | ImageSrcset;
    useSrcset: boolean;
}

class ImageStateManager {
    activeImageInfo: ImageInfo | undefined;

    protected activeIndex = -1;

    protected hasNext = false;

    private imageInfos: ImageInfo[] | undefined;

    setImageInfos(imageInfos: ImageInfo[] | undefined): boolean {
        if (isEqual(imageInfos, this.imageInfos)) {
            return false;
        }

        if (!imageInfos || isEmpty(imageInfos)) {
            this.clear();
        } else {
            this.imageInfos = [...imageInfos];
            this.updateActiveImageInfo(0);
        }
        return true;
    }

    getImageInfos() {
        return this.imageInfos ? [...this.imageInfos] : undefined;
    }

    clear() {
        this.activeIndex = -1;
        this.activeImageInfo = undefined;
        this.imageInfos = undefined;
        this.hasNext = false;
    }

    activateNext(): boolean {
        if (!this.imageInfos) {
            return false;
        }
        if (this.imageInfos.length - 1 > this.activeIndex) {
            // eslint-disable-next-line no-plusplus
            this.updateActiveImageInfo(++this.activeIndex);
            return true;
        }
        return false;
    }

    clone() {
        const cloned = new ImageStateManager();
        cloned.setImageInfos(this.getImageInfos());
        cloned.activeIndex = this.activeIndex;
        cloned.activeImageInfo = this.activeImageInfo;

        return cloned;
    }

    private updateActiveImageInfo(index: number) {
        if (!this.imageInfos) {
            throw new IllegalStateError('No images available');
        }
        this.activeImageInfo = this.imageInfos[index];
        this.activeIndex = index;
        this.hasNext = this.imageInfos.length - 1 > this.activeIndex;
    }
}

@Component({
    selector: 'mhp-ui-lazyload-image',
    templateUrl: './ui-lazyload-image.component.html',
    styleUrls: ['./ui-lazyload-image.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiLazyloadImageComponent
    extends UiBaseComponent
    implements AfterViewInit, OnDestroy
{
    @ViewChild('lazyloadedImage')
    lazyloadedImage?: ElementRef<HTMLImageElement>;

    /**
     * Set a fallback-url that will be used as last-resort (when all sources provided
     * via #imageUrl or #imageUrls could not be loaded)
     */
    @Input()
    set imageFallbackUrl(value: string | ImageSrcset | undefined) {
        this._imageFallbackUrl = value;
        this.useSrcsetFallbackImage = !!value && typeof value !== 'string';
    }

    get imageFallbackUrl() {
        return this._imageFallbackUrl;
    }

    /**
     * Set a single image source.
     * Internally forwards to #imageUrls
     * @param value The image source to be used.
     */
    @Input()
    set imageUrl(value: string | ImageSrcset | undefined) {
        this.imageUrls = value ? [value] : undefined;
    }

    get imageUrl(): string | ImageSrcset | undefined {
        return this.imageStateManager.activeImageInfo?.imageSrc;
    }

    /**
     * Set multiple image sources that will be tried to load until one could be successfully loaded.
     * Final fallback will be #imageFallbackUrl
     * @param value The image sources that should be tried in order.
     */
    @Input()
    set imageUrls(
        value: string | ImageSrcset | (string | ImageSrcset)[] | undefined
    ) {
        let arrayValue: (string | ImageSrcset)[] | undefined = [];
        if (value) {
            arrayValue = isArray(value) ? value : [value];
        }
        const nextPreviousImageStateManager = this.imageStateManager.clone();
        const hasChanged = this.imageStateManager.setImageInfos(
            arrayValue?.map((currentSource) => ({
                imageSrc: currentSource,
                useSrcset: typeof currentSource !== 'string'
            }))
        );

        if (!hasChanged) {
            return;
        }

        // for failed image-loads, do not update previousImageUrl
        if (this._loadingState === 'loading-succeeded') {
            this.previousImageStateManager = nextPreviousImageStateManager;
        }

        if (this.clearPreviousImageOnSourceChange) {
            this.lazyloadedImage?.nativeElement.removeAttribute('srcset');
            this.lazyloadedImage?.nativeElement.removeAttribute('src');

            this.previousImageStateManager = undefined;
        }
    }

    @Input()
    title: string;

    @Input()
    aspectRatio?: number;

    @Input()
    imageFit: 'cover' | 'contain' = 'cover';

    @Input()
    willResize = false;

    @Input()
    forceStopImageReload = false;

    @Input()
    clearPreviousImageOnSourceChange = false;

    @Input()
    forceHidePreviousImage = false;

    @Input()
    srcsetSize = '0';

    @Output()
    readonly stateChange = new EventEmitter<UiLazyLoadImageLoadingState>();

    get useSrcset() {
        return this.imageStateManager.activeImageInfo?.useSrcset;
    }

    get useSrcsetPreviousImage() {
        return this.previousImageStateManager?.activeImageInfo?.useSrcset;
    }

    @HostBinding('class.lazyload-image--loading')
    get isLoading() {
        return this._loadingState === 'start-loading';
    }

    @HostBinding('class.lazyload-image--loaded')
    get loadingSuccess() {
        return this._loadingState === 'loading-succeeded';
    }

    @HostBinding('class.lazyload-image--loading-failed')
    get loadingFailed() {
        return this._loadingState === 'loading-failed';
    }

    @HostBinding('style.paddingTop')
    get paddingTop() {
        if (!this.aspectRatio) {
            return DEFAULT_RATIO;
        }
        return `${100 / this.aspectRatio}%`;
    }

    @HostBinding('class.lazyload-image--image-cover')
    get imageFitCover() {
        return this.imageFit === 'cover';
    }

    @HostBinding('class.lazyload-image--image-contain')
    get imageFitContain() {
        return this.imageFit === 'contain';
    }

    get previousImageUrl(): string | ImageSrcset | undefined {
        return this.previousImageStateManager?.activeImageInfo?.imageSrc;
    }

    useSrcsetFallbackImage: boolean;

    private readonly imageStateManager = new ImageStateManager();

    private previousImageStateManager: ImageStateManager | undefined;

    private _imageFallbackUrl?: string | ImageSrcset;

    private _loadingState: UiLazyLoadImageLoadingState;

    constructor(
        private readonly elementRef: ElementRef,
        // get a reference to the host change detector to allow update host-bindings
        @SkipSelf() private readonly hostChangeDetectorRef: ChangeDetectorRef,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly ngZone: NgZone,
        @Optional()
        @Inject(UI_LAZY_LOAD_IMAGE_COMPONENT_CONFIG_TOKEN)
        private readonly config?: UiLazyloadImageComponentConfig,
        @Inject(LAZYLOAD_IMAGE_HOOKS)
        @Optional()
        private readonly defaultImageHooks?: IntersectionObserverHooks
    ) {
        super();

        if (config?.placeholderImageUrl) {
            this.imageFallbackUrl = config.placeholderImageUrl;
        }
    }

    ngAfterViewInit() {
        if (this.srcsetSize === '0') {
            // determine srcset-size automatically
            if (this.willResize) {
                this.initSrcsetSizeUpdateLogic();
            } else {
                this.updateSrcsetSize();
            }
        }
    }

    onStateChanged(stateChange: StateChange) {
        const { reason } = stateChange;

        switch (reason) {
            case 'start-loading':
            case 'loading-succeeded':
            case 'loading-failed':
                this.ngZone.run(() => {
                    this._loadingState = reason;
                    this.stateChange.emit(this._loadingState);

                    if (reason === 'loading-failed') {
                        if (!this.imageStateManager.activateNext()) {
                            this.previousImageStateManager = undefined;
                        }
                    }

                    if (reason === 'loading-succeeded') {
                        setTimeout(() => {
                            this.previousImageStateManager =
                                this.imageStateManager.clone();
                            this.changeDetectorRef.detectChanges();
                        }, FADE_DURATION);
                    }

                    this.hostChangeDetectorRef.detectChanges();
                });
                break;
            default:
                break;
        }
    }

    @MemoizeObservable()
    getObservableHook$(): Observable<
        Observable<{ isIntersecting: boolean }> | undefined
    > {
        return this.observeProperty<UiLazyloadImageComponent, boolean>(
            'forceStopImageReload',
            true
        ).pipe(
            map((forceStopImageReload) => {
                if (!forceStopImageReload) {
                    return undefined;
                }

                return of({
                    isIntersecting: false
                });
            })
        );
    }

    private initSrcsetSizeUpdateLogic() {
        new Observable<void>((subscriber) => {
            const resizeObserver = new ResizeObserver(() => {
                subscriber.next(undefined);
            });

            resizeObserver.observe(this.elementRef.nativeElement);

            return () => {
                resizeObserver.disconnect();
            };
        })
            .pipe(
                throttleTime(200, asyncScheduler, {
                    trailing: true
                }),
                startWith(undefined),
                this.takeUntilDestroy()
            )
            .subscribe(() => {
                this.updateSrcsetSize();
            });
    }

    private updateSrcsetSize() {
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                this.srcsetSize = `${Math.round(
                    this.elementRef.nativeElement.getBoundingClientRect().width
                )}px`;
                this.changeDetectorRef.detectChanges();
            });
        });
    }
}
