import { Observable, concat, merge } from 'rxjs';
import { distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { I18nSettings, I18nSettingsManager } from '@mhp/ui-shared-services';
import { StorageMap } from '@ngx-pwa/local-storage';

const STORAGE_KEY_LANGUAGE = 'AML_LANGUAGE';

/**
 * I18nSettingsManager implementation persisting settings in local state.
 */
@Injectable()
export class I18nLocalStateSettingsManager
    implements I18nSettingsManager<I18nSettings>
{
    constructor(private readonly storageMap: StorageMap) {}

    loadSettings$(): Observable<I18nSettings | undefined> {
        return this.getActiveLanguage$().pipe(
            map((language) => ({
                language
            }))
        );
    }

    persistSettings(settings: Partial<I18nSettings>): void {
        this.loadSettings$()
            .pipe(
                first(),
                switchMap((currentSettings) => {
                    const storageCalls: Observable<void>[] = [];
                    if (
                        settings?.language &&
                        currentSettings?.language !== settings.language
                    ) {
                        storageCalls.push(
                            this.storageMap.set(
                                STORAGE_KEY_LANGUAGE,
                                settings.language,
                                {
                                    type: 'string'
                                }
                            )
                        );
                    }
                    return concat(...storageCalls);
                })
            )
            .subscribe();
    }

    /**
     * Returns the active region if set.
     */
    @MemoizeObservable()
    private getActiveLanguage$(): Observable<string | undefined> {
        return merge(
            this.storageMap.get(STORAGE_KEY_LANGUAGE, { type: 'string' }),
            this.storageMap.watch(STORAGE_KEY_LANGUAGE, { type: 'string' })
        ).pipe(distinctUntilChanged(), lazyShareReplay());
    }
}
