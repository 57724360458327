import { isEmpty } from 'lodash-es';
import { BehaviorSubject, Observable, merge, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { MemoizeObservable, lazyShareReplay } from '@mhp/common';

export interface ActivityReport {
    type: string;
}

/**
 * An ActivityHandler allows registering multiple activity-providers
 * and combines their results into one stream.
 */
export class ActivityHandler {
    private readonly activityProviderSubject = new BehaviorSubject<
        Observable<ActivityReport>[] | undefined
    >(undefined);

    destroy() {
        this.activityProviderSubject.next(undefined);
        this.activityProviderSubject.complete();
    }

    /**
     * Returns a stream of activities reported by registered activity providers.
     */
    @MemoizeObservable()
    getActivityStream$(): Observable<ActivityReport | undefined> {
        return this.activityProviderSubject.pipe(
            switchMap((providers) => {
                if (!providers || isEmpty(providers)) {
                    return of(undefined);
                }
                return merge(...providers);
            }),
            lazyShareReplay()
        );
    }

    /**
     * Register an activity provider that emits ActivityReports.
     * @param activityProvider
     * @return Function callback to be called to unregister the registered provider.
     */
    registerActivityProvider(activityProvider: Observable<ActivityReport>) {
        this.activityProviderSubject.next([
            ...(this.activityProviderSubject.value || []),
            activityProvider
        ]);
        return () => {
            this.activityProviderSubject.next(
                this.activityProviderSubject.value?.filter(
                    (provider) => provider !== activityProvider
                )
            );
        };
    }
}
