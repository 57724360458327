import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@jsverse/transloco';

/**
 * Uses a given translation callback to translate a given key
 * and uses a given default value (or the input key) as a fallback,
 * when there is no translation available for the given key.
 */
@Pipe({
    name: 'translateFallback',
    pure: true
})
export class TranslateFallbackPipe implements PipeTransform {
    transform(
        translationKey: string,
        translocoCallback: (
            key: string,
            parameters?: any
        ) => string | undefined = translate,
        defaultValue?: string,
        parameters?: any
    ): string {
        const translatedString = translocoCallback(translationKey, parameters);

        if (!translatedString || translatedString.endsWith(translationKey)) {
            return defaultValue || translationKey;
        }

        return translatedString;
    }
}
