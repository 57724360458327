import { Observable } from 'rxjs';

import { InjectionToken } from '@angular/core';
import { Animation } from '@mhp-immersive-exp/contracts/src/animation/animation.interface';
import { GetCamerasResponsePayload } from '@mhp-immersive-exp/contracts/src/camera/camera.interface';
import { Cinematic } from '@mhp-immersive-exp/contracts/src/cinematic/cinematic.interface';
import { Environment } from '@mhp-immersive-exp/contracts/src/environment/environment.interface';
import { OptionMetadata } from '@mhp-immersive-exp/contracts/src/generic/metadata-aware.interface';
import { Highlight } from '@mhp-immersive-exp/contracts/src/highlight/highlight.interface';
import { ProductAlternative } from '@mhp-immersive-exp/contracts/src/product/products-file.interface';

import { StrategyProvider } from '../strategy';

/**
 * Defines the communication-interface to trigger certain actions that are dependent on the backend the application
 * is currently talking to.
 */
export interface ProductDataStrategy<
    CameraMeta extends OptionMetadata = OptionMetadata,
    EnvironmentMeta extends OptionMetadata = OptionMetadata
> {
    /*
     * Products
     */

    /**
     * Get a list of available products from the backend.
     * @return Observable stream emitting the current set of available products. It's not guaranteed that the stream completes after the first emission.
     */
    getAvailableProducts$(): Observable<string[]>;

    /*
     * Preconfigured products
     */

    /**
     * Get a list of available product alternatives from the backend.
     * @param productId The productId to be used as context.
     * @return Observable stream emitting the current set of available product alternatives. It's not guaranteed that the stream completes after the first emission.
     */
    getAvailableProductAlternatives$(
        productId: string
    ): Observable<ProductAlternative[] | undefined>;

    /*
     * Environments
     */

    /**
     * Get a list of available environments from the backend.
     * @param productId The productId to be used as context.
     * @return Observable stream emitting the current set of available environments. It's not guaranteed that the stream completes after the first emission.
     */
    getAvailableEnvironments$(
        productId: string
    ): Observable<Environment<EnvironmentMeta>[]>;

    /**
     * Get the default environment for the given productId.
     * @param productId The productId to fetch the default environment for.
     * @return Observable stream emitting the current default environment if defined.
     */
    getDefaultEnvironment$(productId: string): Observable<Environment<EnvironmentMeta> | undefined>;

    /*
     * Cameras / Beautyshots
     */

    /**
     * Get a list of available cameras from the backend.
     * @param productId The productId to be used as context.
     * @param options Options that can be used to customize behavior.
     * @return Observable stream emitting the current set of available cameras. It's not guaranteed that the stream completes after the first emission.
     */
    getAvailableCameras$<O extends Record<string, unknown>>(
        productId: string,
        options?: O
    ): Observable<GetCamerasResponsePayload<CameraMeta> | undefined>;

    /*
     * Animations
     */

    /**
     * Get a list of available animations from the backend.
     * @param productId The productId to be used as context.
     * @return Observable stream emitting the current set of available animations. It's not guaranteed that the stream completes after the first emission.
     */
    getAvailableAnimations$(productId: string): Observable<Animation[]>;

    /*
     * Highlights
     */

    /**
     * Get a list of available highlights from the backend.
     * @param productId The productId to be used as context.
     * @return Observable stream emitting the current set of available highlights. It's not guaranteed that the stream completes after the first emission.
     */
    getAvailableHighlights$(productId: string): Observable<Highlight[]>;

    /*
     * Cinematics
     */

    /**
     * Get a list of available cinematics from the backend.
     * @param productId The productId to be used as context.
     * @return Observable stream emitting the current set of available cinematics. It's not guaranteed that the stream completes after the first emission.
     */
    getAvailableCinematics$(productId: string): Observable<Cinematic[]>;
}

export const PRODUCT_DATA_STRATEGY_PROVIDER_TOKEN = new InjectionToken<
    StrategyProvider<ProductDataStrategy>
>('Provider for ProductDataStrategy');
