import { Action, createReducer, on } from '@ngrx/store';

import { StreamStatus, StreamingState } from "..";
import { setEngineHostUrl, setStreamStatus } from '../actions';

const INITIAL_STATE: StreamingState = {
    engineHostUrl: undefined,
    streamStatus: StreamStatus.DISCONNECTED
};

const reducer = createReducer<StreamingState | undefined>(
    INITIAL_STATE,
    on(setStreamStatus, (state: StreamingState, payload) => ({
            ...state,
            streamStatus: payload.status
        })),
    on(setEngineHostUrl, (state: StreamingState, payload) => ({
            ...state,
            engineHostUrl: payload.engineHostUrl
        }))
);

export function streamingStateReducer(
    state: StreamingState | undefined,
    action: Action
) {
    return reducer(state, action);
}
