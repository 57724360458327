<ng-template #uiExpansionPanelPrefix>
    <div
        mhpUiStickyHeaderIntersectionMarker
        (elementRef)="onPanelIntersectionMarkerCreate($event)"
        class="sticky-header-expansion-panel-intersection-marker"
    ></div>
</ng-template>
<ng-template #uiExpansionPanelHeaderPrefix>
    <div
        mhpUiStickyHeaderIntersectionMarker
        (elementRef)="onPanelHeaderIntersectionMarkerCreate($event)"
        class="sticky-header-expansion-panel-header-intersection-marker"
    ></div>
</ng-template>
<ng-content></ng-content>
