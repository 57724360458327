import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import { ApplicationStateService } from '@mhp/ui-shared-services';

import { LocalApplicationState } from '../state/local-application-state.interface';
import {
    DEALER_HOOKS_TOKEN,
    DealerHooks
} from './dealer-hooks/dealer-hooks.interface';

/**
 * Guard being responsible to resolve parameters related to dealer functionality.
 */
@Injectable({ providedIn: 'root' })
export class DealerEntryParametersGuard {
    constructor(
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        @Inject(DEALER_HOOKS_TOKEN)
        private readonly dealerHooksService: DealerHooks
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        return this.dealerHooksService
            .handleDealerStartsSession$(route)
            .pipe(map(() => true));
    }
}
