import { pick, without } from 'lodash-es';

import { Inject, Injectable, InjectionToken } from '@angular/core';
import { SerializableSessionInfo } from '@mhp-immersive-exp/contracts/src';
import { IllegalStateError } from '@mhp/common';
import { SerializerService } from '@mhp/ui-shared-services';

import { EngineSessionData } from './engine-session-data.interface';

export declare type CompleteLocationSessionData = Omit<
    EngineSessionData,
    'country'
>;

export declare type PartialLocationSessionData =
    Partial<CompleteLocationSessionData>;

declare type KeyOfLocationSessionData = Extract<
    keyof CompleteLocationSessionData,
    string
>;

declare type KeyOfSerializableSessionInfo = Extract<
    keyof SerializableSessionInfo,
    string
>;

export interface SessionUrlServiceConfig {
    dealerBaseUrl: string;
    configurationRouteSegmentName: string;
}

export const SESSION_URL_SERVICE_CONFIG_TOKEN =
    new InjectionToken<SessionUrlServiceConfig>('SessionUrlServiceConfig');

@Injectable({
    providedIn: 'root'
})
export class SessionUrlService {
    constructor(
        private readonly serializerService: SerializerService,
        @Inject(SESSION_URL_SERVICE_CONFIG_TOKEN)
        private readonly config: SessionUrlServiceConfig
    ) {}

    getDealerUrlToConfiguration(sessionData?: EngineSessionData) {
        const pathname = sessionData
            ? this.buildConfigurationPathname(sessionData)
            : '';
        return `${this.config.dealerBaseUrl}${pathname}`;
    }

    /**
     * Construct the pathname part for a given CountryAwareSerializableSessionInfo
     * @param sessionInfo The session info to construct the URL for.
     */
    buildConfigurationPathname(sessionInfo: EngineSessionData): string {
        return `/${this.buildUrlCommands(sessionInfo).join('/')}`;
    }

    getSerializedLocationEngineSessionData(
        sessionData: Partial<CompleteLocationSessionData>
    ) {
        return this.serializerService.serializeData<
            Partial<CompleteLocationSessionData>
        >({
            config: sessionData.config,
            camera: sessionData.camera,
            environment: sessionData.environment,
            animations: sessionData.animations
            // skip watermarking here as it's of no use in the location-state
        });
    }

    getDeserializedPartialLocationEngineSessionData(
        serializedSessionData: string
    ) {
        const deserializedData = this.serializerService.deserializeData<object>(
            serializedSessionData
        );

        try {
            // check if we have a v2 payload here
            const v2SessionData =
                this.interpretAsV2SessionData(deserializedData);
            return {
                ...pick<SerializableSessionInfo>(
                    v2SessionData,
                    'camera',
                    'environment',
                    'animations'
                ),
                config: {
                    ...v2SessionData.product
                }
            };
        } catch {
            // cannot be interpreted as V2 input, check V1 compatibility
            const keys = Object.keys(deserializedData);
            const remainingKeys = without(
                keys,
                'config' satisfies KeyOfLocationSessionData,
                'camera' satisfies KeyOfLocationSessionData,
                'environment' satisfies KeyOfLocationSessionData,
                'animations' satisfies KeyOfLocationSessionData
                // skip watermarking here as it shouldn't be part of the location state
            );

            if (remainingKeys.length > 0) {
                throw new IllegalStateError(
                    'Seems we are deserializing something other than PartialLocationSessionData',
                    deserializedData
                );
            }
        }

        return deserializedData as PartialLocationSessionData;
    }

    buildUrlCommands(
        sessionInfo: Partial<EngineSessionData> & {
            country: string;
            config: {
                id: string;
            };
        }
    ) {
        return [
            sessionInfo.country,
            this.config.configurationRouteSegmentName,
            sessionInfo.config.id,
            this.getSerializedLocationEngineSessionData(sessionInfo)
        ];
    }

    private interpretAsV2SessionData(
        deserializedData: object
    ): SerializableSessionInfo {
        const keys = Object.keys(deserializedData);

        const remainingKeys = without(
            keys,
            'product' satisfies KeyOfSerializableSessionInfo,
            'camera' satisfies KeyOfLocationSessionData,
            'environment' satisfies KeyOfLocationSessionData,
            'animations' satisfies KeyOfLocationSessionData
            // skip watermarking here as it shouldn't be part of the location state
        );

        if (remainingKeys.length > 0) {
            throw new IllegalStateError(
                'Seems we are deserializing something other than SerializableSessionInfo',
                deserializedData
            );
        }

        return deserializedData as SerializableSessionInfo;
    }
}
