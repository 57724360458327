import { NgZone } from '@angular/core';

import { IllegalStateError } from '../errors';

export interface NgZoneAware {
    ngZone: NgZone;
}

/*
 * Decorator function that runs the given function inside ngZone using ngZone.run()
 */
export function RunInZone<T extends NgZoneAware>() {
    return function (
        target: NgZoneAware,
        propertyKey: string,
        descriptor: PropertyDescriptor
    ) {
        const oldFunction = descriptor.value;
        descriptor.value = function (...args: any[]) {
            if (!(<T>this).ngZone) {
                throw new IllegalStateError(
                    'RunInZone requires ngZone as instance field. Inject using private readonly ngZone: NgZone'
                );
            }
            return (<T>this).ngZone.run(() => oldFunction.apply(this, args));
        };
    };
}
