import { MarkdownService } from 'ngx-markdown';
import { Observable } from 'rxjs';
import { filter, first, map, switchMap, take, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { translate } from '@jsverse/transloco';
import {
    CommonDialogsService,
    DialogButtonType,
    L10nService
} from '@mhp/ui-shared-services';

import { OneTrustService } from './one-trust/one-trust.service';

@Injectable({
    providedIn: 'root'
})
export class CookieService {
    constructor(
        private readonly commonDialogService: CommonDialogsService,
        private readonly markdown: MarkdownService,
        private readonly l10nService: L10nService,
        private readonly oneTrustService: OneTrustService
    ) {}

    /**
     * Show the cookie policy
     */
    showCookiePolicy$(): Observable<void> {
        return this.l10nService.getActiveCountry$().pipe(
            first(),
            switchMap((activeCountry) => {
                const cookieMarkdownRaw = `<div id="ot-sdk-cookie-policy"></div>`;

                const dialogResult$ = this.commonDialogService
                    .openAdvancedConfirmDialog$(
                        ' ',
                        cookieMarkdownRaw,
                        [
                            {
                                id: 'MANAGE_PREFERENCES',
                                label: translate<string>(
                                    'COOKIE_LANDING.CTA_MANAGE_PREFERENCES'
                                ),
                                type: DialogButtonType.SECONDARY
                            },
                            {
                                id: 'OK',
                                label: translate<string>('COMMON.CONFIRM'),
                                type: DialogButtonType.PRIMARY
                            }
                        ],
                        {
                            dialogOptions: {
                                width: '1100px',
                                panelClass: [
                                    'legal-text',
                                    'legal-text--cookie-policy'
                                ]
                            },
                            skipWrappingParagraph: true
                        }
                    )
                    .pipe(
                        tap((dialogResult) => {
                            if (dialogResult.result === 'MANAGE_PREFERENCES') {
                                this.oneTrustService.toggleManageCookiePreferencesDialog();
                            }
                        }),
                        filter(
                            (dialogResult) =>
                                dialogResult.result !== 'MANAGE_PREFERENCES'
                        ),
                        take(1),
                        map(() => undefined)
                    );

                // the content of the dialog needs to be initialized by the OneTrust SDK once #ot-sdk-cookie-policy is present in the DOM
                setTimeout(() => {
                    this.oneTrustService.initializeCookiePolicyHtml();
                });

                return dialogResult$;
            })
        );
    }
}
