import { MonoTypeOperatorFunction, Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { Injectable, NgModuleRef, Type } from '@angular/core';
import { AvailabilityAware } from '@mhp-immersive-exp/contracts/src/generic';
import {
    IllegalStateError,
    MemoizeObservable,
    lazyShareReplay
} from '@mhp/common';
import {
    ApplicationStateService,
    L10nService,
    gtmGA4SetDataLayerProps
} from '@mhp/ui-shared-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StorageMap } from '@ngx-pwa/local-storage';

import { environment } from '../../../environments/environment';
import { LocalApplicationState } from '../../state';
import {
    VisualizationMode,
    selectActiveVisualizationMode,
    selectCandidateVisualizationMode,
    selectCandidateVisualizationModeAck,
    selectTargetVisualizationMode,
    setActiveVisualizationMode,
    setCandidateVisualizationMode,
    setCandidateVisualizationModeAck,
    setTargetVisualizationMode
} from '../state';

export interface ModuleInfo {
    module: Type<any>;
    ngModuleRef: NgModuleRef<any>;
}

const STORAGE_KEY_TARGET_VISUALIZATION_MODE = 'AML_TARGET_VISUALIZATION_MODE';

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class VisualizationModeService {
    constructor(
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly storageMap: StorageMap,
        private readonly l10nService: L10nService
    ) {
        this.initTracking();
    }

    /**
     * Determine the VisualizationMode to be used for the current session.
     */
    determineVisualizationMode$(): Observable<VisualizationMode> {
        return combineLatest([
            this.getTargetVisualizationMode$(),
            this.getPersistedTargetVisualizationMode$(),
            this.isStreamVisualizationModeAvailable$()
        ]).pipe(
            map(
                ([
                    targetVisualizationMode,
                    persistedTargetVisualizationMode,
                    streamAvailableForPublic
                ]) => {
                    if (targetVisualizationMode) {
                        return targetVisualizationMode;
                    }
                    // if the vis-mode has been persisted and we're either running a dealer-build or the stream is enabled for public
                    if (
                        persistedTargetVisualizationMode &&
                        (environment.appConfig.dealer.dealerBuild ||
                            streamAvailableForPublic)
                    ) {
                        return persistedTargetVisualizationMode;
                    }
                    if (
                        environment.appConfig.visualization
                            ?.defaultVisualizationMode
                    ) {
                        return environment.appConfig.visualization
                            .defaultVisualizationMode;
                    }
                    throw new IllegalStateError(
                        'Could not determine visualization mode'
                    );
                }
            )
        );
    }

    /**
     * Updates the target VisualizationMode to be used when working with
     * the application.
     * @param targetMode
     * @param updateUserChoice If the given target mode should be persisted as user-choice.
     */
    setTargetVisualizationMode(
        targetMode: VisualizationMode,
        updateUserChoice = false
    ) {
        this.applicationStateService.dispatch(
            setTargetVisualizationMode({
                mode: targetMode
            })
        );

        if (updateUserChoice) {
            this.setPersistedTargetVisualizationMode$(targetMode);
        }
    }

    /**
     * Updates the active VisualizationMode to be used when working with
     * the application.
     * @param targetMode
     */
    setActiveVisualizationMode(targetMode: VisualizationMode) {
        this.applicationStateService.dispatch(
            setActiveVisualizationMode({
                mode: targetMode
            })
        );
    }

    /**
     * Updates the candidate VisualizationMode to be used when working with the application.
     * @param candidateMode
     */
    setCandidateVisualizationMode(
        candidateMode: VisualizationMode | undefined
    ) {
        this.applicationStateService.dispatch(
            setCandidateVisualizationMode({
                mode: candidateMode
            })
        );
        this.setCandidateVisualizationModeAck(undefined);
    }

    /**
     * Updates the acknowledgement state for the candidate visualization mode.
     */
    setCandidateVisualizationModeAck(ack: boolean | undefined) {
        this.applicationStateService.dispatch(
            setCandidateVisualizationModeAck({
                ack
            })
        );
    }

    /**
     * Emit the current target visualization mode: The application is trying
     * to transition into this mode but it may not be in this mode yet. See #getActiveVisualizationMode().
     */
    @MemoizeObservable()
    getTargetVisualizationMode$(): Observable<VisualizationMode | undefined> {
        return combineLatest([
            this.applicationStateService
                .getLocalState()
                .pipe(selectTargetVisualizationMode),
            this.isStreamVisualizationModeAvailable$()
        ]).pipe(
            map(
                ([
                    targetVisualizationMode,
                    isStreamVisualizationModeAvailable
                ]) => {
                    if (!targetVisualizationMode) {
                        return targetVisualizationMode;
                    }
                    return isStreamVisualizationModeAvailable
                        ? targetVisualizationMode
                        : VisualizationMode.BASIC;
                }
            ),
            lazyShareReplay()
        );
    }

    /**
     * Emit the currently active visualization mode: The application is currently
     * using this mode for visualization but it may be trying to transition
     * into another visualization mode. See #getTargetVisualizationMode()
     */
    @MemoizeObservable()
    getActiveVisualizationMode$(): Observable<VisualizationMode | undefined> {
        return this.applicationStateService
            .getLocalState()
            .pipe(selectActiveVisualizationMode);
    }

    /**
     * Emit the current candidate visualization mode: The application is currently
     * not using this mode for visualization but it is able to transition into the
     * given mode when it is acknowledged.
     */
    @MemoizeObservable()
    getCandidateVisualizationMode$(): Observable<
        VisualizationMode | undefined
    > {
        return this.applicationStateService
            .getLocalState()
            .pipe(selectCandidateVisualizationMode);
    }

    /**
     * Emit if the current candidate visualization mode has been acknowledged or not.
     */
    @MemoizeObservable()
    getCandidateVisualizationModeAck$(): Observable<boolean | undefined> {
        return this.applicationStateService
            .getLocalState()
            .pipe(selectCandidateVisualizationModeAck);
    }

    /**
     * Set the target visualization mode that a user wants to reach and which will be used when
     * coming back to the configurator after having left it.
     * @param visualizationMode The visualization mode to try to reach when reloading the application.
     */
    setPersistedTargetVisualizationMode$(
        visualizationMode: VisualizationMode
    ): Observable<void> {
        const persist$ = this.storageMap
            .set(STORAGE_KEY_TARGET_VISUALIZATION_MODE, visualizationMode, {
                type: 'string'
            })
            .pipe(lazyShareReplay());
        persist$.subscribe();
        return persist$;
    }

    /**
     * Returns the persisted target VisualizationMode (if any).
     * @return Observable<VisualizationMode | undefined>
     */
    getPersistedTargetVisualizationMode$(): Observable<
        VisualizationMode | undefined
    > {
        return this.storageMap
            .get(STORAGE_KEY_TARGET_VISUALIZATION_MODE, {
                type: 'string'
            })
            .pipe(
                map((visMode) => {
                    switch (visMode) {
                        case VisualizationMode.BASIC:
                            return VisualizationMode.BASIC;
                        case VisualizationMode.STREAM:
                            return VisualizationMode.STREAM;
                        default:
                            return undefined;
                    }
                })
            );
    }

    /**
     * Emits whether the stream-visualization mode is available for the current country.
     */
    @MemoizeObservable()
    isStreamVisualizationModeAvailable$(): Observable<boolean> {
        return this.l10nService.getActiveCountry$().pipe(
            map(
                (activeCountry) =>
                    environment.appConfig.dealer.dealerBuild ||
                    environment.appConfig.visualization.publicStreamAvailable ||
                    (activeCountry &&
                        environment.appConfig.visualization.countriesPublicStreamAvailable.includes(
                            activeCountry
                        ))
            ),
            distinctUntilChanged(),
            lazyShareReplay()
        );
    }

    /**
     * OperatorFunction that filters a given input of AvailabilityAwares based on the
     * currently active visualization mode.
     */
    filterAvailableEntities<
        T extends AvailabilityAware
    >(): MonoTypeOperatorFunction<T[]> {
        return (input) =>
            combineLatest(this.getActiveVisualizationMode$(), input).pipe(
                map(([activeVisualizationMode, availableEntitiesCandidates]) =>
                    availableEntitiesCandidates.filter((candidate) => {
                        if (!activeVisualizationMode) {
                            return false;
                        }
                        if (!candidate.availability) {
                            return true;
                        }
                        return candidate.availability === 'Stream'
                            ? activeVisualizationMode ===
                                  VisualizationMode.STREAM
                            : activeVisualizationMode ===
                                  VisualizationMode.BASIC;
                    })
                )
            );
    }

    /**
     * OperatorFunction that filters a given input of AvailabilityAware based on the
     * currently active visualization mode.
     */
    filterAvailableEntity<
        T extends AvailabilityAware
    >(): MonoTypeOperatorFunction<T> {
        return (input) =>
            input.pipe(
                map((candidate) => [candidate]),
                this.filterAvailableEntities(),
                map((filteredCandidates) => filteredCandidates[0])
            );
    }

    /**
     * Initialize tracking for the visualization mode.
     * @private
     */
    private initTracking() {
        this.getActiveVisualizationMode$()
            .pipe(untilDestroyed(this))
            .subscribe((activeVisualizationMode) => {
                gtmGA4SetDataLayerProps({
                    immersive_experience_status:
                        activeVisualizationMode === VisualizationMode.STREAM
                            ? 'active'
                            : 'inactive'
                });
            });
    }
}
