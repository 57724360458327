import { I18nConfiguration } from '@mhp/ui-shared-services';

import { environment } from '../../environments/environment';

export const AML_I18N_CONFIG = (): I18nConfiguration => ({
    defaultLang: 'en',
    fallbackLang: 'en',
    isProdMode: environment.production,
    availableLangs: [
        {
            id: 'en',
            label: 'english'
        },
        {
            id: 'de',
            label: 'deutsch'
        },
        {
            id: 'fr',
            label: 'français'
        },
        {
            id: 'ja',
            label: '日本語'
        },
        {
            id: 'zh',
            label: '中文'
        }
    ],
    translationFileUrlFactory:
        environment.appConfig.i18n.translationFileUrlFactory
});
