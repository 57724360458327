import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';

/**
 * This extension of the regular CDK Platform service allows an additional check
 * for IPAD_OS.
 */
@Injectable({
    providedIn: 'root'
})
export class ExtendedPlatform extends Platform {
    IPAD_OS = navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
}
