import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { IllegalStateError, MemoizeObservable } from '@mhp/common';

export interface UrlShortenerServiceConfig {
    baseUrl: string;
}

export const URL_SHORTENER_SERVICE_CONFIG_TOKEN =
    new InjectionToken<UrlShortenerServiceConfig>('UrlShortenerServiceConfig');

/**
 * Provides logic to create shortened URLs.
 */
@Injectable({
    providedIn: 'root'
})
export class UrlShortenerService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(URL_SHORTENER_SERVICE_CONFIG_TOKEN)
        private readonly config: UrlShortenerServiceConfig
    ) {}

    /**
     * Shortens the given URL using the mhp-shortener service.
     * The returned observable is cached for the given input-url so
     * a previously generated url is returned for the same url input.
     *
     * @param inputUrl
     */
    @MemoizeObservable()
    shortenUrl$(inputUrl: string): Observable<string> {
        return this.httpClient
            .get<{ url: string }>(`${this.config.baseUrl}/create`, {
                params: new HttpParams().set('url', inputUrl)
            })
            .pipe(
                map(({ url }) => url),
                tap((url) => {
                    if (!url) {
                        throw new IllegalStateError(
                            'Received empty short-url from url-shortener'
                        );
                    }
                }),
                shareReplay()
            );
    }

    /**
     * Generates a short-code for the given inputUrl.
     * @param inputUrl The url to generate the short-code for.
     */
    @MemoizeObservable()
    generateShortCode$(inputUrl: string): Observable<string> {
        return this.httpClient
            .get<{ configCode: string }>(
                `${this.config.baseUrl}/createConfigCode`,
                {
                    params: new HttpParams().set('url', inputUrl)
                }
            )
            .pipe(
                map(({ configCode }) => configCode),
                tap((code) => {
                    if (!code) {
                        throw new IllegalStateError(
                            'Received empty short code from url-shortener'
                        );
                    }
                }),
                shareReplay()
            );
    }

    /**
     * Resolves a given short-code to a url.
     * @param code
     */
    resolveShortCode$(code: string): Observable<string> {
        return this.httpClient
            .get<{
                url: string;
            }>(`${this.config.baseUrl}/resolve/${code}`)
            .pipe(
                map(({ url }) => {
                    if (url === 'null') {
                        throw new IllegalStateError(
                            `Failed resolving code ${code}: got null url`
                        );
                    }
                    return url;
                })
            );
    }
}
