import { DIRECTION_ALL } from 'hammerjs';

import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';

@Injectable()
export class TouchPanelHammerConfig extends HammerGestureConfig {
    override overrides = {
        pan: {
            threshold: 5,
            direction: DIRECTION_ALL
        }
    };

    constructor() {
        super();
    }
}
