import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
    declarations: [],
    imports: [CommonModule, MatRadioModule],
    exports: [MatRadioModule]
})
export class UiRadioModule {}
