import { Observable } from 'rxjs';

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';

import { RecaptchaConnectionError } from './errors/errors';

@Injectable({
    providedIn: 'root'
})
export class RecaptchaErrorHandlerService {
    constructor(@Inject(DOCUMENT) private readonly document: Document) {}

    /**
     * Observes changes in the body-children (non-recursive) to capture the addition
     * of the recaptcha hint "Could not connect to the reCAPTCHA service. Please check your internet connection and reload to get a reCAPTCHA challenge."
     * Unfortunately this is not caught by the recaptcha-error-handler so we have to do it this way.
     * See https://mhpimmersive.atlassian.net/browse/AMR-519
     */
    @MemoizeObservable()
    startRecaptchaConnectionErrorHandling$(): Observable<RecaptchaConnectionError> {
        return new Observable<RecaptchaConnectionError>((subscriber) => {
            const mutationObserver = new MutationObserver((mutationRecords) => {
                mutationRecords.forEach((mutationRecord) => {
                    if (mutationRecord.type !== 'childList') {
                        return;
                    }
                    if (mutationRecord.addedNodes.length !== 1) {
                        return;
                    }
                    if (
                        mutationRecord.addedNodes[0].textContent?.startsWith(
                            'Could not connect to the reCAPTCHA service.'
                        )
                    ) {
                        subscriber.next(
                            new RecaptchaConnectionError(
                                mutationRecord.addedNodes[0].textContent
                            )
                        );

                        mutationObserver.disconnect();
                        subscriber.complete();

                        // remove node afterwards
                        document.body.removeChild(mutationRecord.addedNodes[0]);
                    }
                });
            });
            mutationObserver.observe(document.body, {
                childList: true,
                subtree: false
            });

            return () => {
                mutationObserver.disconnect();
            };
        }).pipe(lazyShareReplay());
    }
}
