import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UiHotspotsComponent } from './ui-hotspots.component';

@NgModule({
    declarations: [UiHotspotsComponent],
    exports: [UiHotspotsComponent],
    imports: [CommonModule]
})
export class UiHotspotsModule {}
