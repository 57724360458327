import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UiImageSrcsetPipe } from './ui-image-srcset.pipe';

@NgModule({
    declarations: [UiImageSrcsetPipe],
    exports: [UiImageSrcsetPipe],
    imports: [CommonModule]
})
export class UiImageSrcsetModule {}
