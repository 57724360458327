import { BehaviorSubject, ReplaySubject, filter, take } from 'rxjs';

import { DOCUMENT } from '@angular/common';
import {
    Inject,
    Injectable,
    InjectionToken,
    Renderer2,
    RendererFactory2
} from '@angular/core';
import { IllegalStateError } from '@mhp/common';
import { I18nService } from '@mhp/ui-shared-services';

export interface OneTrustServiceConfig {
    loadAutoBlockScript: boolean;
    domainScriptId: string;
}

export const ONE_TRUST_SERVICE_CONFIG_TOKEN =
    new InjectionToken<OneTrustServiceConfig>('ONE_TRUST_SERVICE_CONFIG_TOKEN');

@Injectable({
    providedIn: 'root'
})
export class OneTrustService {
    private initialized = false;

    private readonly renderer: Renderer2;

    private readonly onReadySubject = new BehaviorSubject<boolean>(false);

    private readonly consentChangedSubject = new ReplaySubject<
        Set<'C0001' | 'C0002' | 'C0003' | 'C0004' | 'C0005'>
    >(1);

    constructor(
        rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private readonly document: Document,
        @Inject(ONE_TRUST_SERVICE_CONFIG_TOKEN)
        private readonly config: OneTrustServiceConfig,
        private readonly i18nService: I18nService
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);

        this.initConsentCategoryBinding();
    }

    /**
     * Initializes the one-trust cookie consent script.
     * This needs to be called only once.
     */
    initOneTrustCookieConsentScript() {
        if (this.initialized) {
            throw new IllegalStateError('OneTrust script already loaded');
        }

        if (this.config.loadAutoBlockScript) {
            const oneTrustAutoBlockScript =
                this.renderer.createElement('script');
            this.renderer.setAttribute(
                oneTrustAutoBlockScript,
                'src',
                `https://cdn-ukwest.onetrust.com/consent/${this.config.domainScriptId}/OtAutoBlock.js`
            );
            this.renderer.setAttribute(
                oneTrustAutoBlockScript,
                'type',
                'text/javascript'
            );
            this.renderer.appendChild(
                this.document.head,
                oneTrustAutoBlockScript
            );
        }

        const oneTrustSDKStubScript = this.renderer.createElement('script');
        this.renderer.setAttribute(
            oneTrustSDKStubScript,
            'src',
            'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js'
        );
        this.renderer.setAttribute(
            oneTrustSDKStubScript,
            'data-domain-script',
            this.config.domainScriptId
        );
        this.renderer.setAttribute(
            oneTrustSDKStubScript,
            'type',
            'text/javascript'
        );
        this.renderer.setAttribute(oneTrustSDKStubScript, 'charset', 'UTF-8');
        this.renderer.appendChild(this.document.head, oneTrustSDKStubScript);

        const oneTrustSDKStubScript2 = this.renderer.createElement('script');
        const text = this.renderer.createText(`
        if (!window.oneTrust) window.oneTrust = {};
        function OptanonWrapper() {
            window.oneTrust.onReady();
        }`);
        this.renderer.appendChild(oneTrustSDKStubScript2, text);
        this.renderer.appendChild(this.document.head, oneTrustSDKStubScript2);

        // patch appearance of "manage cookies" layer
        const oneTrustCssPatch = this.renderer.createElement('style');
        this.renderer.appendChild(
            oneTrustCssPatch,
            this.renderer.createText(`
        .onetrust-pc-dark-filter {
            backdrop-filter: blur(40px);
        }
        `)
        );
        this.renderer.appendChild(this.document.head, oneTrustCssPatch);

        // register onReady callback
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (<any>window).oneTrust.onReady = () => this.onReadySubject.next(true);

        this.initLanguageBinding();
    }

    toggleManageCookiePreferencesDialog() {
        this.getOneTrustSDK()?.ToggleInfoDisplay();
    }

    changeLanguage(lang: string) {
        this.getOneTrustSDK()?.changeLanguage(lang);
    }

    /**
     * Call this when an element with id 'ot-sdk-cookie-policy' is present in the DOM to show the cookie-list and should
     * be initialized by OneTrust.
     */
    initializeCookiePolicyHtml() {
        this.getOneTrustSDK()?.initializeCookiePolicyHtml();
    }

    /**
     * Returns an observable that emits the current consent categories as soon as the user has given consent or changed his preference.
     * - C0001: Strictly Necessary Cookies
     * - C0002: Performance Cookies
     * - C0003: Functionality Cookies
     * - C0004: Targeting Cookies
     * - C0005: Social Media Cookies
     */
    getConsentCategories$() {
        return this.consentChangedSubject.asObservable();
    }

    private initLanguageBinding() {
        this.i18nService.getActiveLang$().subscribe((activeLang) => {
            this.registerOnReadyCallback(() => this.changeLanguage(activeLang));
        });
    }

    private initConsentCategoryBinding() {
        this.registerOnReadyCallback(() => {
            const activeGroups: string | undefined = (<any>window)
                .OnetrustActiveGroups;
            if (activeGroups) {
                this.consentChangedSubject.next(
                    this.interpretConsentCategory(activeGroups)
                );
            }
            this.getOneTrustSDK()?.OnConsentChanged((info) => {
                this.consentChangedSubject.next(
                    this.interpretConsentCategory(
                        (<any>window).OnetrustActiveGroups
                    )
                );
            });
        });
    }

    private interpretConsentCategory(
        consentCategory: string
    ): Set<'C0001' | 'C0002' | 'C0003' | 'C0004' | 'C0005'> {
        return new Set(
            consentCategory.split(',').filter((category) => !!category)
        ) as Set<'C0001' | 'C0002' | 'C0003' | 'C0004' | 'C0005'>;
    }

    private getOneTrustSDK():
        | {
              changeLanguage: (lang: string) => void;
              ToggleInfoDisplay: () => void;
              Init: () => void;
              initializeCookiePolicyHtml: () => void;
              OnConsentChanged: (callback: (info: unknown) => void) => void;
          }
        | undefined {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (<any>window).OneTrust;
    }

    private registerOnReadyCallback(callback: () => void) {
        this.onReadySubject
            .pipe(
                filter((ready) => ready),
                take(1)
            )
            .subscribe(callback);
    }
}
