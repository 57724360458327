import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input
} from '@angular/core';

@Component({
    selector: 'mhp-dialog-body',
    templateUrl: './dialog-body.component.html',
    styleUrls: ['./dialog-body.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogBodyComponent {
    @HostBinding('class.dialog-body--no-padding')
    @Input()
    noPadding: boolean;
}
