import { Route } from '@angular/router';

import { ROUTE_CONFIGURATION } from '../app/app-route-names';
import { NoopDealerModule } from '../app/dealer/noop-dealer.module';
import { RegionSelectorGuard } from '../app/settings/region-selector/region-selector-guard';

/**
 * Define modules that are additionally loaded in the AppModule and can be overridden based on scope (e.g. dealer, brand-store, ...)
 */
export const SCOPE_DEPENDENT_MODULES = [NoopDealerModule];

/**
 * Add scope-dependent routes that should be registered as children of the region sub-route (e.g. /DE/xxx)
 */
export const SCOPE_DEPENDENT_REGION_CHILD_ROUTES: Route[] = [
    {
        path: ROUTE_CONFIGURATION,
        canActivate: [RegionSelectorGuard],
        loadChildren: () =>
            import('../app/web-configurator/web-configurator.module').then(
                (m) => m.WebConfiguratorModule
            ),
        data: {
            animation: 'CONFIGURATION'
        }
    }
];
