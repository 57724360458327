import { Action, combineReducers } from '@ngrx/store';

import { brandStoreStateReducer } from '../../brand-store/state/reducers/brand-store.reducer';
import { loadingStateReducer } from '../../common/loading-indicator/state/reducers/loading-state.reducer';
import { salesforceStateReducer } from '../../configuration/salesforce/state/reducers/salesforce-state.reducer';
import { configurationReducer } from '../../configuration/state/reducers/configuration.reducer';
import { dealerStateReducer } from '../../dealer/state';
import { commonMenuReducer } from '../../menu/state/reducers/common-menu.reducer';
import { orderReducer } from '../../order-management/state';
import { settingsStateReducer } from '../../settings/state/reducers/settings-state.reducer';
import { LocalApplicationState } from '../local-application-state.interface';

const reducer = combineReducers({
    settings: settingsStateReducer,
    configuration: configurationReducer,
    order: orderReducer,
    menu: commonMenuReducer,
    dealer: dealerStateReducer,
    loading: loadingStateReducer,
    salesforce: salesforceStateReducer,
    brandStore: brandStoreStateReducer
});

export function localApplicationStateReducer(
    state: LocalApplicationState | undefined,
    action: Action
) {
    return reducer(state, action);
}
