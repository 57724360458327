import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProductState } from '@mhp/communication-models';

import { selectConfigurationState } from '../../../application-state';
import { enhanceSelector } from '../../../application-state/helpers';

export const selectProductState = enhanceSelector(
    pipe(
        selectConfigurationState,
        map(
            (configurationState) =>
                configurationState && configurationState.productState
        ),
        map((productState): ProductState | undefined => {
            if (!productState?.id) {
                return undefined;
            }
            // do an explicit mapping here to avoid emitting possibly unknown properties used internally by the engine
            return {
                id: productState.id,
                configOptions: productState.configOptions,
                configurationId: productState.configurationId,
                country: productState.country
            };
        })
    ),
    {
        distinctUntilChanged: true
    }
);
