import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { CanColor, ThemePalette } from '@angular/material/core';

import { UiBaseComponent } from '../common/ui-base-component/ui-base.component';
import { ImageSrcset } from '../common/ui-image-srcset/ui-image-srcset.pipe';

export interface UiGridListItem<T = string> {
    id: T;
    selected?: boolean;
    imageUrl: string | ImageSrcset | ImageSrcset[];
    imageAspectRatio?: number;
    title?: string;
    subtitle?: string;
    disabled?: boolean;
    // optional icon displayed in the top-right corner
    iconId?: string;
}

@Component({
    selector: 'mhp-ui-grid-list',
    templateUrl: './ui-grid-list.component.html',
    styleUrls: ['./ui-grid-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiGridListComponent<T = string>
    extends UiBaseComponent
    implements CanColor
{
    @Input()
    items: UiGridListItem<T>[];

    @Input()
    itemsPerRow = 3;

    @Input()
    color: ThemePalette;

    @Input()
    defaultColor: ThemePalette;

    @Input()
    imageFallbackUrl?: string;

    @Input()
    showTitleOnImageFallback = true;

    /**
     * Will the rendered items resize during runtime or will they keep the same dimension.
     * This will steer if images of higher resolution will be fetched if required.
     */
    @Input()
    willItemsResize: boolean;

    @Output()
    readonly itemSelect = new EventEmitter<UiGridListItem<T>>();

    constructor() {
        super();

        this.completeOnDestroy(this.itemSelect);
    }

    intentSelect(item: UiGridListItem<T>) {
        this.itemSelect.emit(item);
    }

    itemsTrackBy(index: number, item: UiGridListItem<T>) {
        return item.id;
    }
}
