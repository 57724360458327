import { isNil } from 'lodash-es';
import { combineLatest, delay, of, switchMap } from 'rxjs';

import { Injectable } from '@angular/core';
import { translate } from '@jsverse/transloco';
import { UiMatDialogService } from '@mhp/ui-components';
import {
    CommonDialogsService,
    EngineConnectionStateService,
    I18nService
} from '@mhp/ui-shared-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class BrandStoreConnectionStateHintService {
    constructor(
        private readonly engineConnectionStateService: EngineConnectionStateService,
        private readonly uiMatDialogService: UiMatDialogService,
        private readonly commonDialogsService: CommonDialogsService,
        private readonly i18nService: I18nService
    ) {
        this.initDisplayEngineConnectionStateChange();
    }

    private initDisplayEngineConnectionStateChange() {
        combineLatest([
            this.engineConnectionStateService.getHubConnectionState$(),
            this.engineConnectionStateService.getEngineConnectionState$(),
            this.i18nService.getActiveLang$()
        ])
            .pipe(
                switchMap(([hubConnected, engineConnected]) => {
                    if (
                        (hubConnected || isNil(hubConnected)) &&
                        (engineConnected || isNil(engineConnected))
                    ) {
                        // all fine..
                        return of({
                            hub: true,
                            engine: true
                        });
                    }

                    return of({
                        hub: !!hubConnected,
                        engine: !!engineConnected
                    }).pipe(delay(500));
                }),
                switchMap(({ hub, engine }) => {
                    if (hub && engine) {
                        return of(undefined);
                    }
                    // one of hub or engine is not connected
                    return this.commonDialogsService.openAdvancedConfirmDialog$(
                        hub
                            ? translate(
                                  'CONNECTION_STATE.ENGINE.DISCONNECTED.TITLE'
                              )
                            : translate(
                                  'CONNECTION_STATE.NETWORK_HUB.DISCONNECTED.TITLE'
                              ),
                        hub
                            ? translate(
                                  'CONNECTION_STATE.ENGINE.DISCONNECTED.DESCRIPTION'
                              )
                            : translate(
                                  'CONNECTION_STATE.NETWORK_HUB.DISCONNECTED.DESCRIPTION'
                              ),
                        [],
                        {
                            showCancel: false,
                            dialogOptions: {
                                disableClose: true,
                                backdropClass: 'mhp-ui-modal-backdrop--blurry',
                                width: '500px',
                                minWidth: '0',
                                maxWidth: 'calc(100vw - 20px)'
                            }
                        }
                    );
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }
}
