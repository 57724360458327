import { InjectionToken } from '@angular/core';

export interface UiNotificationConfig {
    defaultAutohideDuration: number;
    closeIconId: string;
}

export const UI_NOTIFICATION_CONFIG = new InjectionToken<UiNotificationConfig>(
    'UiNotificationServiceConfig'
);
