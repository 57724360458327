import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiProgressSpinnerModule } from '@mhp/ui-components';

import { HeaderBarModule } from '../../header-bar/header-bar.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { LoadingIndicatorComponent } from './loading-indicator.component';

@NgModule({
    declarations: [LoadingIndicatorComponent],
    exports: [LoadingIndicatorComponent],
    imports: [
        CommonModule,
        HeaderBarModule,
        UiProgressSpinnerModule,
        SpinnerModule
    ]
})
export class LoadingIndicatorModule {}
