import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
    declarations: [],
    imports: [CommonModule, MatFormFieldModule],
    exports: [MatFormFieldModule]
})
export class UiFormFieldModule {}
