<div class="ui-grid-list" role="list">
    <mhp-ui-grid-list-item
        *ngFor="let item of items; trackBy: itemsTrackBy; index as itemIndex"
        [imageUrl]="item.imageUrl"
        [imageFallbackUrl]="imageFallbackUrl"
        [disabled]="item.disabled"
        [imageAspectRatio]="item.imageAspectRatio"
        [selected]="item.selected"
        [title]="item.title"
        [showTitleOnImageFallback]="showTitleOnImageFallback"
        [subtitle]="item.subtitle"
        [iconId]="item.iconId"
        (intentSelect)="intentSelect(item)"
        [style.width]="100 / itemsPerRow + '%'"
        [color]="color"
        [index]="itemIndex"
        [willResize]="willItemsResize"
    ></mhp-ui-grid-list-item>
</div>
