import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import { enhanceSelector } from '@mhp/ui-shared-services';

import { LocalApplicationState } from '../../../state';

export const selectActiveOrderSearchTerm = enhanceSelector<
    LocalApplicationState,
    string | undefined
>(pipe(map((state) => state.order?.activeSearchTerm)), {
    distinctUntilChanged: true
});

export const selectSearchInputActive = enhanceSelector<
    LocalApplicationState,
    boolean
>(pipe(map((state) => state.order && state.order.searchInputActive)), {
    distinctUntilChanged: true
});

export const selectShowSearchResults = enhanceSelector<
    LocalApplicationState,
    boolean
>(
    pipe(
        map(
            (state) =>
                !state.order?.searchInputActive &&
                !!state.order?.activeSearchTerm
        )
    ),
    {
        distinctUntilChanged: true
    }
);
