import { first } from 'rxjs/operators';

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    ViewChild
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { UiBaseComponent } from '@mhp/ui-components';

@Component({
    selector: 'mhp-header-bar',
    templateUrl: './header-bar.component.html',
    styleUrls: ['./header-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderBarComponent
    extends UiBaseComponent
    implements AfterViewInit
{
    @Input()
    hideLogo: boolean;

    @ViewChild('logoContainer', {
        read: ElementRef
    })
    logoContainer: ElementRef;

    constructor(private readonly matIconRegistry: MatIconRegistry) {
        super();
    }

    ngAfterViewInit() {
        this.matIconRegistry
            .getNamedSvgIcon('logo_aml', 'mhp-ui')
            .pipe(this.takeUntilDestroy(), first())
            .subscribe((svgElement) => {
                this.logoContainer.nativeElement.appendChild(svgElement);
            });
    }
}
