import { Observable } from 'rxjs';

import { InjectionToken } from '@angular/core';
import { ConfigModel } from '@mhp-immersive-exp/contracts/src/configuration/config-model.interface';
import {
    ConfigurationResponsePayload,
    ProjectReturn
} from '@mhp-immersive-exp/contracts/src/configuration/configuration-response.interface';
import { ProjectParameters } from '@mhp-immersive-exp/contracts/src/project-parameters/project-parameters';

import { StrategyProvider } from '../strategy';

/**
 * Defines the communication-interface to get hold of information that is dependent on the backend the application
 * is currently talking to.
 */
export interface ProductConfigurationStrategy<
    P extends ProjectParameters = ProjectParameters,
    PR extends ProjectReturn = ProjectReturn
> {
    /**
     * Fetch configuration metadata from the ruler.
     * @param productId The productId to fetch the metadata for.
     * @param config Optionally an already existing configuration for the metadata to be based on.
     * @param projectParameters Optionally The projectParameters to include in the payload for the backend.
     * @param country Optionally the country that the current request relates to.
     */
    getConfigurationMetadata$(
        productId: string,
        config?: ConfigModel[] | undefined,
        projectParameters?: P,
        country?: string | undefined
    ): Observable<ConfigurationResponsePayload<PR>>;

    /**
     * Patch a configuration based on options to be added and removed.
     * @param productId The productId for the product the configuration is relevant for.
     * @param config The configuration to be patched.
     * @param country The country serving as context-data for the configuration.
     * @param add Options to be added
     * @param remove Options to be removed
     */
    patchConfiguration$(
        productId: string,
        config: ConfigModel[],
        country: string,
        add?: ConfigModel[],
        remove?: ConfigModel[]
    ): Observable<ConfigModel[]>;
}

export const PRODUCT_CONFIGURATION_STRATEGY_PROVIDER_TOKEN = new InjectionToken<
    StrategyProvider<ProductConfigurationStrategy>
>('Provider for ProductConfigurationStrategy');
