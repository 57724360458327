import { InjectionToken } from '@angular/core';
import type { StreamLimits } from '@mhp-immersive-exp/sdk/streaming/monkeyway/internal/types/stream-options.js';

import { CutStreamLimitsConfig } from './cut-event-handling/sliding-time-window-based-streaming-event-handler';
import { ExtendedStreamingEventHandlerFactory } from './cut-event-handling/streaming-event-handler.interfaces';

export interface ExtendedCutStreamLimits extends CutStreamLimitsConfig {
    handlerFactory?: ExtendedStreamingEventHandlerFactory;
}

export interface MonkeywayEnvironmentConfig {
    baseUrl: string;
    appEnvId: string;
    apiKey: string;
    streamLimits?: StreamLimits;
    cutStreamLimits?: ExtendedCutStreamLimits;
}

export const MONKEYWAY_ENVIRONMENT_CONFIG_TOKEN =
    new InjectionToken<MonkeywayEnvironmentConfig>(
        'MonkeywayEnvironmentConfig'
    );
