import {
    MARKED_OPTIONS,
    MarkdownModule as NgxMarkdownModule
} from 'ngx-markdown';

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

@NgModule({
    declarations: [],
    imports: [CommonModule, NgxMarkdownModule],
    exports: [NgxMarkdownModule]
})
export class MarkdownModule {
    static forRoot(): ModuleWithProviders<MarkdownModule> {
        return {
            ngModule: MarkdownModule,
            providers: NgxMarkdownModule.forRoot({
                markedOptions: {
                    provide: MARKED_OPTIONS,
                    useValue: {
                        gfm: true,
                        breaks: true
                    }
                }
            }).providers
        };
    }
}
