<mhp-dialog-header
    [hideTopGradient]="hideTopGradient"
    [hideLogo]="hideLogo"
    [closeImplicit]="closeImplicit"
    (dialogClose)="intentDialogClose()"
></mhp-dialog-header>

<mhp-dialog-body *ngIf="wrapInDialogBody; else noBodyWrap">
    <ng-container #componentOutlet></ng-container>
    <ng-container *ngTemplateOutlet="content"></ng-container>
</mhp-dialog-body>

<ng-template #noBodyWrap>
    <ng-container #componentOutlet></ng-container>
    <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-template>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>
