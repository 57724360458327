import { contain, cover } from 'intrinsic-scale';
import { Observable, combineLatest, map } from 'rxjs';

import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    TemplateRef
} from '@angular/core';
import { MemoizeObservable, runInZone } from '@mhp/common';

import { ResizeObservable, TRACK_BY_ID, UiBaseComponent } from '../common';
import {
    UiHotspot,
    UiHotspotEvent,
    UiHotspotEventType
} from './ui-hotspots.interface';

@Component({
    selector: 'mhp-ui-hotspots',
    templateUrl: './ui-hotspots.component.html',
    styleUrls: ['./ui-hotspots.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiHotspotsComponent<T extends UiHotspot = UiHotspot>
    extends UiBaseComponent
    implements OnInit
{
    @Input()
    referenceDimensions: { width: number; height: number } = {
        width: 16,
        height: 9
    };

    @Input()
    hotspots: UiHotspot[] = [];

    @Input()
    hotspotTemplateRef!: TemplateRef<{
        uiHotspot: T;
        uiHotspotElement: HTMLDivElement;
        index: number;
    }>;

    @Input()
    objectFit?: 'contain' | 'cover' = 'cover';

    @Input()
    assignTabIndex = true;

    @Output()
    readonly hotspotEvent = new EventEmitter<UiHotspotEvent<T> | undefined>();

    trackById = TRACK_BY_ID;

    constructor(
        private readonly elementRef: ElementRef<HTMLElement>,
        private readonly ngZone: NgZone
    ) {
        super();
    }

    ngOnInit() {
        if (!this.hotspotTemplateRef) {
            throw new Error(
                'No template reference provided for hotspot template'
            );
        }
    }

    intentSelectHotspot(
        hotspot: T,
        hotspotRef: HTMLDivElement,
        event: UIEvent
    ) {
        this.hotspotEvent.emit({
            hotspot,
            element: hotspotRef,
            type: UiHotspotEventType.SELECT
        });
    }

    onMouseEnterHotspot(
        hotspot: T,
        hotspotRef: HTMLDivElement,
        event: MouseEvent
    ) {
        this.hotspotEvent.next({
            hotspot,
            element: hotspotRef,
            type: UiHotspotEventType.MOUSE_ENTER
        });
    }

    onMouseLeaveHotspot(
        hotspot: T,
        hotspotRef: HTMLDivElement,
        event: MouseEvent
    ) {
        this.hotspotEvent.next({
            hotspot,
            element: hotspotRef,
            type: UiHotspotEventType.MOUSE_LEAVE
        });
    }

    @MemoizeObservable()
    getHotspotsWithAbsoluteCoordinates$(): Observable<T[] | undefined> {
        return combineLatest([
            this.observeProperty<UiHotspotsComponent, T[]>('hotspots', true),
            this.observeProperty<
                UiHotspotsComponent,
                { width: number; height: number }
            >('referenceDimensions'),
            this.observeProperty<UiHotspotsComponent, 'cover' | 'contain'>(
                'objectFit',
                true
            ),
            this.getHostElementSize$()
        ]).pipe(
            runInZone(this.ngZone),
            map(([hotspots, referenceDimensions, objectFit, hostElementSize]) =>
                hotspots?.map((hotspot) => {
                    const { width, height, x, y } = (
                        objectFit === 'cover' ? cover : contain
                    )(
                        hostElementSize.width,
                        hostElementSize.height,
                        referenceDimensions.width,
                        referenceDimensions.height
                    );

                    return {
                        ...hotspot,
                        x: Math.round(hotspot.x * width + x),
                        y: Math.round(hotspot.y * height + y)
                    };
                })
            )
        );
    }

    private getHostElementSize$() {
        return new ResizeObservable(this.elementRef.nativeElement);
    }
}
