import {
    isArray,
    isFunction,
    isObject,
    isUndefined,
    mergeWith
} from 'lodash-es';

const customizer = (a, b) => {
    if (isArray(a)) return a;
    if (isFunction(a)) return a;

    return isObject(a) || isUndefined(a) ? undefined : a;
};

/**
 * A replacement for the lodash `defaultsDeep` function, which does also merge arrays in an unexpected way.
 * The custom implementation has the same behavior except when handling arrays. These are now used as is and not merged or processed
 * recursively.
 * See https://stackoverflow.com/questions/75246405/customizer-for-mergewith-to-not-merge-nested-arrays for reference.
 *
 * @param args The objects to process. First argument is the target-object to which things get written, following arguments are the source-objects
 *             from which to get values from (processed from left to right).
 *             See lodash#mergeWith for reference.
 */
export const defaultsDeepCustom = (...args: object[]) => {
    return mergeWith.apply(this, [...args, customizer]);
};
